import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { TrackEventContext } from 'common/containers/EventContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { LocationContext } from 'common/containers/RouterContainer';
import connect from 'common/core/connect';
import Helmet from 'common/helmets/Helmet';
import Button from 'common/inputs/Button';
import Link from 'common/Link';
import AdminCreatePostModal from 'common/subdomain/admin/AdminCreatePostModal';
import AdminFeedbackBulkEditForm from 'common/subdomain/admin/AdminFeedbackBulkEditForm';
import Tappable from 'common/Tappable';
import mapify from 'common/util/mapify';
import stringSort from 'common/util/stringSort';
import withContexts from 'common/util/withContexts';

import AdminFeedbackMobileWrapper from './AdminFeedbackMobileWrapper';
import AdminFeedbackPost from './AdminFeedbackPost';
import AdminFeedbackPostList from './AdminFeedbackPostList';
import AdminFeedbackSidebar from './AdminFeedbackSidebar';

import 'css/components/subdomain/admin/_AdminFeedback.scss';

const DefaultSavedFilterName = 'Default';
const MobileWidth = 780;

class AdminFeedback extends Component {
  static propTypes = {
    board: PropTypes.object,
    boards: PropTypes.array,
    company: PropTypes.object,
    location: PropTypes.object,
    openModal: PropTypes.func,
    post: PropTypes.object,
    postActivity: PropTypes.object,
    postList: PropTypes.object,
    roadmaps: PropTypes.array,
    router: PropTypes.object,
    trackEvent: PropTypes.func,
  };

  state = {
    activeSavedFilter: this.getInitialActiveSavedFilter(),
    isMobile: false,
    postSectionVisible: true,
    selectingFirstPost: false,
    selectedPostsMap: {},
    showSidebar: !this.props.company?.viewerPreferences?.collapsedSidebar,
  };

  componentDidMount() {
    const {
      board,
      company: { fullScreenAdminView },
      post,
      router,
    } = this.props;
    if (!fullScreenAdminView) {
      if (board && post) {
        router.replace(`/admin/board/${board.urlName}/p/${post.urlName}`);
      } else {
        router.replace('/admin');
      }
      return;
    }

    // Prevent race with the sidebar activating the saved filter
    setTimeout(() => {
      this.selectFirstPost();
    }, 0);

    const { trackEvent } = this.props;
    trackEvent('Viewed Feedback Page');

    window.addEventListener('resize', this.onResize);

    if (document.body.clientWidth <= MobileWidth) {
      this.setState({ showSidebar: false, isMobile: true, postSectionVisible: !!post });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.activeSavedFilter === null && prevState.activeSavedFilter !== null) {
      // Resets the active saved filter
      this.onUpdateActiveSavedFilter({ name: prevState.activeSavedFilter });
      return;
    }

    if (this.props.location.search !== prevProps.location.search) {
      this.updateLastActiveFilters();
      this.validateAndUpdateBoardQuery();
    }

    // Prevent race with the sidebar activating the saved filter
    setTimeout(() => {
      this.selectFirstPost();
    }, 0);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  getInitialActiveSavedFilter() {
    const {
      company: { savedFilters, viewerPreferences },
    } = this.props;
    const lastUsedFilter = viewerPreferences?.lastActiveFilters?.savedFilter;
    const filterExists = savedFilters.some((filter) => filter.name === lastUsedFilter);
    if (lastUsedFilter && filterExists) {
      return lastUsedFilter;
    }

    const overwroteDefaultFilter = savedFilters.some((filter) => filter.isDefault);
    let firstFilter = 'Default';

    if (overwroteDefaultFilter) {
      firstFilter = [...savedFilters].sort(stringSort('name'))[0]?.name;
    }

    return firstFilter;
  }

  onPostEdited = (post) => {
    const { location, router } = this.props;
    router.replace({
      pathname: `/admin/feedback/${post.board.urlName}/p/${post.urlName}`,
      query: location.query,
    });
  };

  setSelectedPostsMap = (selectedPostsMap) => {
    this.setState({ selectedPostsMap });
  };

  onResize = (event) => {
    const { post } = this.props;
    this.setState((state) => {
      const isMobile = document.body.clientWidth <= MobileWidth;
      let postSectionVisible = state.postSectionVisible;
      if (!isMobile) {
        // always visible on desktop-sized screens
        postSectionVisible = true;
      } else if (!post) {
        // don't show empty post on mobile
        postSectionVisible = false;
      }
      return { isMobile, postSectionVisible };
    });
  };

  onToggleCollapsedSidebar = async () => {
    const collapsedSidebar = this.state.showSidebar;
    this.setState(({ showSidebar }) => ({ showSidebar: !showSidebar }));
    await AJAX.post('/api/viewer/updatePreferences', {
      preferences: {
        collapsedSidebar,
      },
    });
    await this.props.reloadCompany();
  };

  onCreatePostTapped = () => {
    this.props.openModal(AdminCreatePostModal, {
      board: this.props.board,
      boards: this.props.boards,
      allowCreateAnother: true,
    });
  };

  onUpdateActiveSavedFilter = (filter) => {
    const filterName = filter?.name ?? DefaultSavedFilterName;
    if (this.state.activeSavedFilter === filterName) {
      // The active filter was reselected, so we need to clear any modified filters.
      this.setState({ activeSavedFilter: null });
    } else {
      this.setState({ activeSavedFilter: filterName }, () => {
        this.updateLastActiveFilters();
      });
    }
  };

  selectFirstPost() {
    const {
      location: { pathname, query },
      postList,
      router,
    } = this.props;
    const isPostSelected = pathname.match(/^\/admin\/feedback\/[a-z0-9-]+\/p\/[a-z0-9-]+/);
    if (isPostSelected) {
      if (this.state.selectingFirstPost) {
        this.setState({
          selectingFirstPost: false,
        });
      }
      return;
    }

    const firstPost = postList && postList.posts && postList.posts[0];
    if (!firstPost) {
      return;
    }

    // Prevent multiple router navigations
    if (this.state.selectingFirstPost) {
      return;
    }

    this.setState(
      {
        selectingFirstPost: true,
      },
      () => {
        router.replace({
          pathname: `/admin/feedback/${firstPost.board.urlName}/p/${firstPost.urlName}`,
          query,
        });
      }
    );
  }

  togglePostSectionVisibility = () => {
    const { isMobile } = this.state;
    if (isMobile) {
      this.setState((state) => ({ postSectionVisible: !state.postSectionVisible }));
    }
  };

  async updateLastActiveFilters() {
    // Keep track of the last active filters
    await AJAX.post('/api/viewer/updatePreferences', {
      preferences: {
        lastActiveFilters: {
          savedFilter: this.state.activeSavedFilter,
          filterQuery: this.props.location.search,
        },
      },
    });
    await this.props.reloadCompany();
  }

  validateAndUpdateBoardQuery = () => {
    const { boards, location, router } = this.props;
    const { boards: boardQuery } = location.query;

    if (!boardQuery) {
      return;
    }

    const boardsMap = mapify(boards, 'urlName');
    const boardURLNames = boardQuery.split('_');
    const filteredBoardURLNames = boardURLNames.filter((urlName) => !!boardsMap[urlName]);

    if (filteredBoardURLNames.length !== boardURLNames.length) {
      router.replace({
        pathname: location.pathname,
        query: { ...location.query, boards: filteredBoardURLNames.join('_') },
      });
    }
  };

  renderPostSection() {
    const { isMobile, postSectionVisible, selectedPostsMap } = this.state;
    const { board, boards, customPostFields, post, postActivity, roadmaps } = this.props;
    if (!postSectionVisible) {
      return null;
    }

    const isBulkEditModeOn = !!Object.keys(selectedPostsMap).length;
    const content = isBulkEditModeOn ? (
      <AdminFeedbackBulkEditForm
        boards={boards}
        onPostsEdited={this.setSelectedPostsMap}
        post={post}
        roadmaps={roadmaps}
        selectedPostsMap={selectedPostsMap}
      />
    ) : (
      <AdminFeedbackPost
        board={board}
        boards={boards}
        customPostFields={customPostFields}
        key={post?._id}
        onEdited={this.onPostEdited}
        post={post}
        postActivity={postActivity}
        roadmaps={roadmaps}
      />
    );

    if (!isMobile) {
      return content;
    }

    const isIDBEnabled = this.props.company?.featureAllowlist?.includes('idea-database');
    return (
      <AdminFeedbackMobileWrapper
        className={isIDBEnabled ? 'v2' : undefined}
        onBack={this.togglePostSectionVisibility}>
        {content}
      </AdminFeedbackMobileWrapper>
    );
  }

  renderContent() {
    const { boards, company } = this.props;

    if (company.postCount === 0 && boards.length > 0) {
      const firstBoard = boards[0];
      const importURL = `/admin/settings/boards/${firstBoard.urlName}/data-import`;
      return (
        <div className="newBoard">
          <div className="text">
            <div className="title">Nice, a new board!</div>
            <div className="subtitle">Lead by example by creating your first few&nbsp;posts.</div>
            <Button
              buttonType="whiteButton withBorder"
              onTap={this.onCreatePostTapped}
              value={
                <>
                  <div className="icon-plus" />
                  <span>Create Post</span>
                </>
              }
            />
            <div className="subtitle existingFeedback">Have existing&nbsp;feedback?</div>
            <Link className="cta" to={importURL}>
              <Button
                buttonType="whiteButton withBorder"
                value={
                  <>
                    <div className="icon-download" />
                    <span>Import via .csv</span>
                  </>
                }
              />
            </Link>
          </div>
        </div>
      );
    }

    return (
      <>
        <AdminFeedbackPostList
          activeSavedFilter={this.state.activeSavedFilter}
          board={this.props.board}
          boards={this.props.boards}
          onPostSelected={this.togglePostSectionVisibility}
          onPostsSelected={this.setSelectedPostsMap}
          onUpdateActiveSavedFilter={this.onUpdateActiveSavedFilter}
          postList={this.props.postList}
          selectedPostsMap={this.state.selectedPostsMap}
          sidebarCollapsed={!this.state.showSidebar}
        />
        {this.renderPostSection()}
      </>
    );
  }

  renderSidebar() {
    const { activeSavedFilter, showSidebar } = this.state;

    const chevronClassNames = classnames({
      icon: true,
      'icon-chevron-left': showSidebar,
      'icon-chevron-right': !showSidebar,
    });

    const collapseBarClassNames = classnames({
      sidebarCollapse: true,
      collapsed: !showSidebar,
    });

    return (
      <>
        <AdminFeedbackSidebar
          activeSavedFilter={activeSavedFilter}
          board={this.props.board}
          boards={this.props.boards}
          onUpdateActiveSavedFilter={this.onUpdateActiveSavedFilter}
          showSidebar={showSidebar}
        />
        <div className={collapseBarClassNames}>
          <Tappable onTap={this.onToggleCollapsedSidebar}>
            <div className="collapseButton">
              <div className={chevronClassNames} />
            </div>
          </Tappable>
        </div>
      </>
    );
  }

  render() {
    const { post, board } = this.props;
    const title =
      post?.title && board ? `${post.title} | ${board.name} | Canny` : 'Feedback | Canny';
    return (
      <div className="adminFeedback">
        <Helmet title={title} />
        {this.renderSidebar()}
        {this.renderContent()}
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadCompany: () => dispatch(reloadCompany()),
  })),
  withContexts(
    {
      company: CompanyContext,
      location: LocationContext,
      openModal: OpenModalContext,
      trackEvent: TrackEventContext,
    },
    { forwardRef: true }
  )
)(AdminFeedback);
