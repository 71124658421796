import { ErrorType, LoadedType, RequestType } from 'common/actions/ideaFields';

import requestReducerFactory, { type RequestState } from './requestReducerFactory';
export { defaultState } from './requestReducerFactory';

import type { IdeaField } from 'common/api/endpoints/idea';

export type IdeaFieldsState = RequestState<IdeaField[]>;

export default requestReducerFactory<IdeaField[]>(RequestType, LoadedType, ErrorType);
