import React, { useContext } from 'react';

import { compose } from 'redux';

import { loadRoadmaps } from 'common/actions/roadmaps';
import Accordion from 'common/common/Accordion';
import { CompanyContext } from 'common/containers/CompanyContainer';
import asyncConnect from 'common/core/asyncConnect';
import withAccessControl from 'common/routing/withAccessControl';
import AdminFeatureUpsell from 'common/subdomain/admin/AdminFeatureUpsell';
import { H2, P, Span } from 'common/ui/Text';
import { StarterPlanID } from 'common/util/isStarter';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';

import RoadmapSettingsArchivedRow from './RoadmapSettingsArchivedRow';
import RoadmapSettingsUnarchivedRow from './RoadmapSettingsUnarchivedRow';

import type { Roadmap } from 'common/api/endpoints/roadmaps';

import 'css/components/subdomain/admin/_AdminRoadmapSettingsArchive.scss';

type OwnProps = Record<string, never>;

type ConnectProps = {
  roadmaps?: Roadmap[];
};

type Props = OwnProps & ConnectProps;

const AdminRoadmapSettingsArchive = ({ roadmaps = [] }: Props) => {
  const company = useContext(CompanyContext);

  const archivedRoadmaps = roadmaps.filter((roadmap) => roadmap.archived);
  const activeRoadmaps = roadmaps.filter((roadmap) => !roadmap.archived);
  const hasOneActive = activeRoadmaps.length < 2;

  if (!company.features.prioritizationRoadmap) {
    return (
      <div className="adminRoadmapSettingsArchive">
        <AdminFeatureUpsell
          cta="Score and rank features to understand priority"
          feature="prioritizationRoadmap"
          planID={StarterPlanID}
        />
      </div>
    );
  }

  return (
    <div className="adminRoadmapSettingsArchive">
      <div className="content">
        <div className="headingWrapper">
          <H2 variant="headingMd">Roadmaps</H2>
          <P className="description">Archive, unarchive, or delete your roadmaps permanently.</P>
        </div>
        <Accordion title={<Span variant="headingSm">Archived ({archivedRoadmaps.length})</Span>}>
          <div className="roadmaps">
            {archivedRoadmaps.map((roadmap) => (
              <RoadmapSettingsArchivedRow roadmap={roadmap} key={roadmap._id} />
            ))}
          </div>
        </Accordion>
        <Accordion title={<Span variant="headingSm">Active ({activeRoadmaps.length})</Span>}>
          <div className="roadmaps">
            {activeRoadmaps.map((roadmap) => (
              <RoadmapSettingsUnarchivedRow
                disableActions={hasOneActive}
                roadmap={roadmap}
                key={roadmap._id}
              />
            ))}
          </div>
        </Accordion>
      </div>
    </div>
  );
};

// TODO: fix hairy bug in withAccessControl that's causing type issues
export default compose(
  asyncConnect(
    [
      {
        promise: ({ store: { dispatch } }) => {
          return dispatch(loadRoadmaps());
        },
      },
    ],
    (state: any) => ({ roadmaps: state.roadmaps?.roadmaps })
  ),
  withAccessControl<Props>(
    testEveryPermission(RoutePermissions.adminSettings.roadmap.archive),
    '/admin/settings'
  )
)(AdminRoadmapSettingsArchive) as unknown as React.FC<OwnProps>;
