import React, { useContext, useEffect } from 'react';

import Card from 'common/common/Card';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { RouterContext } from 'common/containers/RouterContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import { P, Span } from 'common/ui/Text';
import styles from 'css-module/components/subdomain/public/_PendingInviteNotice.module.scss';

import type { Company } from 'common/api/endpoints/companies';
import type { Viewer } from 'common/api/endpoints/viewer';

const PendingInviteNotice = () => {
  const company = useContext<Company>(CompanyContext);
  const viewer = useContext<Viewer>(ViewerContext);
  const router = useContext(RouterContext);

  useEffect(() => {
    if (company.viewerIsMember) {
      // if they are a member already, redirect to admin view
      router.replace('/admin');
    } else if (!viewer || viewer.loggedOut) {
      // if they are not logged in, redirect to home page
      router.replace('/');
    }
  }, [company, viewer, router]);

  // if they are a member already, show a message saying we are redirecting them to the admin view
  if (company.viewerIsMember) {
    return (
      <main className={styles.pendingInviteNotice}>
        <Card borderStyle="solid" className={styles.card}>
          <P variant="headingMd">
            We are redirecting you to <Span fontWeight="semibold">{company.name}</Span>'s admin view
          </P>
          <P variant="bodyMd" className={styles.subheading}>
            Please, wait a few&nbsp;seconds.
          </P>
        </Card>
      </main>
    );
  }

  return (
    <main className={styles.pendingInviteNotice}>
      <Card borderStyle="solid" className={styles.card}>
        <P variant="headingMd">We sent you an email to verify your identity</P>
        <P variant="bodyMd" className={styles.subheading}>
          Please check your inbox to join <Span fontWeight="semibold">{company.name}</Span> as
          an&nbsp;admin.
        </P>
      </Card>
    </main>
  );
};

export default PendingInviteNotice;
