import React from 'react';

import { ClipboardList, FileSpreadsheet, Mail } from 'lucide-react';

import AdminOnboardingHeader, { AdminOnboardingSubHeader } from './AdminOnboardingHeader';
import AdminOnboardingMultiSelect from './AdminOnboardingMultiSelect';
import AdminOnboardingOtherInput from './AdminOnboardingOtherInput';

import HelpscoutLogo from 'img/helpscout-logo-small.png';
import HubspotLogo from 'img/hubspot-logo-small.png';
import IntercomLogo from 'img/intercom-logo-small.png';
import SalesforceLogo from 'img/salesforce-logo-small.png';
import ZendeskLogo from 'img/zendesk-logo-small.png';

import type { UseCase } from './Types';

import 'css/components/subdomain/admin/_AdminOnboarding.scss';

type Props = {
  onChange: (useCase: UseCase) => void;
  useCase: UseCase;
};

const AdminOnboardingWhere = ({ onChange, useCase }: Props) => {
  // helpers
  const onChangeOther = (other: string) => {
    onChange({
      ...useCase,
      otherFeedbackSource: other,
    });
  };

  const onChangeSelect = (feedbackSources: string[]) => {
    onChange({
      ...useCase,
      feedbackSources,
    });
  };

  const { feedbackSources, otherFeedbackSource } = useCase;
  const selectedOther = feedbackSources.includes('other');
  return (
    <div className="onboardingWhere">
      <AdminOnboardingHeader>Where do you currently collect feedback?</AdminOnboardingHeader>
      <AdminOnboardingSubHeader>Select all that apply:</AdminOnboardingSubHeader>
      <AdminOnboardingMultiSelect
        onChange={onChangeSelect}
        options={[
          { name: 'intercom', label: 'Intercom', startImg: IntercomLogo },
          { name: 'helpScout', label: 'Help Scout', startImg: HelpscoutLogo },
          { name: 'hubspot', label: 'HubSpot', startImg: HubspotLogo },
          { name: 'salesforce', label: 'Salesforce', startImg: SalesforceLogo },
          { name: 'zendesk', label: 'Zendesk', startImg: ZendeskLogo },
          { name: 'email', label: 'Email', startIcon: Mail },
          { name: 'spreadsheets', label: 'Spreadsheets', startIcon: FileSpreadsheet },
          { name: 'surveys', label: 'Surveys', startIcon: ClipboardList },
          { name: 'other', label: 'Other' },
          {
            name: 'none',
            label: "I don't currently collect feedback",
          },
        ]}
        selected={feedbackSources}
      />
      {selectedOther && (
        <AdminOnboardingOtherInput
          onChange={onChangeOther}
          placeholder="e.g. interviews, focus groups, etc."
          value={otherFeedbackSource}
        />
      )}
    </div>
  );
};

export default AdminOnboardingWhere;
