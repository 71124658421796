import React from 'react';

import classnames from 'classnames';

import ModalPortal from 'common/modals/ModalPortal';

import 'css/components/modals/_ModernModal.scss';

type Props = {
  closeOnClickAway?: boolean;
  overlayClassName?: string;
  modalClassName?: string;
  sectionsClassName?: string;
  footer: React.ReactNode;
  header: React.ReactNode;
  onClose: () => void;
  sections: React.ReactNode[];
  hideHeader?: boolean;
  hideFooter?: boolean;
};

const ModernModal = ({
  closeOnClickAway = true,
  overlayClassName,
  modalClassName,
  sectionsClassName,
  onClose,
  header,
  footer,
  sections,
  hideHeader,
  hideFooter,
}: Props) => {
  return (
    <ModalPortal
      overlayClassName={classnames('modernModalPortal', overlayClassName)}
      modalClassName={classnames('modernModalPortalContents', modalClassName)}
      closeOnClickAway={closeOnClickAway}
      onClose={onClose}>
      {!hideHeader && (
        <header className="content header">
          <h1 className="headerCopy">{header}</h1>
          <button className="close" onClick={onClose}>
            <div className="closeIcon icon icon-x" />
          </button>
        </header>
      )}

      <div className={classnames('sections', sectionsClassName)}>
        {sections.map((section, index: number) => (
          <section className="content section" key={index}>
            {section}
          </section>
        ))}
      </div>

      {!hideFooter && <section className="content footer">{footer}</section>}
    </ModalPortal>
  );
};

export default ModernModal;
