import React, { Component } from 'react';

import classnames from 'classnames';
import { ArrowDown, ArrowUp, ArrowUpDown } from 'lucide-react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadRoadmapPosts } from 'common/actions/roadmapPosts';
import { reloadRoadmap } from 'common/actions/roadmaps';
import AJAX from 'common/AJAX';
import { CustomPostFieldTypes } from 'common/api/resources/postFields';
import Tooltip from 'common/common/Tooltip';
import Truncate from 'common/common/Truncate';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { LocationContext, ParamsContext } from 'common/containers/RouterContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import IsAdminViewContext from 'common/contexts/IsAdminViewContext';
import connect from 'common/core/connect';
import Draggable from 'common/draganddrop/Draggable';
import DropArea from 'common/draganddrop/DropArea';
import CheckboxInput from 'common/inputs/CheckboxInput';
import StarRatingInput from 'common/inputs/StarRatingInput';
import KeyCodes from 'common/KeyCodes';
import mod from 'common/mod';
import Months from 'common/Months';
import PostStatus from 'common/post/PostStatus';
import {
  CalculationFunctions,
  CalculationOpportunityFactors,
  CalculationStaticFactors,
} from 'common/prioritization/CalculationFactorTypes';
import calculateScores from 'common/prioritization/calculations';
import {
  DefaultDescriptionColumnFields,
  DescriptionColumnTypes,
} from 'common/prioritization/DescriptionColumnTypes';
import getScoreForDisplay from 'common/prioritization/getScoreForDisplay';
import ImpactFactorTypes from 'common/prioritization/ImpactFactorTypes';
import Spinner from 'common/Spinner';
import Tappable from 'common/Tappable';
import CheckboxV2 from 'common/ui/CheckboxV2';
import { P } from 'common/ui/Text';
import delayer from 'common/util/delayer';
import { getQueryFilterParams } from 'common/util/filterPosts';
import groupify from 'common/util/groupify';
import hasPermission from 'common/util/hasPermission';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import stringSort from 'common/util/stringSort';
import withContexts from 'common/util/withContexts';

import AdminRoadmapAccordion from './AdminRoadmapAccordion';
import AdminRoadmapAddPost from './AdminRoadmapAddPost';
import AdminRoadmapOwnerCell from './AdminRoadmapOwnerCell';
import AdminRoadmapPostIntegrations from './AdminRoadmapPostIntegrations';
import AdminRoadmapPostTitle from './AdminRoadmapPostTitle';
import AdminRoadmapTableRow from './AdminRoadmapTableRow';
import AdminRoadmapTextCell from './AdminRoadmapTextCell';
import { determineSortFunction } from './SortUtils';
import AdminRoadmapBulkActionBar from '../AdminRoadmapBulkActionBar';
import {
  GroupByTypes,
  getGroupableCustomPostFields,
} from '../AdminRoadmapHeader/AdminRoadmapGroupBy';

import 'css/components/subdomain/admin/AdminRoadmap/_AdminRoadmapTable.scss';

const RowHeight = 50;
const BufferSize = 20; // Number of extra rows to render above/below viewport

const GroupByOptions = {
  // TODO: remove this when there are no more options set to'none' in the database as the user's  last viewed setting
  None: 'None',
  Board: 'Board',
  Category: 'Category',
  Owner: 'Owner',
  Status: 'Status',
};

const ColumnSections = ['title', 'fyi', 'impact', 'effort', 'sticky'];
const DefaultSort = { column: 'score', order: 'desc' };
const FibonacciOptions = [1, 2, 3, 5, 8, 13, 21, 34, 55, 89, 144, 233].map((option) => ({
  value: option,
  label: String(option),
}));
const FibonacciOptionsWithZero = [0, ...FibonacciOptions.map((option) => option.value)].map(
  (option) => ({ value: option, label: String(option) })
);
const MobileWidth = 780;
const NumberOneToTenOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((option) => ({
  value: option,
  label: String(option),
}));
const NumberZeroToTenOptions = [0, ...NumberOneToTenOptions.map((option) => option.value)].map(
  (option) => ({ value: option, label: String(option) })
);
const RoadmapSupportedIntegrations = [
  'asana',
  'azureDevops',
  'clickup',
  'github',
  'jira',
  'linear',
];
const SumColumnIDs = { score: true };
const SumFactorTypes = { fibonacci: true, numberToOneHundred: true };
const UpdateValueDelay = 100;

export const getColumnWidths = (roadmap) => {
  if (!roadmap?.settings?.columnWidths) {
    return defaultColumnWidths(roadmap);
  } else {
    return roadmap.settings.columnWidths;
  }
};

const defaultTitleWidth = 500;
const defaultColumnWidth = 130;

export const defaultColumnWidths = (roadmap) => {
  const descriptionColumnWidths = {};
  const descriptionColumns = roadmap?.descriptionColumns ?? [];
  descriptionColumns.forEach(({ _id }) => (descriptionColumnWidths[_id] = defaultColumnWidth));

  const factorColumnWidths = {};
  const factorColumns = roadmap?.factors ?? [];
  factorColumns.forEach(({ _id }) => (factorColumnWidths[_id] = defaultColumnWidth));

  return {
    title: defaultTitleWidth,
    ...descriptionColumnWidths,
    ...factorColumnWidths,
  };
};

export const Errors = {
  Creating: 'creating',
  Loading: 'loading',
};

const getOptionsFromCustomPostField = (customPostField) => {
  if (customPostField.type === CustomPostFieldTypes.dropdown) {
    return customPostField.options.map((option) => ({
      _id: option,
      name: option,
    }));
  }

  return [];
};

class AdminRoadmapTable extends Component {
  static propTypes = {
    boards: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        urlName: PropTypes.string,
      })
    ),
    distinctBoards: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
      })
    ),
    distinctCategories: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
      })
    ),
    distinctOwners: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
      })
    ),
    hiddenColumnIDs: PropTypes.arrayOf(PropTypes.string),
    company: PropTypes.shape({
      clickup: PropTypes.object,
      github: PropTypes.object,
      jira: PropTypes.object,
    }),
    groupBy: PropTypes.string,
    onClosePost: PropTypes.func,
    onOpenPost: PropTypes.func,
    isAdminView: PropTypes.bool,
    roadmap: PropTypes.shape({
      _id: PropTypes.string,
    }),
    roadmapPosts: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        post: PropTypes.shape({
          _id: PropTypes.string,
          eta: PropTypes.string,
          owner: PropTypes.shape({
            avatarURL: PropTypes.string,
            name: PropTypes.string,
            urlName: PropTypes.string,
          }),
          status: PropTypes.string,
          title: PropTypes.string,
          urlName: PropTypes.string,
        }),
      })
    ),
    selectedPost: PropTypes.object,
    statuses: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
      })
    ),
    viewer: PropTypes.shape({
      _id: PropTypes.string,
    }),
    updateServerSettings: PropTypes.func,
  };

  // default state
  state = {
    scrollTop: 0,
    viewportHeight: 0,
    columns: [],
    draggingColumnID: null,
    resizing: false,
    resizingColumnID: null,
    resizeStartPoint: null,
    resizeAmount: null,
    mouseDifference: null,
    resizeStartingWidth: null,
    errors: {},
    isMobile: null,
    rows: [],
    sort: DefaultSort,
    ungroupedRows: [],
    selectedPostIDs: [],
  };

  constructor(props, context) {
    super(props, context);

    this.addPostRef = React.createRef();
    this.tableRef = React.createRef();
    this._resizeDelayer = new delayer(this.onResize, 500);
    this._updateFactorDelayer = new delayer(this.updateFactorValue, UpdateValueDelay);
    this.containerRef = React.createRef();
    this._scrollDelayer = new delayer(this.handleScroll, 16); // 60fps
  }

  componentDidMount() {
    const {
      company: { viewerPreferences },
    } = this.props;
    const { roadmapSort: sort } = viewerPreferences ?? {};

    this.setState({
      columns: this.getColumns(),
      rows: this.getRows(),
      sort,
    });

    window.addEventListener('resize', this._resizeDelayer.callAfterDelay, false);
    window.addEventListener('mouseup', this.onMouseUp);
    window.addEventListener('mousemove', this.onMouseMove);
    this.onResize();
    this.containerRef.current?.addEventListener('scroll', this._scrollDelayer.callAfterDelay);
  }

  componentDidUpdate(prevProps) {
    const {
      company: { viewerPreferences },
      hiddenColumnIDs,
      groupBy,
      roadmap,
      selectedPost,
      roadmapPosts,
    } = this.props;
    const { roadmapSort: savedSort } = viewerPreferences ?? {};

    if (roadmap !== prevProps.roadmap || hiddenColumnIDs !== prevProps.hiddenColumnIDs) {
      const columns = this.getColumns();
      const isSameRoadmap = roadmap?._id === prevProps.roadmap?._id;
      if (isSameRoadmap) {
        this.setState({ columns });
      } else {
        // restore sort to default if it cannot be applied to the new roadmap
        this.setState(({ sort: appliedSort }) => {
          const appliedSortColumnExists = columns.some(
            (column) => column.id === appliedSort?.column
          );
          const savedSortColumnExists = columns.some((column) => column.id === savedSort?.column);

          if (savedSortColumnExists) {
            return {
              columns,
              sort: savedSort,
              ungroupedRows: [],
            };
          } else if (appliedSortColumnExists) {
            return {
              columns,
              sort: appliedSort,
              ungroupedRows: [],
            };
          } else {
            return {
              columns,
              sort: DefaultSort,
              ungroupedRows: [],
            };
          }
        });

        this.setState({ selectedPostIDs: [] });
      }
    }

    if (roadmapPosts !== prevProps.roadmapPosts || groupBy !== prevProps.groupBy) {
      this.setState((state) => ({ rows: this.getRows(state.sort) }));
    }

    if (selectedPost !== prevProps.selectedPost || groupBy !== prevProps.groupBy) {
      this.setState({
        columns: this.getColumns(),
      });
    }
  }

  componentWillUnmount() {
    this._resizeDelayer.cancel();
    this._updateFactorDelayer.cancel();
    window.removeEventListener('resize', this._resizeDelayer.callAfterDelay);
    window.removeEventListener('mouseup', this.onMouseUp);
    window.removeEventListener('mousemove', this.onMouseMove);
    this.containerRef.current?.removeEventListener('scroll', this._scrollDelayer.callAfterDelay);
  }

  updateViewportHeight = () => {
    if (this.containerRef.current) {
      this.setState({ viewportHeight: this.containerRef.current.clientHeight });
    }
  };

  handleScroll = (event) => {
    const { groupBy } = this.props;

    // TODO: handle scroll when grouping is enabled
    if (groupBy && groupBy !== GroupByOptions.None) {
      return;
    }

    // wait for the next frame to update the scrollTop to avoid blocking the repaint
    window.requestAnimationFrame(() => {
      this.setState({ scrollTop: event.target.scrollTop });
    });
  };

  getVisibleRange = () => {
    // TODO: This doesn't take into account groupings yet, so those remain unoptimized
    const { scrollTop, viewportHeight } = this.state;
    const { rows } = this.state;

    const startIndex = Math.max(0, Math.floor(scrollTop / RowHeight) - BufferSize);
    const endIndex = Math.min(
      rows.length,
      Math.ceil((scrollTop + viewportHeight) / RowHeight) + BufferSize
    );

    return { startIndex, endIndex };
  };

  renderVirtualizedRows(rows, columns) {
    const { errors, selectedPost, selectedPostIDs } = this.state;
    const { startIndex, endIndex } = this.getVisibleRange();
    const totalHeight = rows.length * RowHeight;
    const visibleRows = rows.slice(startIndex, endIndex);

    return (
      <div className="virtualizedListContainer" style={{ height: totalHeight }}>
        <div className="virtualizedViewport" style={{ top: startIndex * RowHeight }}>
          <ul className="categoryRowBlock">
            {visibleRows.map((row) => (
              <AdminRoadmapTableRow
                key={row._id}
                row={row}
                columns={columns}
                errors={errors}
                getCellKey={this.getCellKey}
                getColumnWidth={this.getColumnWidth}
                selectedPost={selectedPost}
                selectedPostIDs={selectedPostIDs}
              />
            ))}
          </ul>
        </div>
      </div>
    );
  }

  changeSort = (column) => {
    const { sort } = this.state;
    const newSort = {
      column,
      order: sort?.order === 'desc' ? 'asc' : 'desc', // switch order
    };

    this.setState({
      rows: this.getRows(newSort),
      sort: newSort,
    });

    return AJAX.post('/api/viewer/updatePreferences', {
      preferences: { roadmapSort: newSort },
    });
  };

  getStatuses = () => {
    const { company } = this.props;
    const statuses = company.statuses.map((status) => status.name.toLowerCase());
    return statuses;
  };

  sortByCompanyStatusOrder = (groupings) => {
    const companyStatuses = this.getStatuses();

    return [...groupings].sort((a, b) => {
      if (
        companyStatuses.indexOf(a.name.toLowerCase()) <
        companyStatuses.indexOf(b.name.toLowerCase())
      ) {
        return -1;
      }
      if (
        companyStatuses.indexOf(a.name.toLowerCase()) >
        companyStatuses.indexOf(b.name.toLowerCase())
      ) {
        return 1;
      }

      return 0;
    });
  };

  sortGroupings = (groupings) => {
    const { groupBy } = this.props;

    // When they are grouped by status, we want to order them based on how they defined
    // them rather than alphabetically
    if (groupBy !== GroupByOptions.Status) {
      return groupings.sort(stringSort('name', 'asc', true));
    } else {
      return this.sortByCompanyStatusOrder(groupings);
    }
  };

  getRows = (sort) => {
    const { error, groupBy, loading, roadmap, roadmapPosts } = this.props;
    if (roadmapPosts.length < 1 || error || loading) {
      return [];
    }

    const sortedRows = [];
    const scores = calculateScores(roadmapPosts, roadmap.factors);
    let remainingPosts = roadmapPosts;
    // When grouping is turned on, we need to map posts and keep them in their group
    if (groupBy && groupBy !== GroupByOptions.None) {
      const groupByData = this.getGroupByState();
      const { groupings, key, type } = groupByData;

      groupings.forEach((distinctGroup) => {
        const filteredPosts = roadmapPosts.filter((roadmapPost) => {
          if (type === GroupByTypes.default) {
            return roadmapPost.post[key] === distinctGroup._id;
          }

          return roadmapPost.post.customPostFields.some(
            (customPostField) =>
              customPostField.customPostFieldID === key &&
              customPostField.value === distinctGroup._id
          );
        });

        remainingPosts = remainingPosts.filter((post) => !filteredPosts.includes(post));
        const rows = this.mapRoadmapPostsToRows(filteredPosts, scores);
        sortedRows.push(...this.sortRows(rows, sort));
      });

      // posts that don't fit in (i.e. no owner assigned)
      const ungroupedRows = this.mapRoadmapPostsToRows(remainingPosts, scores);
      const sortedUngroupedRows = this.sortRows(ungroupedRows, sort);
      this.setState({
        ungroupedRows: sortedUngroupedRows.map((row, index) => ({
          ...row,
          index: index + sortedRows.length,
        })),
      });
    } else {
      const rows = this.mapRoadmapPostsToRows(roadmapPosts, scores);
      sortedRows.push(...this.sortRows(rows, sort));
    }

    return sortedRows.map((row, index) => ({ ...row, index }));
  };

  getGroupByState = () => {
    const {
      customPostFields,
      distinctBoards,
      distinctCategories,
      distinctOwners,
      groupBy,
      statuses,
    } = this.props;

    const defaultGroups = {
      Board: {
        groupings: distinctBoards,
        key: 'boardID',
        type: GroupByTypes.default,
        label: 'Board',
      },
      Categories: {
        groupings: distinctCategories,
        key: 'categoryID',
        type: GroupByTypes.default,
        label: 'Category',
      },
      Owner: {
        groupings: distinctOwners,
        key: 'ownerID',
        type: GroupByTypes.default,
        label: 'Owner',
      },
      Status: { groupings: statuses, key: 'status', type: GroupByTypes.default, label: 'Status' },
    };

    const groups = getGroupableCustomPostFields(customPostFields).reduce(
      (groupMap, customPostField) => {
        return {
          ...groupMap,
          [customPostField._id]: {
            groupings: getOptionsFromCustomPostField(customPostField),
            key: customPostField._id,
            type: GroupByTypes.customPostField,
            label: customPostField.name,
          },
        };
      },
      defaultGroups
    );

    return groups[groupBy];
  };

  getIntegrationsCount = (company) => {
    if (!this.props.isAdminView) {
      return 0;
    }
    const integrationVisibilityConditions = {
      asana: true,
      azureDevops: true,
      clickup: company.clickup?.authorized,
      github: company.github?.installationID,
      jira: company.jira?.connected,
      linear: true,
    };
    return RoadmapSupportedIntegrations.filter(
      (integrationName) =>
        company?.activeIntegrations[integrationName] &&
        integrationVisibilityConditions[integrationName]
    ).length;
  };

  getColumns = () => {
    const { boards, company, deleteRoadmapPost, selectedPost, roadmap, groupBy } = this.props;
    const { isMobile } = this.state;

    const impacts = roadmap?.factors.filter(({ effort }) => !effort) ?? [];
    const efforts = roadmap?.factors.filter(({ effort }) => effort) ?? [];

    const widths = getColumnWidths(roadmap);

    const initialColumns = [
      {
        draggable: false,
        factorType: null,
        id: 'title',
        render: ({ index, roadmapPost, value: title }) => (
          <AdminRoadmapPostTitle
            onDeletePost={() => deleteRoadmapPost(roadmapPost)}
            onOpenPost={() => this.props.onOpenPost(roadmapPost.post)}
            title={title}
            key={selectedPost?._id}
            index={index}
            onSelected={(v) => this.onPostSelected(v, roadmapPost)}
            onKeyDown={(e) => this.onKeyDown('title', roadmapPost, e)}
            isOpen={selectedPost?._id === roadmapPost.postID}
            isSelected={this.state.selectedPostIDs.includes(roadmapPost.postID)}
            isGroupMember={groupBy && groupBy !== 'None'}
          />
        ),
        section: 'title',
        sortKey: 'title',
        sortable: true,
        sticky: true,
        style: {},
        width: widths.title ?? defaultTitleWidth,
      },
    ];

    const getEffortRender = (effort) => {
      return this.getCellRenderer(
        null,
        effort.type,
        {
          getOnChangeHandler: (roadmapPost) => (e) => this.onKeyDown(effort._id, roadmapPost, e),
          getUpdateHandler: (roadmapPost) => (value) =>
            this.updateFactorValue(effort, roadmapPost, value),
          getUpdateOnDelayHandler: (roadmapPost) => (e, value) => {
            // PercentageInput and TextInputWithSuggestions pass value directly
            const newValue = value ?? e.target.value;
            const numberValue = newValue === '' ? null : Number(newValue);
            this._updateFactorDelayer.callAfterDelay(effort, roadmapPost, numberValue);
          },
        },
        { areStarsZeroable: false, isEffort: true }
      );
    };

    const lastColumns = efforts.map((effort) => {
      return {
        draggable: true,
        factorType: roadmap?.effortType,
        focusable: true,
        id: effort._id,
        render: getEffortRender(effort),
        section: 'effort',
        sortKey: effort._id,
        hidden: this.props.hiddenColumnIDs.includes(effort._id),
        sortable: true,
        style: {},
        width: widths[effort._id] ?? (effort.type === 'stars' ? 142 : defaultColumnWidth),
      };
    });

    const stickyColumns = [
      {
        draggable: false,
        factorType: null,
        id: 'score',
        render: ({ value }) => (
          <P variant="bodyMd" className="cellInput">
            {this.renderScoreCell(value)}
          </P>
        ),
        section: 'sticky',
        sortable: true,
        sortKey: 'score',
        sticky: true,
        style: {},
        width: defaultColumnWidth,
      },
    ];

    const integrationsColumn = {
      id: 'integrations',
      draggable: false,
      hidden: this.getIntegrationsCount(company) === 0,
      render: ({ value: post }) => (
        <AdminRoadmapPostIntegrations boards={boards} post={post} roadmap={roadmap} />
      ),
      section: 'sticky',
      sortable: false,
      sticky: !isMobile,
      width: Math.max(this.getIntegrationsCount(company), 1.5) * 44,
      style: {},
    };

    if (isMobile) {
      lastColumns.push(integrationsColumn);
    } else {
      stickyColumns.push(integrationsColumn);
    }

    const columns = initialColumns
      .concat(
        roadmap?.descriptionColumns.map((descriptionColumn) =>
          this.mapDescriptionColumnToColumn(descriptionColumn, roadmap)
        ) ?? []
      )
      .concat(impacts.map((factor) => this.mapFactorToColumn(factor, roadmap)) ?? [])
      .concat(lastColumns)
      .concat(stickyColumns)
      .filter((column) => roadmap?.columns.includes(column.id));

    // sort columns by the stored order
    if (roadmap?.columns) {
      const columnIndexMap = roadmap.columns.reduce(
        (columnIndexMap, columnID, idx) => ({
          ...columnIndexMap,
          [columnID]: idx,
        }),
        {}
      );
      columns.sort((columnA, columnB) => {
        const columnAComesFirst = columnIndexMap[columnA.id] < columnIndexMap[columnB.id];
        const columnBComesFirst = columnIndexMap[columnA.id] > columnIndexMap[columnB.id];

        if (columnAComesFirst) {
          return -1;
        } else if (columnBComesFirst) {
          return 1;
        } else {
          return 0;
        }
      });
    }

    // columns in sections with length === 1, should be undraggable
    const columnsBySection = groupify(columns, 'section');
    for (const columns of Object.values(columnsBySection)) {
      if (columns.length === 1) {
        const [column] = columns;
        column.draggable = false;
      }
    }

    const orderedColumns = [];
    for (const section of ColumnSections) {
      const sectionColumns = columnsBySection[section] ?? [];
      orderedColumns.push(...sectionColumns);
    }

    // automatically adjust right offset for sticky columns
    [...orderedColumns].reverse().forEach((column, idx, columns) => {
      if (!column.sticky || column.hidden) {
        return;
      }

      const lastColumn = columns[idx - 1];
      column.style = {
        ...column.style,
        right: idx === 0 ? 0 : lastColumn.width + lastColumn.style.right || 0,
      };
    });

    return orderedColumns;
  };

  getDropAreaElements = (columns) => {
    const { draggingColumnID } = this.state;
    const allAreaElements = [...columns].reduce((elements, column) => {
      if (column.hidden) {
        return elements;
      }

      const lastWidth = elements[elements.length - 1]?.left ?? 0;
      return elements.concat([
        {
          position: 'before',
          column: column,
          left: lastWidth,
        },
        {
          position: 'after',
          column: column,
          left: lastWidth + column.width,
        },
      ]);
    }, []);

    const draggingColumn = columns.find((column) => column.id === draggingColumnID);
    return allAreaElements.filter((areaElement) => {
      const isColumnDraggable = areaElement.column?.draggable;
      const isSameSection = areaElement.column?.section === draggingColumn?.section;
      return isColumnDraggable && isSameSection;
    });
  };

  getErrorByType = (value, type, options = {}) => {
    const error = ImpactFactorTypes[type].validate(value);

    // validate effort is not zero
    if (options.isEffort && value === 0) {
      return 'Please enter a value greater than 0';
    } else if (options.isEffort && value === null) {
      return;
    }

    return error;
  };

  getCellRenderer = (factor, type, handlerGetters = {}, options = {}) => {
    const { company, viewer } = this.props;
    const { getOnChangeHandler, getUpdateHandler, getUpdateOnDelayHandler } = handlerGetters;

    const canEdit = hasPermission('manageRoadmap', company, viewer);

    const renderer = {
      calculation: ({ roadmapPost, value }) => {
        const isOpportunityFactor = factor.calculation.field in CalculationOpportunityFactors;
        const isNotCount = factor.calculation.function !== CalculationFunctions.count;
        const isRevenue = factor.calculation.field in CalculationStaticFactors;

        let renderValue = '';
        if ((isOpportunityFactor || isRevenue) && isNotCount) {
          renderValue = `$${(value || 0).toLocaleString('en-US')}`;
        } else {
          renderValue = String(value || 0);
        }

        return (
          <AdminRoadmapTextCell
            className={'cellInput focusable calculationFactor'}
            disabled={true}
            value={renderValue}
            onKeyDown={getOnChangeHandler(roadmapPost)}
          />
        );
      },
      checkbox: ({ roadmapPost, value } = { value: false }) => (
        <CheckboxInput
          defaultChecked={value}
          disabled={!canEdit}
          isFocusable={true}
          onChange={getUpdateHandler(roadmapPost)}
          onKeyDown={getOnChangeHandler(roadmapPost)}
        />
      ),
      fibonacci: ({ roadmapPost, value } = { value: 0 }) => (
        <AdminRoadmapTextCell
          className="cellInput focusable"
          disabled={!canEdit}
          maxLength={3}
          onChange={getUpdateOnDelayHandler(roadmapPost)}
          onKeyDown={getOnChangeHandler(roadmapPost)}
          suggestions={options.isEffort ? FibonacciOptions : FibonacciOptionsWithZero}
          placeholder={options.isEffort ? '1-233' : '0-233'}
          value={value === 0 || value ? String(value) : null}
        />
      ),
      numberToTen: ({ roadmapPost, value } = { value: 0 }) => (
        <AdminRoadmapTextCell
          className="cellInput focusable"
          disabled={!canEdit}
          maxLength={2}
          onChange={getUpdateOnDelayHandler(roadmapPost)}
          onKeyDown={getOnChangeHandler(roadmapPost)}
          suggestions={options.isEffort ? NumberOneToTenOptions : NumberZeroToTenOptions}
          placeholder={options.isEffort ? '1-10' : '0-10'}
          value={value === 0 || value ? String(value) : null}
        />
      ),
      numberToOneHundred: ({ roadmapPost, value } = { value: 0 }) => (
        <AdminRoadmapTextCell
          className="cellInput focusable"
          disabled={!canEdit}
          maxLength={4}
          onChange={getUpdateOnDelayHandler(roadmapPost)}
          onKeyDown={getOnChangeHandler(roadmapPost)}
          placeholder={options.isEffort ? '1-100' : '0-100'}
          value={value === 0 || value ? String(value) : null}
        />
      ),
      percentage: ({ roadmapPost, value } = { value: 0 }) => (
        <AdminRoadmapTextCell
          className="cellInput focusable"
          disabled={!canEdit}
          onChange={getUpdateOnDelayHandler(roadmapPost)}
          onKeyDown={getOnChangeHandler(roadmapPost)}
          percentage
          placeholder="0"
          value={value === 0 || value ? String(value) : null}
        />
      ),
      stars: ({ roadmapPost, value: stars } = { value: 0 }) => (
        <StarRatingInput
          disabled={!canEdit}
          isZeroValid={options.areStarsZeroable}
          onChange={getUpdateHandler(roadmapPost)}
          onKeyDown={getOnChangeHandler(roadmapPost)}
          starAmount={5}
          value={stars}
        />
      ),
    };

    return renderer[type] ?? renderer.numberToOneHundred;
  };

  mapDescriptionColumnToColumn = (descriptionColumn, roadmap) => {
    const columnWidths = getColumnWidths(roadmap);
    const width = columnWidths[descriptionColumn._id] ?? defaultColumnWidth;
    return {
      id: descriptionColumn._id,
      sortKey: descriptionColumn.fieldID ?? descriptionColumn.name,
      draggable: true,
      factorType: null,
      focusable: true,
      hidden: this.props.hiddenColumnIDs.includes(descriptionColumn._id),
      render: ({ roadmapPost }) => {
        // render custom field
        if (descriptionColumn.fieldType === DescriptionColumnTypes.customField) {
          const customField = roadmapPost.post.customPostFields.find(
            (customPostField) => customPostField.customPostFieldID === descriptionColumn.fieldID
          );

          if (!customField?.value) {
            return '';
          }
          if (Array.isArray(customField.value)) {
            const fieldValue = customField.value.join(', ');
            return (
              <Truncate title={fieldValue}>
                <P variant="bodyMd">{fieldValue}</P>
              </Truncate>
            );
          }
          return (
            <Truncate title={customField.value}>
              <P variant="bodyMd">{customField.value}</P>
            </Truncate>
          );
        }

        // render default columns
        switch (descriptionColumn.field) {
          case DefaultDescriptionColumnFields.status: {
            return <PostStatus showOpen={true} status={roadmapPost.post.status} />;
          }
          case DefaultDescriptionColumnFields.eta: {
            const eta = roadmapPost.post.eta;
            const date = new Date(eta);
            const isDateValid = eta && !Number.isNaN(+date);
            if (!isDateValid) {
              return '';
            }

            return (
              <P variant="bodyMd">{`${Months[date.getUTCMonth()].substr(
                0,
                3
              )} ${date.getUTCFullYear()}`}</P>
            );
          }
          case DefaultDescriptionColumnFields.category: {
            return (
              <Truncate numberOfLines={2} title={roadmapPost.post.category?.name ?? ''}>
                <P variant="bodyMd">{roadmapPost.post.category?.name ?? ''}</P>
              </Truncate>
            );
          }
          case DefaultDescriptionColumnFields.owner: {
            return <AdminRoadmapOwnerCell user={roadmapPost.post.owner} />;
          }
          case DefaultDescriptionColumnFields.tags: {
            const tags = roadmapPost.post.tags.map((tag) => tag.name).join(', ');
            return (
              <Truncate title={tags}>
                <P variant="bodyMd">{tags}</P>
              </Truncate>
            );
          }
          case DefaultDescriptionColumnFields.board: {
            return (
              <Truncate numberOfLines={2} title={roadmapPost.post.board?.name ?? ''}>
                <P variant="bodyMd">{roadmapPost.post.board?.name ?? ''}</P>
              </Truncate>
            );
          }
          default:
            return '';
        }
      },
      section: 'fyi',
      style: {},
      sortable: true,
      width,
    };
  };

  mapFactorToColumn = (factor, roadmap) => {
    const renderer = this.getCellRenderer(
      factor,
      factor.type,
      {
        getOnChangeHandler: (roadmapPost) => (e) => this.onKeyDown(factor._id, roadmapPost, e),
        getUpdateHandler: (roadmapPost) => (value) =>
          this.updateFactorValue(factor, roadmapPost, value),
        getUpdateOnDelayHandler: (roadmapPost) => (e, value) => {
          // PercentageInput and TextInputWithSuggestions pass value directly
          const numberValue = Number(value ?? e.target.value);
          this._updateFactorDelayer.callAfterDelay(factor, roadmapPost, numberValue);
        },
      },
      { areStarsZeroable: true, isEffort: false }
    );

    return {
      id: factor._id,
      sortKey: factor._id,
      draggable: true,
      factorType: factor.type,
      focusable: true,
      hidden: this.props.hiddenColumnIDs.includes(factor._id),
      render: renderer,
      section: 'impact',
      sortable: true,
      width: getColumnWidths(roadmap)[factor._id] ?? defaultColumnWidth,
      style: {},
    };
  };

  mapRoadmapPostsToRows = (roadmapPosts, scores) => {
    return roadmapPosts.map((roadmapPost) => {
      const score = scores[roadmapPost._id];
      const row = {
        ownerID: roadmapPost.post.ownerID,
        boardID: roadmapPost.post.boardID,
        categoryID: roadmapPost.post.categoryID,
        _id: roadmapPost._id,
        roadmapPost,
        effort: roadmapPost.effort,
        integrations: roadmapPost.post,
        score,
        status: roadmapPost.post.status,
        title: roadmapPost.post.title,
        ...roadmapPost.factorValues,
      };

      return row;
    });
  };

  onCreateRoadmapPost = (post) => {
    const { createRoadmapPost } = this.props;
    this.setState({ sort: null }, () => createRoadmapPost(post));
  };

  onColumnMove = async (fromColumnID, dropArea) => {
    const { reloadRoadmap, roadmap } = this.props;
    const { columns } = this.state;
    const updatedColumns = [...columns];

    const intoColumn = updatedColumns.find((column) => column.id === dropArea.column.id);
    if (intoColumn.id === fromColumnID) {
      return;
    }

    const fromColumn = updatedColumns.find((column) => column.id === fromColumnID);
    if (fromColumn.section !== intoColumn.section) {
      return;
    }

    // delete fromColumn from original position
    const fromColumnIdx = updatedColumns.findIndex((column) => column.id === fromColumnID);
    updatedColumns.splice(fromColumnIdx, 1);

    // get new index depending on where the drop area was
    const intoColumnIdx = updatedColumns.findIndex((column) => column.id === dropArea.column.id);
    const targetIndex = {
      before: intoColumnIdx,
      after: intoColumnIdx + 1,
    }[dropArea.position];

    // insert fromColumn before/after the drop area.
    updatedColumns.splice(targetIndex, 0, fromColumn);

    // create list of column ids
    const orderPayload = updatedColumns.map((column) => column.id);

    // update state to re-order columns in the ui immediately
    this.setState({ columns: updatedColumns, draggingColumnID: null });

    await AJAX.post('/api/roadmaps/update', {
      archived: roadmap.archived,
      columns: orderPayload,
      name: roadmap.name,
      roadmapID: roadmap._id,
      columnWidths: getColumnWidths(roadmap),
    });

    return reloadRoadmap(roadmap.urlName);
  };

  getNextRow = (columnID, roadmapPost) => {
    const { rows } = this.state;

    const rowIdx = rows.findIndex((row) => row._id === roadmapPost._id);
    const nextRowIdx = mod(rowIdx + 1, rows.length);
    const nextRow = rows[nextRowIdx];
    return { row: nextRow, overflowed: nextRowIdx < rowIdx };
  };

  getPreviousRow = (columnID, roadmapPost) => {
    const { rows } = this.state;

    const rowIdx = rows.findIndex((row) => row._id === roadmapPost._id);
    const previousRowIdx = mod(rowIdx - 1, rows.length);
    const previousRow = rows[previousRowIdx];
    return { row: previousRow, overflowed: previousRow > rowIdx };
  };

  getNextColumn = (columnID, roadmapPost) => {
    const { columns } = this.state;

    const columnIndex = columns.findIndex((column) => column.id === columnID);
    const nextColumn = columns.find(
      (column, idx) => idx > columnIndex && column.focusable && !column.hidden
    );

    const firstFocusableColumn = columns.find((column) => column.focusable && !column.hidden);
    return { column: nextColumn ?? firstFocusableColumn, overflowed: !nextColumn };
  };

  getPreviousColumn = (columnID, roadmapPost) => {
    const { columns } = this.state;
    const reverseColumns = [...columns].reverse();

    const columnIndex = reverseColumns.findIndex((column) => column.id === columnID);
    const previousColumn = reverseColumns.find(
      (column, idx) => idx > columnIndex && column.focusable && !column.hidden
    );

    const firstFocusableColumn = reverseColumns.find(
      (column) => column.focusable && !column.hidden
    );
    return { column: previousColumn ?? firstFocusableColumn, overflowed: !previousColumn };
  };

  onKeyDown = (columnID, roadmapPost, e) => {
    const { onOpenPost } = this.props;
    let nextCellKey = null;

    if (e.keyCode === KeyCodes.DownArrow || (!e.shiftKey && e.keyCode === KeyCodes.Enter)) {
      // Down arrow/Enter go down
      const { row } = this.getNextRow(columnID, roadmapPost);
      nextCellKey = this.getCellKey(columnID, row);
    } else if (e.keyCode === KeyCodes.UpArrow) {
      // Up arrow go up
      const { row } = this.getPreviousRow(columnID, roadmapPost);
      nextCellKey = this.getCellKey(columnID, row);
    } else if (!e.shiftKey && e.keyCode === KeyCodes.Tab) {
      // Tab go right
      const { column, overflowed } = this.getNextColumn(columnID, roadmapPost);
      const { row } = overflowed ? this.getNextRow(columnID, roadmapPost) : { row: roadmapPost };
      nextCellKey = this.getCellKey(column?.id, row);
    } else if (e.shiftKey && e.keyCode === KeyCodes.Tab) {
      // Shift + Tab go left
      const { column, overflowed } = this.getPreviousColumn(columnID, roadmapPost);
      const { row } = overflowed
        ? this.getPreviousRow(columnID, roadmapPost)
        : { row: roadmapPost };
      nextCellKey = this.getCellKey(column?.id, row);
    } else if (e.shiftKey && e.keyCode === KeyCodes.Enter) {
      // Shift + Enter go to "add new post" row.
      nextCellKey = 'add-row-cell';
    } else if (e.keyCode === KeyCodes.J && columnID === 'title') {
      const { row } = this.getNextRow(columnID, roadmapPost);
      onOpenPost(row.roadmapPost.post);
    } else if (e.keyCode === KeyCodes.K && columnID === 'title') {
      const { row } = this.getPreviousRow(columnID, roadmapPost);
      onOpenPost(row.roadmapPost.post);
    } else if (e.keyCode === KeyCodes.Escape) {
      this.props.onClosePost();
    }

    if (nextCellKey) {
      e.preventDefault();
      e.stopPropagation();
      this.focusCellByKey(nextCellKey);
    }
  };

  onPostSelected = (isSelected, roadmapPost) => {
    if (isSelected) {
      this.setState(({ selectedPostIDs }) => ({
        selectedPostIDs: [...selectedPostIDs, roadmapPost.postID],
      }));
    } else {
      this.setState(({ selectedPostIDs }) => ({
        selectedPostIDs: selectedPostIDs.filter((postID) => postID !== roadmapPost.postID),
      }));
    }
  };

  onToggleAllPostSelection = () => {
    const { roadmapPosts } = this.props;

    if (!this.allPostsSelected()) {
      // select all
      this.setState({ selectedPostIDs: roadmapPosts.map(({ postID }) => postID) });
    } else {
      this.setState({ selectedPostIDs: [] });
    }
  };

  allPostsSelected = () => {
    const { selectedPostIDs } = this.state;
    const { roadmapPosts } = this.props;
    return selectedPostIDs.length === roadmapPosts.length;
  };

  focusCellByKey = (cellKey) => {
    if (cellKey === 'add-row-cell') {
      this.addPostRef.current?.getWrappedInstance?.()?.onAddPost?.();
      return;
    }

    const { columns } = this.state;
    const table = this.tableRef.current;
    const tableContainer = table.parentNode;
    const cell = document.querySelector(`[data-cell-key="${cellKey}"]`);

    // when virtualized, the cell may not exist
    if (!cell) {
      return;
    }

    const cellRect = cell.getBoundingClientRect();
    const focusableElement = cell.querySelector('.focusable');
    const stickyWidth = columns.reduce((width, column) => {
      if (typeof column.width !== 'number') {
        return width;
      }

      return column.sticky && !column.hidden ? column.width + width : width;
    }, 0);

    if (focusableElement) {
      focusableElement.focus();
      let scroll = null;

      // calculate horizontal scroll
      const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
      const cellLeftOffset = cellRect.left - 500; // subtract post title column's min-width
      const cellRightOffset = cellRect.right - (viewportWidth - stickyWidth);
      const isCellHorizontallyVisible = cellLeftOffset >= 0 && cellRightOffset <= 0;
      if (!isCellHorizontallyVisible) {
        const cellRightBorder = cell.offsetLeft + cell.clientWidth - viewportWidth + stickyWidth;
        const cellLeftBorder = cell.offsetLeft - 500;

        scroll = {
          ...scroll,
          left: cellLeftOffset < 0 ? cellLeftBorder : cellRightBorder,
        };
      }

      // calculate vertical scroll
      const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
      const cellBottomOffset = cellRect.bottom - viewportHeight;
      const cellTopOffset = cellRect.top - (table.offsetTop + cell.clientHeight);
      const isCellVerticallyVisible = cellTopOffset >= 0 && cellBottomOffset <= 0;
      if (!isCellVerticallyVisible) {
        const cellTopBorder = cell.offsetTop - cell.clientHeight;
        const cellBottomBorder =
          cell.offsetTop - viewportHeight + (table.offsetTop + cell.clientHeight);

        scroll = {
          ...scroll,
          top: cellTopOffset < 0 ? cellTopBorder : cellBottomBorder,
        };
      }

      // scroll automatically
      if (scroll) {
        tableContainer.scrollTo({
          ...scroll,
          behavior: 'smooth',
        });
      }
    }
  };

  onResizeStart = (e, columnID) => {
    const width = document.querySelector(`#header-${columnID}`).getBoundingClientRect().width;
    this.setState({
      resizingColumnID: columnID,
      resizeStartPoint: e.clientX,
      resizeStartingWidth: width,
      resizeAmount: width,
      resizing: true,
    });
  };

  onResizeEnd = async (columnID) => {
    const { reloadRoadmap, roadmap } = this.props;
    const { resizeAmount } = this.state;
    if (!resizeAmount) {
      return;
    }

    const columnWidths = getColumnWidths(roadmap);

    columnWidths[columnID] = resizeAmount;

    this.setState({
      resizing: false,
    });

    await this.props.updateServerSettings(
      roadmap.settings.hiddenColumnIDs,
      this.props.groupBy,
      roadmap.settings.lastActiveFilters?.viewID ?? null,
      columnWidths
    );

    await reloadRoadmap(roadmap.urlName);

    this.setState({
      resizingColumnID: null,
      resizeAmount: null,
      resizeStartPoint: null,
      mouseDifference: null,
      resizeStartingWidth: null,
    });
  };

  onMouseMove = (e) => {
    const { resizing, resizingColumnID, resizeStartPoint, columns, resizeStartingWidth } =
      this.state;
    if (resizing && !!columns) {
      let leftOffset = e.clientX;

      // use the width that the heading started with
      const width = resizeStartingWidth;

      // record the difference between offset and starting point
      const mouseDifference = leftOffset - resizeStartPoint;

      // clamp the offset based on width
      const minWidth = resizingColumnID === 'title' ? 264 : 130;
      const maxWidth = 600;

      if (width + mouseDifference <= minWidth) {
        leftOffset = resizeStartPoint - width + minWidth;
      }
      if (width + mouseDifference >= maxWidth) {
        leftOffset = resizeStartPoint - width + maxWidth;
      }

      this.setState({
        resizeAmount: width + leftOffset - resizeStartPoint,
        mouseDifference,
      });
    }
  };

  onMouseUp = () => {
    if (this.state.resizingColumnID) {
      this.onResizeEnd(this.state.resizingColumnID);
    }
  };

  onDragStart = (columnID) => {
    this.setState({ draggingColumnID: columnID });
  };

  onDragEnd = () => {
    this.setState({ draggingColumnID: null });
  };

  getCellKey = (columnID, roadmapPost) => {
    if (!columnID || !roadmapPost?._id) {
      return null;
    }

    return `${columnID}-${roadmapPost._id}`;
  };

  updateFactorValue = async (factor, roadmapPost, newValue) => {
    const { boards, company, location, params, reloadRoadmapPosts, roadmap } = this.props;

    const validationError = this.getErrorByType(newValue, factor.type, { isEffort: factor.effort });
    if (validationError) {
      this.setState((state) => ({
        errors: {
          ...state.errors,
          [this.getCellKey(factor._id, roadmapPost)]: validationError,
        },
      }));
      return;
    }

    const response = await AJAX.post('/api/roadmaps/posts/update', {
      factorValues: {
        ...roadmapPost.factorValues,
        [factor._id]: newValue,
      },
      roadmapPostID: roadmapPost._id,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });
    this.setState((state) => ({
      errors: {
        ...state.errors,
        [this.getCellKey(factor._id, roadmapPost)]: error ? 'Please enter a valid value' : null,
      },
      sort: null,
    }));

    const board = boards.find((board) => board.urlName === params.boardURLName);
    const queryParams = getQueryFilterParams(board, company, location, {}, roadmap);

    if (!error) {
      this.setState({ sort: null }, () => reloadRoadmapPosts(queryParams));
    }
  };

  onResize = (event) => {
    const { isMobile } = this.state;
    this.updateViewportHeight();
    const newIsMobile = document.body.clientWidth <= MobileWidth;
    if (isMobile === newIsMobile) {
      return;
    }
    this.setState({ isMobile: newIsMobile }, this.recalculateColumns);
  };

  recalculateColumns = () => {
    this.setState({ columns: this.getColumns() });
  };

  sortRows = (rows, sort) => {
    const { company, roadmap } = this.props;
    const { rows: oldRows } = this.state;

    if (!sort) {
      // create map from rows to their old position in the list
      const oldRowsMap = oldRows.reduce(
        (oldRowsMap, oldRow, idx) => ({
          ...oldRowsMap,
          [oldRow._id]: idx,
        }),
        {}
      );

      // if sort is not defined, we should use the old rows ordering
      return [...rows].sort((rowA, rowB) => {
        const rowAOldIdx = oldRowsMap[rowA._id];
        const rowBOldIdx = oldRowsMap[rowB._id];

        // new rows should be appended to the end of the row list
        if (rowAOldIdx === undefined) {
          return 1;
        } else if (rowBOldIdx === undefined) {
          return -1;
        }

        const rowAComesFirst = rowAOldIdx < rowBOldIdx;
        const rowBComesFirst = rowAOldIdx > rowBOldIdx;
        if (rowAComesFirst) {
          return -1;
        } else if (rowBComesFirst) {
          return 1;
        } else {
          return 0;
        }
      });
    }

    const { column, order } = sort;

    const sortFunction = determineSortFunction(roadmap, column);

    return [...rows].sort((rowA, rowB) => {
      return sortFunction(rowA, rowB, column, order, company, roadmap);
    });
  };

  getColumnWidth = (column) => {
    const { resizingColumnID, resizeAmount } = this.state;
    return resizingColumnID === column.id ? resizeAmount : column.width;
  };

  renderHeaderCell = (column, index, canEdit) => {
    const { sort, resizingColumnID, resizing, draggingColumnID } = this.state;
    const { roadmap, roadmapPosts } = this.props;

    if (column.hidden) {
      return null;
    }

    const cellTitles = {
      effort: 'Effort',
      integrations: 'Push',
      score: 'Score',
      title: `Posts (${roadmapPosts.length})`,
    };

    // Add names to column titles for factors and descriptionColumns
    roadmap?.factors.forEach((factor) => {
      cellTitles[factor._id] = factor.name;
    });
    roadmap?.descriptionColumns.forEach((descriptionColumn) => {
      cellTitles[descriptionColumn._id] = descriptionColumn.name;
    });

    const content = cellTitles[column.id];
    let sortIcon = null;
    if (sort && column.id === sort.column) {
      sortIcon = {
        desc: <ArrowDown className="sort-icon" strokeWidth={2} size={14} />,
        asc: <ArrowUp className="sort-icon" strokeWidth={2} size={14} />,
      }[sort.order];
    } else if (column.sortable) {
      sortIcon = <ArrowUpDown className="sort-icon unsorted-icon" strokeWidth={2} size={14} />;
    }

    const width = this.getColumnWidth(column);

    const allowConfiguration = this.props.isAdminView;

    const showResizeHandle = draggingColumnID === null && allowConfiguration;
    const showPostSelectionToggle = column.id === 'title' && allowConfiguration;

    const disableReorder = !column.draggable || !canEdit || !allowConfiguration;

    return (
      <div
        id={`header-${column.id}`}
        className={classnames('cell header', column.id, `section-${column.section}`, {
          sticky: column.sticky,
        })}
        style={{ ...column.style, width }}
        key={`${column.id}_${roadmap?._id}`}>
        <Draggable
          disabled={disableReorder}
          onDragEnd={this.onDragEnd}
          onDragStart={this.onDragStart}
          placeholder={<div className="draggablePlaceholder" />}
          value={column.id}>
          <div className="inner-wrapper">
            {showPostSelectionToggle ? (
              <CheckboxV2
                className="header-checkbox"
                checked={this.allPostsSelected()}
                onChange={this.onToggleAllPostSelection}
              />
            ) : null}
            <Tappable onTap={column.sortable ? () => this.changeSort(column.sortKey) : () => null}>
              <div className="content">
                <Tooltip
                  className="adminRoadmapTableLabelTooltip"
                  delay={300}
                  position="top"
                  value={content}>
                  <div className="label">
                    <Truncate numberOfLines={1}>{content}</Truncate>
                    <div>{sortIcon}</div>
                  </div>
                </Tooltip>
              </div>
            </Tappable>
          </div>
        </Draggable>
        {showResizeHandle ? (
          <div
            className={classnames('ColumnResize__container', {
              'ColumnResize__container--active':
                resizing && this.state.resizingColumnID === column.id,
            })}
            onMouseDown={(e) => this.onResizeStart(e, column.id)}
            style={{
              height: resizing && resizingColumnID === column.id ? this.getRoadmapHeight() : 50,
            }}>
            <div className="ColumnResize__handle"></div>
          </div>
        ) : null}
      </div>
    );
  };

  renderNewRowCell = (column, total) => {
    const { boards, roadmapPosts, company, viewer } = this.props;

    if (column.hidden) {
      return null;
    }

    const showTotal = SumColumnIDs[column.id] || SumFactorTypes[column.factorType];
    const columnTitle = showTotal && column.id !== 'score' ? 'total' : column.id;
    const cellTitles = {
      score: this.renderScoreCell(total),
      title: hasPermission('manageRoadmap', company, viewer) ? (
        <AdminRoadmapAddPost
          ref={this.addPostRef}
          boards={boards}
          createRoadmapPost={this.onCreateRoadmapPost}
          roadmapPosts={roadmapPosts}
        />
      ) : (
        ''
      ),
      total,
    };

    const width = this.getColumnWidth(column);

    const content = !showTotal || total !== 0 ? cellTitles[columnTitle] : null;

    let copy = null;

    if (showTotal) {
      copy = (
        <P variant="bodyMd" className="cellInput">
          {content}
        </P>
      );
    } else if (content) {
      copy = content;
    }

    return (
      <div
        className={classnames('cell', column.id, `section-${column.section}`, {
          sticky: column.sticky,
        })}
        data-cell-key={`new-row-${column.id}`}
        key={`new-row-${column.id}`}
        style={{ ...column.style, width }}>
        {copy}
      </div>
    );
  };

  renderBulkActionBar = () => {
    const { roadmap, roadmapPosts, selectedPost } = this.props;
    const { selectedPostIDs } = this.state;

    if (!this.props.isAdminView) {
      return null;
    }

    if (selectedPostIDs.length === 0) {
      return null;
    }

    const selectedPosts = roadmapPosts
      .filter((roadmapPost) => selectedPostIDs.includes(roadmapPost.postID))
      .map((roadmapPost) => roadmapPost.post);

    return (
      <AdminRoadmapBulkActionBar
        onClose={() => this.setState({ selectedPostIDs: [] })}
        currentRoadmap={roadmap}
        selectedPost={selectedPost}
        selectedPosts={selectedPosts}
      />
    );
  };

  getRoadmapHeight = () => {
    const { roadmapPosts, groupBy } = this.props;
    const table = this.tableRef.current;
    const tableContainer = table.parentNode;
    const scrollHeight = tableContainer?.scrollTop ?? 0;
    if (groupBy && groupBy !== GroupByOptions.None) {
      const { groupings } = this.getGroupByState();
      const { ungroupedRows } = this.state;
      // header row + groupings + roadmapPosts + (1 if ungrouped > 0)
      return (
        50 * (groupings.length + roadmapPosts.length + 1 + (ungroupedRows.length > 0 ? 1 : 0)) -
        scrollHeight
      );
    } else {
      return 50 * (roadmapPosts.length + 1) - scrollHeight;
    }
  };

  renderDropArea = (dropAreaElement) => {
    return (
      <DropArea
        className="dropArea"
        key={`${dropAreaElement.position}-${dropAreaElement.column?.id}`}
        onDrop={(fromColumn) => this.onColumnMove(fromColumn, dropAreaElement)}
        // subtract half the width to make it centered, and make it full height.
        style={{ left: dropAreaElement.left - 10 }}>
        <div className="dropAreaBackground" style={{ height: this.getRoadmapHeight() }} />
      </DropArea>
    );
  };

  renderNewRow = (columns, rows) => {
    const { roadmap } = this.props;

    if (!roadmap) {
      return null;
    }

    const cells = columns.map((column) => {
      let total = 0;
      if (SumFactorTypes[column.factorType] || SumColumnIDs[column.id]) {
        total = rows.reduce((totalCount, row) => {
          return row[column.id] ? (totalCount += row[column.id]) : totalCount;
        }, 0);
      }

      return this.renderNewRowCell(column, total);
    });

    return <div className="row newRow">{cells}</div>;
  };

  renderScoreCell(value) {
    return (
      <span className={classnames('scoreLabel', { highScore: value >= 1000 })}>
        {getScoreForDisplay(value)}
      </span>
    );
  }

  renderHeaderRow = (columns) => {
    const { company, viewer } = this.props;
    const canEdit = hasPermission('manageRoadmap', company, viewer);
    const dropAreaElements = this.getDropAreaElements(columns);
    return (
      <div className="row headerRow">
        {dropAreaElements.map(this.renderDropArea)}
        {columns.map((column, i) => this.renderHeaderCell(column, i, canEdit))}
      </div>
    );
  };

  renderFullWidthRow = (columns, content) => {
    const stickyColumns = columns.filter((column) => column.sticky && !column.hidden);

    return (
      <div className="row fullWidth">
        <div className="cell fullWidth">{content}</div>
        {stickyColumns.map((column) => (
          <div
            className={classnames('cell', column.id, `section-${column.section}`, {
              sticky: column.sticky,
            })}
            key={column.id}
            style={{ ...column.style, width: column.width }}
          />
        ))}
      </div>
    );
  };

  wrapRowsInCategory = (rows) => {
    const { groupings, key, label, type } = this.getGroupByState();
    const sortedGroupings = this.sortGroupings(groupings);
    const { columns, errors, selectedPost, selectedPostIDs, ungroupedRows } = this.state;

    return (
      <>
        {sortedGroupings.map((grouping, index) => {
          const relevantRows =
            type === GroupByTypes.default
              ? rows.filter((row) => row[key] === grouping._id)
              : rows.filter((row) =>
                  row.roadmapPost.post.customPostFields.some(
                    (field) => field.customPostFieldID === key && field.value === grouping._id
                  )
                );
          return (
            <AdminRoadmapAccordion
              count={relevantRows.length}
              key={grouping._id}
              groupID={grouping._id}
              name={grouping.name}>
              {relevantRows.map((row) => (
                <AdminRoadmapTableRow
                  key={row._id}
                  row={row}
                  columns={columns}
                  errors={errors}
                  getCellKey={this.getCellKey}
                  getColumnWidth={this.getColumnWidth}
                  selectedPost={selectedPost}
                  selectedPostIDs={selectedPostIDs}
                />
              ))}
            </AdminRoadmapAccordion>
          );
        })}
        {ungroupedRows.length !== 0 && (
          <AdminRoadmapAccordion
            count={ungroupedRows.length}
            key="ungrouped"
            name={`No ${label}`}
            groupID="ungrouped">
            {ungroupedRows.map((row, index) => (
              <AdminRoadmapTableRow
                key={row._id}
                row={row}
                columns={columns}
                errors={errors}
                getCellKey={this.getCellKey}
                getColumnWidth={this.getColumnWidth}
                selectedPost={selectedPost}
                selectedPostIDs={selectedPostIDs}
              />
            ))}
          </AdminRoadmapAccordion>
        )}
      </>
    );
  };

  render() {
    const { error, groupBy, loading, roadmapPosts } = this.props;
    const { columns, rows } = this.state;
    if (!roadmapPosts) {
      return null;
    }

    let tableBody = null;
    if (loading) {
      tableBody = this.renderFullWidthRow(columns, <Spinner />);
    } else if (error === Errors.Loading) {
      tableBody = this.renderFullWidthRow(
        columns,
        <div className="error">
          Something went wrong while trying to load these posts. Please, try again later.
        </div>
      );
    } else if (error === Errors.Creating) {
      tableBody = this.renderFullWidthRow(
        columns,
        <div className="error">
          Something went wrong while trying to create a post. Please, try again later.
        </div>
      );
    } else {
      if (groupBy && groupBy !== GroupByOptions.None) {
        tableBody = this.wrapRowsInCategory(rows);
      } else {
        tableBody = this.renderVirtualizedRows(rows, columns);
      }
    }

    return (
      <div
        className="adminRoadmapTable"
        ref={(el) => {
          this.tableRef.current = el;
          this.containerRef.current = el?.parentNode;
        }}>
        {this.renderHeaderRow(columns)}
        {tableBody}
        {this.renderNewRow(columns, rows)}
        {this.renderBulkActionBar()}
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadRoadmapPosts: (queryParams) => {
      return dispatch(reloadRoadmapPosts(queryParams));
    },
    reloadRoadmap: (roadmapURLName) => {
      return dispatch(reloadRoadmap(roadmapURLName));
    },
  })),
  withContexts(
    {
      company: CompanyContext,
      location: LocationContext,
      params: ParamsContext,
      viewer: ViewerContext,
      isAdminView: IsAdminViewContext,
    },
    { forwardRef: true }
  )
)(AdminRoadmapTable);
