import React, { useContext } from 'react';

import { CompanyContext } from 'common/containers/CompanyContainer';
import { getQueryParams } from 'common/containers/QueueItemListContainer';
import { LocationContext } from 'common/containers/RouterContainer';
import cloneElementWithProps from 'common/core/cloneElementWithProps';

import 'css/components/subdomain/admin/_AdminQueue.scss';

import AdminQueueAuditLog from './AdminQueueAuditLog';
import AdminQueuePosts from './AdminQueuePosts';
import AdminQueueSpam from './AdminQueueSpam';

import type { Company } from 'common/api/endpoints/companies';

const AdminQueueSwitch = (props: Record<string, unknown>) => {
  const location = useContext(LocationContext);
  const company = useContext<Company>(CompanyContext);
  const queryParams = getQueryParams(location);

  // A user can set their preferences to spam, ut then downgrade and lose access to spam.
  // To prevent crashes we don't allow the component to be rendered. Resetting prefs is handled by AdminQueue
  if (queryParams?.source === 'spam') {
    return cloneElementWithProps(<AdminQueueSpam />, props);
  }

  if (company.queueAutomation?.enabled) {
    return cloneElementWithProps(<AdminQueueAuditLog />, props);
  }

  return cloneElementWithProps(<AdminQueuePosts />, props);
};

export default AdminQueueSwitch;
