import React from 'react';

import { Minus } from 'lucide-react';

import {
  isActionAddPostCategory,
  isActionAddPostTags,
  isActionAddToRoadmap,
  isActionApplyPostStatus,
} from 'common/automations/actionUtils';
import IconButtonV2 from 'common/ui/IconButtonV2';
import SingleSelect from 'common/ui/SingleSelect';
import stringSort from 'common/util/stringSort';
import unique from 'common/util/unique';

import AddPostCategory from './AddPostCategory';
import AddPostTags from './AddPostTags';
import AddToRoadmap from './AddToRoadmap';
import ApplyPostStatus from './ApplyPostStatus';
import { actionLabels, findActiveOption } from '../util';

import type { Action, ActionType, Filter } from 'common/automations/constants';

export interface FormAction extends Action {
  id: string;
}

type ActionRowProps = {
  action: FormAction;
  allowedActions: ActionType[];
  filters: Filter[];
  onActionChange: (properties: FormAction['properties']) => void;
  onActionTypeChange: (id: string, actionType: ActionType) => void;
  onRemove: (id: string) => void;
};

const ActionRow = ({
  action,
  allowedActions,
  filters,
  onActionChange,
  onActionTypeChange,
  onRemove,
}: ActionRowProps) => {
  // Allowed actions, plus the currently selected action
  const actionTypeDropdownOptions = unique([...allowedActions, action.type])
    .map((actionType) => ({
      label: actionLabels[actionType],
      value: actionType,
    }))
    .sort(stringSort('label', 'asc', true));

  return (
    <div className="actionRow">
      <SingleSelect
        className="grid1"
        options={actionTypeDropdownOptions}
        // Assert these types, since the select is not clearable
        onChange={(option) => option && onActionTypeChange(action.id, option.value as ActionType)}
        placeholder="Select an action"
        value={findActiveOption(actionTypeDropdownOptions, action.type)}
      />
      <ActionField
        key={action.id}
        filters={filters}
        type={action.type}
        properties={action.properties}
        onChange={onActionChange}
      />
      <IconButtonV2
        className="grid3"
        size="medium"
        variant="plain"
        icon={Minus}
        aria-label="Remove action"
        onClick={() => onRemove(action.id)}
      />
    </div>
  );
};

interface ActionFieldProps<A extends Action = Action> {
  filters: Filter[];
  type: A['type'];
  properties: A['properties'];
  onChange: <OcA extends Action = A>(properties?: OcA['properties']) => void;
}

const ActionField = ({ filters, ...props }: ActionFieldProps<Action>) => {
  if (isActionAddPostCategory(props)) {
    const { properties, onChange } = props;
    return <AddPostCategory filters={filters} properties={properties} onChange={onChange} />;
  } else if (isActionAddPostTags(props)) {
    const { properties, onChange } = props;
    return <AddPostTags filters={filters} properties={properties} onChange={onChange} />;
  } else if (isActionApplyPostStatus(props)) {
    const { properties, onChange } = props;
    return <ApplyPostStatus filters={filters} properties={properties} onChange={onChange} />;
  } else if (isActionAddToRoadmap(props)) {
    const { properties, onChange } = props;
    return <AddToRoadmap filters={filters} properties={properties} onChange={onChange} />;
  } else {
    return null;
  }
};

export default ActionRow;
