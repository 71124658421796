import React, { useContext } from 'react';

import { Confidence } from 'common/api/resources/inbox';
import Popover from 'common/common/Popover';
import { BoardsContext } from 'common/containers/BoardsContainer';
import { CompanyContext } from 'common/containers/CompanyContainer';
import CheckboxWithLabel from 'common/ui/CheckboxWithLabel';
import { H3, P } from 'common/ui/Text';
import styles from 'css-module/components/subdomain/admin/AdminQueue/_AdminQueueSettingsModal.module.scss';

import useFilterControls from '../useFilterControls';

import type { PostType } from 'common/actions/queueItemQueries';
import type { Board } from 'common/api/endpoints/boards';

type BoardsFilterProps = {
  boards: Board[];
  selectedBoardURLNames?: string[];
  onBoardSelect: (board: Board) => (checked: boolean) => void;
};

type ViewOptionsFilterProps = {
  selectedPostTypes?: PostType[];
  onTogglePostType: (type: PostType) => (checked: boolean) => void;
};

type SpamFilterProps = {
  selectedConfidence?: Confidence[];
  onToggleSpamConfidence: (value: Confidence) => (checked: boolean) => void;
};

const ViewOptions: { label: string; type: PostType }[] = [
  { label: 'New posts', type: 'newPost' },
  { label: 'Duplicate posts', type: 'duplicate' },
];

const SpamConfidenceOptions = [
  { label: 'High confidence', value: Confidence.highConfidence },
  { label: 'Low confidence', value: Confidence.lowConfidence },
] as const;

const BoardsFilter = ({ boards, selectedBoardURLNames, onBoardSelect }: BoardsFilterProps) => {
  return (
    <div className={styles.content}>
      <H3 className={styles.subheading}>Boards</H3>
      {boards.length === 0 && <P>No boards found</P>}
      <ul className={styles.list}>
        {boards.map((board) => (
          <li key={board._id}>
            <CheckboxWithLabel
              aria-label={`toggle ${board.name}`}
              checked={selectedBoardURLNames?.includes(board.urlName) ?? false}
              onChange={onBoardSelect(board)}
              size="medium">
              {board.name}
            </CheckboxWithLabel>
          </li>
        ))}
      </ul>
    </div>
  );
};

const ViewOptionsFilter = ({ selectedPostTypes, onTogglePostType }: ViewOptionsFilterProps) => {
  return (
    <div className={styles.content}>
      <H3 className={styles.subheading}>View Options</H3>
      <ul className={styles.list}>
        {ViewOptions.map((option) => (
          <li key={option.type}>
            <CheckboxWithLabel
              checked={selectedPostTypes?.includes(option.type) ?? false}
              onChange={onTogglePostType(option.type)}
              size="medium">
              {option.label}
            </CheckboxWithLabel>
          </li>
        ))}
      </ul>
    </div>
  );
};

const SpamFilter = ({ selectedConfidence, onToggleSpamConfidence }: SpamFilterProps) => {
  return (
    <div className={styles.content}>
      <H3 className={styles.subheading}>Spam</H3>
      <ul className={styles.list}>
        {SpamConfidenceOptions.map(({ label, value }) => (
          <li key={value}>
            <CheckboxWithLabel
              checked={selectedConfidence?.includes(value)}
              onChange={onToggleSpamConfidence(value)}
              size="medium">
              {label}
            </CheckboxWithLabel>
          </li>
        ))}
      </ul>
    </div>
  );
};

type Props = {
  reloadCompany: () => void;
};

const AdminQueueFiltersModal = ({ reloadCompany }: Props) => {
  const boards = useContext(BoardsContext);
  const company = useContext(CompanyContext);
  const isQueueAutomationEnabled = !!company.queueAutomation?.enabled;

  const { queryParams, updateFilters } = useFilterControls({ reloadCompany });

  if ('loading' in boards) {
    return null;
  }

  const onBoardSelect = (board: Board) => (checked: boolean) => {
    const currentValue = queryParams.boardURLNames ?? [];
    if (checked) {
      updateFilters({ boardURLNames: [...currentValue, board.urlName] });
    } else {
      const filteredBoards = currentValue.filter((urlName) => urlName !== board.urlName);

      updateFilters({ boardURLNames: filteredBoards.length ? filteredBoards : undefined });
    }
  };

  const onTogglePostType = (type: PostType) => (checked: boolean) => {
    const currentValue = queryParams.postTypes ?? [];
    if (checked) {
      updateFilters({ postTypes: [...currentValue, type] });
    } else {
      const filteredTypes = currentValue.filter((currentType) => currentType !== type);

      updateFilters({ postTypes: filteredTypes.length ? filteredTypes : undefined });
    }
  };

  const onToggleSpamConfidence = (value: Confidence) => (checked: boolean) => {
    const currentValue = queryParams.confidence || [];

    if (checked) {
      updateFilters({ confidence: [...currentValue, value] });
    } else {
      const filteredConfidence = currentValue.filter((confidenceItem) => confidenceItem !== value);
      updateFilters({ confidence: filteredConfidence });
    }
  };

  return (
    <Popover className={styles.settingsModal}>
      {!isQueueAutomationEnabled ? (
        <>
          <BoardsFilter
            boards={boards}
            selectedBoardURLNames={queryParams.boardURLNames}
            onBoardSelect={onBoardSelect}
          />
          <div className={styles.divider} />
          <ViewOptionsFilter
            selectedPostTypes={queryParams.postTypes}
            onTogglePostType={onTogglePostType}
          />
          <div className={styles.divider} />
        </>
      ) : null}
      <SpamFilter
        selectedConfidence={queryParams.confidence}
        onToggleSpamConfidence={onToggleSpamConfidence}
      />
    </Popover>
  );
};

export default AdminQueueFiltersModal;
