const devVariables = {
  asanaClientID: '1202621338805258',
  azureClientID: 'ea3dd8ad-4e1e-42d3-bfd4-0c9e9f892ce0',
  azureDevopsClientID: '4B5C0247-4B14-4036-AACC-FCF584C82D4E',
  cannyCompanyID: '66a242797628d618ab638200', // feedback.cannisters.website
  discordClientID: '915397534577479700',
  gongClientID: 'he3tn2n02',
  googleOauthClientID: '407440207476-ovmdrqb7bghgvavddlp9rt8b640jr5a5.apps.googleusercontent.com',
  helpscoutClientID: 'DFSnAoDcz5Vv7cNQwiqZJ38JS7HsHpBx',
  hostnameOverride: 'cannisters.website',
  intercomClientID: 'd1393031-ae6b-4e50-a13e-00dba48d6298',
  jiraIntegrationKey: 'canny-addon-dev',
  linearClientID: 'c1c54d81a0c6e6d82aedfce4a0346c4b',
  salesforceConsumerKey:
    '3MVG9LBJLApeX_PD9jYUxqSbdBT1LrZXEyqQmBi5PrK2k_ej0tfx2bqaOoaKdrdlWVL4tIqQ7tQ==',
  slackClientID: '8409347460.179589159411',
  supportsSearchEngineIndexing: false,
  zendeskClientID: 'cannisters_testing_client',
  zoomClientID: 'PmWrTRHlQjuabzmTF5UXsw',
};

type Keys = keyof typeof devVariables;
type Config = {
  [K in keyof typeof devVariables]: (typeof devVariables)[K];
};
type Override<T, O> = Omit<T, keyof O> & O;

const stagingVariables = {
  asanaClientID: '1202621338805258',
  azureClientID: 'ea3dd8ad-4e1e-42d3-bfd4-0c9e9f892ce0',
  azureDevopsClientID: '4B5C0247-4B14-4036-AACC-FCF584C82D4E',
  cannyCompanyID: '678174a14e468d7f17a69ea1', // feedback.cannisters.cafe
  discordClientID: '915397534577479700',
  gongClientID: 'he3tn2n02',
  googleOauthClientID: '407440207476-60t2tkbtpupspui8be37gi9ios3hg07h.apps.googleusercontent.com',
  helpscoutClientID: 'VN2U97RvESmHl8NOjRsKP5d2337iOYwq',
  hostnameOverride: 'cannisters.cafe',
  intercomClientID: 'd1393031-ae6b-4e50-a13e-00dba48d6298',
  jiraIntegrationKey: 'canny-addon-dev',
  linearClientID: 'c1c54d81a0c6e6d82aedfce4a0346c4b',
  salesforceConsumerKey:
    '3MVG9LBJLApeX_PD9jYUxqSbdBT1LrZXEyqQmBi5PrK2k_ej0tfx2bqaOoaKdrdlWVL4tIqQ7tQ==',
  slackClientID: '8409347460.179589159411',
  supportsSearchEngineIndexing: false,
  zendeskClientID: 'cannisters_testing_client',
  zoomClientID: 'PmWrTRHlQjuabzmTF5UXsw',
} satisfies Config;

type ProductionConfig = Override<Config, { hostnameOverride: null }>;
const productionVariables = {
  asanaClientID: '1204095351455949',
  azureClientID: 'ea3dd8ad-4e1e-42d3-bfd4-0c9e9f892ce0',
  azureDevopsClientID: '4B5C0247-4B14-4036-AACC-FCF584C82D4E',
  cannyCompanyID: '58a6799d8d61b9968dad91a2', // feedback.canny.io
  discordClientID: '915397534577479700',
  gongClientID: 'he3tn2n02',
  googleOauthClientID: '407440207476-18s2lf12sn6317eu06llthplbfj4gal2.apps.googleusercontent.com',
  helpscoutClientID: 'LIUtk58FzBO0jXVLhUg28gkN9NJLPOKG',
  hostnameOverride: null,
  intercomClientID: '50674c8b-5219-4362-b8fc-cda413cc5a21',
  jiraIntegrationKey: 'canny-addon',
  linearClientID: 'c1c54d81a0c6e6d82aedfce4a0346c4b',
  salesforceConsumerKey:
    '3MVG9LBJLApeX_PD9jYUxqSbdBT1LrZXEyqQmBi5PrK2k_ej0tfx2bqaOoaKdrdlWVL4tIqQ7tQ==',
  slackClientID: '8409347460.179589159411',
  supportsSearchEngineIndexing: true,
  zendeskClientID: 'zdg-canny-zendesk-integration',
  zoomClientID: 'QZtY5HP9SXaAAXZaun0QOA',
} satisfies ProductionConfig;

export default function getConfig<K extends Keys>(key: K): Config[K] | ProductionConfig[K] {
  if (__DEV_ENV__) {
    return devVariables[key];
  }

  if (__STAGING__) {
    return stagingVariables[key];
  }

  return productionVariables[key];
}
