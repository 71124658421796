import React, { memo } from 'react';

import classnames from 'classnames';

import AdminRoadmapTableCell from './AdminRoadmapTableCell';

import type { Column, Row } from './AdminRoadmapTable';
import type { Post } from 'common/api/resources/posts';

type Props = {
  row: Row;
  columns: Column[];
  errors: Record<string, string>;
  getCellKey: (columnID: string, row: unknown) => string;
  getColumnWidth: (column: Column) => number;
  selectedPost: Post;
  selectedPostIDs: string[];
};

/**
 * If a `column.render` method is not pure, then this component could render outdated rows.
 * Every prop that a row needs to render must be passed through the props of this
 * component in order to properly render each row.
 * Read the discussion on https://github.com/Canny/canny/pull/7311#pullrequestreview-2509192970
 */
const AdminRoadmapTableRow = memo(
  ({ row, columns, errors, getCellKey, getColumnWidth, selectedPost, selectedPostIDs }: Props) => {
    const isPostSelected = row.roadmapPost.postID === selectedPost?._id;
    const isChecked = selectedPostIDs.includes(row.roadmapPost.postID);

    const isLastRegularColumn = (column: Column) => {
      const lastRegularColumn = [...columns].reverse().find((col) => !col.sticky);
      return lastRegularColumn?.id === column.id;
    };

    return (
      <li
        className={classnames('row', {
          selected: isPostSelected || isChecked,
        })}>
        {columns
          .filter((column) => !column.hidden)
          .map((column) => (
            <AdminRoadmapTableCell
              key={getCellKey(column.id, row)}
              column={column}
              error={errors[getCellKey(column.id, row)]}
              cellKey={getCellKey(column.id, row)}
              width={getColumnWidth(column)}
              isLastRegularColumn={isLastRegularColumn(column)}>
              {column.render({
                index: row.index,
                roadmapPost: row.roadmapPost,
                value: row[column.id as keyof Row],
              })}
            </AdminRoadmapTableCell>
          ))}
      </li>
    );
  }
);

export default AdminRoadmapTableRow;
