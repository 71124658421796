export default function cloneWithDefinedProps<O extends object, K extends keyof O>(
  object: O,
  props: readonly K[] = []
) {
  return props.reduce((acc, prop) => {
    if (typeof object[prop] !== 'undefined') {
      acc[prop] = object[prop];
    }
    return acc;
  }, {} as Pick<O, K>);
}
