export enum AutopilotActionType {
  create = 'create',
  merge = 'merge',
  dismiss = 'dismiss',
}

export enum AutopilotActionSourceType {
  post = 'post',
  draft = 'draft',
  idea = 'idea',
  insight = 'insight',
}
/**
 * The maximum number of pending drafts to allow before we start processing feature extraction items.
 */
export const MaxPendingDrafts = 100;
