import { DefaultDescriptionColumnFields } from 'common/prioritization/DescriptionColumnTypes';
import { isNotNil } from 'common/util/isNil';
import mapify from 'common/util/mapify';

import type { Row } from './AdminRoadmapTable';
import type { Company } from 'common/api/endpoints/companies';
import type { Roadmap } from 'common/api/endpoints/roadmaps';
import type { Tag } from 'common/api/resources/posts';

const defaultSort = (rowA: Row, rowB: Row, column: 'title', order: string) => {
  let rowAComesFirst;
  let rowBComesFirst;
  if (rowA.roadmapPost.post[column] === undefined) {
    return order === 'desc' ? -1 : 1;
  }
  if (rowB.roadmapPost.post[column] === undefined) {
    return order === 'desc' ? 1 : -1;
  }

  if (isNotNil(rowA.roadmapPost.post[column]) && isNotNil(rowB.roadmapPost.post[column])) {
    rowAComesFirst =
      rowA.roadmapPost.post[column].toLowerCase() < rowB.roadmapPost.post[column].toLowerCase();
    rowBComesFirst =
      rowA.roadmapPost.post[column].toLowerCase() > rowB.roadmapPost.post[column].toLowerCase();
  }

  if (rowAComesFirst) {
    return order === 'desc' ? 1 : -1;
  } else if (rowBComesFirst) {
    return order === 'desc' ? -1 : 1;
  } else {
    // default to score
    return rowB.score - rowA.score;
  }
};

// Helper function to get the index of a value in columnOptions for dropdown (select and multiselect) fields
const getIndexOfValueForDropdownFields = (
  value: string | string[] | undefined,
  columnOptions: string[]
): number => {
  if (!value) {
    return -1;
  }

  // for multiselect fields, we need to find the minimum index among the selected options
  if (Array.isArray(value)) {
    return value.reduce((minIndex, option) => {
      const index = columnOptions.indexOf(option);
      return index !== -1 && index < minIndex ? index : minIndex;
    }, columnOptions.length);
  }
  return columnOptions.indexOf(value) ?? -1;
};

const sortByCustomDescriptionColumn = (
  rowA: Row,
  rowB: Row,
  column: string,
  order: string,
  _company: Company,
  roadmap: Roadmap
) => {
  const columnOptions = roadmap.descriptionColumns?.find(
    (descriptionColumn) => descriptionColumn.fieldID === column
  )?.options;

  const descriptionColumnA = rowA.roadmapPost.post.customPostFields.find(
    (customPostField) => customPostField.customPostFieldID === column
  );
  const descriptionColumnB = rowB.roadmapPost.post.customPostFields.find(
    (customPostField) => customPostField.customPostFieldID === column
  );

  if (!descriptionColumnA || !descriptionColumnA?.value) {
    return order === 'desc' ? -1 : 1;
  }
  if (!descriptionColumnB || !descriptionColumnB?.value) {
    return order === 'desc' ? 1 : -1;
  }

  let rowAComesFirst = descriptionColumnA.value < descriptionColumnB.value;
  let rowBComesFirst = descriptionColumnA.value > descriptionColumnB.value;

  // only the dropdown and multiselect fields have options
  if (columnOptions) {
    const indexA = getIndexOfValueForDropdownFields(descriptionColumnA.value, columnOptions);
    const indexB = getIndexOfValueForDropdownFields(descriptionColumnB.value, columnOptions);

    rowAComesFirst = indexA < indexB;

    rowBComesFirst = indexA > indexB;
  }

  if (rowAComesFirst) {
    return order === 'desc' ? 1 : -1;
  } else if (rowBComesFirst) {
    return order === 'desc' ? -1 : 1;
  } else {
    // default to score
    return rowB.score - rowA.score;
  }
};

const sortByRoadmapFactor = (rowA: Row, rowB: Row, column: string, order: string) => {
  const factorValuesA = rowA.roadmapPost.factorValues;
  const factorValuesB = rowB.roadmapPost.factorValues;

  const aValue = factorValuesA[column];
  const bValue = factorValuesB[column];

  if (aValue === undefined || aValue === null) {
    return order === 'desc' ? -1 : 1;
  }
  if (bValue === undefined || bValue === null) {
    return order === 'desc' ? 1 : -1;
  }

  const rowAComesFirst = aValue < bValue;
  const rowBComesFirst = aValue > bValue;

  if (rowAComesFirst) {
    return order === 'desc' ? 1 : -1;
  } else if (rowBComesFirst) {
    return order === 'desc' ? -1 : 1;
  } else {
    // default to score
    return rowB.score - rowA.score;
  }
};

const sortByName = (
  rowA: Row,
  rowB: Row,
  column: 'category' | 'owner' | 'board',
  order: string
) => {
  const rowAOwnerName = rowA.roadmapPost.post[column]?.name ?? '';
  const rowBOwnerName = rowB.roadmapPost.post[column]?.name ?? '';

  if (!rowAOwnerName) {
    return order === 'desc' ? -1 : 1;
  }
  if (!rowBOwnerName) {
    return order === 'desc' ? 1 : -1;
  }

  const rowAComesFirst = rowAOwnerName.toLowerCase() < rowBOwnerName.toLowerCase();
  const rowBComesFirst = rowAOwnerName.toLowerCase() > rowBOwnerName.toLowerCase();

  if (rowAComesFirst) {
    return order === 'desc' ? 1 : -1;
  } else if (rowBComesFirst) {
    return order === 'desc' ? -1 : 1;
  } else {
    // default to score
    return rowB.score - rowA.score;
  }
};

const concatenateName = (listOfObjects: Tag[]) => {
  const names = listOfObjects.map((object) => object.name);
  return names.join(', ');
};

const sortByConcatName = (rowA: Row, rowB: Row, column: 'tags', order: string) => {
  const rowAOwnerName =
    rowA.roadmapPost.post[column] && rowA.roadmapPost.post[column]?.length
      ? concatenateName(rowA.roadmapPost.post[column])
      : '';
  const rowBOwnerName =
    rowB.roadmapPost.post[column] && rowB.roadmapPost.post[column]?.length
      ? concatenateName(rowB.roadmapPost.post[column])
      : '';

  if (!rowAOwnerName) {
    return order === 'desc' ? -1 : 1;
  }
  if (!rowBOwnerName) {
    return order === 'desc' ? 1 : -1;
  }

  const rowAComesFirst = rowAOwnerName.toLowerCase() < rowBOwnerName.toLowerCase();
  const rowBComesFirst = rowAOwnerName.toLowerCase() > rowBOwnerName.toLowerCase();

  if (rowAComesFirst) {
    return order === 'desc' ? 1 : -1;
  } else if (rowBComesFirst) {
    return order === 'desc' ? -1 : 1;
  } else {
    // default to score
    return rowB.score - rowA.score;
  }
};

const sortByStatus = (rowA: Row, rowB: Row, column: 'status', order: string, company: Company) => {
  const status = company.statuses.map((status) => status.name);
  const rowAComesFirst =
    status.indexOf(rowA.roadmapPost.post[column]) < status.indexOf(rowB.roadmapPost.post[column]);
  const rowBComesFirst =
    status.indexOf(rowA.roadmapPost.post[column]) > status.indexOf(rowB.roadmapPost.post[column]);

  if (rowAComesFirst) {
    return order === 'desc' ? 1 : -1;
  } else if (rowBComesFirst) {
    return order === 'desc' ? -1 : 1;
  } else {
    // default to score
    return rowB.score - rowA.score;
  }
};

const sortByDate = (rowA: Row, rowB: Row, column: 'eta', order: string) => {
  const etaA =
    rowA.roadmapPost.post[column] !== null
      ? new Date(rowA.roadmapPost.post[column] as string)
      : null;
  const etaB = rowB.roadmapPost.post[column]
    ? new Date(rowB.roadmapPost.post[column] as string)
    : null;

  const areBothDefined = !!etaA && !!etaB;
  const definedEta = etaA ?? etaB;

  // Ensure empty cells are always at the top or bottom of the list.
  const rowAComesFirst = areBothDefined ? etaA > etaB : etaB && definedEta === etaB;
  const rowBComesFirst = areBothDefined ? etaA < etaB : etaA && definedEta === etaA;

  if (rowAComesFirst) {
    return order === 'desc' ? 1 : -1;
  } else if (rowBComesFirst) {
    return order === 'desc' ? -1 : 1;
  } else {
    // default to score
    return rowB.score - rowA.score;
  }
};

const sortByEffortOrScore = (rowA: Row, rowB: Row, column: 'effort' | 'score', order: string) => {
  const rowAComesFirst = rowA[column] < rowB[column];
  const rowBComesFirst = rowA[column] > rowB[column];

  if (rowAComesFirst) {
    return order === 'desc' ? 1 : -1;
  } else if (rowBComesFirst) {
    return order === 'desc' ? -1 : 1;
  } else {
    // default to score
    return rowB.score - rowA.score;
  }
};

export const determineSortFunction = (roadmap: Roadmap, column: string) => {
  const customFields = roadmap.descriptionColumns.filter(
    (descriptionColumn) => descriptionColumn.fieldType === 'customField'
  );

  const customFieldsMappedByFieldID = mapify(customFields, 'fieldID');
  const roadmapFactorsMappedByFieldID = mapify(roadmap.factors, '_id');

  if (Object.keys(customFieldsMappedByFieldID).includes(column)) {
    return sortByCustomDescriptionColumn;
  }

  if (Object.keys(roadmapFactorsMappedByFieldID).includes(column)) {
    return sortByRoadmapFactor;
  }

  if (
    column === DefaultDescriptionColumnFields.owner ||
    column === DefaultDescriptionColumnFields.category ||
    column === DefaultDescriptionColumnFields.board
  ) {
    return sortByName;
  }

  if (column === DefaultDescriptionColumnFields.tags) {
    return sortByConcatName;
  }

  if (column === DefaultDescriptionColumnFields.status) {
    return sortByStatus;
  }

  if (column === DefaultDescriptionColumnFields.eta) {
    return sortByDate;
  }

  if (column === 'score' || column === 'effort') {
    return sortByEffortOrScore;
  }

  return defaultSort;
};
