import React, { useContext } from 'react';

import { X } from 'lucide-react';

import { permissionNames } from 'common/bae/constants';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { CloseModalContext } from 'common/containers/ModalContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import Modal from 'common/modals/Modal';
import IconButtonV2 from 'common/ui/IconButtonV2';
import { H2, Span } from 'common/ui/Text';
import UserLockup from 'common/user/UserLockup';
import getFirstName from 'common/util/getFirstName';
import getMembersWithPermission from 'common/util/getMembersWithPermission';
import queryString from 'common/util/queryString';

import 'css/components/modals/_AccessModal.scss';

import type { Company, RolePermissionName } from 'common/api/endpoints/companies';
import type { Viewer } from 'common/api/endpoints/viewer';

type Props = {
  requiredPermissions: RolePermissionName[];
};

const AccessModal: React.FC<Props> = ({ requiredPermissions }: Props) => {
  const company = useContext<Company>(CompanyContext);
  const viewer = useContext<Viewer>(ViewerContext);
  const closeModal = useContext(CloseModalContext);

  const recipients = getMembersWithPermission('manageTeammates', company);
  const rolesWithPermissions = company.roles.filter((role) =>
    requiredPermissions.every((permKey) => role.permissions[permKey])
  );
  const permissions = requiredPermissions.map((permKey) => permissionNames[permKey]);

  return (
    <Modal className="accessModal">
      <div className="topContainer">
        <header>
          <H2 variant="headingSm" className="heading">
            You do not have access to this feature
          </H2>
          <Span variant="bodyMd" className="subheading">
            Please ask one of your teammates with admin access to give you&nbsp;permission.
          </Span>
        </header>
        <IconButtonV2
          variant="plain"
          className="closeButton"
          onClick={closeModal}
          icon={X}
          aria-label="close button"
          size="medium"
        />
      </div>
      <div className="divider" />
      <div className="admins">
        {recipients.map((recipient) => {
          const link =
            'mailto:' +
            recipient.email +
            queryString.stringify({
              subject: 'Canny - Requesting Additional Permissions',
              body:
                'Hi ' +
                getFirstName(recipient.name) +
                ',\n' +
                '\n' +
                "I'd like to request additional permissions in Canny. Specifically, I would like to be able to use the following permission: " +
                permissions.join(', ') +
                '\n' +
                '\n' +
                'This would involve changing my role to one of the following roles: ' +
                rolesWithPermissions.map((role) => role.name).join(', ') +
                '. Alternatively, you could add the permission to my current role or create a new role entirely.' +
                '\n' +
                '\n' +
                'You can make these changes here: https://' +
                company.subdomain +
                '.canny.io/admin/settings/team\n' +
                '\n' +
                'Thanks,\n' +
                getFirstName(viewer.name),
            });

          return (
            <a key={recipient._id} href={link} rel="noopener" target="_blank" className="admin">
              <UserLockup
                showBadge={false}
                showCompanyNames={false}
                showProfile={false}
                showTint={false}
                user={recipient}
              />
            </a>
          );
        })}
      </div>
    </Modal>
  );
};

export default AccessModal;
