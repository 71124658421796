import React, { useState } from 'react';

import { LogOut } from 'lucide-react';

import { reloadViewer } from 'common/actions/viewer';
import AJAX from 'common/AJAX';
import connect from 'common/core/connect';
import ButtonV2 from 'common/ui/ButtonV2';

import type { Dispatch } from 'redux-connect';

type ConnectProps = {
  reloadViewer: () => void;
};

const LogoutButton = ({ reloadViewer }: ConnectProps) => {
  const [loading, setLoading] = useState(false);

  const onLogout = async () => {
    setLoading(true);
    try {
      await AJAX.post('/api/viewer/logout');
      await reloadViewer();
    } finally {
      setLoading(false);
    }
  };

  return (
    <ButtonV2
      onClick={onLogout}
      aria-label="Log out"
      variant="outlined"
      color="primary"
      startIcon={LogOut}
      size="medium"
      loading={loading}>
      Log out
    </ButtonV2>
  );
};

export default connect(null, (dispatch: Dispatch) => ({
  reloadViewer: () => dispatch(reloadViewer()),
}))(LogoutButton);
