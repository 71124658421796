import React from 'react';

import styles from 'css-module/components/subdomain/admin/v2/_AdminV2Nav.module.scss';

import AdminV2NavTooltip from './AdminV2NavTooltip';

import type { LucideIcon } from 'lucide-react';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  Icon: LucideIcon;
  label: string;
  active?: boolean;
}

const AdminV2NavIconButton = ({ Icon, label, active = false, ...props }: Props) => {
  return (
    <button
      aria-label={label}
      data-active={active ? '' : undefined}
      className={styles.iconLink}
      {...props}>
      <Icon className={styles.icon} />
      <AdminV2NavTooltip label={label} className={styles.tooltip} />
    </button>
  );
};

export default AdminV2NavIconButton;
