import { Errors as CustomFieldErrors, CustomFieldTypes } from 'common/customPostFields/Constants';
import { getFieldsErrors } from 'common/customPostFields/Utils';
import validateInput from 'common/validateInput';

import type { Board } from 'common/api/endpoints/boards';
import type { BoardField } from 'common/api/resources/postFields';

export type PostForm = {
  title: string;
  details: string;
  categoryID: string | null;
  boardID: string;
  files: string; // JSON-encoded list
  fileURLs: string; // JSON-encoded list
  imageURLs: string; // JSON-encoded list
  customFieldValuesMap: Record<string, string | Array<string>>;
};

export type FormErrors = Partial<{
  title: string;
  details: string;
  categoryID: string;
  boardID: string;
  fileURLs: string;
  imageURLs: string;
  customFieldValuesMap: Record<string, string>;
}>;

export const getFormErrors = (form: PostForm, board: Board) => {
  const errors: FormErrors = {};

  const getErrorMessage = (field: BoardField, errorType: keyof typeof CustomFieldErrors) => {
    const validationMessage =
      {
        [CustomFieldTypes.integer.name]: `Please enter an integer`,
        [CustomFieldTypes.multilineText.name]: `Please enter some text`,
        [CustomFieldTypes.text.name]: `Please enter some text`,
      }[field.type] || `This field is invalid`;

    const message =
      {
        [CustomFieldErrors.incompatibleTypes]: validationMessage,
        [CustomFieldErrors.missingFields]: `Please complete this field`,
      }[errorType] || `There is an error on this field`;

    return message;
  };

  if (!validateInput.postTitle(form.title)) {
    errors.title = 'Please add a title';
  }

  if (!validateInput.postDetails(form.details)) {
    errors.details = 'Please enter details between 0 and 5000 characters.';
  }

  if (!validateInput.postImageURLs(form.imageURLs)) {
    errors.imageURLs = 'Images were not uploaded correctly';
  }

  if (!validateInput.publicNonImageFileURLs(form.fileURLs)) {
    errors.fileURLs = 'Files were not uploaded correctly';
  }

  if (board.settings.detailsRequired && !form.details) {
    errors.details = 'Please enter details';
  }

  const customFieldErrors = getFieldsErrors(form.customFieldValuesMap, board.boardFields) as Array<{
    field: BoardField;
    type: keyof typeof CustomFieldErrors;
  }>;

  if (customFieldErrors.length) {
    errors.customFieldValuesMap = customFieldErrors.reduce((map, error) => {
      const message = getErrorMessage(error.field, error.type);
      return {
        ...map,
        [error.field.customPostFieldID]: message,
      };
    }, {} as typeof errors.customFieldValuesMap);
  }

  return errors;
};
