import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadAllBoards } from 'common/actions/boards';
import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import TextToggle from 'common/common/TextToggle';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { TrackEventContext } from 'common/containers/EventContainer';
import { ShowIntercomContext } from 'common/containers/IntercomContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { LocationContext } from 'common/containers/RouterContainer';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import Helmet from 'common/helmets/Helmet';
import Button from 'common/inputs/Button';
import ChangeInvoiceEmailModal from 'common/modals/ChangeInvoiceEmailModal';
import ConfirmModal from 'common/modals/ConfirmModal';
import InvoicesModal from 'common/modals/InvoicesModal';
import UpsellModal from 'common/modals/UpsellModal';
import withAccessControl from 'common/routing/withAccessControl';
import AdminPlanErrors from 'common/subdomain/admin/AdminPlanErrors';
import AdminBillingPlan from 'common/subdomain/admin/billing/AdminBillingPlan';
import AdminCCModal from 'common/subdomain/admin/billing/AdminCCModal';
import AdminChurnModal from 'common/subdomain/admin/billing/AdminChurnModal';
import Tappable from 'common/Tappable';
import { H2 } from 'common/ui/Text';
import isFree, { FreePlanID, FreeV1PlanID, FreeV2PlanID } from 'common/util/isFree';
import isGrowth, { GrowthAnnualPlanID, GrowthPlanID } from 'common/util/isGrowth';
import isStarter, { StarterAnnualPlanID, StarterPlanID } from 'common/util/isStarter';
import numberWithCommas from 'common/util/numberWithCommas';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import withContexts from 'common/util/withContexts';

import { BillingCard, PlanCard, TrialCard } from './BillingCards';
import DowngradeModal from './DowngradeModal';
import { PlanNames, getCompanyDiscount, getPlanName } from './utils';

import 'css/components/subdomain/admin/billing/_AdminBillingSettings.scss';

const FreeTierID = '102';
const BizTrialPlanID = 'business-trial-1';

const changePlanErrors = {
  DoesNotQualify: 'does not qualify',
  DowngradeNotAllowed: 'downgrade not allowed',
  InvalidPlanID: 'invalid planID',
};

const Modals = {
  cancel: 'cancel',
  charge: 'charge',
  churn: 'churn',
  creditCard: 'creditCard',
  invoices: 'invoices',
  upsell: 'upsell',
  qualifyPlan: 'qualifyPlan',
};

const ExcludedDiscounts = {
  [PlanNames.starter]: [
    'YRoKegye', // startupV1 year one 75%
    'esUanZHm', // startupV1 year two 50%
    'h9ltb0jf', // startupV1 one year 50%
    'TfLHBqXA', // startupV2 year one 75%
    '6dBkiwBv', // startupV2 year two 50%
    'P142VUck', // startupV2 one year 50%
  ],
  [PlanNames.growth]: [
    'YRoKegye', // startupV1 year one 75%
    'esUanZHm', // startupV1 year two 50%
    'h9ltb0jf', // startupV1 one year 50%
  ],
};

class AdminBillingSettings extends Component {
  static propTypes = {
    company: PropTypes.shape({
      billingData: PropTypes.shape({
        activeUntil: PropTypes.string,
        cancelled: PropTypes.bool,
        hasCard: PropTypes.bool,
        invoiceEmail: PropTypes.string,
        plan: PropTypes.object,
      }),
      contributorCount: PropTypes.number,
    }),
    discordSettings: PropTypes.object,
    openModal: PropTypes.func,
    showIntercom: PropTypes.func,
    trackEvent: PropTypes.func,
    viewer: PropTypes.shape({
      email: PropTypes.string,
    }),
  };

  state = {
    billingCycle: 'yearly',
    cardError: null,
    ccModalCallback: null,
    changingPlan: false,
    changingPlanID: null,
    changePlanErrors: null,
    changePlanSubmissionError: null,
    endingPlanTrial: false,
    endPlanTrialErrors: null,
    endPlanSubmissionError: false,
    error: false,
    lostFeatures: null,
    modal: null,
    planTrialLostFeatures: null,
    trialPlanID: null,
  };

  componentDidMount() {
    const { trackEvent } = this.props;
    trackEvent('Viewed Billing Page');

    if (this.props.location.query?.action === 'qualify') {
      this.setState({ modal: Modals.qualifyPlan });
    }
  }

  onCancelPlan = () => {
    this.setState({ modal: Modals.cancel });
  };

  onContactSupport = () => {
    this.props.showIntercom();
  };

  onChangeInvoiceEmail = () => {
    this.props.openModal(ChangeInvoiceEmailModal);
  };

  onChangePlan = async ({ confirm, planID }) => {
    this.setState({
      changePlanSubmissionError: null,
      changingPlan: true,
      changingPlanID: planID,
      error: false,
      cardError: null,
    });

    const response = await AJAX.post('/api/billing/changePlan', { confirm, planID });
    await this.props.reloadCompany();

    if (response === 'success') {
      this.setState({
        changingPlan: false,
        changingPlanID: null,
        changePlanErrors: null,
        lostFeatures: null,
      });
      return;
    }

    let responseJSON;
    try {
      responseJSON = JSON.parse(response);
    } catch (e) {
      responseJSON = null;
    }

    if (responseJSON?.error === 'no card') {
      const success = await this.onUpdateCard();

      if (success) {
        this.onChangePlan({ confirm, planID });
      }
      this.setState({ changingPlan: false });
      return;
    }

    this.setState({ changingPlan: false });
    if (responseJSON?.error === 'invalid planID') {
      if (confirm) {
        this.setState({ changePlanSubmissionError: changePlanErrors.InvalidPlanID });
      }
      return;
    }

    if (responseJSON?.error === 'no churn reason') {
      this.setState({ modal: Modals.churn });
      return;
    }

    if (responseJSON?.error === 'does not qualify') {
      this.setState({
        changePlanErrors: responseJSON.errors,
        lostFeatures: responseJSON.lostFeatures?.length > 0 ? responseJSON.lostFeatures : null,
      });
      if (confirm) {
        this.setState({ changePlanSubmissionError: changePlanErrors.DoesNotQualify });
      }
      return;
    }

    if (responseJSON?.error === 'lost features unconfirmed') {
      this.setState({
        changePlanErrors: null,
        lostFeatures: responseJSON.lostFeatures,
      });
      return;
    }

    if (responseJSON?.error === 'card error') {
      this.setState({
        cardError: responseJSON.errorMessage,
      });
      return;
    }
    this.setState({
      error: true,
    });
  };

  onEndTrial = async ({ confirm }) => {
    this.setState({
      endingPlanTrial: true,
      endPlanTrialErrors: null,
      planTrialLostFeatures: null,
      endPlanSubmissionError: false,
    });

    const response = await AJAX.post('/api/billing/endTrial', { confirm });

    if (response === 'success') {
      this.props.reloadCompany();
      this.setState({
        endingPlanTrial: false,
        planTrialLostFeatures: null,
      });
      return;
    }

    let responseJSON;
    try {
      responseJSON = JSON.parse(response);
    } catch (e) {
      responseJSON = null;
    }

    if (responseJSON?.error === 'does not qualify') {
      this.setState({
        endingPlanTrial: false,
        endPlanTrialErrors: responseJSON.errors,
        planTrialLostFeatures:
          responseJSON.lostFeatures?.length > 0 ? responseJSON.lostFeatures : null,
      });
      return;
    }

    if (responseJSON?.error === 'lost features unconfirmed') {
      this.setState({
        endingPlanTrial: false,
        endPlanTrialErrors: null,
        planTrialLostFeatures: responseJSON.lostFeatures,
      });
      return;
    }

    this.setState({ endPlanSubmissionError: true, endingPlanTrial: false });
  };

  onShowInvoices = () => {
    this.setState({ modal: Modals.invoices });
  };

  onToggleBillingCycle = (billingCycle) => {
    this.setState({ billingCycle });
  };

  onCloseInvoices = () => {
    this.setState({ modal: null });
  };

  onCloseCCModal = async (error) => {
    const { ccModalCallback } = this.state;

    if (error) {
      this.setState({ cardError: error.message });
    }

    this.setState({
      ccModalCallback: null,
      modal: null,
    });
    ccModalCallback(false);
  };

  onSuccessCCModal = async () => {
    const { ccModalCallback } = this.state;

    this.setState({
      ccModalCallback: null,
      modal: null,
    });
    ccModalCallback(true);
  };

  onCloseChargeModal = async () => {
    const {
      company,
      viewer: { email },
    } = this.props;

    this.setState({
      modal: null,
      changingPlanID: null,
    });

    AJAX.post('/api/analytics/log', {
      key: 'annual_pricing_confirm_cancel',
      data: {
        company: company._id,
        viewer: email,
      },
    });
  };

  onStartTrial = (trialPlanID) => {
    this.setState({
      modal: Modals.upsell,
      trialPlanID,
    });
  };

  updateCard = async () => {
    this.setState({ modal: Modals.creditCard });

    return new Promise((resolve) => {
      this.setState({ ccModalCallback: resolve });
    });
  };

  onUpdateCard = async () => {
    this.setState({ cardError: null });

    const { trackEvent } = this.props;
    trackEvent('Clicked Change Card (Billing)');

    const success = await this.updateCard();

    if (!success) {
      trackEvent('Failed To Add Card (Billing)');
      return false;
    }

    await this.props.reloadCompany();
    trackEvent('Updated Card (Billing)');
    return true;
  };

  onUpgrade = (planID) => {
    const isAnnualPlan = [GrowthAnnualPlanID, StarterAnnualPlanID].includes(planID);
    if (isAnnualPlan) {
      this.setState({ modal: Modals.charge, changingPlanID: planID });
      return;
    }

    this.onChangePlan({ confirm: false, planID });
  };

  onUpsellDismiss = () => {
    this.setState({
      modal: null,
      trialPlanID: null,
    });
  };

  getPlanDiscount(planName) {
    const discount = getCompanyDiscount(this.props.company, this.state.billingCycle);

    if (planName === PlanNames.business || !discount) {
      return null;
    }

    const excludedDiscounts = ExcludedDiscounts[planName];
    return !excludedDiscounts.includes(discount.couponID) ? discount : null;
  }

  getUpgradeDiscount(planID) {
    const discount = getCompanyDiscount(this.props.company, this.state.billingCycle);

    if (!discount) {
      return null;
    }

    const { allowedPlanIDs } = discount;
    if (!allowedPlanIDs || allowedPlanIDs.includes(planID)) {
      return discount;
    }

    return null;
  }

  shouldOfferGrowthTrial = () => {
    const { billingData, planTrial } = this.props.company;
    const didGrowthTrial = isGrowth(planTrial?.planID);
    const didBizTrial = planTrial?.planID === BizTrialPlanID;
    const planExpired = !billingData?.plan?.planID;
    const onFree = isFree(billingData?.plan?.planID);
    const onStarter = isStarter(billingData?.plan?.planID);
    return !(didGrowthTrial || didBizTrial) && (planExpired || onFree || onStarter);
  };

  shouldOfferStarterTrial = () => {
    const { billingData, planTrial, trialingPlan } = this.props.company;
    const didStarterTrial = isStarter(planTrial?.planID);
    const onGrowthTrial = isGrowth(trialingPlan?.planID);
    const planExpired = !billingData?.plan?.planID;
    const onFree = isFree(billingData?.plan?.planID);
    return !onGrowthTrial && !didStarterTrial && (planExpired || onFree);
  };

  renderCancelSection() {
    const { billingData } = this.props.company;
    const { plan, cancelled } = billingData;

    if (!plan || isFree(plan.planID) || plan.planID === FreeTierID || cancelled) {
      return null;
    }

    const selfServeCancellation = !plan.isBusiness;
    return (
      <div className="cancelSection">
        <div className="endSubscription">End Subscription</div>
        <div className="explanation">
          Upon cancelling, your users will no longer be able to give feedback and your admin
          features will no longer be available.
        </div>
        <Tappable onTap={selfServeCancellation ? this.onCancelPlan : this.onContactSupport}>
          <div className="cancelButton">
            {selfServeCancellation
              ? 'Cancel subscription'
              : 'Contact us to cancel your subscription'}
          </div>
        </Tappable>
      </div>
    );
  }

  renderChargeModal(planID) {
    const { stats } = this.props.company;
    const { changingPlanID } = this.state;
    const { adminCount } = stats;
    const additionalAdmins = Math.max(0, adminCount - 5);

    const basePrice = isStarter(changingPlanID) ? 79 : 359;
    const adminPrice = isStarter(changingPlanID) ? 18 : 36;
    let charge = (basePrice + additionalAdmins * adminPrice) * 12;

    const discount = this.getUpgradeDiscount(changingPlanID);
    if (discount?.amountOff) {
      charge -= discount.amountOff;
    } else if (discount?.percentOff) {
      charge = Math.round((charge * (100 - discount.percentOff)) / 100);
    }

    const message = (
      <>
        Your credit card will be charged ${numberWithCommas(charge)} annually.
        <br />
        Would you like to continue?
      </>
    );
    return (
      <ConfirmModal
        closeModal={this.onCloseChargeModal}
        message={message}
        onConfirm={() => this.onChangePlan({ confirm: false, planID: changingPlanID })}
        portalContainerID="adminSettings"
        submitButtonValue="Continue"
        useModalPortal={true}
      />
    );
  }

  renderChurnModal() {
    const { showToast } = this.props;
    const { changingPlanID, modal } = this.state;

    const onClose = ({ completed }) => {
      this.setState({ modal: null });
      if (!completed) {
        return;
      }

      if (modal === Modals.cancel) {
        showToast('Subscription cancelled', ToastTypes.success);
      }

      if (modal === Modals.churn) {
        this.onChangePlan({ confirm: false, planID: changingPlanID });
      }
    };

    const type = modal === Modals.cancel ? 'cancel' : 'downgrade';
    return <AdminChurnModal onClose={onClose} type={type} />;
  }

  renderModal() {
    const { modal } = this.state;

    if (!modal) {
      return;
    }

    if (modal === Modals.cancel || modal === Modals.churn) {
      return this.renderChurnModal();
    }

    if (modal === Modals.charge) {
      return this.renderChargeModal();
    }

    if (modal === Modals.creditCard) {
      return <AdminCCModal onClose={this.onCloseCCModal} onSuccess={this.onSuccessCCModal} />;
    }

    if (modal === Modals.invoices) {
      return <InvoicesModal onClose={this.onCloseInvoices} company={this.props.company} />;
    }

    if (modal === Modals.upsell) {
      const { trialPlanID } = this.state;
      const cta = isStarter(trialPlanID)
        ? 'More power for small businesses'
        : 'Empower your whole team with more powerful features and integrations';
      return (
        <UpsellModal
          cta={cta}
          feature="billing"
          onClose={this.onUpsellDismiss}
          onUpsell={this.onUpsellDismiss}
          planID={trialPlanID}
          show
        />
      );
    }

    // Would be nice to use this same modal for plan changing and trial ending
    if (modal === Modals.qualifyPlan) {
      return (
        <DowngradeModal
          onClose={() => this.setState({ modal: null })}
          header="Restore Access"
          cta="Restore Access"
          onCTA={async () => AJAX.post('/api/billing/qualifyCurrentPlan')}
        />
      );
    }
  }

  renderError() {
    const { error } = this.state;
    if (!error) {
      return null;
    }
    return (
      <div className="error">
        Something went wrong, please try again or{' '}
        <Tappable onTap={this.props.showIntercom}>
          <span className="link">message us for help</span>
        </Tappable>
        .
      </div>
    );
  }

  renderCardError() {
    const { cardError } = this.state;
    if (!cardError) {
      return null;
    }

    return (
      <div className="error">
        {cardError === 'server error' ? (
          <>
            Something went wrong, please try again or{' '}
            <Tappable onTap={this.props.showIntercom}>
              <span className="link">message us for help</span>
            </Tappable>
            .
          </>
        ) : (
          cardError
        )}
      </div>
    );
  }

  renderChangePlanSubmissionError() {
    const { changePlanSubmissionError: error, endPlanSubmissionError } = this.state;
    if (!error && !endPlanSubmissionError) {
      return null;
    }

    let message;
    if (error === changePlanErrors.DoesNotQualify) {
      message = 'You need to disable additional features before proceeding.';
    } else if (error === changePlanErrors.InvalidPlanID) {
      message = (
        <>
          We cannot upgrade your plan,{' '}
          <Tappable onTap={this.props.showIntercom}>
            <span className="link">please message us to proceed</span>
          </Tappable>
          .
        </>
      );
    } else {
      message = (
        <>
          Something went wrong, please try again or{' '}
          <Tappable onTap={this.props.showIntercom}>
            <span className="link">message us for help</span>
          </Tappable>
          .
        </>
      );
    }

    return <div className="error right">{message}</div>;
  }

  renderFreePlans() {
    const { changingPlan, changingPlanID } = this.state;
    const { billingData, stats } = this.props.company;
    const { expired, onboardingDue } = billingData;
    const { planID } = billingData.plan || {};

    const onFreeLegacy = [FreeV1PlanID, FreeV2PlanID].includes(planID);
    const onFreeAutopilot = planID === FreePlanID;
    const cta = expired && onboardingDue && !stats.onboardedAt ? 'Get More Time' : 'Use Canny Free';
    const namePill = onFreeLegacy && 'with Autopilot & Private Boards';

    const freePlans = [];
    if (onFreeLegacy) {
      freePlans.push(
        <AdminBillingPlan
          cta={cta}
          description="This plan is deprecated and will not get any feature updates."
          name="Free (Legacy)"
          price={0}
          selected
        />
      );
    }

    freePlans.push(
      <AdminBillingPlan
        cta={cta}
        description="Set up your feedback portal with just the basics"
        disabled={changingPlan}
        name="Free"
        namePill={namePill}
        onSelect={() => this.onChangePlan({ confirm: false, planID: FreePlanID })}
        price={0}
        selected={onFreeAutopilot}
        selecting={changingPlan && changingPlanID === FreePlanID}
      />
    );

    return freePlans;
  }

  renderGrowthPlan() {
    const { billingData, monthlySpend, stats } = this.props.company;
    const { billingCycle, changingPlan, changingPlanID } = this.state;

    const { planID } = billingData.plan || {};
    const { adminCount } = stats;
    const onFree = isFree(planID);
    const onGrowth = isGrowth(planID);

    const discount = this.getPlanDiscount(PlanNames.growth);

    const additionalAdmins = Math.max(0, adminCount - 5);
    const basePrice = billingCycle === 'monthly' ? 399 : 359;
    const additionalAdminPrice = billingCycle === 'monthly' ? 40 : 36;
    let growthUpgradePrice = basePrice + additionalAdmins * additionalAdminPrice;
    if (discount?.amountOff) {
      growthUpgradePrice = Math.max(0, growthUpgradePrice - discount.amountOff);
    } else if (discount?.percentOff) {
      growthUpgradePrice = Math.round((growthUpgradePrice * (100 - discount.percentOff)) / 100);
    }

    const growthPrice = onGrowth ? monthlySpend / 100 : growthUpgradePrice;

    // not gonna show breakdown for growth plans as there is two many combinations of discounts and sliding/nonsliding plans
    const showBreakdown = onFree && additionalAdmins > 0;
    const breakdown = showBreakdown
      ? [
          { label: 'Base price', value: `$${basePrice}/mo` },
          {
            label: 'Additional admins',
            value: `$${additionalAdminPrice} x ${additionalAdmins} admins`,
          },
        ]
      : null;

    const yearlyBilling = billingCycle === 'yearly';
    const timeframe = yearlyBilling ? 'mo (billed yearly)' : 'mo';
    const upgradePlanID = yearlyBilling ? GrowthAnnualPlanID : GrowthPlanID;

    const description = 'Scale insights across your team with integrations and automations';
    const offerTrial = this.shouldOfferGrowthTrial();
    if (offerTrial) {
      return (
        <AdminBillingPlan
          breakdown={breakdown}
          cta="Start Trial"
          description={description}
          disabled={changingPlan}
          name="Growth"
          onSelect={() => this.onStartTrial(upgradePlanID)}
          price={growthPrice}
          timeframe={timeframe}
        />
      );
    }

    return (
      <AdminBillingPlan
        breakdown={breakdown}
        cta={onGrowth ? 'Change term' : 'Upgrade'}
        description={description}
        disabled={changingPlan}
        name="Growth"
        onSelect={() => this.onUpgrade(upgradePlanID)}
        price={growthPrice}
        selected={planID === upgradePlanID}
        selecting={changingPlan && changingPlanID === upgradePlanID}
        timeframe={timeframe}
      />
    );
  }

  renderStarterPlan() {
    const { billingData, monthlySpend, stats } = this.props.company;
    const { billingCycle, changingPlan, changingPlanID } = this.state;

    const { planID } = billingData.plan || {};
    const { adminCount } = stats;
    const onFree = isFree(planID);
    const onStarter = isStarter(planID);
    const onGrowth = isGrowth(planID);

    const discount = this.getPlanDiscount(PlanNames.starter);

    const additionalAdmins = Math.max(0, adminCount - 3);
    const basePrice = billingCycle === 'monthly' ? 99 : 79;
    const additionalAdminPrice = billingCycle === 'monthly' ? 20 : 18;
    let starterUpgradePrice = basePrice + additionalAdmins * additionalAdminPrice;
    if (discount?.amountOff) {
      starterUpgradePrice = Math.max(0, starterUpgradePrice - discount.amountOff);
    } else if (discount?.percentOff) {
      starterUpgradePrice = Math.round((starterUpgradePrice * (100 - discount.percentOff)) / 100);
    }

    const starterPrice = onStarter ? monthlySpend / 100 : starterUpgradePrice;

    // // not gonna show breakdown for starter plans as there is two many combinations of discounts and sliding/nonsliding plans
    const showBreakdown = onFree && additionalAdmins > 0;
    const breakdown = showBreakdown
      ? [
          { label: 'Base price', value: `$${basePrice}/mo` },
          {
            label: 'Additional admins',
            value: `$${additionalAdminPrice} x ${additionalAdmins} admins`,
          },
        ]
      : null;

    const yearlyBilling = billingCycle === 'yearly';
    const upgradePlanID = yearlyBilling ? StarterAnnualPlanID : StarterPlanID;
    const timeframe = yearlyBilling ? 'mo (billed yearly)' : 'mo';

    const description = 'Get more out of your feedback with advanced tools';
    const offerTrial = this.shouldOfferStarterTrial();
    if (offerTrial) {
      return (
        <AdminBillingPlan
          breakdown={breakdown}
          cta="Start Trial"
          description={description}
          disabled={changingPlan}
          name="Starter"
          onSelect={() => this.onStartTrial(upgradePlanID)}
          price={starterPrice}
          timeframe={timeframe}
        />
      );
    }

    let cta = 'Upgrade';
    if (onGrowth) {
      cta = 'Downgrade';
    } else if (onStarter) {
      cta = 'Change term';
    }
    return (
      <AdminBillingPlan
        breakdown={breakdown}
        cta={cta}
        description={description}
        disabled={changingPlan}
        name="Starter"
        onSelect={() => this.onUpgrade(upgradePlanID)}
        price={starterPrice}
        selected={planID === upgradePlanID}
        selecting={changingPlan && changingPlanID === upgradePlanID}
        timeframe={timeframe}
      />
    );
  }

  renderPlanCards() {
    const { changingPlan } = this.state;
    const { billingData, monthlySpend } = this.props.company;
    const { isBusiness } = billingData.plan || {};

    const bizDescription = 'Deploy additional permissions, compliance, and customizations';
    if (isBusiness) {
      return (
        <div className="plans">
          <AdminBillingPlan
            description={bizDescription}
            disabled={changingPlan}
            name="Business"
            price={monthlySpend / 100}
            selected
          />
        </div>
      );
    }

    return (
      <div className="plans">
        {this.renderFreePlans()}
        {this.renderStarterPlan()}
        {this.renderGrowthPlan()}
        <AdminBillingPlan
          cta="Contact Us"
          description={bizDescription}
          disabled={changingPlan}
          name="Business"
          onSelect={this.onContactSupport}
          priceCustom
        />
      </div>
    );
  }

  renderPlanBillingCycleToggle() {
    const { billingData } = this.props.company;
    const { billingCycle } = this.state;

    const { isBusiness: onBusiness } = billingData.plan || {};

    if (onBusiness) {
      return null;
    }

    return (
      <TextToggle
        onChange={this.onToggleBillingCycle}
        options={[
          {
            label: 'Pay Monthly',
            value: 'monthly',
          },
          {
            label: 'Pay Yearly',
            value: 'yearly',
          },
        ]}
        selected={billingCycle}
      />
    );
  }

  renderPlans() {
    return (
      <div className="plansContainer">
        <div className="plansHeader">
          <H2 fontWeight="regular" variant="headingLg">
            Change your plan
          </H2>
          {this.renderPlanBillingCycleToggle()}
        </div>
        {this.renderPlanCards()}
      </div>
    );
  }

  renderChangePlanError() {
    const { changingPlan, changingPlanID, changePlanErrors, lostFeatures } = this.state;

    if (!changePlanErrors && !lostFeatures) {
      return;
    }

    const planName = getPlanName(changingPlanID);
    const cta = planName ? `Accept and move to ${planName}` : 'Accept and change plan';
    const warning = planName
      ? `In order to use the Canny ${planName} plan, your team needs to turn off these features:`
      : 'In order to use this Canny plan, your team needs to turn off these features:';

    return (
      <div className="confirmation">
        {changePlanErrors ? <div className="message">{warning}</div> : null}
        <AdminPlanErrors
          lostFeatures={lostFeatures}
          planErrors={changePlanErrors}
          reloadErrors={() => this.onChangePlan({ confirm: false, planID: changingPlanID })}
          isReloading={changingPlan}
        />
        <div className="acceptAndChange">
          <Button
            buttonType="cannyButton"
            loading={changingPlan}
            onTap={() => this.onChangePlan({ confirm: true, planID: changingPlanID })}
            value={cta}
          />
        </div>
        {this.renderChangePlanSubmissionError()}
      </div>
    );
  }

  renderEndPlanTrialError() {
    const { endingPlanTrial, endPlanTrialErrors, planTrialLostFeatures, endPlanSubmissionError } =
      this.state;

    if (!endPlanTrialErrors && !planTrialLostFeatures && !endPlanSubmissionError) {
      return;
    }

    return (
      <div className="confirmation">
        {endPlanTrialErrors && (
          <div className="message">
            In order to end your trial, your team needs to turn off these features:
          </div>
        )}
        <AdminPlanErrors
          lostFeatures={planTrialLostFeatures}
          planErrors={endPlanTrialErrors}
          reloadErrors={() => this.onEndTrial({ confirm: false })}
          isReloading={endingPlanTrial}
        />
        <div className="acceptAndChange">
          <Button
            buttonType="cannyButton"
            loading={endingPlanTrial}
            onTap={() => this.onEndTrial({ confirm: true })}
            value="Accept and end trial"
          />
        </div>
        {this.renderChangePlanSubmissionError()}
      </div>
    );
  }

  render() {
    return (
      <>
        <Helmet title="Plans & Billing | Canny" />
        <div className="adminBillingSettings">
          <div className="cardRow">
            <TrialCard onEndTrial={this.onEndTrial} />
            <PlanCard />
            <BillingCard
              onChangeInvoiceEmail={this.onChangeInvoiceEmail}
              onShowInvoices={this.onShowInvoices}
              onQualifyCurrentPlan={() => this.setState({ modal: Modals.qualifyPlan })}
              onUpdateCard={this.onUpdateCard}
            />
          </div>
          {this.renderError()}
          {this.renderCardError()}
          {this.renderEndPlanTrialError()}
          {this.renderPlans()}
          {this.renderChangePlanError()}
          {this.renderCancelSection()}
          {this.renderModal()}
        </div>
      </>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadCompany: () => {
      return Promise.all([dispatch(reloadCompany()), dispatch(reloadAllBoards())]);
    },
  })),
  withAccessControl(
    testEveryPermission(RoutePermissions.adminSettings.billing.subscription),
    '/admin/settings',
    { forwardRef: true }
  ),
  withContexts(
    {
      company: CompanyContext,
      openModal: OpenModalContext,
      showIntercom: ShowIntercomContext,
      showToast: ShowToastContext,
      trackEvent: TrackEventContext,
      location: LocationContext,
      viewer: ViewerContext,
    },
    {
      forwardRef: true,
    }
  )
)(AdminBillingSettings);
