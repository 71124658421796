import React, { forwardRef } from 'react';

import classNames from 'classnames';
import { Check } from 'lucide-react';

import OptionPrefix from './OptionPrefix';

import type { CommonSelectProps, Option } from 'common/ui/common/select/SelectCommon';
import type { PropGetters } from 'downshift';

import 'css/components/_SelectOptions.scss';

type Props = Pick<CommonSelectProps, 'optionsPlacement' | 'disabled' | 'optionsMaxWidth'> & {
  options: Option[];
  ctaOption?: Option;
  highlightedIndex?: number;
  getItemProps: PropGetters<Option>['getItemProps'];
  selectedItems?: Option[];
  getMenuProps: PropGetters<Option>['getMenuProps'];
  isOpen: boolean;
};

function group(options: Option[]) {
  const groups: Record<string, { options: (Option & { index: number })[]; title: string }> = {
    ungrouped: { options: [], title: '' },
  };
  options.forEach((object, index) => {
    const optionWithIndex = { ...object, index };
    if (!object.groupBy) {
      groups.ungrouped.options.push(optionWithIndex);
      return;
    }

    const groupTitle = object.groupBy;
    if (!groups[groupTitle]) {
      groups[groupTitle] = { options: [], title: groupTitle };
    }
    groups[groupTitle].options.push(optionWithIndex);
  });

  return groups;
}

const SelectOptions = forwardRef<HTMLLIElement, Props>(
  (
    {
      options,
      selectedItems,
      highlightedIndex,
      getMenuProps,
      getItemProps,
      isOpen,
      ctaOption,
      disabled,
      optionsPlacement = 'bottom',
      optionsMaxWidth,
    },
    ref
  ) => {
    const groups = group(options);

    const selectedItemsObj: Record<string, boolean> =
      selectedItems?.reduce((acc, curr) => {
        return { ...acc, [curr.value]: true };
      }, {}) || {};
    return (
      <ul
        className={classNames('selectOptions', {
          isOpen,
          [`dropdownOptions-${optionsPlacement}`]: true,
        })}
        {...getMenuProps({ disabled })}
        style={optionsMaxWidth ? { maxWidth: optionsMaxWidth } : undefined}>
        <ul className="optionsList">
          {!options.length ? (
            <li className="selectOption noResult">No results found</li>
          ) : (
            Object.entries(groups).map(([groupId, group]) => (
              <React.Fragment key={group.title}>
                <li className="groupTitle">{group.title}</li>
                <ul>
                  {groups[groupId].options.map((item) => {
                    const isSelected = !!selectedItemsObj[item.value];
                    return (
                      <li
                        ref={ref}
                        className={classNames('selectOption', {
                          optionHighlighted: highlightedIndex === item.index,
                          disabledV2: item.disabled,
                          selectedV2: isSelected,
                        })}
                        tabIndex={-1}
                        key={item.index + item.value}
                        {...getItemProps({
                          item,
                          index: item.index,
                          disabled: item.disabled,
                          'aria-selected': isSelected,
                        })}>
                        <span className="optionLeftWrapper">
                          <OptionPrefix
                            avatar={item.avatar}
                            prefixIcon={item.prefixIcon}
                            flair={item.flair}
                            disabled={item.disabled}
                          />
                          <span className="optionText">{item.render ?? item.label}</span>
                        </span>
                        <Check
                          className={classNames('suffixIcon', {
                            disabledV2: item.disabled,
                            selectedV2: isSelected,
                          })}
                          aria-label="check icon"
                        />
                      </li>
                    );
                  })}
                </ul>
              </React.Fragment>
            ))
          )}
        </ul>

        {ctaOption && (
          <li
            ref={ref}
            className={classNames('selectOption ctaOption', {
              optionHighlighted: highlightedIndex === options.length,
            })}
            tabIndex={-1}
            key={ctaOption.value}
            {...getItemProps({
              item: ctaOption,
              index: options.length,
            })}>
            <OptionPrefix
              avatar={ctaOption.avatar}
              prefixIcon={ctaOption.prefixIcon}
              flair={ctaOption.flair}
              disabled={ctaOption.disabled}
            />
            <span className="optionText">{ctaOption.label}</span>
          </li>
        )}
      </ul>
    );
  }
);

export default SelectOptions;
