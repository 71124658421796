import { useEffect, useState } from 'react';

export const MobileMediaQuery = '(max-width: 780px)';

export const isMobile = () => {
  // if it's server render, we don't have access to the document object
  if (typeof window === 'undefined') {
    return false;
  }

  return window.matchMedia(MobileMediaQuery).matches;
};

const useMedia = (query: string) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    let mounted = true;
    if (typeof window === 'undefined') {
      return;
    }

    const mediaQueryList: MediaQueryList = window.matchMedia(query);

    const onChange = () => {
      if (!mounted) {
        return;
      }

      setMatches(Boolean(mediaQueryList.matches));
    };

    mediaQueryList.onchange = onChange;
    setMatches(mediaQueryList.matches);

    return () => {
      mounted = false;
      mediaQueryList.onchange = null;
    };
  }, [query]);

  return matches;
};

export default useMedia;
