import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import AccountModal, { FormStates } from 'common/AccountModal';
import { invalidatePostQueries } from 'common/actions/postQueries';
import { invalidateSuggestions } from 'common/actions/postSuggestions';
import { invalidateUserQueries } from 'common/actions/userQueries';
import AJAX from 'common/AJAX';
import areObjectsEqual from 'common/areObjectsEqual';
import { LoadStatus } from 'common/constants/files';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { LocationContext, RouterContext } from 'common/containers/RouterContainer';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import FileRenderer from 'common/file/FileRenderer';
import getAcceptedMimeTypes from 'common/file/utils/getAcceptedMimeTypes';
import getValidFileURLs from 'common/file/utils/getValidFileURLs';
import uploadFile, { numberOfFilesLimit } from 'common/file/utils/uploadFile';
import Form from 'common/Form';
import FormField from 'common/FormField';
import translateString from 'common/i18n/translateString';
import AutoResizeTextarea from 'common/inputs/AutoResizeTextarea';
import Button from 'common/inputs/Button';
import TextInput from 'common/inputs/TextInput';
import UploadFileButton from 'common/inputs/UploadFileButton';
import CustomPostFieldInput, { getErrorMessages } from 'common/post/CustomPostFieldInput';
import PostCategoryMenu from 'common/post/PostCategoryMenu';
import Strings from 'common/Strings';
import Tappable from 'common/Tappable';
import delayer from 'common/util/delayer';
import isWidget from 'common/util/isWidget';
import mapify from 'common/util/mapify';
import nbspLastSpace from 'common/util/nbspLastSpace';
import numberWithCommas from 'common/util/numberWithCommas';
import parseAPIResponse from 'common/util/parseAPIResponse';
import withContexts from 'common/util/withContexts';
import validateInput from 'common/validateInput';

import PostList from './PostList';

import 'css/components/post/_CreatePostForm.scss';

const UncategorizedCategory = {
  name: 'Uncategorized',
  urlName: '__uncategorized',
};

class CreatePostForm extends Component {
  static propTypes = {
    autoFocus: PropTypes.bool,
    board: PropTypes.shape({
      _id: PropTypes.string,
      categories: PropTypes.array,
      postCount: PropTypes.number,
      strings: PropTypes.shape({
        createCTA: PropTypes.string,
        detailsField: PropTypes.string,
        detailsPlaceholder: PropTypes.string,
        titleField: PropTypes.string,
        titlePlaceholder: PropTypes.string,
      }),
    }).isRequired,
    className: PropTypes.string,
    company: PropTypes.object,
    disabled: PropTypes.bool,
    location: PropTypes.shape({
      state: PropTypes.object,
    }),
    openModal: PropTypes.func,
    router: PropTypes.object.isRequired,
    onPostCreated: PropTypes.func.isRequired,
    showSuggestedPosts: PropTypes.bool,
    viewer: PropTypes.shape({
      _id: PropTypes.string,
    }),
  };

  static defaultProps = {
    autoFocus: false,
    disabled: false,
    showSuggestedPosts: false,
  };

  state = {
    categoryDropdownOpen: false,
    customFieldValuesMap: null,
    error: null,
    erroredFields: [],
    files: [],
    selectedCategory: null,
    submitted: false,
    submitting: false,
  };

  constructor(props, context) {
    super(props, context);

    this._formDelayer = new delayer(this.onFormChangeAfterDelay, 500);

    this.categorySelectorRef = React.createRef();
    this.detailsRef = React.createRef();
    this.titleRef = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;

    const { location, router } = this.props;
    const createForm = location.state && location.state.createForm;
    router.replace({
      pathname: location.pathname,
      query: location.query,
      state: Object.assign({
        createForm: Object.assign({}, createForm, {
          pendingSearch: false,
        }),
      }),
    });

    this.setDefaultCategory();

    document.addEventListener('click', this.onTouchEnd, false);
  }

  componentWillUnmount() {
    this._isMounted = false;
    this._formDelayer.cancel();

    document.removeEventListener('click', this.onTouchEnd, false);
  }

  resetState = () => {
    const { location, router } = this.props;
    router.replace({
      pathname: location.pathname,
      query: location.query,
      state: Object.assign({}, location.state, {
        createForm: {
          details: '',
          pendingSearch: false,
          title: '',
        },
      }),
    });

    this._formDelayer.cancel();
  };

  setDefaultCategory = () => {
    const { board, location, router } = this.props;
    const { selectedCategory: selectedCategoryURLName } = location.query;

    if (!selectedCategoryURLName) {
      return;
    }

    if (selectedCategoryURLName === UncategorizedCategory.urlName) {
      this.setState({ selectedCategory: UncategorizedCategory });
      return;
    }

    const { categories = [] } = board;
    const selectedCategory = categories.find(
      (category) => category.urlName === selectedCategoryURLName
    );

    if (!selectedCategory) {
      this.setState({ selectedCategory: null });

      const query = { ...location.query };
      delete query.selectedCategory;

      router.replace({
        pathname: location.pathname,
        query,
      });

      return;
    }

    this.setState({ selectedCategory });
  };

  createPost = (postData) => {
    this.setState({
      submitting: true,
    });

    AJAX.post('/api/posts/create', postData, this.onCreateResponse);
  };

  onCategorySelected = (category) => {
    const { location, router } = this.props;

    router.replace({
      pathname: location.pathname,
      query: { ...location.query, selectedCategory: category?.urlName },
    });

    this.setState({
      categoryDropdownOpen: false,
      selectedCategory: category,
    });
  };

  onCreateResponse = (response) => {
    const { error, parsedResponse } = parseAPIResponse(response, {
      isSuccessful: (parsedResponse) => parsedResponse.post,
      errors: {
        'slow down':
          'You are trying to create posts too fast. Please wait a few minutes before trying again.',
        spam: `Our system identifies parts of this post as spam. Please, try with different content.`,
      },
    });

    if (error) {
      this.setState({
        submitting: false,
        error: error.message,
      });
      return;
    }

    this.setState({
      submitting: false,
      submitted: true,
    });

    // Post successfully created.
    // 1. Reset delayers and state
    this.resetState();

    // 2. Invalidate data and reload board
    this.props.invalidatePostQueries();

    // 3. Callback
    this.props.onPostCreated(parsedResponse.post);

    // 4. If the user authenticated to perform this action, we can now refresh
    if (this._refreshCallback) {
      this._refreshCallback();
    }
  };

  onFormChange = () => {
    const { board, location, router } = this.props;
    if (board.postCount === 0) {
      return;
    }

    const createForm = (location.state && location.state.createForm) || {};
    const details = this.detailsRef.current.getValue();
    const title = this.titleRef.current.getValue();
    if (!details && !title) {
      this.resetState();
      return;
    }

    setTimeout(() => {
      const details = createForm && createForm.details;
      const title = createForm && createForm.title;
      this._formDelayer.callAfterDelay();

      if (details || title) {
        return;
      }

      const newState = Object.assign({}, location.state, {
        createForm: Object.assign({}, createForm, {
          pendingSearch: true,
        }),
      });
      if (areObjectsEqual(location.state, newState)) {
        return;
      }

      router.replace({
        pathname: location.pathname,
        query: location.query,
        state: newState,
      });
    }, 0);
  };

  onFormChangeAfterDelay = () => {
    if (!this._isMounted) {
      return;
    }

    const { location, router } = this.props;
    const createForm = location.state && location.state.createForm;
    const details = this.detailsRef.current.getValue();
    const title = this.titleRef.current.getValue();

    const newState = Object.assign({}, location.state, {
      createForm: Object.assign({}, createForm, {
        details,
        pendingSearch: false,
        title,
      }),
    });
    if (areObjectsEqual(location.state, newState)) {
      return;
    }

    router.replace({
      pathname: location.pathname,
      query: location.query,
      state: newState,
    });
  };

  onFile = async (file) => {
    const { files } = this.state;
    const { openModal, viewer, showToast } = this.props;
    if (!viewer || viewer.loggedOut) {
      openModal(AccountModal, {
        formState: FormStates.signup,
        onSuccess: (callback) => {
          callback();
        },
      });
      return;
    }

    if (files.length >= numberOfFilesLimit) {
      showToast('You have reached the file limit.', ToastTypes.error);

      return;
    }

    this.setState({ error: null, erroredFields: [] });

    await uploadFile({
      file,
      viewer,
      onFileError: this.onFileError,
      onFileUploading: this.onFileUploading,
      onFileUploaded: this.onFileUploaded,
    });
  };

  onFileError = (file, error) => {
    this.setState((state) => ({
      error,
      files: state.files.filter((f) => f.uniqueID !== file.uniqueID),
    }));
  };

  onFileRemoved = (file) => {
    this.setState((state) => ({
      files: state.files.filter((f) => f.uniqueID !== file.uniqueID),
    }));
  };

  onFileStart = () => {
    const { files } = this.state;
    const { openModal, viewer, showToast } = this.props;
    if (!viewer?._id) {
      openModal(AccountModal, {
        formState: FormStates.signup,
        onSuccess: (callback) => {
          callback();
        },
      });

      // do not continue uploading file
      return false;
    }

    if (files.length >= numberOfFilesLimit) {
      showToast('You have reached the file limit.', ToastTypes.error);

      return false;
    }

    // continue uploading file
    return true;
  };

  onFileUploading = (file) => {
    this.setState((state) => ({
      files: [...state.files, file],
    }));
  };

  onFileUploaded = (file) => {
    this.setState((state) => ({
      files: state.files.map((f) => (f.uniqueID === file.uniqueID ? file : f)),
    }));
  };

  onShowCategoryDropdown = () => {
    const { categoryDropdownOpen } = this.state;
    this.setState({
      categoryDropdownOpen: !categoryDropdownOpen,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();

    const { disabled } = this.props;
    const { customFieldValuesMap, selectedCategory } = this.state;
    if (disabled) {
      return;
    }

    if (this.state.submitting) {
      return;
    }

    const board = this.props.board;
    const title = this.titleRef.current.getValue().trim();
    const details = this.detailsRef.current.getValue().trim();
    const validFileURLs = getValidFileURLs(this.state.files);
    const imageURLs = JSON.stringify(validFileURLs.imageURLs);
    const fileURLs = JSON.stringify(validFileURLs.nonImageFileURLs);

    if (!board || !validateInput.id(board._id)) {
      this.setState({
        error: Strings.miscError,
      });
      return;
    } else if (!validateInput.postTitle(title)) {
      this.setState({
        error: 'Please enter a title between 0 and 400 characters.',
      });
      return;
    } else if (!validateInput.postDetails(details)) {
      this.setState({
        error: 'Please enter details between 0 and 5000 characters.',
      });
      return;
    } else if (!validateInput.postImageURLs(imageURLs)) {
      this.setState({
        error: Strings.miscError,
      });
      return;
    } else if (!validateInput.publicNonImageFileURLs(fileURLs)) {
      this.setState({
        error: 'Files were not uploaded correctly.',
      });
      return;
    } else if (board.settings.detailsRequired && !details) {
      this.setState({
        error: 'Please enter details',
      });
      return;
    }

    const hasCustomFields = board.boardFields.length;
    if (hasCustomFields) {
      const { erroredFields, errorMessages } = getErrorMessages(
        customFieldValuesMap,
        board.boardFields
      );
      if (errorMessages.length) {
        const error = (
          <>
            <p>There are some issues with your form:</p>
            <ul>
              {errorMessages.map((message) => (
                <li key={message}>{nbspLastSpace(message)}</li>
              ))}
            </ul>
          </>
        );

        this.setState({ error, erroredFields });
        return;
      }
    }

    this.setState({
      error: null,
      erroredFields: [],
    });

    const postData = {
      boardID: board._id,
      customFieldValuesMap: hasCustomFields ? customFieldValuesMap || {} : null, // avoid blocking non-allowlisted companies
      details,
      fileURLs,
      imageURLs,
      title,
      categoryID: selectedCategory?._id,
    };

    if (this.props.viewer._id) {
      this.createPost(postData);
      return;
    }

    this.props.openModal(AccountModal, {
      formState: FormStates.signup,
      onSuccess: (callback) => {
        this._refreshCallback = callback;
        this.createPost(postData);
      },
    });
  };

  onTouchEnd = (e) => {
    const { categoryDropdownOpen } = this.state;
    if (!categoryDropdownOpen) {
      return;
    }

    if (!this.categorySelectorRef.current) {
      return;
    }

    if (!this.categorySelectorRef.current.contains(e.target)) {
      this.setState({ categoryDropdownOpen: false });
      return;
    }
  };

  onCustomFieldChange = (value, boardField) => {
    this.setState((state) => ({
      customFieldValuesMap: {
        ...state.customFieldValuesMap,
        [boardField.customPostFieldID]: value,
      },
    }));
  };

  renderCategorySelector() {
    const { board } = this.props;
    const { categories } = board;
    if (!categories || categories.length === 0) {
      return null;
    }

    const { selectedCategory } = this.state;
    const className = classnames('categoryDropdown', { selected: !!selectedCategory });
    const label = selectedCategory?.name ?? 'Select Category';
    const trigger = (
      <FormField label="Category">
        <Tappable onTap={this.onShowCategoryDropdown}>
          <div className="topContainer">
            <div className="label">{label}</div>
            <div className="icon-chevron-down" />
          </div>
        </Tappable>
      </FormField>
    );

    if (categories.length < 3) {
      return (
        <div className={className} ref={this.categorySelectorRef}>
          {trigger}
          {this.renderCategoryDropdown()}
        </div>
      );
    }

    return (
      <PostCategoryMenu board={board} onCategorySelected={this.onCategorySelected}>
        <div className={className}>{trigger}</div>
      </PostCategoryMenu>
    );
  }

  renderCategoryDropdown() {
    const { categoryDropdownOpen } = this.state;
    if (!categoryDropdownOpen) {
      return null;
    }

    const {
      board: { categories },
    } = this.props;
    const parentCategories = categories.filter((category) => !category.parentID);
    const options = [];

    parentCategories.forEach((parent) => {
      options.push(parent, ...categories.filter((category) => category.parentID === parent._id));
    });

    const items = options.map((category) => {
      const isSub = !!category.parentID;
      const classNames = classnames({
        option: true,
        subOption: isSub,
      });

      return (
        <Tappable
          key={category._id}
          onTap={this.onCategorySelected.bind(this, category)}
          stopPropagation={true}>
          <div className={classNames}>
            {category.name + ' (' + numberWithCommas(category.postCount) + ')'}
          </div>
        </Tappable>
      );
    });

    items.push(
      <Tappable
        key="_uncategorized"
        onTap={() => this.onCategorySelected(UncategorizedCategory)}
        stopPropagation={true}>
        <div className="option">{UncategorizedCategory.name}</div>
      </Tappable>
    );

    return <div className="dropdown card">{items}</div>;
  }

  renderTitleInput() {
    const { state } = this.props.location;
    const {
      autoFocus,
      board: { strings },
    } = this.props;
    return (
      <FormField label={translateString(strings, 'titleField')}>
        <TextInput
          autoFocus={autoFocus}
          ref={this.titleRef}
          defaultValue={state && state.createForm && state.createForm.title}
          onChange={this.onFormChange}
          placeholder={translateString(strings, 'titlePlaceholder')}
        />
      </FormField>
    );
  }

  renderSuggestedPosts() {
    const {
      showSuggestedPosts,
      location: { state },
    } = this.props;
    if (!showSuggestedPosts) {
      return null;
    }

    if (!state?.createForm?.title) {
      return null;
    }

    const { suggestedPosts } = this.props;
    if (!suggestedPosts || suggestedPosts.loading || suggestedPosts.error) {
      return null;
    }

    if (suggestedPosts.posts && suggestedPosts.posts.length === 0) {
      return null;
    }

    return (
      <div className="suggestedPosts">
        <div className="prompt">Is your post similar to one of these?</div>
        <PostList
          maxPosts={3}
          noPostsMessage={<div />}
          postList={suggestedPosts}
          // Widget only suggests from current board
          showBoard={!isWidget()}
          showComments={true}
          showMenu={false}
          showPagination={false}
          showPrivateComments={false}
        />
      </div>
    );
  }

  renderDetailsInput() {
    const { state } = this.props.location;
    const {
      board: { strings },
    } = this.props;

    return (
      <FormField label={translateString(strings, 'detailsField')}>
        <AutoResizeTextarea
          ref={this.detailsRef}
          defaultValue={state && state.createForm && state.createForm.details}
          maxRows={10}
          minRows={3}
          onChange={this.onFormChange}
          placeholder={translateString(strings, 'detailsPlaceholder')}
        />
      </FormField>
    );
  }

  renderCustomFields() {
    const {
      board: { boardFields },
    } = this.props;
    const { erroredFields } = this.state;

    const erroredFieldsMap = mapify(erroredFields, '_id');
    return boardFields.map((boardField) => {
      const label = boardField.required ? `${boardField.label} *` : boardField.label;
      return (
        <FormField label={label} key={boardField._id}>
          <CustomPostFieldInput
            postField={boardField}
            errored={!!erroredFieldsMap[boardField._id]}
            onChange={this.onCustomFieldChange}
          />
        </FormField>
      );
    });
  }

  renderErrorMessage() {
    if (!this.state.error) {
      return null;
    }

    return (
      <div className="error" role="alert">
        {this.state.error}
      </div>
    );
  }

  render() {
    var className = 'createPostForm';
    if (this.props.className) {
      className += ' ' + this.props.className;
    }
    const { board, company } = this.props;
    const { files, submitted, submitting } = this.state;
    const filesUploading = files.some((file) => file.uploadStatus !== LoadStatus.loaded);
    const acceptedMimeTypes = getAcceptedMimeTypes(company);

    return (
      <Form
        acceptedFileTypes={acceptedMimeTypes}
        addEventsToDocument={false}
        allowFileUpload={true}
        className={className}
        disableSubmit={submitted || submitting || filesUploading}
        onFile={this.onFile}
        onSubmit={this.onSubmit}
        submitOnEnter={false}>
        {this.renderCategorySelector()}
        {this.renderTitleInput()}
        {this.renderDetailsInput()}
        {this.renderCustomFields()}
        {this.renderSuggestedPosts()}
        <FileRenderer
          className="postFormFileRenderer"
          allowRemove={true}
          files={this.state.files}
          onFileRemoved={this.onFileRemoved}
        />
        <div className="formButtons">
          <UploadFileButton
            acceptedMimeTypes={acceptedMimeTypes}
            defaultStyle={false}
            onFileError={this.onFileError}
            onFileStart={this.onFileStart}
            onFileUploading={this.onFileUploading}
            onFileUploaded={this.onFileUploaded}
          />
          <Button
            buttonType="cannyButton"
            disabled={submitted || filesUploading}
            formButton={true}
            loading={submitting}
            tint={true}
            value={translateString(board.strings, 'createCTA')}
          />
        </div>
        {this.renderErrorMessage()}
      </Form>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    invalidatePostQueries: () => {
      return Promise.all([
        dispatch(invalidatePostQueries()),
        dispatch(invalidateSuggestions()),
        isWidget() && dispatch(invalidateUserQueries()),
      ]);
    },
  })),
  withContexts(
    {
      company: CompanyContext,
      location: LocationContext,
      openModal: OpenModalContext,
      router: RouterContext,
      showToast: ShowToastContext,
      viewer: ViewerContext,
    },
    {
      forwardRef: true,
    }
  )
)(CreatePostForm);
