import React, { useContext, useEffect } from 'react';

import { Bell, Megaphone } from 'lucide-react';

import { CompanyContext } from 'common/containers/CompanyContainer';
import publicConfig from 'common/core/publicConfig';
import { isMobile } from 'common/hooks/useMedia';
import NotificationsMenu from 'common/notifications/NotificationsMenu';
import AdminOnboardingProgress from 'common/subdomain/admin/AdminOnboardingProgress';
import isFree from 'common/util/isFree';
import styles from 'css-module/components/subdomain/admin/v2/_AdminV2Nav.module.scss';

import AdminNavAccountMenu from './AdminNavAccountMenu';
import AdminV2NavDesktopMenu from './AdminV2NavDesktopMenu';
import AdminV2NavIconButton from './AdminV2NavIconButton';
import AdminV2NavMobileMenu from './AdminV2NavMobileMenu';

export default function AdminV2Nav({ notifications }: { notifications: unknown }) {
  const company = useContext(CompanyContext);

  useEffect(() => {
    window.Canny('initChangelog', {
      appID: publicConfig('cannyCompanyID'),
      position: isMobile() ? 'bottom' : 'right',
      align: 'bottom',
    });
  }, []);

  // Check if the user is on a free plan or if the plan has expired
  const billingData = company.billingData ?? { plan: null, status: null };
  const onboardedAt = company.stats.onboardedAt;
  const onFree = isFree(billingData.plan?.planID ?? null);
  const isOnboarding =
    `onboardingDue` in billingData && billingData.onboardingDue && !onboardedAt && onFree;

  return (
    <nav className={styles.nav}>
      <AdminV2NavMobileMenu />
      <AdminV2NavDesktopMenu />
      <ul className={styles.navSection}>
        {isOnboarding ? (
          <li>
            <AdminOnboardingProgress />
          </li>
        ) : null}
        <li>
          <AdminV2NavIconButton Icon={Megaphone} data-canny-changelog label="Announcements" />
        </li>
        <li>
          <NotificationsMenu
            iconStyle="light"
            linkToAdmin={true}
            position={isMobile() ? 'bottom' : 'right'}
            showTooltip={false}
            notifications={notifications}
            button={({ onClick }: { onClick: () => void }) => (
              <AdminV2NavIconButton Icon={Bell} label="Notifications" onClick={onClick} />
            )}
          />
        </li>
        <li className={styles.accountOption}>
          <AdminNavAccountMenu />
        </li>
      </ul>
    </nav>
  );
}
