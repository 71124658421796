import { ErrorType, LoadedType, RequestType } from 'common/actions/autopilotCounts';

import requestReducerFactory, { defaultState as _defaultState } from './requestReducerFactory';

import type { AutopilotCounts } from 'common/api/endpoints/autopilot';

const reducer = requestReducerFactory<AutopilotCounts>(RequestType, LoadedType, ErrorType);

export type AutopilotCountsState = ReturnType<typeof reducer>;
export const defaultState = _defaultState;
export default reducer;
