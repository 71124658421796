import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import AJAX from 'common/AJAX';
import Card from 'common/common/Card';
import Pill, { DefaultPillStyles } from 'common/common/Pill';
import Tooltip from 'common/common/Tooltip';
import { CompanyContext } from 'common/containers/CompanyContainer';
import ContentContainer from 'common/containers/ContentContainer';
import { LocationContext, RouterContext } from 'common/containers/RouterContainer';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import { CookieNames } from 'common/cookies/cookies';
import connect from 'common/core/connect';
import * as FileUploader from 'common/FileUploader';
import SubdomainAccountSettingsHelmet from 'common/helmets/SubdomainAccountSettingsHelmet';
import { SupportedLanguages } from 'common/i18n/supportedLanguages';
import FileInput from 'common/inputs/FileInput';
import TextInput from 'common/inputs/TextInput';
import Spinner from 'common/Spinner';
import Alert, { AlertTypes } from 'common/ui/Alert';
import ButtonV2 from 'common/ui/ButtonV2';
import SingleSelect from 'common/ui/SingleSelect';
import SwitchV2 from 'common/ui/SwitchV2';
import { H1, H2, P } from 'common/ui/Text';
import UserAvatar from 'common/user/UserAvatar';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import withContexts from 'common/util/withContexts';
import validateInput from 'common/validateInput';

import 'css/components/subdomain/public/_SubdomainAccountSettings.scss';

class SubdomainAccountSettings extends Component {
  static propTypes = {
    company: PropTypes.shape({
      name: PropTypes.string,
    }),
    preferredLanguage: PropTypes.string,
    viewer: PropTypes.shape({
      hasProfile: PropTypes.bool,
      enableTranslations: PropTypes.bool,
      name: PropTypes.string,
    }),
  };

  state = {
    addingImage: false,
    avatarURL: this.props.viewer.avatarURL,
    editing: false,
    enableTranslations: this.props.viewer.enableTranslations,
    error: null,
    hasEdits: false,
    locale: this.props.preferredLanguage || 'browser',
  };

  constructor(props, context) {
    super(props, context);

    this.emailRef = React.createRef();
    this.nameRef = React.createRef();
  }

  componentDidMount() {
    const { location, router, showToast } = this.props;
    if (!location.query?.success) {
      return;
    }

    router.replace({
      pathname: location.pathname,
    });

    const email = location.query?.emailVerification;
    const message = email ? `A verification has been sent to ${email}` : 'Settings updated';

    showToast(message, ToastTypes.success);
  }

  onFormChange = () => {
    this.setState({
      hasEdits: true,
    });
  };

  onLocaleChange = (locale) => {
    this.setState({
      hasEdits: true,
      locale: locale.value,
    });
  };

  onToggleTranslations = (enableTranslations) => {
    this.setState({
      hasEdits: enableTranslations !== this.props.viewer.enableTranslations,
      enableTranslations,
    });
  };

  onNewImage = async (image) => {
    if (!image) {
      return;
    }

    this.setState({
      addingImage: true,
    });

    const { viewer } = this.props;
    let imageURL = null;
    let error = null;

    try {
      imageURL = await FileUploader.upload(image, viewer);
    } catch (err) {
      error = err;
    }

    if (error || !imageURL) {
      const message =
        error?.data?.message ?? 'Something went wrong, please try again or contact support';
      this.setState({
        addingImage: false,
        error: message,
      });
    }

    this.setState({
      addingImage: false,
      avatarURL: imageURL,
      hasEdits: true,
    });
  };

  onSubmit = async () => {
    const name = this.nameRef.current.getValue().trim();
    const email = this.emailRef.current.getValue().trim();

    let error = null;
    if (!validateInput.userName(name)) {
      error = 'Please enter a valid name (2-50 characters)';
    } else if (!validateInput.email(email)) {
      error = 'Please enter a valid email';
    }

    if (error) {
      this.setState({ error });
      return;
    }

    this.setState({
      editing: true,
      error: null,
    });

    const { avatarURL, enableTranslations, locale } = this.state;
    const { viewer } = this.props;

    const promises = [
      AJAX.post('/api/viewer/setPreferredLanguage', {
        enableTranslations,
        language: locale === 'browser' ? null : locale,
      }),
    ];

    if (!viewer.hasProfile) {
      promises.push(
        AJAX.post('/api/viewer/edit', {
          avatarURL: avatarURL,
          email,
          name,
        })
      );
    }
    const [localeResponse, editResponse] = await Promise.all(promises);

    const { error: editError } = editResponse
      ? parseAPIResponse(editResponse, {
          errors: {
            'email taken': 'There is another account associated with this email.',
          },
          isSuccessful: isDefaultSuccessResponse,
        })
      : {};
    const { error: localeError } = parseAPIResponse(localeResponse, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (editError || localeError) {
      const errorMessage = editError?.message || localeError?.message;
      this.setState({
        errorMessage,
        editing: false,
      });
      return;
    }

    const hasEmailChanged = viewer.email.toLowerCase() !== email.toLowerCase() && !!editResponse;

    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    if (hasEmailChanged) {
      params.set('emailVerification', email);
    }
    params.set('success', 'true');

    url.search = params.toString();
    window.location.replace(url.toString());
  };

  renderProfileMessage() {
    const { company, viewer } = this.props;
    const { hasProfile } = viewer;
    if (!hasProfile) {
      return null;
    }

    return (
      <Alert
        headingText="Note"
        subText={`Your feedback profile is based on your account with ${company.name}. Changes to your
          profile in ${company.name} will also be reflected here. If you have any issues, reach out
          to ${company.name}'s support team directly.`}
        type={AlertTypes.Info}
      />
    );
  }

  renderSaveButton() {
    const { hasEdits } = this.state;
    const { viewer } = this.props;
    const { editing } = this.state;

    return (
      <ButtonV2
        disabled={viewer.hasProfile && !hasEdits}
        loading={editing}
        size="medium"
        onClick={this.onSubmit}>
        Save
      </ButtonV2>
    );
  }

  renderSettings() {
    const { addingImage } = this.state;
    const { viewer } = this.props;

    const avatarViewer = Object.assign({}, viewer, {
      avatarURL: this.state.avatarURL,
    });

    return (
      <div className="settings">
        <div className="editUserAvatar">
          <UserAvatar user={avatarViewer} />
          <FileInput
            disabled={viewer.hasProfile}
            onFile={this.onNewImage}
            value={addingImage ? <Spinner /> : <span>Upload image</span>}
          />
        </div>
        <div className="fields">
          <TextInput
            defaultValue={viewer.name}
            disabled={viewer.hasProfile}
            inset="Name"
            placeholder="Bob Bobby"
            onChange={this.onFormChange}
            ref={this.nameRef}
          />
          <TextInput
            defaultValue={viewer.email}
            disabled={viewer.hasProfile}
            inset="Email"
            placeholder="bob@appy.com"
            onChange={this.onFormChange}
            ref={this.emailRef}
          />
        </div>
        {this.renderProfileMessage()}
        {this.renderPendingEmailVerification()}
        {this.renderEmailChangeWarning()}
        {this.renderLanguagePreferences()}
        {this.renderError()}
        {this.renderSaveButton()}
      </div>
    );
  }

  renderContents() {
    const { viewer } = this.props;
    if (!viewer || !viewer._id) {
      return <div className="loggedOut">You are not logged in.</div>;
    }

    return this.renderSettings();
  }

  renderError() {
    if (!this.state.error) {
      return null;
    }

    return <div className="error">{this.state.error}</div>;
  }

  renderEmailChangeWarning() {
    const { viewer } = this.props;
    const { hasEdits } = this.state;

    if (!hasEdits) {
      return null;
    }

    // emails are case insensitive - ignore case on the backend endpoint as well
    if (viewer.email && viewer.email.toLowerCase() === this.emailRef.current?.getValue()) {
      return null;
    }

    return (
      <Alert
        className="emailChangeAlert"
        type={AlertTypes.Danger}
        headingText="Warning!"
        subText="Changing your email will disconnect your account from Google, Okta, OneLogin, and/or OpenID Connect. To reconnect, you'll need to log in with your new email through those services."
      />
    );
  }

  renderLanguagePreferences() {
    const { company } = this.props;

    if (!company?.enableTranslations || !company.features.translateContent) {
      return (
        <>
          <H2 className="subheading" variant="headingLg">
            Content Translations
          </H2>
          <div className="translationsDisabled">
            {company.name} has not enabled translations for their feedback. If you have any
            questions, reach out to {company.name}'s support team&nbsp;directly.
          </div>
        </>
      );
    }

    const { enableTranslations, locale } = this.state;
    const localeOptions = [
      {
        label: 'Use browser language',
        value: 'browser',
      },
      ...Object.keys(SupportedLanguages).map((languageName) => ({
        label: languageName,
        value: SupportedLanguages[languageName],
      })),
    ];
    const selectedOption = localeOptions.find((localeOption) => {
      return locale === localeOption.value;
    });
    return (
      <div>
        <H2 className="subheading" variant="headingLg">
          Content translations
        </H2>
        <div className="toggleContainer">
          <P>Enable automatic translations</P>
          <SwitchV2 onChange={this.onToggleTranslations} checked={enableTranslations} />
        </div>
        <span className="languageSelection">
          <P variant="bodyMd">Select your preferred language</P>
          <SingleSelect
            className="languageSelectField"
            onChange={this.onLocaleChange}
            options={localeOptions}
            value={selectedOption}
            disabled={!enableTranslations}
          />
        </span>
      </div>
    );
  }

  renderPendingEmailVerification() {
    const { hasProfile, viewer } = this.props;
    const { pendingEmailVerification } = viewer;

    if (!pendingEmailVerification || hasProfile) {
      return null;
    }

    return (
      <Card className="pendingEmailVerification">
        <P variant="bodySm" className="pendingEmail">
          {pendingEmailVerification.email}
        </P>
        <Tooltip value={`A verification has been sent to this email`} position="top">
          <Pill className="boardInviteStatusPill" pillStyle={DefaultPillStyles.warning} centered>
            Pending
          </Pill>
        </Tooltip>
      </Card>
    );
  }

  render() {
    return (
      <ContentContainer innerClassName="subdomainAccountSettings card">
        <SubdomainAccountSettingsHelmet />
        <H1 className="mainHeading" variant="headingXl">
          Account Settings
        </H1>
        {this.renderContents()}
      </ContentContainer>
    );
  }
}

export default compose(
  connect(({ cookies }) => ({ preferredLanguage: cookies?.[CookieNames.locale] })),
  withContexts(
    {
      company: CompanyContext,
      location: LocationContext,
      router: RouterContext,
      showToast: ShowToastContext,
      viewer: ViewerContext,
    },
    {
      forwardRef: true,
    }
  )
)(SubdomainAccountSettings);
