import React, { useContext, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadAzureDevopsSettings } from 'common/actions/azureDevopsSettings';
import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import { ActiveIntegrationContext } from 'common/containers/ActiveIntegrationsContainer';
import { CompanyContext } from 'common/containers/CompanyContainer';
import connect from 'common/core/connect';
import publicConfig from 'common/core/publicConfig';
import Helmet from 'common/helmets/Helmet';
import Button from 'common/inputs/Button';
import TextInput from 'common/inputs/TextInput';
import withAccessControl from 'common/routing/withAccessControl';
import Spinner from 'common/Spinner';
import AdminFeatureBlock from 'common/subdomain/admin/AdminFeatureBlock';
import Tappable from 'common/Tappable';
import Alert, { AlertTypes } from 'common/ui/Alert';
import devURL from 'common/util/devURL';
import isNil from 'common/util/isNil';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import queryString from 'common/util/queryString';

import * as AzureDevopsPropTypes from './AzureDevopsPropTypes';
import Rules from './Rules';

import 'css/components/subdomain/admin/_AdminAzureDevopsSettings.scss';

const Application = {
  clientID: publicConfig('azureDevopsClientID'),
  scopes: ['vso.work_full'],
};

const Errors = {
  notInstalled: 'integration is not installed',
};

// OAuth Installation docs:
// https://docs.microsoft.com/en-us/azure/devops/integrate/get-started/authentication/oauth?view=azure-devops
const AdminAzureDevopsSettings = (props) => {
  // context
  const activeIntegrations = useContext(ActiveIntegrationContext);
  const company = useContext(CompanyContext);

  // state
  const [accountName, setAccountName] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // effects
  useEffect(
    () => {
      const { location, router } = props;
      if (!location.query.code || !location.query.accountName) {
        return;
      }

      router.replace({ pathname: location.pathname });
      install(location.query.code, location.query.accountName);
    },
    // This probably isn't correct, re-enable this later
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // helpers
  const install = async (code, accountName) => {
    setError(null);
    setLoading(true);

    const response = await AJAX.post('/api/azureDevops/install', {
      accountName,
      code,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: {
        'account not found': `There is no organization named "${accountName}" linked to your Azure DevOps account.`,
        'already installed': 'Azure DevOps is already installed.',
        'integration limit':
          'You have too many integrations installed already. Uninstall another one, or reach out to sales to increase your integrations limit.',
        'plan does not support':
          'Your Plan does not support installing this integration. Please, contact sales for support.',
        'not authorized':
          'You are not authorized to install this integration. Please, contact a Canny admin.',
      },
    });

    if (error) {
      setLoading(false);
      setError(error.message);
      return;
    }

    await props.reloadAzureDevopsSettings();
    await props.reloadCompany();
    setLoading(false);
  };

  const uninstall = async () => {
    setLoading(true);

    const response = await AJAX.post('/api/azureDevops/uninstall');
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (error) {
      setLoading(false);
      setError(error.message);
      return;
    }

    await props.reloadAzureDevopsSettings();
    await props.reloadCompany();
    setLoading(false);
  };

  const getInstallURL = () => {
    const params = {
      client_id: Application.clientID,
      redirect_uri: devURL('https://canny.io/azure-devops-redirect'),
      response_type: 'Assertion',
      scope: Application.scopes.join(','),
      state: encodeURIComponent(
        JSON.stringify({
          accountName,
          subdomain: company.subdomain,
        })
      ),
    };

    const url = `https://app.vssps.visualstudio.com/oauth2/authorize${queryString.stringify(
      params
    )}`;
    return url;
  };

  // renderers
  const renderContents = () => {
    const { azureDevopsSettings } = props;
    const { installation, rules, states } = azureDevopsSettings;
    const { integrationCount, integrationLimit } = activeIntegrations;

    const canInstall = isNil(integrationLimit) || integrationCount < integrationLimit;
    const isUninstalled = azureDevopsSettings.error === Errors.notInstalled;

    if (!company?.integrations?.azureDevops) {
      return renderBusinessUpsell();
    } else if (loading) {
      return renderLoading();
    } else if (isUninstalled && canInstall) {
      return renderInstallButton();
    } else if (isUninstalled && !canInstall) {
      return renderIntegrationsUpsell();
    } else if (azureDevopsSettings.error) {
      return (
        <div className="error">
          There was an error fetching the integration settings. Try again in a few minutes, if the
          issue persists please reach out for support.
        </div>
      );
    } else if (installation.lostAccess && canInstall) {
      return renderLostAccess();
    } else if (installation.lostAccess && !canInstall) {
      return renderIntegrationsUpsell();
    }

    return (
      <div className="installation">
        <div className="status">
          <div className="text">Azure DevOps is installed.</div>
          <Tappable onTap={uninstall}>
            <div className="uninstall">Uninstall</div>
          </Tappable>
        </div>
        <Rules
          azureDevopsRules={rules}
          azureDevopsStates={states}
          onError={setError}
          onRuleCreated={props.reloadAzureDevopsSettings}
          onRuleDeleted={props.reloadAzureDevopsSettings}
        />
      </div>
    );
  };

  const renderLostAccess = () => {
    const installURL = getInstallURL();
    return (
      <div className="uninstalled">
        <Alert
          className="text"
          type={AlertTypes.Warning}
          headingText="Oops!"
          subText="Azure DevOps has lost access to Canny. Please enter your Azure DevOps organization name to
          reinstall the integration. For example, if you access your Azure DevOps instance using the
            following link: https://dev.azure.com/Canny, you would write Canny in the field."
        />
        <TextInput
          inset="Organization Name"
          onChange={(e) => setAccountName(e.currentTarget.value)}
        />
        <a href={installURL} className="buttonContainer" rel="noreferrer noopener nofollow">
          <Button disabled={!accountName} value="Reinstall Azure DevOps" />
        </a>
      </div>
    );
  };

  const renderInstallButton = () => {
    const installURL = getInstallURL();
    return (
      <div className="uninstalled">
        <div className="text">
          Enter your Azure DevOps organization name to install our integration. For example, if you
          access your Azure DevOps instance using the following link: https://dev.azure.com/Canny,
          you would write Canny in the field.
        </div>
        <TextInput
          inset="Organization Name"
          onChange={(e) => setAccountName(e.currentTarget.value)}
        />
        <a href={installURL} className="buttonContainer" rel="noreferrer noopener nofollow">
          <Button disabled={!accountName} value="Install Azure DevOps" />
        </a>
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <div className="spinnerContainer">
        <Spinner />
      </div>
    );
  };

  const renderError = () => {
    if (!error) {
      return null;
    }

    return <div className="error">{error}</div>;
  };

  const renderBusinessUpsell = () => {
    return (
      <AdminFeatureBlock
        feature="Azure DevOps Integration"
        benefit="Link posts to work items, and sync states from Azure DevOps to Canny."
        showBillingLink={false}
      />
    );
  };

  const renderIntegrationsUpsell = () => {
    return (
      <AdminFeatureBlock
        feature="more integrations"
        benefit="You have reached the limit of integrations allowed on your plan. Reach out to sales to upgrade your account to support connecting more integrations."
        showBillingLink={false}
      />
    );
  };

  return (
    <div className="adminAzureDevopsSettings">
      <Helmet title="Azure DevOps Integration | Canny" />
      <div className="settingsHeading">Azure DevOps Integration</div>
      <div className="content">
        <div className="text">
          Canny for Azure DevOps lets you link Canny posts with Azure DevOps work items, and sync
          states from Azure DevOps to Canny. This helps your engineering and product management
          teams communicate priorities.
        </div>
        {renderContents()}
        {renderError()}
      </div>
    </div>
  );
};

AdminAzureDevopsSettings.propTypes = {
  company: PropTypes.shape({ _id: PropTypes.string }),
  azureDevopsSettings: PropTypes.shape({
    installation: PropTypes.object,
    rules: AzureDevopsPropTypes.rules,
    states: AzureDevopsPropTypes.states,
  }),
};

export default compose(
  connect(
    (state) => ({ azureDevopsSettings: state.azureDevopsSettings }),
    (dispatch) => ({
      reloadAzureDevopsSettings: () => dispatch(reloadAzureDevopsSettings()),
      reloadCompany: () => dispatch(reloadCompany()),
    })
  ),
  withAccessControl(
    testEveryPermission(RoutePermissions.integrations.azureDevOps),
    '/admin/settings'
  )
)(AdminAzureDevopsSettings);
