import React, { useContext } from 'react';

import { Lightbulb, Lock, Map, RefreshCw, Triangle } from 'lucide-react';

import { BoardsContext } from 'common/containers/BoardsContainer';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import { H5, P, Span } from 'common/ui/Text';
import UserAvatar from 'common/user/UserAvatar';

import type { Company } from 'common/api/endpoints/companies';
import type { BoardInput } from 'common/subdomain/admin/AdminOnboarding/AdminOnboardingCreateBoard';

import 'css/components/subdomain/admin/_AdminBoardPreview.scss';

const PostPreview = ({ votes }: { votes: number }) => {
  return (
    <div className="postPreview">
      <div className="voteButton">
        <Triangle className="upvote" size={10} />
        <P className="voteCount" variant="bodySm">
          {votes}
        </P>
      </div>
      <div className="details">
        <div className="line one"></div>
        <div className="line two"></div>
        <div className="line three"></div>
      </div>
    </div>
  );
};

type Props = {
  board?: BoardInput;
};

const AdminBoardPreview = ({ board }: Props) => {
  // context
  let boards = useContext(BoardsContext);
  const company = useContext<Company>(CompanyContext);
  const viewer = useContext(ViewerContext);

  // Boards could potentially be loading. If so, just chill out for a bit
  if (!Array.isArray(boards)) {
    boards = [];
  }

  const firstBoard = boards[0];
  const boardName = firstBoard?.name || board?.name || 'Feature Requests';
  const boardURL = firstBoard?.urlName || board?.url || 'feature-requests';
  const url = `${company.subdomain}.canny.io/${boardURL}`;
  return (
    <div className="adminBoardPreview">
      <div className="urlBar">
        <div className="circles">
          <div className="circle close" />
          <div className="circle minimize" />
          <div className="circle maximize" />
        </div>
        <div className="urlInput">
          <Lock className="lock" size={10} />
          <Span variant="bodySm" className="https">
            https://
          </Span>
          <Span variant="bodySm" className="url">
            {url}
          </Span>
        </div>
      </div>
      <div className="header">
        <div className="navBar">
          <H5 className="companyName">{company.name}</H5>
          <UserAvatar user={viewer} />
        </div>
        <div className="tabs">
          <div className="tab selected">
            <Lightbulb size={14} />
            <P variant="bodySm">{boardName}</P>
          </div>
          <div className="tab">
            <Map size={14} />
            <P variant="bodySm">Roadmap</P>
          </div>
          <div className="tab">
            <RefreshCw size={14} />
            <P variant="bodySm">Changelog</P>
          </div>
        </div>
      </div>
      <div className="posts">
        <PostPreview votes={24} />
        <PostPreview votes={16} />
        <PostPreview votes={12} />
        <PostPreview votes={10} />
        <PostPreview votes={7} />
      </div>
    </div>
  );
};

export default AdminBoardPreview;
