import React, { Component } from 'react';

import Dropdown from 'common/Dropdown';

import 'css/components/chrome/_CompanySelector.scss';

class CompanySelector extends Component {
  state = {
    loading: false,
  };

  getOptions = () => {
    const { viewer } = this.props;
    return viewer.companies.map((company) => ({ name: company._id, render: company.name }));
  };

  onChange = (companyID) => {
    const { onCompanySelected, viewer } = this.props;
    const selectedCompany = viewer.companies.find((company) => company._id === companyID);
    onCompanySelected(selectedCompany);
  };

  render() {
    const { company, onCompanySelected, viewer } = this.props;
    if (!onCompanySelected || !viewer || !viewer.companies.length > 1) {
      return null;
    }

    return (
      <div className="companySelector">
        <Dropdown
          defaultSelectedName={company._id}
          dropdownClassName="companySelectorDropdown"
          options={this.getOptions()}
          onChange={this.onChange}
        />
      </div>
    );
  }
}

export default CompanySelector;
