import React from 'react';

import type { CommonSelectProps } from 'common/ui/common/select/SelectCommon';

import 'css/components/_SupportingTexts.scss';

type Props = Pick<CommonSelectProps, 'supportingText' | 'error'>;
const SupportingTexts = ({ error, supportingText }: Props) => {
  if (error) {
    return <span className="supportingText errorTextV2">{error}</span>;
  }
  return supportingText ? <span className="supportingText context">{supportingText}</span> : null;
};
export default SupportingTexts;
