import React, { forwardRef } from 'react';

import classnames from 'classnames';

import SpinnerV2 from 'common/SpinnerV2';

import type { LucideProps } from 'lucide-react';
import 'css/components/_IconButtonV2.scss';

// Design System Button Styles https://www.figma.com/file/MKc5gEytDNyueOf4TXtxZs/Canny-UI-Kit?type=design&node-id=2089-7765&mode=design&t=tvEgLGQectc6UmrL-0
type Size = 'small' | 'medium' | 'large' | 'xlarge';

const IconSizes = {
  small: 14,
  medium: 16,
  large: 18,
  xlarge: 20,
} as const;
interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  'aria-label': string;
  color?: 'primary' | 'error';
  icon: React.ComponentType<LucideProps>;
  loading?: boolean;
  size: Size;
  strokeWidth?: number;
  variant?: 'contained' | 'outlined' | 'plain';
}

const IconButtonV2 = forwardRef<HTMLButtonElement, Props>(
  (
    {
      'aria-label': ariaLabel,
      className,
      color = 'primary',
      disabled,
      icon: Icon,
      loading,
      onClick,
      size = 'medium',
      strokeWidth = 2,
      type = 'button',
      variant = 'outlined',
      ...props
    }: Props,
    ref
  ) => {
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      if (disabled) {
        return;
      }

      onClick?.(e);
    };

    return (
      <button
        aria-disabled={disabled}
        aria-label={ariaLabel}
        className={classnames(className, 'iconButtonV2', {
          [color]: true,
          [size]: true,
          [variant]: true,
          disabled,
          loading,
        })}
        ref={ref}
        onClick={handleClick}
        type={type}
        {...props}>
        {loading ? (
          <SpinnerV2 size={size} />
        ) : (
          <Icon className="buttonIcon" size={IconSizes[size]} strokeWidth={strokeWidth} />
        )}
      </button>
    );
  }
);

export default IconButtonV2;
