import React from 'react';

import classNames from 'classnames';

import Colors from 'common/colors/constants';
import LazyLoadedImage from 'common/LazyLoadedImage';

import type { Option } from 'common/ui/common/select/SelectCommon';
import 'css/components/_OptionPrefix.scss';

type Props = Pick<Option, 'avatar' | 'flair' | 'prefixIcon' | 'disabled'> & { multi?: boolean };

const OptionPrefix = ({ prefixIcon: PrefixIcon, flair, avatar, disabled, multi }: Props) => {
  const hasPrefix = PrefixIcon || flair || avatar;
  if (!hasPrefix) {
    return null;
  }

  return (
    <span className={classNames('optionPrefix', { disabledV2: disabled, multi })}>
      {PrefixIcon && (
        <PrefixIcon aria-label={`prefix icon ${PrefixIcon.displayName}`} className="prefixIcon" />
      )}
      {flair && (
        <span className="flair" style={{ background: disabled ? Colors.gray80 : Colors[flair] }} />
      )}
      {avatar && <LazyLoadedImage className="optionAvatar" src={avatar} alt="User Avatar" />}
    </span>
  );
};
export default OptionPrefix;
