import React, { useContext, useState } from 'react';

import classnames from 'classnames';
import dayjs from 'dayjs';

import Card from 'common/common/Card';
import { CompanyContext } from 'common/containers/CompanyContainer';
import Spinner from 'common/Spinner';
import Timestamp from 'common/Timestamp';
import { H2 } from 'common/ui/Text';
import getPlanNameForTrial from 'common/util/getPlanNameForTrial';
import isFree from 'common/util/isFree';
import isNil from 'common/util/isNil';
import numberWithCommas from 'common/util/numberWithCommas';

import { getPlanName } from './utils';

import type { FullBillingData as BillingData, Discount } from 'common/api/endpoints/companies';

const LineItem = ({
  label,
  value,
  cta,
  onCTA,
}: {
  label: React.ReactNode;
  value: React.ReactNode;
  cta?: React.ReactNode;
  onCTA?: () => void;
}) => {
  return (
    <div className="lineItem">
      <div className="label">{label}</div>
      <div className="valueRow">
        <div className="value">{value}</div>
        {cta ? (
          <button className={classnames('cta', { noGap: !value })} onClick={onCTA}>
            {cta}
          </button>
        ) : null}
      </div>
    </div>
  );
};

const discountString = (discount: Discount) => {
  const amount =
    (discount.amountOff ? '$' : '') +
    (discount.amountOff ? discount.amountOff / 100 : discount.percentOff) +
    (discount.percentOff ? '%' : '') +
    ' off';

  let duration;
  if (discount.duration === 'forever') {
    duration = 'forever';
  } else if (discount.duration === 'once') {
    duration = 'once';
  } else {
    duration = 'for ' + discount.durationInMonths + ' months';
  }

  return `${amount} ${duration}`;
};

export const TrialCard = ({
  onEndTrial,
}: {
  onEndTrial: (options: { confirm: boolean }) => Promise<void>;
}) => {
  const { planTrial, billingData } = useContext(CompanyContext);
  const [isEndingTrial, setIsEndingTrial] = useState(false);

  const handleEndTrial = async () => {
    if (isEndingTrial) {
      return;
    }

    setIsEndingTrial(true);
    await onEndTrial({ confirm: false });
    setIsEndingTrial(false);
  };

  if (!billingData?.plan || !planTrial || planTrial.cancelled || planTrial.upgraded) {
    return null;
  }

  const planName = getPlanNameForTrial(planTrial);

  return (
    <Card borderStyle="solid" className="billingCard">
      <H2 fontWeight="regular" variant="headingLg">
        Plan trial
      </H2>

      <LineItem label="Plan name" value={planName} />
      <LineItem
        label="Trial ends"
        value={<Timestamp showRelative={false} timestamp={planTrial.end} />}
        cta={isEndingTrial ? <Spinner /> : 'End trial now'}
        onCTA={handleEndTrial}
      />
    </Card>
  );
};

export const PlanCard = () => {
  const { billingData, discount, monthlySpend } = useContext(CompanyContext);

  if (!billingData) {
    return null;
  }

  return (
    <Card borderStyle="solid" className="billingCard">
      <H2 fontWeight="regular" variant="headingLg">
        Your plan
      </H2>
      <LineItem
        label="Plan name"
        value={billingData.plan ? getPlanName(billingData.plan.planID) : 'None'}
      />
      {billingData.plan ? (
        <>
          <LineItem
            label="Term"
            value={billingData.plan.timeframe === 'monthly' ? 'Monthly' : 'Annual'}
          />
          <LineItem
            label="Price"
            value={`$${numberWithCommas(Math.ceil(monthlySpend / 100))}/${
              billingData.plan.timeframe === 'monthly' ? 'mo' : 'yr'
            }`}
          />
          {discount ? <LineItem label="Discount" value={discountString(discount)} /> : null}
        </>
      ) : null}
    </Card>
  );
};

const BillingStatusLineItem = ({
  billingData,
  onQualifyCurrentPlan,
}: {
  billingData: BillingData;
  onQualifyCurrentPlan: () => void;
}) => {
  const { plan } = billingData;

  let status = 'Active';
  let cta = undefined;
  let onCTA = undefined;
  if (!plan) {
    status = 'Cancelled';
  } else if (billingData.expired) {
    status = 'Expired';
    cta = 'Unexpire';
    onCTA = onQualifyCurrentPlan;
  } else if (billingData.scheduledChange) {
    const planName = getPlanName(billingData.scheduledPlanID);
    status = planName ? `Moving to "${planName}"` : 'Changing plan';
  } else if (
    billingData.cancelled &&
    billingData.activeUntil &&
    billingData.activeUntil > dayjs().toDate()
  ) {
    status = 'Cancelling';
  }

  return (
    <LineItem
      label="Status"
      value={
        <>
          {status}
          {billingData.activeUntil && status !== 'Active' ? (
            <>
              {' '}
              on <Timestamp showRelative={false} timestamp={billingData.activeUntil} />
            </>
          ) : null}
        </>
      }
      cta={cta}
      onCTA={onCTA}
    />
  );
};

export const BillingCard = ({
  onChangeInvoiceEmail,
  onQualifyCurrentPlan,
  onShowInvoices,
  onUpdateCard,
}: {
  onChangeInvoiceEmail: () => void;
  onQualifyCurrentPlan: () => void;
  onShowInvoices: () => void;
  onUpdateCard: () => void;
}) => {
  const { billingData } = useContext(CompanyContext);
  if (!billingData || !('cancelled' in billingData)) {
    return null;
  }

  const inactive =
    billingData.cancelled ||
    isNil(billingData.activeUntil) ||
    billingData.activeUntil < dayjs().toDate();

  return (
    <Card borderStyle="solid" className="billingCard">
      <H2 fontWeight="regular" variant="headingLg">
        Billing
      </H2>
      <LineItem
        label="Next billing cycle"
        value={
          billingData.activeUntil && !inactive ? (
            <Timestamp showRelative={false} timestamp={billingData.activeUntil} />
          ) : (
            'Never'
          )
        }
      />
      <BillingStatusLineItem
        billingData={billingData}
        onQualifyCurrentPlan={onQualifyCurrentPlan}
      />
      {billingData.plan && !isFree(billingData.plan.planID) ? (
        <LineItem
          label="Billing email"
          value={billingData.invoiceEmail}
          cta="Change"
          onCTA={onChangeInvoiceEmail}
        />
      ) : null}
      <LineItem
        label="Credit card"
        value={billingData.hasCard ? `Ends with ${billingData.last4}` : 'None'}
        cta={billingData.hasCard ? 'Change card or address' : 'Add'}
        onCTA={onUpdateCard}
      />
      <LineItem label="History" value={null} cta="See invoices" onCTA={onShowInvoices} />
    </Card>
  );
};
