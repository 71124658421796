import AJAX from 'common/AJAX';
import createCustomError from 'common/util/createCustomError';
import parseAPIResponse from 'common/util/parseAPIResponse';
import validateInput from 'common/validateInput';

import type { Viewer } from './containers/ViewerContainer';

const FileUploaderError = createCustomError('FileUploaderError');

const postFileAsync = (file: File, fileType: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    AJAX.postFile('/api/files/upload', { file }, { fileType }, (response) => {
      if (response === 'server error') {
        reject('server error');
        return;
      }

      resolve(response);
    });
  });
};

export const upload = async (file: File, viewer: Viewer): Promise<string> => {
  if (!viewer || viewer.loggedOut) {
    throw new FileUploaderError({
      message: 'You must be logged in to upload a file',
    });
  }

  if (!file) {
    throw new FileUploaderError({
      message: 'No file provided',
    });
  }

  if (!validateInput.publicFile(file)) {
    throw new FileUploaderError({
      message: 'Invalid file (8MB max)',
    });
  }

  let parsedAPIResponse;

  try {
    const response = await postFileAsync(file, file.type);
    parsedAPIResponse = parseAPIResponse<{ fileURL: string }>(response, {
      isSuccessful: (parsedResponse) =>
        !!parsedResponse.fileURL && typeof parsedResponse.fileURL === 'string',
      errors: {
        'failed to upload file': 'Failed to upload file',
        'plan does not support': 'This workspace does not support file attachments',
      },
    });
  } catch (error) {
    throw new FileUploaderError({
      message: 'Server error',
      file,
      fileType: file.type,
    });
  }

  const { error, parsedResponse } = parsedAPIResponse;

  if (error || !parsedResponse) {
    throw new FileUploaderError({
      file,
      fileType: file.type,
      message: error?.message ?? 'Server error',
      response: parsedAPIResponse,
    });
  }

  return parsedResponse.fileURL;
};
