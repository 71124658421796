import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import * as AuthRequests from 'common/auth/AuthRequests';
import { IsWidgetContext } from 'common/containers/IsWidgetContainer';
import { LocationContext, RouterContext } from 'common/containers/RouterContainer';
import LazyLoadedImage from 'common/LazyLoadedImage';
import Text from 'common/ui/Text';
import withContexts from 'common/util/withContexts';

import AuthButton from './AuthButton';
import Button from './inputs/Button';
import OAuthFlows from './OAuthFlows';
import Tappable from './Tappable';

import GoogleIcon from 'img/google-icon.svg';

import 'css/components/_LoginForm.scss';

class LoginForm extends Component {
  static propTypes = {
    hasSuccessAction: PropTypes.bool.isRequired,
    initiateProvider: PropTypes.string,
    isWidget: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    oauthProvider: PropTypes.string,
    removeBranding: PropTypes.bool,
    onEmail: PropTypes.func.isRequired,
    onForgotPassword: PropTypes.func.isRequired,
    onSignup: PropTypes.func.isRequired,
    adminLogInURL: PropTypes.string,
    onSuccess: PropTypes.func.isRequired,
    router: PropTypes.object.isRequired,
  };

  state = {
    error: null,
  };

  componentDidMount() {
    if (this.props.initiateProvider) {
      this.onOAuth(this.props.initiateProvider);
      return;
    }

    if (!this.state.loading) {
      return;
    }

    const { location, router } = this.props;
    this.onOAuthContinue(location.query);
    router.replace(location.pathname);
  }

  onOAuth = (oauthTypeName) => {
    if (this.state.loading) {
      return;
    }

    this.setState({
      error: null,
      loading: oauthTypeName,
    });

    var OAuthFlow = OAuthFlows.getFlow(oauthTypeName);
    var flow = new OAuthFlow({
      onContinue: this.onOAuthContinue,
      onFailure: this.onOAuthFailure,
      onSuccess: this.onOAuthSuccess,
      redirectURL: null,
      separateWindow: null,
      shouldOpenNewTab: this.props.hasSuccessAction,
    });
    flow.connect();
  };

  onOAuthFailure = (error) => {
    this.setState({
      error,
    });
  };

  onOAuthSuccess = (oauthData) => {
    this.login({
      ...oauthData,
      useTokenAuth: this.props.isWidget,
    });
  };

  login = async (requestData) => {
    const { error, parsedResponse, redirecting } = await AuthRequests.login(requestData);

    if (redirecting) {
      return;
    }

    if (!error) {
      this.props.onSuccess(parsedResponse);
      return;
    }

    this.setState({
      error: error.message,
      loading: null,
    });
  };

  render() {
    const loginCopy = {
      headerText: this.props.removeBranding ? 'Log in with' : 'Log in to Canny with',
      adminLinkText: this.props.removeBranding ? 'Log in with SSO' : 'Log in to Canny with SSO',
    };

    return (
      <div className="loginForm">
        <div className="topContainer">
          <h1>{loginCopy.headerText}</h1>
          <div className="buttons">
            <AuthButton
              authType="facebook"
              onFailure={this.onOAuthFailure}
              onSuccess={this.onOAuthSuccess}
              value={<div className="icon icon-facebook" />}
            />
            <AuthButton
              authType="google"
              onFailure={this.onOAuthFailure}
              onSuccess={this.onOAuthSuccess}
              value={
                <LazyLoadedImage
                  alt="google icon"
                  loading="eager"
                  src={GoogleIcon}
                  className="google"
                />
              }
            />
            <AuthButton
              authType="github"
              onFailure={this.onOAuthFailure}
              onSuccess={this.onOAuthSuccess}
              value={<div className="icon icon-github" />}
            />
            <div className="centeredDivider">OR</div>
            <Button
              buttonType="emailButton"
              onTap={this.props.onEmail}
              value={<div className="icon icon-email"></div>}
            />
          </div>
          {this.props.adminLogInURL && (
            <Text href={this.props.adminLogInURL} className="accountModalAdminLink" as="a">
              {loginCopy.adminLinkText}
            </Text>
          )}
          {this.state.error ? <div className="error">{this.state.error}</div> : null}
        </div>
        <div className="accountModalCTA">
          Need an account?{' '}
          <Tappable onTap={this.props.onSignup}>
            <span className="underline">Sign up</span>
          </Tappable>
        </div>
      </div>
    );
  }
}

export default compose(
  withContexts({
    isWidget: IsWidgetContext,
    location: LocationContext,
    router: RouterContext,
  })
)(LoginForm);
