import {
  ErrorType,
  InvalidateType,
  LoadedMoreType,
  LoadedType,
  type QueryParams,
  RequestMoreType,
  RequestType,
} from 'common/actions/ideaPost';

import queryReducerFactory, { type QueriesState, type QueryState } from './queryReducerFactory';
export { defaultState } from './queryReducerFactory';

import type { Post } from 'common/api/resources/posts';

export type IdeaPostQueriesState = QueriesState<Post, QueryParams>;
export type IdeaPostQueryState = QueryState<Post, QueryParams>;

type PostQueryObject = Post & { [key: string]: unknown };
export default queryReducerFactory<PostQueryObject, QueryParams>({
  errorType: ErrorType,
  invalidateType: InvalidateType,
  loadedMoreType: LoadedMoreType,
  loadedType: LoadedType,
  requestMoreType: RequestMoreType,
  requestType: RequestType,
  uniqueKey: '_id',
});
