import React from 'react';

import classnames from 'classnames';

import Tappable from 'common/Tappable';

import 'css/components/subdomain/admin/_AdminFeedbackMobileWrapper.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
  onBack: () => void;
};

const AdminFeedbackMobileWrapper = ({ onBack, className, children }: Props) => (
  <div className={classnames('adminFeedbackMobileWrapper', className)}>
    <Tappable onTap={onBack}>
      <div className="mobileHeader">
        <span className="icon-chevron-left backIcon" /> Feedback
      </div>
    </Tappable>
    {children}
  </div>
);

export default AdminFeedbackMobileWrapper;
