import React, { useCallback, useContext, useState } from 'react';

import { type Dispatch, compose } from 'redux';

import { reloadTLDVSettings } from 'common/actions/tldvSettings';
import AJAX from 'common/AJAX';
import { IntegrationItemNames, SyncIntegrationNames } from 'common/constants/autopilotIntegrations';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { TLDVSettingsContext } from 'common/containers/TLDVSettingsContainer';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import connect from 'common/core/connect';
import Helmet from 'common/helmets/Helmet';
import TextInput from 'common/inputs/TextInput';
import withAccessControl from 'common/routing/withAccessControl';
import SpinnerV2 from 'common/SpinnerV2';
import AdminFeatureUpsell from 'common/subdomain/admin/AdminFeatureUpsell';
import AdminIntegrationAutopilotSettingsSection from 'common/subdomain/admin/AdminQueue/AdminIntegrationAutopilotSettingsSection/AdminIntegrationAutopilotSettingsSection';
import AdminSettingsHeader from 'common/subdomain/admin/AdminSettings/AdminSettingsHeader';
import Alert from 'common/ui/Alert';
import ButtonV2 from 'common/ui/ButtonV2';
import Label from 'common/ui/common/Label';
import { P } from 'common/ui/Text';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import styles from 'css-module/components/subdomain/admin/settings/_AdminTLDVSettings.module.scss';

import type { Company } from 'common/api/endpoints/companies';
import type { TLDVSettingsState } from 'common/reducers/tldvSettings';

type OwnProps = {
  tldvSettings: TLDVSettingsState;
};

type ConnectProps = {
  reloadTLDVSettings(): Promise<void>;
};

type Props = OwnProps & ConnectProps;

const InstallContainer = ({
  tldvSettings,
  onAPIKeyChange,
  apiKey,
  onInstallClick,
  loading,
  onUninstallClick,
}: {
  tldvSettings: TLDVSettingsState;
  onAPIKeyChange: (apiKey: string) => void;
  apiKey: string;
  onInstallClick: () => void;
  loading: boolean;
  onUninstallClick: () => void;
}) => {
  const hasLostAccess = !!tldvSettings?.data?.installation?.lostAccess;
  const ctaValue = hasLostAccess ? 'Reinstall tl;dv' : 'Install tl;dv';

  return (
    <div className={styles.installContainer}>
      {hasLostAccess && (
        <Alert type={Alert.AlertTypes.Danger} className={styles.alert}>
          <Alert.Heading>Lost access</Alert.Heading>
          <Alert.Content>
            Your tl;dv integration has lost access to Canny. Please reinstall the integration.
          </Alert.Content>
        </Alert>
      )}
      <P>Enter your tl;dv API key to connect your tl;dv account.</P>
      <div className={styles.fieldsContainer}>
        <div className={styles.installFields}>
          <Label label={<P fontWeight="medium">API Key</P>} labelPlacement="top">
            <TextInput
              className={styles.input}
              onChange={(e) => onAPIKeyChange(e.currentTarget.value)}
              placeholder="e.g. rZmPxkVTqWoLjdCNis"
              value={apiKey}
            />
          </Label>
        </div>
        <div className={styles.installButtons}>
          <ButtonV2 size="medium" onClick={onInstallClick} loading={loading} disabled={!apiKey}>
            {ctaValue}
          </ButtonV2>
          {hasLostAccess && (
            <ButtonV2 variant="outlined" size="medium" onClick={onUninstallClick} color="error">
              Uninstall
            </ButtonV2>
          )}
        </div>
      </div>
    </div>
  );
};

const Contents = ({
  tldvSettings,
  company,
  loading,
  onAPIKeyChange,
  apiKey,
  onInstallClick,
  onUninstallClick,
}: {
  tldvSettings: TLDVSettingsState;
  company: Company;
  loading: boolean;
  onAPIKeyChange: (apiKey: string) => void;
  apiKey: string;
  onInstallClick: () => void;
  onUninstallClick: () => void;
}) => {
  const installation = tldvSettings?.data?.installation;
  const hasInstalled = installation && !!installation?.installed && !installation?.lostAccess;
  if (!company?.integrations?.tldv) {
    return <AdminFeatureUpsell feature="tldv" />;
  } else if (loading) {
    return <SpinnerV2 size="xxxxlarge" />;
  } else if (!hasInstalled) {
    return (
      <InstallContainer
        tldvSettings={tldvSettings}
        onAPIKeyChange={onAPIKeyChange}
        apiKey={apiKey}
        onInstallClick={onInstallClick}
        loading={loading}
        onUninstallClick={onUninstallClick}
      />
    );
  }
  return (
    <>
      <Alert type={Alert.AlertTypes.Confirmation} className={styles.alert}>
        <Alert.Heading>tl;dv connected</Alert.Heading>
        <Alert.Content>
          tl;dv is linked to your workspace. New transcripts will be analyzed for feedback within 24
          hours.
        </Alert.Content>
        <Alert.CTA>
          <ButtonV2 variant="outlined" color="error" size="medium" onClick={onUninstallClick}>
            Uninstall
          </ButtonV2>
        </Alert.CTA>
      </Alert>
      <AdminIntegrationAutopilotSettingsSection
        integrationLabel="tl;dv"
        integrationName={SyncIntegrationNames.tldv}
        integrationType={IntegrationItemNames.call}
      />
    </>
  );
};

const AdminTLDVSettings = (props: Props) => {
  // props
  const { reloadTLDVSettings } = props;

  // context
  const company = useContext<Company>(CompanyContext);
  const showToast = useContext(ShowToastContext);
  const tldvSettings = useContext(TLDVSettingsContext);

  // state
  const [loading, setLoading] = useState<boolean>(false);
  const [apiKey, setAPIKey] = useState<string>('');

  // helpers
  const sendRequest = useCallback(
    async (url: string, params: Record<string, unknown> = {}) => {
      setLoading(true);

      const response = await AJAX.post(url, params);
      const { error } = parseAPIResponse(response, {
        isSuccessful: isDefaultSuccessResponse,
      });

      if (error) {
        setLoading(false);
        showToast(error.message, ToastTypes.error);
        return;
      }

      await reloadTLDVSettings();
      setLoading(false);
    },
    [reloadTLDVSettings, showToast]
  );

  const install = useCallback(
    async () => sendRequest('/api/tldv/install', { apiKey }),
    [sendRequest, apiKey]
  );

  const uninstall = useCallback(async () => sendRequest('/api/tldv/uninstall', {}), [sendRequest]);

  return (
    <main>
      <Helmet title="tl;dv Integration | Canny" />
      <AdminSettingsHeader
        title="tl;dv Integration"
        subheading="Automatically detect and gather feedback from tl;dv transcripts."
        learnMoreLink="https://help.canny.io/en/articles/10222297-tl-dv-integration"
      />
      <div className={styles.content}>
        <Contents
          tldvSettings={tldvSettings}
          company={company}
          loading={loading}
          onAPIKeyChange={setAPIKey}
          apiKey={apiKey}
          onInstallClick={install}
          onUninstallClick={uninstall}
        />
      </div>
    </main>
  );
};

export default compose(
  connect(null, (dispatch: Dispatch<any>) => ({
    reloadTLDVSettings: () => dispatch(reloadTLDVSettings()),
  })),
  withAccessControl<Props>(
    testEveryPermission(RoutePermissions.integrations.tldv),
    '/admin/settings'
  )
)(AdminTLDVSettings) as unknown as React.FC<OwnProps>;
