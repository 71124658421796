import Message from 'common/message/Message';
import devURL from 'common/util/devURL';
import queryString from 'common/util/queryString';

const AzureAuthURL = 'https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize';
const AzureClientID = 'ea3dd8ad-4e1e-42d3-bfd4-0c9e9f892ce0';
const RedirectURI = 'https://canny.io/auth';
const Origin = devURL('https://canny.io');

export default class OAuthAzure {
  constructor({ onSuccess, onFailure, onContinue, shouldOpenNewTab, redirectURL, separateWindow }) {
    this._onFailure = onFailure;
    this._onSuccess = onSuccess;
    this._onContinue = onContinue;
    this._shouldOpenNewTab = shouldOpenNewTab;
    this._redirectURL = redirectURL;
    this._separateWindow = separateWindow;
  }

  connect = () => {
    const url =
      AzureAuthURL +
      queryString.stringify({
        client_id: AzureClientID,
        domain_hint: 'organizations',
        redirect_uri: RedirectURI,
        response_mode: 'query',
        response_type: 'code',
        scope: 'user.read openid email profile',
        state: this.getState(),
      });

    if (!this._shouldOpenNewTab) {
      window.location = url;
      return;
    }

    // No, 'noopener' because we trust Azure
    const authWindow = window.open(url, 'Canny + Microsoft Entra ID Integration');
    const unsubscribe = Message.subscribe(authWindow, Origin, 'authResult', (result) => {
      this._onContinue(result);
      unsubscribe();
    });
  };

  getState = () => {
    const params = {
      authType: 'azure',
      stage: 2,
      ...(this._shouldOpenNewTab && { close: true }),
      ...(this._redirectURL && { redirectURL: encodeURIComponent(this._redirectURL) }),
      ...(this._separateWindow && { separateWindow: encodeURIComponent(this._separateWindow) }),
      ...(__DEV__ && {
        dev: true,
      }),
    };
    return JSON.stringify(params);
  };
}
