import { Component, cloneElement } from 'react';

import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';

import KeyCodes from 'common/KeyCodes';

const PressTimeout = 800;

function getTouch(touch) {
  if (!touch) {
    return null;
  }
  return {
    pageX: touch.pageX,
    pageY: touch.pageY,
    clientX: touch.clientX,
    clientY: touch.clientY,
  };
}

// Handle mouse + touch events with one easy handler, onTap

/**
 * @deprecated Prefer <ButtonV2> (e.g with type 'plain') or <button> instead
 */
export default class Tappable extends Component {
  static propTypes = {
    onTap: PropTypes.func,
    onTapStart: PropTypes.func,
    stopPropagation: PropTypes.bool,
    triggerOnEnter: PropTypes.bool,
    triggerOnSpace: PropTypes.bool,
  };

  static defaultProps = {
    stopPropagation: false,
    triggerOnEnter: false,
    triggerOnSpace: false,
  };

  componentDidMount() {
    this._mouseDown = false;
    this._initialTouch = null;

    if (typeof document === 'undefined') {
      return;
    }

    document.addEventListener('mouseup', this.documentMouseUp, false);
    document.addEventListener('touchend', this.documentTouchEnd, false);
  }

  componentWillUnmount() {
    if (typeof document === 'undefined') {
      return;
    }

    this.cancelPressDetection();
    this.cleanupScrollDetection();

    document.removeEventListener('mouseup', this.documentMouseUp, false);
    document.removeEventListener('touchend', this.documentTouchEnd, false);
  }

  onKeyDown = (e) => {
    const { triggerOnEnter, triggerOnSpace } = this.props;

    const shouldTriggerWithEnter = triggerOnEnter && e.keyCode === KeyCodes.Enter;
    const shouldTriggerWithSpace = triggerOnSpace && e.keyCode === KeyCodes.Space;
    if (shouldTriggerWithEnter || shouldTriggerWithSpace) {
      e.preventDefault();
      this.processEvent(e);
      this.props.onTap?.(e);
    }
  };

  onMouseDown = (e) => {
    this.processEvent(e);

    if (window._blockMouseEvents) {
      return;
    }

    this._mouseDown = true;

    this.props.onTapStart && this.props.onTapStart(e);
  };

  onMouseUp = (e) => {
    this.processEvent(e);

    if (window._blockMouseEvents) {
      return;
    }

    if (!this._mouseDown) {
      return;
    }

    this._mouseDown = false;

    this.props.onTap && this.props.onTap(e);
  };

  documentMouseUp = (e) => {
    if (window._blockMouseEvents) {
      return;
    }

    if (!this._mouseDown) {
      return;
    }

    if (findDOMNode(this).contains(e.target)) {
      return;
    }

    this._mouseDown = false;
  };

  documentTouchEnd = (e) => {
    if (!this._initialTouch) {
      return;
    }

    if (findDOMNode(this).contains(e.target)) {
      return;
    }

    this.endTouch();
  };

  cancelPressDetection = () => {
    clearTimeout(this._pressTimeout);
  };

  cleanupScrollDetection = () => {
    this._scrollParents = undefined;
  };

  detectScroll = () => {
    const didScroll = this._scrollParents.some((scrollParent, i) => {
      return (
        scrollParent.scrollTop !== this._scrollParentsPos[i].top ||
        scrollParent.scrollLeft !== this._scrollParentsPos[i].left
      );
    });
    return didScroll;
  };

  initPressDetection = (e) => {
    this._pressDetected = false;

    e.persist();

    this._pressTimeout = setTimeout(() => {
      this.endTouch(e);
    }, PressTimeout);
  };

  initScrollDetection = () => {
    this._scrollDetected = false;
    this._scrollParents = [];
    this._scrollParentsPos = [];

    for (var node = findDOMNode(this); node; node = node.parentNode) {
      if (node.scrollHeight > node.offsetHeight || node.scrollWidth > node.offsetWidth) {
        this._scrollParents.push(node);
        this._scrollParentsPos.push({
          top: node.scrollTop,
          left: node.scrollLeft,
        });
      }
    }
  };

  endTouch = (e) => {
    this.cancelPressDetection();

    this._initialTouch = null;
  };

  onTouchStart = (e) => {
    this.processEvent(e);

    window._blockMouseEvents = true;

    // don't care about >1 touches
    if (this._initialTouch || e.touches.length > 1) {
      this.endTouch(e);
      return;
    }

    this.props.onTapStart && this.props.onTapStart(e);

    this._initialTouch = getTouch(e.touches[0]);
    this._touchMoved = false;

    this.initPressDetection(e);
    this.initScrollDetection();
  };

  onTouchMove = (e) => {
    this.processEvent(e);

    if (!this._initialTouch) {
      return;
    }

    if (this.detectScroll()) {
      this.endTouch(e);
      return;
    }

    this.cancelPressDetection();

    this._touchMoved = true;
  };

  onTouchEnd = (e) => {
    this.processEvent(e);

    if (!this._initialTouch) {
      return;
    }

    this.endTouch(e);

    if (this.detectScroll()) {
      return;
    } else if (this._touchMoved) {
      return;
    }

    this.props.onTap && this.props.onTap(e);
  };

  processEvent = (e) => {
    if (this.props.stopPropagation) {
      e.stopPropagation();
    }
  };

  render() {
    return cloneElement(this.props.children, {
      onKeyDown: this.onKeyDown,
      onMouseDown: this.onMouseDown,
      onMouseUp: this.onMouseUp,
      onTouchStart: this.onTouchStart,
      onTouchMove: this.onTouchMove,
      onTouchEnd: this.onTouchEnd,
    });
  }
}
