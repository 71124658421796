import React, { useContext } from 'react';

import { SyncReviewIntegrationNames } from 'common/constants/autopilotIntegrations';
import { LocationContext, RouterContext } from 'common/containers/RouterContainer';

import { DuplicatePostSuggestion, UniquePostSuggestion } from './Suggestion';
import { isCannyPost, isPostDraft } from '../utils';

import type { InboxCannyPostItem, InboxDraftItem, QueueInboxItem } from '../types';
import type { Board } from 'common/api/endpoints/boards';
import type { Company } from 'common/api/endpoints/companies';

type Props = {
  item: QueueInboxItem;
  boards: Board[];
  company: Company;
};

const PostDraftSuggestion = ({
  boards,
  company,
  item,
}: {
  boards: Board[];
  company: Company;
  item: InboxDraftItem;
}) => {
  const router = useContext(RouterContext);
  const location = useContext(LocationContext);
  const isReviewSource = Object.values(SyncReviewIntegrationNames).includes(
    item.source as unknown as SyncReviewIntegrationNames
  );
  const defaultLink = isReviewSource
    ? '/admin/settings/autopilot/feedback-discovery'
    : '/admin/settings/integrations';

  if (!item.duplicatePost) {
    return (
      <UniquePostSuggestion
        title={item.title}
        translation={item.translation}
        details={item.details}
        author={item.author}
        created={item.createdAt}
        workaround={item.workaround}
        onClick={() => window.open(item.link ?? defaultLink, '_blank', 'noopener,noreferrer')}
      />
    );
  }
  const duplicatePost = item.duplicatePost;
  const board = boards.find((board) => board._id === duplicatePost.boardID);

  return (
    <DuplicatePostSuggestion
      sourceType="draft"
      title={item.title}
      translation={item.translation}
      details={item.details}
      author={item.author}
      created={item.createdAt}
      board={board}
      duplicatePost={item.duplicatePost}
      company={company}
      workaround={item.workaround}
      onClickDuplicatePost={() =>
        window.open(item.link ?? defaultLink, '_blank', 'noopener,noreferrer')
      }
      onClickOriginalPost={() =>
        router.replace({
          pathname: location.pathname,
          query: { ...location.query, postURLName: item.duplicatePost?.urlName },
        })
      }
    />
  );
};

const CannyPostSuggestion = ({
  boards,
  company,
  item,
}: {
  boards: Board[];
  company: Company;
  item: InboxCannyPostItem;
}) => {
  const router = useContext(RouterContext);
  const location = useContext(LocationContext);

  if (!item.duplicatePost) {
    return (
      <UniquePostSuggestion
        title={item.title}
        translation={item.translation}
        details={item.details}
        author={item.author}
        created={item.created}
        board={item.board}
        onClick={() =>
          router.replace({
            pathname: location.pathname,
            query: { ...location.query, postURLName: item.urlName },
          })
        }
      />
    );
  }

  const duplicatePost = item.duplicatePost;
  const board = boards.find((board) => board._id === duplicatePost.boardID);

  return (
    <DuplicatePostSuggestion
      sourceType="post"
      title={item.title}
      translation={item.translation}
      details={item.details}
      author={item.author}
      created={item.created}
      board={board}
      duplicatePost={item.duplicatePost}
      company={company}
      onClickDuplicatePost={() =>
        router.replace({
          pathname: location.pathname,
          query: { ...location.query, postURLName: item.urlName },
        })
      }
      onClickOriginalPost={() =>
        router.replace({
          pathname: location.pathname,
          query: { ...location.query, postURLName: item.duplicatePost?.urlName },
        })
      }
    />
  );
};

const AdminQueueSuggestionCell = ({ boards, company, item }: Props) => {
  if (isPostDraft(item)) {
    return <PostDraftSuggestion boards={boards} company={company} item={item} />;
  }

  if (isCannyPost(item)) {
    return <CannyPostSuggestion boards={boards} company={company} item={item} />;
  }

  return null;
};

export default AdminQueueSuggestionCell;
