import requestActionFactory from 'common/actions/requestActionFactory';
import Data from 'common/Data';
import promisify from 'common/util/promisify';

import type { Group } from 'common/api/endpoints/group';
import type { Dispatch, GetState, State } from 'redux-connect';

export { defaultState } from 'common/reducers/requestReducerFactory';

// Action Types

const {
  requestAction: groupsRequested,
  loadedAction: groupsLoaded,
  errorAction: groupsError,

  RequestType,
  LoadedType,
  ErrorType,
} = requestActionFactory<Group[]>('groups');

export { RequestType, LoadedType, ErrorType };

// Action Creators

function fetchGroups() {
  return async (dispatch: Dispatch, getState: GetState) => {
    const cookies = getState().cookies;
    try {
      const response = await promisify(
        Data.fetch,
        {
          groups: {
            query: Data.queries.groups,
          },
        },
        cookies
      );
      return dispatch(groupsLoaded(response.groups));
    } catch (error) {
      if (typeof error === 'string') {
        return dispatch(groupsError(error));
      }
      return dispatch(groupsError('server error'));
    }
  };
}

export function loadGroups() {
  return (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    if (shouldFetch(state)) {
      dispatch(groupsRequested());
      return dispatch(fetchGroups());
    }
  };
}

export function reloadGroups() {
  return (dispatch: Dispatch) => {
    return dispatch(fetchGroups());
  };
}

// Helpers

export function shouldFetch(state: State) {
  return !state.groups.data && !state.groups.error;
}
