import React, { Fragment, useContext, useState } from 'react';

import { ChevronDown, ChevronUp } from 'lucide-react';

import { LocationContext, RouterContext } from 'common/containers/RouterContainer';
import IconButtonV2 from 'common/ui/IconButtonV2';
import { H1 } from 'common/ui/Text';

import ColumnSetting from './ColumnSetting';

import type { Column } from './AdminRoadmapViewSettingsModal';
import type { Board } from 'common/api/endpoints/boards';
import type { DescriptionColumn, Factor } from 'common/api/endpoints/roadmaps';
import type { Board as PlainBoard } from 'common/api/resources/board';

import 'css/components/subdomain/admin/AdminRoadmap/_AdminRoadmapViewSettingsSection.scss';

type Props = {
  boards: Board[];
  columns: (DescriptionColumn | Factor)[];
  distinctBoards: PlainBoard[];
  hiddenDescriptionColumns: string[];
  hiddenFactors: string[];
  sectionHeader: string;
  updateColumns: (columns: Column[]) => void;
};

const encodedQueryParams = ['description', 'factor'];

const AdminRoadmapViewSettingsSection = ({
  boards,
  columns,
  distinctBoards,
  hiddenDescriptionColumns,
  hiddenFactors,
  sectionHeader,
  updateColumns,
}: Props) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const router = useContext(RouterContext);
  const location = useContext(LocationContext);

  const clearSettingFromPath = (name: string) => {
    const { query } = location;

    const updatedQuery: Record<string, string | undefined> = {};

    encodedQueryParams.forEach((queryName) => {
      if (query[queryName]) {
        const decodedQuery = JSON.parse(decodeURIComponent(query[queryName]));

        if (decodedQuery[name]) {
          if (Object.keys(decodedQuery).length === 1) {
            updatedQuery[queryName] = undefined;
          } else {
            updatedQuery[queryName] = encodeURIComponent(
              JSON.stringify({
                ...decodedQuery,
                [name]: undefined,
              })
            );
          }
        }
      }
    });

    const newQuery = Object.assign({}, query, {
      [name]: undefined,
      ...updatedQuery,
    });

    router.push({
      pathname: location.pathname,
      query: newQuery,
    });
  };

  return (
    <section className="adminRoadmapViewSettingsSection">
      <div className="sectionHeader">
        <H1 variant="headingSm">{sectionHeader}</H1>
        <IconButtonV2
          aria-label="truncate settings section"
          icon={isTruncated ? ChevronDown : ChevronUp}
          size="medium"
          variant="plain"
          onClick={() => setIsTruncated(!isTruncated)}
        />
      </div>
      {!isTruncated && (
        <ul className="factorVisibilityList">
          {columns.map((column: DescriptionColumn | Factor) => {
            return (
              <Fragment key={column._id}>
                <ColumnSetting
                  boards={boards}
                  column={column}
                  clearSettingFromPath={clearSettingFromPath}
                  distinctBoards={distinctBoards}
                  hiddenFactors={hiddenFactors}
                  hiddenDescriptionColumns={hiddenDescriptionColumns}
                  updateColumns={updateColumns}
                />
              </Fragment>
            );
          })}
        </ul>
      )}
    </section>
  );
};

export default AdminRoadmapViewSettingsSection;
