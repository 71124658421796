import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import Tappable from 'common/Tappable';

import 'css/components/_FileInput.scss';

export default class FileInput extends Component {
  static propTypes = {
    accept: PropTypes.string,
    borderStyle: PropTypes.string,
    disabled: PropTypes.bool,
    onFile: PropTypes.func.isRequired,
    onFileStart: PropTypes.func,
    onOpen: PropTypes.func,
    value: PropTypes.node.isRequired,
    defaultStyle: PropTypes.bool,
  };

  static defaultProps = {
    accept: '*',
    borderStyle: 'solid',
    defaultStyle: true,
  };

  constructor(props) {
    super(props);

    this.fileRef = React.createRef();
  }

  onChange = (event) => {
    var input = event.target;
    if (!input.files || !input.files[0]) {
      return;
    }

    var reader = new FileReader();
    var file = input.files[0];
    reader.onload = (e) => {
      this.props.onFile(file, reader.result);
    };
    reader.readAsDataURL(file);
  };

  openFileInput = () => {
    const { disabled } = this.props;
    if (disabled) {
      return;
    }

    const { onFileStart } = this.props;
    if (onFileStart) {
      const shouldContinue = onFileStart();
      if (!shouldContinue) {
        return;
      }
    }

    this.props.onOpen && this.props.onOpen();
    this.fileRef.current.value = null;
    this.fileRef.current.click();
  };

  render() {
    var style = {
      borderStyle: this.props.borderStyle,
    };

    return (
      <div className="fileInput">
        <Tappable onTap={this.openFileInput}>
          <div
            className={classnames({
              fileInputButton: this.props.defaultStyle,
              disabled: this.props.disabled,
            })}
            style={style}>
            {this.props.value}
          </div>
        </Tappable>
        <input accept={this.props.accept} ref={this.fileRef} type="file" onChange={this.onChange} />
      </div>
    );
  }
}
