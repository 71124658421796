import { GrowthPlanID } from 'common/util/isGrowth';
import { StarterPlanID } from 'common/util/isStarter';

import type { UseCase } from 'common/subdomain/admin/AdminOnboarding/Types';

const getPlanIDFromUseCase = (useCase: UseCase) => {
  const { customAccess, indexed } = useCase;

  if (customAccess) {
    return GrowthPlanID;
  }

  if (indexed) {
    return StarterPlanID;
  }

  return;
};

export default getPlanIDFromUseCase;
