// Deduplicate an array
const uniqueByKey = <K extends PropertyKey, T extends Record<K, unknown>>(input: T[], key: K) => {
  const seen = new Set();
  return input.filter((item) => {
    if (!(key in item)) {
      throw new Error(`Key ${String(key)} not found in item ${item}`);
    }

    const keyValue = item[key];
    if (seen.has(keyValue)) {
      return false;
    }

    seen.add(keyValue);
    return true;
  });
};

export default uniqueByKey;
