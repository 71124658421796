import React, { useCallback, useContext, useEffect, useState } from 'react';

import cn from 'classnames';

import { reloadCompany } from 'common/actions/company';
import { ShowIntercomContext } from 'common/containers/IntercomContainer';
import connect from 'common/core/connect';
import Button from 'common/inputs/Button';
import ModernModal from 'common/modals/ModernModal';
import SpinnerV2 from 'common/SpinnerV2';
import ButtonV2 from 'common/ui/ButtonV2';
import styles from 'css-module/components/subdomain/admin/billing/_DowngradeModal.module.scss';

import AdminPlanErrors from '../AdminPlanErrors';

import type { Dispatch } from 'redux';

const ChangePlanErrors = {
  DoesNotQualify: 'does not qualify',
  DowngradeNotAllowed: 'downgrade not allowed',
  InvalidPlanID: 'invalid planID',
};

type CancelSubscriptionModalProps = {
  onClose: () => void;
  header: string;
  cta: string;
  onCTA: (isConfirmed: boolean) => Promise<string>;
  reloadCompany: () => void;
};

const Error = ({ error, showIntercom }: { error: string; showIntercom: () => void }) => {
  if (!error) {
    return null;
  }

  let message;
  if (error === ChangePlanErrors.DoesNotQualify) {
    return null;
  } else {
    message = (
      <>
        Something went wrong, please try again or{' '}
        <ButtonV2 onClick={showIntercom} size="small" variant="plain" className={styles.link}>
          <span className="link">message us for help</span>
        </ButtonV2>
        .
      </>
    );
  }

  return <div className={cn('error', styles.error)}>{message}</div>;
};

const DowngradeModal = ({
  onClose,
  reloadCompany,
  cta,
  onCTA,
  header,
}: CancelSubscriptionModalProps) => {
  const showIntercom = useContext(ShowIntercomContext);
  const [loading, setLoading] = useState(false);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [unexpiringErrors, setUnexpiringErrors] = useState<string[] | null>(null);
  const [lostFeatures, setLostFeatures] = useState<string[] | null>(null);

  const attemptAction = useCallback(
    async (isConfirmed: boolean) => {
      setLoading(true);
      const response = await onCTA(isConfirmed);

      if (response === 'success') {
        setLoading(false);
        await reloadCompany();
        onClose();
        return;
      }

      let responseJSON;
      try {
        responseJSON = JSON.parse(response);
      } catch (e) {
        responseJSON = null;
      }

      setLoading(false);
      setErrorMessage(responseJSON?.error ?? null);
      setUnexpiringErrors(responseJSON?.errors ?? null);
      setLostFeatures(responseJSON?.lostFeatures ?? null);
    },
    [onCTA, onClose, reloadCompany]
  );

  // Only run once on mount
  useEffect(() => {
    if (!initialLoadComplete) {
      setInitialLoadComplete(true);
      attemptAction(false);
    }
  }, [attemptAction, initialLoadComplete]);

  return (
    <ModernModal
      closeOnClickAway={false}
      header={header}
      modalClassName={styles.modal}
      sections={[
        loading ? (
          <SpinnerV2 size="xlarge" />
        ) : (
          <>
            <div>To continue using Canny, you’ll need to turn off these features.</div>
            <AdminPlanErrors
              lostFeatures={lostFeatures}
              planErrors={unexpiringErrors}
              reloadErrors={() => attemptAction(false)}
              isReloading={loading}
            />
          </>
        ),
      ]}
      footer={
        <>
          {errorMessage ? <Error error={errorMessage} showIntercom={showIntercom} /> : null}
          <Button
            buttonType="cannyButton"
            loading={loading}
            onTap={() => attemptAction(true)}
            value={cta}
          />
        </>
      }
      onClose={onClose}></ModernModal>
  );
};

export default connect(null, (dispatch: Dispatch<any>) => ({
  reloadCompany: () => dispatch(reloadCompany()),
}))(DowngradeModal);
