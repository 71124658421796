import React from 'react';

import Tag from 'common/tags/Tag';
import styles from 'css-module/components/subdomain/admin/AdminSideBar/_CompanyFilterSection.module.scss';

import type { CompanyInfo, Names } from 'common/actions/tpcForFilters';

type Props = {
  companyNames?: Names;
  onCompanySelected: (company: CompanyInfo) => void;
  selections: string[];
};

const CompanyFilterSelections = ({ companyNames, onCompanySelected, selections }: Props) => {
  const filteredSelections = selections
    .filter((companyURLName: string) => {
      return companyNames?.[companyURLName];
    })
    .sort((a, b) => (a > b ? 1 : -1))
    .map((companyURLName) => {
      const company = companyNames?.[companyURLName];
      return company ? (
        <Tag
          key={company.urlName}
          name={company.name}
          onTap={() => onCompanySelected(company)}
          selected={true}
        />
      ) : null;
    });

  if (filteredSelections.length === 0) {
    return null;
  }

  return <div className={styles.companyFilterSectionTagWrapper}>{filteredSelections}</div>;
};

export default CompanyFilterSelections;
