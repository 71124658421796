import queryActionFactory from 'common/actions/queryActionFactory';
import DataV2 from 'common/DataV2';

import type { IdeaImpact } from 'common/api/endpoints/idea';

export type QueryParams = {
  ideaID: string;
};

export const {
  // query helpers
  getQueryKey,

  // action creators
  invalidateQueries: invalidateIdeaImpact,
  loadQuery: loadIdeaImpact,

  // action types
  RequestType,
  RequestMoreType,
  LoadedType,
  LoadedMoreType,
  ErrorType,
  InvalidateType,
} = queryActionFactory<IdeaImpact, QueryParams>('ideaImpact', DataV2.queries.ideaImpact);
