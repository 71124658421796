type Data = Record<string, unknown>;

export abstract class BaseCustomError extends Error {
  message: string;
  data: Data = {};
  constructor(message: string, data?: Data) {
    super();
    this.message = message;
    this.data = data ?? {};
  }
}

type ConstructorProps = { message: string } & Data;

export type CustomErrorConstructor = new (
  message: string | ConstructorProps,
  data?: Data
) => BaseCustomError;

export default function createError(name: string) {
  return class CustomError extends BaseCustomError {
    constructor(message: string, data?: Data);
    /** @deprecated */
    constructor(message: string | ConstructorProps);
    constructor(message: string | ConstructorProps, data?: Data) {
      let messageString;
      let dataObject;
      if (typeof message === 'string') {
        messageString = message;
        dataObject = data ?? {};
      } else {
        messageString = message?.message ?? 'Something went wrong';
        dataObject = message ?? {};
      }
      super(messageString, dataObject);

      Error.captureStackTrace?.(this, CustomError);
      this.name = name;
    }
  } satisfies CustomErrorConstructor;
}
