import React from 'react';

import classnames from 'classnames';

import Colors from 'common/colors/constants';
import Pill from 'common/common/Pill';
import styles from 'css-module/components/subdomain/admin/v2/_AdminV2Nav.module.scss';

type Props = {
  label: string;
  className?: string;
};

const TooltipPillStyle = {
  color: Colors.indigo90,
  background: Colors.white,
  border: Colors.indigo60,
};

const AdminV2NavTooltip = ({ label, className }: Props) => {
  return (
    <div className={classnames(styles.tooltipContent, className)}>
      <Pill className={styles.tooltipPill} pillStyle={TooltipPillStyle}>
        {label}
      </Pill>
    </div>
  );
};

export default AdminV2NavTooltip;
