import { RelativeDateOptions } from 'common/util/dateRanges';

import { userLoaded } from './users';
import Data from '../Data';

// Action Types

export const REQUEST_QUERY = 'canny/user_queries/request_query';
export const QUERY_LOADED = 'canny/user_queries/query_loaded';
export const QUERY_ERROR = 'canny/user_queries/query_error';
export const LOADING_MORE = 'canny/user_queries/loading_more';
export const INVALIDATE = 'canny/user_queries/invalidate';

// Actions

function requestQuery(queryParams) {
  return {
    queryParams,
    timestamp: Date.now(),
    type: REQUEST_QUERY,
  };
}

function queryLoaded(queryParams, result) {
  return {
    queryParams,
    result,
    timestamp: Date.now(),
    type: QUERY_LOADED,
  };
}

function queryError(queryParams, error) {
  return {
    error,
    queryParams,
    timestamp: Date.now(),
    type: QUERY_ERROR,
  };
}

function loadingMore(queryParams) {
  return {
    queryParams,
    timestamp: Date.now(),
    type: LOADING_MORE,
  };
}

function invalidate() {
  return {
    timestamp: Date.now(),
    type: INVALIDATE,
  };
}

// Action Creators

function fetchQuery(queryParams) {
  return (dispatch, getState) => {
    if (!queryParams) {
      return dispatch(queryError('Missing queryParams'));
    }

    const cookies = getState().cookies;
    return Data.fetch(
      {
        result: {
          input: {
            ...getRequestData(queryParams),
          },
          query: Data.queries.users,
        },
      },
      cookies,
      (error, data) => {
        return gotResult(dispatch, queryParams, error, data);
      }
    );
  };
}

/**
 * queryParams of type: {}
 * (until queries are supported)
 */
export function loadQuery(queryParams) {
  return (dispatch, getState) => {
    if (shouldFetchQuery(getState(), queryParams)) {
      dispatch(requestQuery(queryParams));
      return dispatch(fetchQuery(queryParams));
    } else if (isFetchingQuery(getState(), queryParams)) {
      return waitForResult(queryParams);
    }
  };
}

export function loadMore(userList) {
  return (dispatch, getState) => {
    const { queryParams } = userList;
    dispatch(loadingMore(queryParams));

    queryParams.limit = queryParams.limit ? queryParams.limit + 10 : 20;
    return dispatch(fetchQuery(queryParams));
  };
}

export function invalidateUserQueries() {
  return (dispatch) => {
    return dispatch(invalidate());
  };
}

// Helper Functions

export function formatDateRangeString(dateRangeString) {
  const dateRangeArr = dateRangeString.split('_');
  if (dateRangeArr.length === 1) {
    // It's a relative date, so let's get the date range based on today.
    const relativeDateRange = RelativeDateOptions[dateRangeArr[0]];
    return relativeDateRange && relativeDateRange.toRange();
  }

  return dateRangeArr;
}

export function getUserQueryKey(queryParams) {
  const { search, searchByCompanyName, segmentURLName, sort, dateRange, thirdPartyCompanyID } =
    queryParams;

  const queryObject = {
    ...(search && { search }),
    ...(searchByCompanyName && { searchByCompanyName }),
    ...(segmentURLName && { segmentURLName }),
    ...(sort && { sort }),
    ...(dateRange && { dateRange: formatDateRangeString(dateRange) }),
    ...(thirdPartyCompanyID && { thirdPartyCompanyID }),
  };
  return JSON.stringify(queryObject);
}

function getRequestData(queryParams) {
  const { search, searchByCompanyName, segmentURLName, sort, dateRange, thirdPartyCompanyID } =
    queryParams;
  const requestData = {
    limit: queryParams.limit ? queryParams.limit : 10,
    ...(search && { search }),
    ...(searchByCompanyName && { searchByCompanyName }),
    ...(segmentURLName && { segmentURLName }),
    ...(sort && { sort }),
    ...(dateRange && { dateRange: formatDateRangeString(dateRange) }),
    ...(thirdPartyCompanyID && { thirdPartyCompanyID }),
  };
  return requestData;
}

function shouldFetchQuery(state, queryParams) {
  const userQueries = state.userQueries;
  const queryKey = getUserQueryKey(queryParams);
  return !userQueries[queryKey];
}

function isFetchingQuery(state, queryParams) {
  const userQueries = state.userQueries;
  const queryKey = getUserQueryKey(queryParams);
  return !!userQueries[queryKey].loading;
}

// Callback Queue

const resultCallbacks = {};

function waitForResult(queryParams) {
  const queryKey = getUserQueryKey(queryParams);
  return new Promise((resolve, reject) => {
    resultCallbacks[queryKey] = resultCallbacks[queryKey] || [];
    resultCallbacks[queryKey].push(() => {
      resolve();
    });
  });
}

function gotResult(dispatch, queryParams, error, data) {
  var resultAction;
  const promises = [];
  if (error) {
    resultAction = queryError(queryParams, error);
  } else {
    resultAction = queryLoaded(queryParams, data.result);
    data.result.users.forEach((user) => {
      promises.push(dispatch(userLoaded(user.urlName, user)));
    });
  }

  return Promise.all(promises).then(() => {
    return Promise.all([dispatch(resultAction)]).then(() => {
      const queryKey = getUserQueryKey(queryParams);
      if (!resultCallbacks[queryKey]) {
        return;
      }

      resultCallbacks[queryKey].forEach((resultCallback) => {
        resultCallback();
      });
      resultCallbacks[queryKey].length = 0;
    });
  });
}
