import React from 'react';

import classnames from 'classnames';

import type { Column } from './AdminRoadmapTable';

type Props = {
  isLastRegularColumn: boolean;
  column: Column;
  error?: string;
  cellKey: string;
  width: number;
  children: React.ReactNode;
};

const AdminRoadmapTableCell = ({
  isLastRegularColumn,
  column,
  error,
  cellKey,
  width,
  children,
}: Props) => {
  let errorStyling = undefined;
  if (error) {
    // calculate width from content, and center horizontally
    errorStyling = {
      width: `${error.length}ch`,
      left: isLastRegularColumn ? 'auto' : `calc((${column.width}px - ${error.length}ch)  / 2)`,
    };
  }

  return (
    <div
      className={classnames('cell', column.id, `section-${column.section}`, {
        cellError: error,
        sticky: column.sticky,
      })}
      data-cell-key={cellKey}
      style={{ ...column.style, width }}>
      {children}
      {error && (
        <div className="cellErrorMessage" style={errorStyling}>
          {error}
        </div>
      )}
    </div>
  );
};

export default AdminRoadmapTableCell;
