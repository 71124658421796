import React from 'react';

import FileCard from 'common/file/FileCard';

import type { LocalFile } from 'common/constants/files';

import 'css/components/file/_FormFiles.scss';

type Props = {
  allowRemove: boolean;
  files: LocalFile[];
  onFileRemoved?: (file: LocalFile) => void;
};

const FormFiles = ({ allowRemove, files, onFileRemoved }: Props) => {
  const onRemoveClicked = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, file: LocalFile) => {
    e.preventDefault();

    if (!allowRemove || !onFileRemoved) {
      return;
    }

    onFileRemoved(file);
  };

  const renderedFiles = files.map((file) => (
    <FileCard
      allowRemove={allowRemove}
      key={file.uniqueID}
      file={file}
      onFileRemoved={onRemoveClicked}
    />
  ));

  return <div className="formFiles">{renderedFiles}</div>;
};

export default FormFiles;
