import React, { useContext } from 'react';

import { Bot, ChevronUpSquare, GanttChartSquare, Lightbulb, ScrollText, User } from 'lucide-react';

import { AutopilotCountsContext } from 'common/containers/AutopilotCountsContainer';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { LocationContext } from 'common/containers/RouterContainer';
import LazyLoadedImage from 'common/LazyLoadedImage';
import Link from 'common/Link';
import styles from 'css-module/components/subdomain/admin/v2/_AdminV2Nav.module.scss';

import IconLink from './AdminV2NavIconLink';

import CannyIcon from 'img/canny-icon.svg';

const AdminV2NavDesktopMenu = () => {
  const location = useContext(LocationContext);
  const company = useContext(CompanyContext);
  const autopilotCounts = useContext(AutopilotCountsContext);

  const { count: autopilotCount } = autopilotCounts.data ?? { count: 0 };
  const isIDBEnabled = company?.featureAllowlist?.includes('idea-database');

  return (
    <ul className={styles.navSection} data-screen-size="desktop">
      <li>
        <Link className={styles.cannyLogoLink} to="/admin">
          <LazyLoadedImage className={styles.cannyLogoImage} alt="Canny Home" src={CannyIcon} />
        </Link>
      </li>
      {isIDBEnabled && (
        <li>
          <IconLink Icon={Lightbulb} label="Ideas" to="/admin/beta/ideas" />
        </li>
      )}
      <li>
        <IconLink
          active={location.pathname.startsWith('/admin/feedback')}
          Icon={ChevronUpSquare}
          label="Portal"
          to="/admin/feedback"
        />
      </li>
      <li>
        <IconLink
          active={location.pathname.startsWith('/admin/roadmap')}
          Icon={GanttChartSquare}
          label="Roadmaps"
          to="/admin/roadmap"
        />
      </li>
      <li>
        <IconLink
          active={location.pathname.startsWith('/admin/users')}
          Icon={User}
          label="Users"
          to="/admin/users"
        />
      </li>
      <li className={styles.autopilotOption}>
        <IconLink
          active={location.pathname.startsWith('/admin/autopilot')}
          Icon={Bot}
          label="Autopilot"
          to="/admin/autopilot"
        />
        {autopilotCount > 0 ? <div className={styles.autopilotBadge} /> : null}
      </li>
      {company.showChangelog && (
        <li>
          <IconLink
            active={location.pathname.startsWith('/admin/changelog')}
            Icon={ScrollText}
            label="Changelog"
            to="/admin/changelog"
          />
        </li>
      )}
    </ul>
  );
};

export default AdminV2NavDesktopMenu;
