import React, { useContext, useState } from 'react';

import { type Dispatch, compose } from 'redux';

import { reloadTLDVSettings } from 'common/actions/tldvSettings';
import AJAX from 'common/AJAX';
import { SyncIntegrationNames } from 'common/constants/autopilotIntegrations';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import connect from 'common/core/connect';
import TextInput from 'common/inputs/TextInput';
import LazyLoadedImage from 'common/LazyLoadedImage';
import ModernModal from 'common/modals/ModernModal';
import AdminAutopilotInfoBox from 'common/subdomain/admin/AdminQueue/GettingStarted/AdminAutopilotInfoBox';
import { InfoPoints } from 'common/subdomain/admin/AdminQueue/GettingStarted/constants';
import ButtonV2 from 'common/ui/ButtonV2';
import Label from 'common/ui/common/Label';
import { H2, P } from 'common/ui/Text';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import validateInput from 'common/validateInput';
import styles from 'css-module/components/subdomain/admin/AdminQueue/GettingStarted/_AdminAutopilotIntegrationModal.module.scss';

import CannyLogo from 'img/canny-icon.svg';

import type { Props as ModalProps } from './AdminAutopilotIntegrationModal';
import type { Company } from 'common/api/endpoints/companies';

type OwnProps = Omit<ModalProps, 'getOAuthURL' | 'connectDisabled' | 'inputSection'>;

type ConnectProps = {
  reloadTLDVSettings: () => Promise<void>;
};

const TLDVIntegrationModal = ({
  onClose,
  integration,
  enableIntegrationSync,
  reloadTLDVSettings,
}: OwnProps & ConnectProps) => {
  // context
  const company = useContext<Company>(CompanyContext);
  const showToast = useContext(ShowToastContext);

  // state
  const [apiKey, setAPIKey] = useState('');
  const [loading, setLoading] = useState<boolean>(false);

  // helpers
  const onEnableClick = async () => {
    const success = isInstalled || (await install());
    if (success) {
      enableIntegrationSync(integration);
      onClose();
    }
  };

  const isInstalled = company.activeIntegrations?.tldv;
  const isValid = validateInput.string(apiKey) && apiKey.length;

  const install = async () => {
    setLoading(true);

    const response = await AJAX.post('/api/tldv/install', { apiKey });
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: {
        'could not connect to tldv':
          'Failed to connect to tl;dv. Make sure your API Key is correct.',
      },
    });

    if (error) {
      setLoading(false);
      showToast(error.message, ToastTypes.error);
      return false;
    }

    await reloadTLDVSettings();
    setLoading(false);
    return true;
  };

  const footer = (
    <>
      <ButtonV2 variant="outlined" size="medium" onClick={onClose}>
        Cancel
      </ButtonV2>
      <ButtonV2
        size="medium"
        loading={loading}
        onClick={onEnableClick}
        disabled={!isValid && !isInstalled}
        className={styles.connectButton}>
        Connect
      </ButtonV2>
    </>
  );

  const header = (
    <div className={styles.modalHeader}>
      <div className={styles.cannyLogoContainer}>
        <LazyLoadedImage
          src={integration.icon}
          className={styles.integrationLogo}
          alt="tl;dv logo"
        />
      </div>
      <div className={styles.lineContainer}>
        <span className={styles.line} />
        <span className={styles.line} />
      </div>
      <div className={styles.cannyLogoContainer}>
        <LazyLoadedImage src={CannyLogo} className={styles.cannyLogo} alt="Canny logo" />
      </div>
    </div>
  );

  return (
    <ModernModal
      modalClassName={styles.modal}
      header={header}
      onClose={onClose}
      footer={footer}
      sections={[
        <>
          <H2 variant="headingXl" className={styles.heading}>
            Connect tl;dv to Autopilot
          </H2>
          <P className={styles.subheading}>
            Enable Autopilot to capture user feedback found in tl;dv transcripts.
          </P>
          {!isInstalled && (
            <div className={styles.tldvInputs}>
              <Label
                className={styles.tldvLabel}
                label={<P fontWeight="medium">API Key</P>}
                labelPlacement="top">
                <TextInput
                  onChange={(e) => setAPIKey(e.currentTarget.value)}
                  placeholder="e.g. rZmPxkVTqWoLjdCNis"
                  value={apiKey}
                />
              </Label>
            </div>
          )}
          <AdminAutopilotInfoBox points={InfoPoints[SyncIntegrationNames.tldv] ?? []} />
        </>,
      ]}
    />
  );
};

export default compose(
  connect(null, (dispatch: Dispatch<any>) => ({
    reloadTLDVSettings: () => dispatch(reloadTLDVSettings()),
  }))
)(TLDVIntegrationModal) as unknown as React.FC<OwnProps>;
