import React, { type SyntheticEvent, useContext, useEffect, useMemo } from 'react';

import classnames from 'classnames';

import { type CustomPostField, CustomPostFieldTypes } from 'common/api/resources/postFields';
import {
  AlphanumericConditions,
  DropdownConditions,
  InputTypes,
  MultiselectConditions,
  NumericConditions,
  getConditionInputType,
} from 'common/automations/postFieldFilterUtils';
import { CustomPostFieldsContext } from 'common/containers/CustomPostFieldsContainer';
import TextInput from 'common/inputs/TextInput';
import SingleSelect from 'common/ui/SingleSelect';

import { findActiveOption } from '../util';

import type { PostCustomFieldFilter } from 'common/automations/constants';
import type { Option } from 'common/ui/common/select/SelectCommon';

interface Props {
  condition: PostCustomFieldFilter['condition'];
  value: PostCustomFieldFilter['value'];
  onChange: (
    condition?: PostCustomFieldFilter['condition'],
    value?: PostCustomFieldFilter['value']
  ) => void;
  required: boolean;
}

const AlphanumericConditionOptions = [
  { label: 'Is empty', value: AlphanumericConditions.isEmpty },
  { label: 'Is not empty', value: AlphanumericConditions.isNotEmpty },
  { label: 'Contains', value: AlphanumericConditions.contains },
  { label: 'Does not contain', value: AlphanumericConditions.notContains },
];

const NumericConditionOptions = [
  { label: 'Equals', value: NumericConditions.equals },
  { label: 'Does Not Equal', value: NumericConditions.doesNotEqual },
  { label: 'Greater Than', value: NumericConditions.greaterThan },
  { label: 'Less Than', value: NumericConditions.lessThan },
];

const getConditionOptions = (activeField: CustomPostField | undefined) => {
  if (!activeField) {
    return [];
  }

  let conditionOptions: Option[] = [];
  switch (activeField.type) {
    case CustomPostFieldTypes.text:
    case CustomPostFieldTypes.multilineText:
      conditionOptions = AlphanumericConditionOptions;
      break;

    case CustomPostFieldTypes.integer:
      conditionOptions = NumericConditionOptions;
      break;

    case CustomPostFieldTypes.dropdown:
      conditionOptions = [{ label: 'is', value: DropdownConditions.is }];
      break;

    case CustomPostFieldTypes.multiselect:
      conditionOptions = [{ label: 'is one of', value: MultiselectConditions.isOneOf }];
      break;
  }
  return conditionOptions;
};

type ConditionInputProps = {
  inputType: InputTypes | null;
  onChange: (value: PostCustomFieldFilter['value']['value']) => void;
  required: boolean;
  value: PostCustomFieldFilter['value']['value'];
  valueOptions: Option[] | null;
};

const ConditionInput = ({
  inputType,
  onChange,
  required,
  value,
  valueOptions,
}: ConditionInputProps) => {
  // Set the default value to first option, if unset
  useEffect(() => {
    if (inputType !== null && valueOptions && valueOptions.length > 0 && !value) {
      onChange(valueOptions[0].value);
    }
  }, [inputType, valueOptions, value, onChange]);

  if (!inputType) {
    return null;
  }

  switch (inputType) {
    case InputTypes.text:
      return (
        <TextInput
          className={classnames(
            'textInputOverrides',
            required ? 'filterField3of3-required' : 'filterField3of3'
          )}
          onChange={(e: SyntheticEvent<HTMLInputElement>) => onChange(e.currentTarget.value)}
          value={value}
        />
      );
    case InputTypes.number:
      return (
        <TextInput
          className={classnames(
            'textInputOverrides',
            required ? 'filterField3of3-required' : 'filterField3of3'
          )}
          onChange={(e: SyntheticEvent<HTMLInputElement>) => onChange(e.currentTarget.value)}
          type="number"
          value={value}
        />
      );
    case InputTypes.dropdown:
      if (!valueOptions) {
        throw new Error('Implementation error: activeFieldOptions is required for dropdown input');
      }
      return (
        <SingleSelect
          className={classnames(required ? 'filterField3of3-required' : 'filterField3of3')}
          placeholder="Field value"
          onChange={(option) => onChange(option?.value as string)}
          options={valueOptions}
          value={findActiveOption(valueOptions, value)}
          optionsMaxWidth="300px"
        />
      );
    default:
      return null;
  }
};

const PostCustomField = ({ required, condition, value, onChange }: Props) => {
  const customPostFields = useContext(CustomPostFieldsContext);

  const activeField = customPostFields?.data?.find((field) => field._id === value.fieldID);

  // Memo to prevent useEffect from continuously triggering
  const conditionOptions = useMemo(
    () => (activeField ? getConditionOptions(activeField) : []),
    [activeField]
  );
  const activeConditionOption = findActiveOption(conditionOptions, condition);

  // On init, select the first post field
  useEffect(() => {
    if (!value.fieldID && !customPostFields.loading && customPostFields?.data?.[0]) {
      onChange(condition, { fieldID: customPostFields.data[0]._id, value: '' });
    }
  }, [value, customPostFields, onChange, condition]);

  // If no condition selected, select the first valid one
  useEffect(() => {
    if (value.fieldID) {
      if (!activeConditionOption && conditionOptions.length > 0) {
        onChange(conditionOptions[0].value as PostCustomFieldFilter['condition'], value);
      }
    }
  }, [value, activeConditionOption, conditionOptions, onChange, condition]);

  if (customPostFields.loading || !customPostFields.data) {
    // Hide if loading fields
    return null;
  }

  if (customPostFields.data.length === 0) {
    return <div className="textCircle filterField1of1">No custom fields exist</div>;
  }

  // Generate the field options array
  let activeFieldOption: Option | undefined;
  const fieldOptions: Option[] = [];
  customPostFields.data.forEach((field) => {
    const fieldOption = {
      label: field.name,
      value: field._id,
    };
    if (field._id === value.fieldID) {
      activeFieldOption = fieldOption;
    }
    fieldOptions.push(fieldOption);
  });

  // Generate the value options array, if necessary
  let renderThirdField = false;
  let valueOptions = null;
  if (activeField && condition) {
    const inputType = getConditionInputType(condition);
    if (inputType) {
      renderThirdField = true;
    }
    if (inputType === InputTypes.dropdown && activeField.options) {
      valueOptions = activeField.options?.map((option) => ({
        label: option,
        value: option,
      }));
    }
  }

  return (
    <>
      <SingleSelect
        className={renderThirdField ? 'filterField1of3' : 'filterField1of2'}
        placeholder="Post field"
        onChange={(option) =>
          option &&
          onChange(condition, { ...value, fieldID: option.value } as PostCustomFieldFilter['value'])
        }
        options={fieldOptions}
        value={activeFieldOption}
        optionsMaxWidth="300px"
      />
      {conditionOptions.length !== 1 ? (
        <SingleSelect
          className={renderThirdField ? 'filterField2of3' : 'filterField2of2'}
          onChange={(option) =>
            option &&
            onChange(option.value as PostCustomFieldFilter['condition'], { ...value, value: '' })
          }
          options={conditionOptions}
          value={activeConditionOption}
          optionsMaxWidth="300px"
        />
      ) : (
        <div className="textCircle filterField2of3">{conditionOptions[0].label}</div>
      )}
      {renderThirdField ? (
        <ConditionInput
          // TS isn't inferring the type of activeField properly
          inputType={getConditionInputType(condition)}
          onChange={(newValue) => onChange(condition, { ...value, value: newValue })}
          required={required}
          value={value.value}
          valueOptions={valueOptions}
        />
      ) : null}
    </>
  );
};
export default PostCustomField;
