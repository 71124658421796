import React, { Component } from 'react';

import { respondNotFound } from 'common/actions/serverResponse';
import ContentContainer from 'common/containers/ContentContainer';
import asyncConnect from 'common/core/asyncConnect';
import FourOhFourHelmet from 'common/helmets/FourOhFourHelmet';
import Link from 'common/Link';

import 'css/components/_FourOhFour.scss';

class FourOhFour extends Component {
  render() {
    return (
      <ContentContainer innerClassName="fourOhFour">
        <FourOhFourHelmet />
        <span>Oops! We couldn't find the page you were looking&nbsp;for.</span>
        <span>
          <a
            className="underline"
            href={this.props.boardUrl || 'https://feedback.canny.io/feature-requests'}>
            Create a post
          </a>
          <span> about this issue or go back to the </span>
          <Link className="underline" to="/">
            home&nbsp;page
          </Link>
          <span>.</span>
        </span>
      </ContentContainer>
    );
  }
}

export default asyncConnect([
  {
    promise: ({ store: { dispatch } }) => {
      return dispatch(respondNotFound());
    },
  },
])(FourOhFour);
