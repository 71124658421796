import React, { useContext, useState } from 'react';

import { invalidatePostQueries } from 'common/actions/postQueries';
import { invalidatePosts, reloadPost } from 'common/actions/posts';
import { invalidatePostActivities } from 'common/actions/postsActivity';
import { reloadRoadmapPostsForRoadmapsWithPost } from 'common/actions/roadmapPosts';
import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import connect from 'common/core/connect';
import { Span } from 'common/ui/Text';
import UserAvatar from 'common/user/UserAvatar';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';

import GenericActionModal, { GenericActionError } from './GenericActionModal';
import { findStringMatchesByName } from './utils';

import type { CommonModalProps } from './index';
import type { Company } from 'common/api/endpoints/companies';
import type { Post } from 'common/api/resources/posts';
import type { Dispatch } from 'redux-connect';

import 'css/components/subdomain/admin/AdminRoadmap/_ChangePostsOwnerModal.scss';

type OwnProps = CommonModalProps;

type ConnectProps = {
  invalidatePostData: (posts: Post[]) => Promise<void>;
  reloadPost: (post: Post) => Promise<void>;
  reloadRoadmapPostsForRoadmapsWithPosts: (posts: Post[]) => Promise<void>;
};

type Props = OwnProps & ConnectProps;

type GenericOwner = {
  _id: string;
  name: string;
};

const UnassignedOwnerID = '_unassigned';

const OwnerSuggestion = ({
  suggestion,
  onClick,
}: {
  suggestion: GenericOwner;
  onClick: () => void;
}) => {
  const isClearOwnerOption = suggestion._id === UnassignedOwnerID;

  return (
    <button className="ownerSuggestion" onClick={onClick}>
      {!isClearOwnerOption && <UserAvatar user={suggestion} />}
      <Span fontWeight={isClearOwnerOption ? 'medium' : 'regular'}>{suggestion.name}</Span>
    </button>
  );
};

const ChangePostsCategoryModal = ({
  invalidatePostData,
  onClose,
  reloadPost,
  reloadRoadmapPostsForRoadmapsWithPosts,
  selectedPost,
  selectedPosts,
}: Props) => {
  const company = useContext<Company>(CompanyContext);
  const [searchValue, setSearchValue] = useState<string>('');

  const handleSubmit = async (selectedOwner: GenericOwner) => {
    const ownerID = selectedOwner._id === UnassignedOwnerID ? null : selectedOwner._id;

    const responseJSON = await AJAX.post('/api/posts/bulkEdit', {
      ownerID,
      operationType: 'updateOwner',
      postIDs: selectedPosts.map((post) => post._id),
    });
    const { error } = parseAPIResponse(responseJSON, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (error) {
      throw new GenericActionError({ message: error.message, source: 'ChangePostsOwner' });
    } else {
      const postsToInvalidate = selectedPosts.filter((post) => post._id !== selectedPost?._id); // only invalidate posts that aren't the selected post
      await Promise.all([
        invalidatePostData(postsToInvalidate),
        selectedPost && reloadPost(selectedPost),
        reloadRoadmapPostsForRoadmapsWithPosts(selectedPosts),
      ]);
    }
  };

  const allMembers = [
    ...company.members,
    {
      _id: UnassignedOwnerID,
      name: 'Clear Owners',
    },
  ];

  return (
    <GenericActionModal<GenericOwner>
      cta="Update"
      onClose={onClose}
      onSearchValueChanged={(searchValue) => setSearchValue(searchValue)}
      onSubmit={handleSubmit}
      searchValue={searchValue}
      showSearch={allMembers.length > 10}
      successMessage="Successfully updated owner"
      suggestions={findStringMatchesByName(allMembers, searchValue)}
      SuggestionComponent={OwnerSuggestion}
      suggestionsLoading={!company}
      title="Update Owner"
    />
  );
};

// TODO: remove cast once `connect` is typed
export default connect(null, (dispatch: Dispatch) => ({
  invalidatePostData: (posts: Post[]) => {
    return Promise.all([
      dispatch(invalidatePostActivities(posts)),
      dispatch(invalidatePostQueries()),
      dispatch(invalidatePosts(posts)),
    ]);
  },
  reloadPost: (post: Post) => {
    return dispatch(reloadPost(post));
  },
  reloadRoadmapPostsForRoadmapsWithPosts: (posts: Post[]) => {
    return Promise.all(posts.map((post) => dispatch(reloadRoadmapPostsForRoadmapsWithPost(post))));
  },
}))(ChangePostsCategoryModal) as unknown as React.FC<OwnProps>;
