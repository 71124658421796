import React, { Component } from 'react';

import PropTypes from 'prop-types';

import cloneElementWithProps from '../core/cloneElementWithProps';

/**
 * When to use LocationContext vs RouterContext.location:
 *
 * - Use RouterContext.location if you need the current value of the address bar.
 *   Eg, using the address bar as state and need the value to update immediately.
 *
 * - Use LocationContext if you need the location of the currently rendered page. This location may
 *   not match the address bar when page content has changed.
 *   Eg, using the current path to generate a link.
 *
 * Why are they different?
 * - When the url changes, react-router will attempt to render the new DOM tree.
 * - If the new DOM tree contains an `asyncConnect` function redux-connect prevents the route from
 *   changing by preserving the previous value of `location` until the promise resolves.
 * - When the `asyncConnect` resolves, redux-connect will pass the route new `location`,
 *   allowing the new page to render.
 */
export const LocationContext = React.createContext();
export const ParamsContext = React.createContext();
export const RouterContext = React.createContext();

export default class RouterContainer extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
  };

  render() {
    return (
      <LocationContext.Provider value={this.props.location}>
        <ParamsContext.Provider value={this.props.params}>
          <RouterContext.Provider value={this.props.router}>
            {cloneElementWithProps(this.props.children, {
              ...this.props,
            })}
          </RouterContext.Provider>
        </ParamsContext.Provider>
      </LocationContext.Provider>
    );
  }
}
