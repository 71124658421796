import { respondError, respondNotFound } from 'common/actions/serverResponse';

import { invalidatePostQueries } from './postQueries';
import Data from '../Data';

// Action Types

export const InvalidatePosts = 'canny/posts/invalidate_posts';
export const RequestPost = 'canny/posts/request_post';
export const PostLoaded = 'canny/posts/post_loaded';
export const PostNotAuthorized = 'canny/posts/post_not_authorized';
export const PostNotFound = 'canny/posts/post_not_found';
export const PostError = 'canny/posts/post_error';
export const PostsLoaded = 'canny/posts/posts_loaded';

// Actions

function invalidate(posts) {
  return {
    posts,
    timestamp: Date.now(),
    type: InvalidatePosts,
  };
}

function requestPost(board, postURLName) {
  return {
    board,
    postURLName,
    timestamp: Date.now(),
    type: RequestPost,
  };
}

export function postLoaded(board, postURLName, post) {
  return {
    board,
    post: post,
    postURLName,
    timestamp: Date.now(),
    type: PostLoaded,
  };
}

export function postsLoaded(posts) {
  return {
    posts,
    timestamp: Date.now(),
    type: PostsLoaded,
  };
}

function postNotFound(board, postURLName) {
  return {
    board,
    postURLName,
    timestamp: Date.now(),
    type: PostNotFound,
  };
}

function postNotAuthorized(board, postURLName) {
  return {
    board,
    postURLName,
    timestamp: Date.now(),
    type: PostNotAuthorized,
  };
}

function postError(board, postURLName, error) {
  return {
    board,
    error,
    postURLName,
    timestamp: Date.now(),
    type: PostError,
  };
}

// Action Creators

function fetchPost(board, postURLName) {
  return (dispatch, getState) => {
    const cookies = getState().cookies;
    return Data.fetch(
      {
        post: {
          input: {
            boardID: board?._id || board?.requestedBoardID,
            postURLName,
          },
          query: Data.queries.post,
        },
      },
      cookies,
      (error, data) => {
        return gotResult(dispatch, getState(), board, postURLName, error, data);
      }
    );
  };
}

export function invalidatePosts(posts) {
  return (dispatch) => {
    return dispatch(invalidate(posts));
  };
}

export function loadPost(board, postURLName) {
  return (dispatch, getState) => {
    if (shouldFetchPost(getState(), board, postURLName)) {
      dispatch(requestPost(board, postURLName));
      return dispatch(fetchPost(board, postURLName));
    } else if (isFetchingPost(getState(), board, postURLName)) {
      return waitForResult(board, postURLName);
    }
  };
}

export function reloadPost(post) {
  return (dispatch) => {
    return dispatch(fetchPost(post.board, post.urlName));
  };
}

export function reloadPostByURLName(board, postURLName) {
  return (dispatch) => {
    return dispatch(fetchPost(board, postURLName));
  };
}

// Helper Functions

function shouldFetchPost(state, board, postURLName) {
  if (!board || (!board._id && !board.requestedBoardID) || !postURLName) {
    return false;
  }

  const posts = state.posts;
  const post = posts[board._id] ? posts[board._id][postURLName] : null;
  if (!post) {
    return true;
  }

  if (post.notFound || post.loading || post.error) {
    return false;
  }

  return !post.hasOwnProperty('author');
}

function isFetchingPost(state, board, postURLName) {
  if (!board || !board._id || !postURLName) {
    return false;
  }

  return !!state.posts[board._id][postURLName].loading;
}

// Callback Queue

const resultCallbacks = {};

function waitForResult(board, postURLName) {
  return new Promise((resolve, reject) => {
    resultCallbacks[board._id] = resultCallbacks[board._id] || {};
    resultCallbacks[board._id][postURLName] = resultCallbacks[board._id][postURLName] || [];
    resultCallbacks[board._id][postURLName].push(() => {
      resolve();
    });
  });
}

function gotResult(dispatch, state, board, postURLName, error, data) {
  const promises = [];

  var resultAction;
  if (error === 'not found') {
    promises.push(dispatch(respondNotFound()));
    resultAction = postNotFound(board, postURLName);
  } else if (error === 'not authorized') {
    resultAction = postNotAuthorized(board, postURLName);
  } else if (error) {
    promises.push(dispatch(respondError()));
    resultAction = postError(board, postURLName, error);
  } else {
    resultAction = postLoaded(board, postURLName, data.post);
  }

  promises.push(dispatch(resultAction));

  if (state.postQueries.needsInvalidate) {
    promises.push(dispatch(invalidatePostQueries()));
  }

  return Promise.all(promises).then(() => {
    if (!resultCallbacks[board._id] || !resultCallbacks[board._id][postURLName]) {
      return;
    }

    resultCallbacks[board._id][postURLName].forEach((resultCallback) => {
      resultCallback();
    });
    resultCallbacks[board._id][postURLName].length = 0;
  });
}
