import queryActionFactory from 'common/actions/queryActionFactory';
import DataV2 from 'common/DataV2';

import type { Insight } from 'common/api/endpoints/insight';

export type QueryParams = {
  ideaID: string;
};

export const {
  // query helpers
  getQueryKey,

  // action creators
  invalidateQueries: invalidateInsightQueries,
  loadQuery,
  loadMore,

  // action types
  RequestType,
  RequestMoreType,
  LoadedType,
  LoadedMoreType,
  ErrorType,
  InvalidateType,
} = queryActionFactory<Insight, QueryParams>('insightQueries', DataV2.queries.insights);
