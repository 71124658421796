import React, { useContext, useState } from 'react';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import connect from 'common/core/connect';
import UpsellModal from 'common/modals/UpsellModal';
import AdminIntegrationUninstallConfirmationModal from 'common/subdomain/admin/AdminIntegrationSettings/AdminIntegrationCard/AdminIntegrationUninstallConfirmationModal';
import AdminIntegrationSettingsSections, {
  type IntegrationSection,
} from 'common/subdomain/admin/AdminIntegrationSettings/AdminIntegrationSettingsSections';
import { GettingStartedContext } from 'common/subdomain/admin/AdminQueue/GettingStarted/AdminAutopilotGettingStarted';
import AdminAutopilotGettingStartedModalSwitch from 'common/subdomain/admin/AdminQueue/GettingStarted/modals/AdminAutopilotGettingStartedModalSwitch';
import { H2, P } from 'common/ui/Text';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import styles from 'css-module/components/subdomain/admin/settings/AdminIntegrationSettings/_AdminIntegrationSettings.module.scss';

import type { Integration } from 'common/subdomain/admin/AdminQueue/GettingStarted/constants';
import type { Dispatch } from 'redux';

const RequestErrors = {
  'failed to uninstall source': 'We could not uninstall the integration, please try again later.',
  'source not installed': 'Integration is not installed.',
  default: 'Something went wrong, please try again later.',
};

type ConnectProps = {
  reloadCompany: () => void;
};

type OwnProps = {
  settingsTitle: string;
  settingsDescription: React.ReactNode;
  sections: IntegrationSection[];
};

type Props = OwnProps & ConnectProps;

export type Upsell = { feature: string; cta: string; planID: string };

enum CardModal {
  install = 'install',
  uninstall = 'uninstall',
  upsell = 'upsell',
}

const AdminIntegrationSettings = ({
  settingsTitle,
  settingsDescription,
  sections,
  reloadCompany,
}: Props) => {
  // state
  const [modal, setModal] = useState<CardModal | null>(null);
  const [currentIntegration, setCurrentIntegration] = useState<null | Integration>(null);
  const [erroredIntegrationName, setErroredIntegrationName] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [upsell, setUpsell] = useState<Upsell | null>(null);

  // context
  const showToast = useContext(ShowToastContext);

  // helpers
  const startModalFlow = (integration: Integration) => {
    setCurrentIntegration(integration);
    setModal(CardModal.install);
    setLoading(false);
  };

  const endModalFlow = () => {
    setCurrentIntegration(null);
    setModal(null);
    setLoading(false);
  };

  const openUpsell = (upsell: Upsell) => {
    setUpsell(upsell);
    setModal(CardModal.upsell);
  };

  const toggleIntegrationSyncOff = async () => {
    if (!currentIntegration) {
      return null;
    }

    const response = await AJAX.post(`/api/queue/toggleIntegrationSync`, {
      integrationName: currentIntegration.name,
      enabled: false,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: RequestErrors,
    });
    return error;
  };

  const uninstallSource = async () => {
    if (!currentIntegration) {
      return null;
    }

    const response = await AJAX.post(`/api/reviewSources/uninstall`, {
      platform: currentIntegration.platform,
    });
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: RequestErrors,
    });

    if (error) {
      return error;
    }

    return null;
  };

  const uninstallProcess = [
    toggleIntegrationSyncOff,
    uninstallSource,
    async () => {
      await reloadCompany();
      return null;
    },
  ];

  const uninstall = async () => {
    setLoading(true);
    setModal(null);
    for (const action of uninstallProcess) {
      const error = await action();
      if (error) {
        showToast(error.message, ToastTypes.error);
        endModalFlow();
        return;
      }
    }
    endModalFlow();
  };

  const handleSettingErroredIntegrationName = (name?: string) => {
    setErroredIntegrationName(name);

    // if name isn't undefined, that means we had an error
    if (name) {
      showToast('Something went wrong, please try again later.', ToastTypes.error);
    }
  };

  return (
    <>
      <H2 variant="headingMd" className={styles.settingsTitle}>
        {settingsTitle}
      </H2>
      <P className={styles.settingsDescription}>{settingsDescription}</P>
      <AdminIntegrationSettingsSections
        sections={sections}
        startModalFlow={startModalFlow}
        openUpsell={openUpsell}
        loading={loading}
        currentIntegration={currentIntegration}
      />
      <GettingStartedContext.Provider
        value={{
          loading,
          erroredIntegrationName,
          setErroredIntegrationName: handleSettingErroredIntegrationName,
          setLoading,
        }}>
        {modal === CardModal.install && (
          <AdminAutopilotGettingStartedModalSwitch
            integration={currentIntegration}
            onClose={endModalFlow}
            onUninstall={() => setModal(CardModal.uninstall)}
            // empty function as this should only be shown for Review Data Sync Sources
            enableIntegrationSync={() => null}
          />
        )}
      </GettingStartedContext.Provider>
      {modal === CardModal.uninstall && currentIntegration && (
        <AdminIntegrationUninstallConfirmationModal
          integrationLabel={currentIntegration.label}
          onConfirm={uninstall}
          onCancel={endModalFlow}
        />
      )}
      {modal === CardModal.upsell && upsell && (
        <UpsellModal
          {...upsell}
          onClose={() => {
            setUpsell(null);
            setModal(null);
          }}
          show
        />
      )}
    </>
  );
};

export default connect(null, (dispatch: Dispatch<any>) => ({
  reloadCompany: () => {
    return dispatch(reloadCompany());
  },
}))(AdminIntegrationSettings) as unknown as React.FC<OwnProps>;
