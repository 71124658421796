import React, { useContext, useState } from 'react';

import { ArchiveRestore, Trash } from 'lucide-react';

import { loadRoadmaps, reloadRoadmaps } from 'common/actions/roadmaps';
import AJAX from 'common/AJAX';
import Card from 'common/common/Card';
import Tooltip from 'common/common/Tooltip';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import asyncConnect from 'common/core/asyncConnect';
import ModernConfirmModal from 'common/modals/ModernConfirmModal';
import UpsellModal from 'common/modals/UpsellModal';
import IconButtonV2 from 'common/ui/IconButtonV2';
import { P } from 'common/ui/Text';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';

import type { Roadmap } from 'common/api/endpoints/roadmaps';

type Props = {
  roadmap: Roadmap;
  reloadRoadmaps: () => void;
};

const tooltipDelay = 300;

const RoadmapSettingsArchivedRow = ({ roadmap, reloadRoadmaps }: Props) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUpsellModal, setShowUpsellModal] = useState(false);

  const [isBeingRestored, setIsBeingRestored] = useState(false);
  const [isBeingDeleted, setIsBeingDeleted] = useState(false);
  const showToast = useContext(ShowToastContext);

  const onDeleteModalConfirmed = async () => {
    setIsBeingDeleted(true);
    setShowDeleteModal(false);
    const response = await AJAX.post('/api/roadmaps/delete', { roadmapID: roadmap._id });
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: {
        default: 'Failed to delete roadmap',
        'invalid roadmapID': 'There is a problem deleting this roadmap',
        'not authorized': "You don't have the permissions to delete roadmaps",
      },
    });

    if (error) {
      setIsBeingDeleted(false);
      showToast(error.message, ToastTypes.error);
      return;
    }
    await reloadRoadmaps();
    setIsBeingDeleted(false);
  };

  const onRestoreRoadmap = async () => {
    setIsBeingRestored(true);
    setShowDeleteModal(false);

    const response = await AJAX.post('/api/roadmaps/update', {
      archived: false,
      columns: roadmap.columns,
      name: roadmap.name,
      roadmapID: roadmap._id,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: {
        default: 'Failed to restore roadmap',
        'invalid roadmapID': 'There is a problem restoring this roadmap',
        'not authorized': "You don't have the permissions to restore roadmaps",
        'roadmap limit':
          'Your plan does not support an extra roadmap. Remove or archive one first, to be able to restore this one.',
      },
    });

    if (error?.type === 'roadmap limit') {
      setShowUpsellModal(true);
      setIsBeingRestored(false);
      return;
    }

    if (error) {
      setIsBeingRestored(false);
      showToast(error.message, ToastTypes.error);
      return;
    }
    await reloadRoadmaps();
    setIsBeingRestored(false);
  };

  const onCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const onDeleteRoadmap = () => {
    setShowDeleteModal(true);
  };
  const onUpsell = () => {
    setShowUpsellModal(false);
  };

  const onUpsellDismiss = () => {
    setShowUpsellModal(false);
  };

  return (
    <>
      <Card borderStyle="solid" className="roadmap" key={roadmap._id}>
        <P>
          {roadmap.name} ({roadmap.roadmapPostCount})
        </P>
        <div className="roadmapActions">
          <Tooltip
            disabled={isBeingRestored || isBeingDeleted}
            delay={tooltipDelay}
            value="Restore roadmap"
            position="left">
            <IconButtonV2
              icon={ArchiveRestore}
              onClick={() => !isBeingRestored && onRestoreRoadmap()}
              aria-label="restore roadmap"
              variant="plain"
              size="medium"
              loading={isBeingRestored}
              disabled={isBeingDeleted}
            />
          </Tooltip>
          <span className="separator" />
          <Tooltip
            disabled={isBeingRestored || isBeingDeleted}
            delay={tooltipDelay}
            value="Delete roadmap"
            position="left">
            <IconButtonV2
              icon={Trash}
              onClick={() => !isBeingDeleted && onDeleteRoadmap()}
              aria-label="delete roadmap"
              variant="plain"
              color="error"
              size="medium"
              loading={isBeingDeleted}
              disabled={isBeingRestored}
            />
          </Tooltip>
        </div>
      </Card>
      {showDeleteModal ? (
        <ModernConfirmModal
          onClose={onCloseDeleteModal}
          onConfirm={onDeleteModalConfirmed}
          confirmText="Delete"
          type="destructive"
          header="Delete Roadmap">
          <P>Are you sure you want to delete {roadmap.name}? This cannot be&nbsp;undone.</P>
        </ModernConfirmModal>
      ) : null}
      <UpsellModal
        cta="Unlimited roadmaps enable more advanced&nbsp;planning"
        feature="limits.prioritizationRoadmaps"
        onClose={onUpsellDismiss}
        onUpsell={onUpsell}
        show={showUpsellModal}
      />
    </>
  );
};

export default asyncConnect(
  [
    {
      promise: ({ store: { dispatch } }) => {
        return dispatch(loadRoadmaps());
      },
    },
  ],
  undefined,
  (dispatch) => ({
    reloadRoadmaps: () => dispatch(reloadRoadmaps()),
  })
)(RoadmapSettingsArchivedRow);
