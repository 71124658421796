import React from 'react';

import { type CompanyInfo, type Names, reloadTPCForFilters } from 'common/actions/tpcForFilters';
import connect from 'common/core/connect';
import SingleSelectWithSearch from 'common/ui/SingleSelectWithSearch';
import stringSort from 'common/util/stringSort';
import useDelayer from 'common/util/useDelayer';

import type { Option } from 'common/ui/common/select/SelectCommon';
import type { Dispatch } from 'redux';

type ConnectProps = {
  reloadAccountOwners?: (search?: string) => Promise<void>;
};

type OwnProps = {
  accountOwnerNames?: Names;
  accountOwnerSelected: (accountOwner: CompanyInfo | undefined) => void;
  loading: boolean;
  selection?: string;
};

type Props = OwnProps & ConnectProps;

const AccountOwnerFilterInput = ({
  accountOwnerNames,
  accountOwnerSelected,
  loading,
  reloadAccountOwners,
  selection,
}: Props) => {
  const selectFilter = (filter?: Option) => {
    accountOwnerSelected(filter && accountOwnerNames ? accountOwnerNames[filter.value] : undefined);
  };

  const searchDelayer = useDelayer(reloadAccountOwners, 300);
  const onSearchChange = (search?: string) => {
    if (search && search.length > 0) {
      searchDelayer(search);
    }
  };

  // Our current TPC search doesn't do name parts and only prefix matches
  // purposefully match that search functionality by only returning prefix matches here
  const options: Option[] = Object.values(accountOwnerNames ?? {})
    .sort(stringSort('name'))
    .map((option) => {
      return {
        label: option.name,
        value: option.urlName,
      };
    });

  const getSelectedOption = () => {
    const selectedAccountOwnerInfo =
      selection && accountOwnerNames ? accountOwnerNames[selection] : undefined;

    if (!selectedAccountOwnerInfo) {
      return undefined;
    }

    const { name, urlName } = selectedAccountOwnerInfo;
    return {
      label: name,
      value: urlName,
    };
  };

  return (
    <SingleSelectWithSearch
      options={options}
      onChange={selectFilter}
      allowClear
      loading={loading}
      placeholder="Search..."
      value={getSelectedOption()}
      onSearchChange={onSearchChange}
    />
  );
};

export default connect(null, (dispatch: Dispatch<any>) => ({
  reloadAccountOwners: (search: string) => dispatch(reloadTPCForFilters(search)),
}))(AccountOwnerFilterInput);
