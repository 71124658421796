import queryActionFactory from 'common/actions/queryActionFactory';
import DataV2 from 'common/DataV2';

import type { Post } from 'common/api/resources/posts';

export type QueryParams = {
  ideaID: string;
};

export const {
  // query helpers
  getQueryKey,

  // action creators
  invalidateQueries: invalidateIdeaPost,
  loadQuery: loadIdeaPost,

  // action types
  RequestType,
  RequestMoreType,
  LoadedType,
  LoadedMoreType,
  ErrorType,
  InvalidateType,
} = queryActionFactory<Post, QueryParams>('ideaPost', DataV2.queries.ideaPost);
