import requestActionFactory from 'common/actions/requestActionFactory';
import DataV2 from 'common/DataV2';

import type { IdeaField } from 'common/api/endpoints/idea';
import type { Dispatch, GetState, State } from 'redux-connect';

export { defaultState } from 'common/reducers/requestReducerFactory';

// Action Types

const {
  requestAction: fieldsRequested,
  loadedAction: fieldsLoaded,
  errorAction: fieldsError,

  RequestType,
  LoadedType,
  ErrorType,
} = requestActionFactory<IdeaField[]>('ideaFields');

export { RequestType, LoadedType, ErrorType };

// Action Creators

function fetchFields() {
  return async (dispatch: Dispatch, getState: GetState) => {
    const cookies = getState().cookies;
    try {
      const response = await DataV2.fetch(DataV2.queries.ideaFields, {}, cookies);
      return dispatch(fieldsLoaded(response.items));
    } catch (error) {
      if (typeof error === 'string') {
        return dispatch(fieldsError(error));
      }
      return dispatch(fieldsError('server error'));
    }
  };
}

export function loadFields() {
  return (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    if (shouldFetch(state)) {
      dispatch(fieldsRequested());
      return dispatch(fetchFields());
    }
  };
}

export function reloadFields() {
  return (dispatch: Dispatch) => {
    return dispatch(fetchFields());
  };
}

// Helpers

export function shouldFetch(state: State) {
  return !state.ideaFields.data && !state.ideaFields.error;
}
