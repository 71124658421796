export default function parseJSON(json, callback) {
  let object;
  try {
    object = JSON.parse(json);
  } catch (e) {
    callback(e);
  }

  callback(null, object);
}
