import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import { TintColorContext } from 'common/containers/TintColorContainer';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import KeyCodes from 'common/KeyCodes';
import withContexts from 'common/util/withContexts';

import 'css/components/_Form.scss';

const isFileEvent = (e, acceptedFileTypes) => {
  const { dataTransfer } = e;

  if (!dataTransfer) {
    return false;
  }

  if (!dataTransfer.items && !dataTransfer.files) {
    return false;
  }

  const items = Array.from(dataTransfer.items || dataTransfer.files);
  const hasCorrectFileType = items.some((item) => acceptedFileTypes.includes(item.type));

  return hasCorrectFileType;
};

class Form extends Component {
  static propTypes = {
    acceptedFileTypes: PropTypes.array,
    addEventsToDocument: PropTypes.bool.isRequired, // For the forms which do not have any inputs, this needs to be set to true in order for cmd + enter event to work. Don't use it more than once in the same page
    allowFileUpload: PropTypes.bool,
    disableSubmit: PropTypes.bool.isRequired,
    onFile: PropTypes.func,
    onSubmit: PropTypes.func,
    submitOnEnter: PropTypes.bool,
    tintColor: PropTypes.string,
  };

  static defaultProps = {
    addEventsToDocument: false,
    allowFileUpload: false,
    disableSubmit: true,
    maxFiles: Infinity,
    submitOnEnter: true,
  };

  state = {
    documentDragDepth: 0,
  };

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  componentDidMount() {
    const { addEventsToDocument, allowFileUpload } = this.props;
    const target = addEventsToDocument ? document : this.formRef.current;
    target.addEventListener('keydown', this.onKeyDown);

    if (!allowFileUpload) {
      return;
    }

    document.addEventListener('dragenter', this.onDocumentDragEnter);
    document.addEventListener('dragleave', this.onDocumentDragLeave);
    document.addEventListener('dragover', this.onDocumentDragOver);
    document.addEventListener('drop', this.onDocumentDrop);
  }

  componentWillUnmount() {
    const target = this.props.addEventsToDocument ? document : this.formRef.current;
    target.removeEventListener('keydown', this.onKeyDown);

    if (!this.props.allowFileUpload) {
      return;
    }

    document.removeEventListener('dragenter', this.onDocumentDragEnter);
    document.removeEventListener('dragleave', this.onDocumentDragLeave);
    document.removeEventListener('dragover', this.onDocumentDragOver);
    document.removeEventListener('drop', this.onDocumentDrop);
  }

  onDocumentDragEnter = (e) => {
    const { acceptedFileTypes } = this.props;
    if (!isFileEvent(e, acceptedFileTypes)) {
      return;
    }

    const { documentDragDepth } = this.state;
    this.setState({
      documentDragDepth: documentDragDepth + 1,
    });
  };

  onDocumentDragLeave = (e) => {
    const { acceptedFileTypes } = this.props;
    if (!isFileEvent(e, acceptedFileTypes)) {
      return;
    }

    const { documentDragDepth } = this.state;
    this.setState({
      documentDragDepth: documentDragDepth - 1,
    });
  };

  onDocumentDragOver = (e) => {
    const { acceptedFileTypes } = this.props;
    if (!isFileEvent(e, acceptedFileTypes)) {
      return;
    }

    e.preventDefault();
  };

  onDocumentDrop = (e) => {
    const { acceptedFileTypes } = this.props;
    if (!isFileEvent(e, acceptedFileTypes)) {
      return;
    }

    e.preventDefault();
    this.setState({
      documentDragDepth: 0,
    });
  };

  onDragOver = (e) => {
    e.preventDefault();
  };

  onDrop = (e) => {
    const { acceptedFileTypes } = this.props;
    if (!isFileEvent(e, acceptedFileTypes)) {
      return;
    }

    const { onFile } = this.props;
    if (!onFile) {
      return;
    }

    e.preventDefault();

    this.setState({
      documentDragDepth: 0,
    });

    const { dataTransfer } = e;
    const items = Array.from(dataTransfer.items || dataTransfer.files).filter((item) =>
      acceptedFileTypes.includes(item.type)
    );

    if (items.length > this.props.maxFiles) {
      this.props.showToast(
        `You can only upload up to ${this.props.maxFiles} files at a time.`,
        ToastTypes.error
      );
      return;
    }

    items.forEach((item) => {
      const file = item.getAsFile();
      const reader = new FileReader();
      reader.onload = (e) => onFile(file);
      reader.readAsDataURL(file);
    });
  };

  onKeyDown = (e) => {
    const { submitOnEnter } = this.props;
    if (
      !submitOnEnter &&
      e.keyCode === KeyCodes.Enter &&
      e.target.type?.toLowerCase() !== 'textarea'
    ) {
      e.preventDefault();
    }

    // mac: command / ctrl, windows: ctrl
    const isMetaPressed = e.metaKey || e.ctrlKey;
    const shouldSubmit = isMetaPressed && e.keyCode === KeyCodes.Enter;
    if (!shouldSubmit) {
      return;
    }

    this.onSubmit(e);
  };

  onPaste = (e) => {
    const { clipboardData } = e;
    if (!clipboardData || !clipboardData.items || !clipboardData.items.length) {
      return;
    }

    const { acceptedFileTypes, onFile } = this.props;
    if (!onFile) {
      return;
    }

    const items = Array.from(clipboardData.items).filter(
      (item) => item.kind === 'file' && acceptedFileTypes.includes(item.type)
    );

    if (items.length === 0) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    items.forEach((item) => {
      const file = item.getAsFile();
      const reader = new FileReader();
      reader.onload = (e) => onFile(file);
      reader.readAsDataURL(file);
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { disableSubmit, onSubmit } = this.props;
    if (disableSubmit) {
      return;
    }

    onSubmit(e);
  };

  renderDropZone() {
    const { documentDragDepth } = this.state;
    if (documentDragDepth === 0) {
      return null;
    }

    const { tintColor } = this.props;
    return (
      <div
        className="dropZone"
        style={{
          backgroundColor: tintColor,
        }}
      />
    );
  }

  render() {
    const { allowFileUpload, className } = this.props;
    const formProps = {
      className: classnames(className, { fileForm: allowFileUpload }),
      ...(allowFileUpload
        ? { onDragOver: this.onDragOver, onDrop: this.onDrop, onPaste: this.onPaste }
        : {}),
    };

    return (
      <form {...formProps} onSubmit={this.onSubmit} ref={this.formRef}>
        {allowFileUpload && this.renderDropZone()}
        {this.props.children}
      </form>
    );
  }
}

export default withContexts({ tintColor: TintColorContext, showToast: ShowToastContext })(Form);
