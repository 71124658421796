import React, { Component } from 'react';

import classnames from 'classnames';
import { Eye, Megaphone, Menu } from 'lucide-react';
import PropTypes from 'prop-types';

import AJAX from 'common/AJAX';
import Colors from 'common/colors/constants';
import Pill from 'common/common/Pill';
import Tooltip from 'common/common/Tooltip';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { LocationContext } from 'common/containers/RouterContainer';
import publicConfig from 'common/core/publicConfig';
import LazyLoadedImage from 'common/LazyLoadedImage';
import Link from 'common/Link';
import NotificationsMenu from 'common/notifications/NotificationsMenu';
import SpinnerV2 from 'common/SpinnerV2';
import Tappable from 'common/Tappable';
import CountBadge from 'common/ui/CountBadge';
import isFree from 'common/util/isFree';
import parseAPIResponse from 'common/util/parseAPIResponse';
import withContexts from 'common/util/withContexts';

import AdminOnboardingProgress from './AdminOnboardingProgress';
import AdminNavAccountMenu from './v2/AdminV2Nav/AdminNavAccountMenu';

import WhiteCannyIcon from 'img/canny-icon-white.svg';

import 'css/components/subdomain/admin/_AdminNav.scss';

class AdminNav extends Component {
  static propTypes = {
    company: PropTypes.shape({
      showChangelog: PropTypes.bool,
    }),
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
    notifications: PropTypes.object,
  };

  state = {
    changelogMenuOpen: false,
    mobileMenuOpen: false,
    count: 0,
    countLoading: true,
  };

  constructor(props, context) {
    super(props, context);

    this.changelogButtonRef = React.createRef();
    this.containerRef = React.createRef();
  }

  async fetchNavInboxCount() {
    const { company } = this.props;

    if (company.queueAutomation?.enabled) {
      return;
    }

    const response = await AJAX.post('/api/queue/count');
    const { parsedResponse, error } = parseAPIResponse(response, {
      isSuccessful: (parsedResponse) => !!parsedResponse.result,
    });

    if (error) {
      this.setState({ countLoading: false });
      return;
    }

    this.setState({ count: parsedResponse.result.count, countLoading: false });
  }

  componentDidMount() {
    document.addEventListener('click', this.onDocumentClick, false);
    Canny('initChangelog', {
      appID: publicConfig('cannyCompanyID'),
      position: 'bottom',
      align: 'right',
    });
    this.fetchNavInboxCount();
  }

  componentDidUpdate(prevProps) {
    const { mobileMenuOpen } = this.state;
    const { location, company } = this.props;
    const shouldCloseMobileMenu = location !== prevProps.location && mobileMenuOpen;

    if (shouldCloseMobileMenu) {
      this.setState({ mobileMenuOpen: false });
    }

    if (company.queueAutomation?.enabled !== prevProps.company.queueAutomation?.enabled) {
      this.fetchNavInboxCount();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onDocumentClick, false);
  }

  onDocumentClick = (e) => {
    this.toggleChangelogMenuOpen(e);

    const { mobileMenuOpen } = this.state;
    if (!mobileMenuOpen) {
      return;
    }

    const container = this.containerRef.current;
    if (!container?.contains(e.target)) {
      // touchend happens outside of menu, close menu
      this.setState({ mobileMenuOpen: false });
      return;
    }
  };

  toggleChangelogMenuOpen = (e) => {
    const { changelogMenuOpen } = this.state;
    const changelogButton = this.changelogButtonRef.current;
    if (!changelogMenuOpen && changelogButton?.contains(e.target)) {
      this.setState({ changelogMenuOpen: true });
      return;
    }
    this.setState({ changelogMenuOpen: false });
  };

  renderChangelogIcon() {
    return (
      <div className="changelogIcon" data-canny-changelog>
        <button ref={this.changelogButtonRef}>
          <Tooltip
            className="adminNavTooltipIcon"
            disabled={this.state.changelogMenuOpen}
            position="bottom"
            value="New in Canny">
            <div className="navIconContainer">
              <Megaphone color="white" size="20" />
            </div>
          </Tooltip>
        </button>
      </div>
    );
  }

  renderChangelogLink() {
    const { company } = this.props;
    if (!company.showChangelog) {
      return null;
    }

    const {
      location: { pathname },
    } = this.props;
    const isChangelogActive = pathname.startsWith('/admin/changelog');
    const changelogLinkClassName = classnames('navLink', {
      active: isChangelogActive,
    });

    return (
      <Link className={changelogLinkClassName} to="/admin/changelog">
        Changelog
      </Link>
    );
  }

  renderOnboarding() {
    const { billingData, stats } = this.props.company;
    if (!billingData) {
      return null;
    }

    const { onboardingDue, plan } = billingData;
    const onFree = isFree(plan?.planID);
    if (onboardingDue && onFree && !stats.onboardedAt) {
      return (
        <div className="onboardingProgressContainer">
          <AdminOnboardingProgress menuPosition="bottom" />
        </div>
      );
    }

    return null;
  }

  renderPreviewIcon() {
    const {
      board,
      entry,
      location: { pathname },
      post,
    } = this.props;

    const hideURLs = [
      '/admin/users',
      '/admin/settings',
      '/admin/changelog/create',
      '/admin/moderation',
    ];
    const shouldHide = hideURLs.some((url) => pathname.startsWith(url));
    if (shouldHide) {
      return null;
    }

    let redirectURL = '/';
    const isChangelog = pathname.startsWith('/admin/changelog');
    const isChangelogList = pathname === '/admin/changelog';

    if (isChangelogList) {
      redirectURL = `/changelog`;
    } else if (isChangelog && entry) {
      const entryUnpublished = entry.status === 'draft' || entry.status === 'scheduled';
      if (entryUnpublished) {
        return null;
      }

      if (entry.urlName) {
        redirectURL = `/changelog/${entry.urlName}`;
      }
    }

    const hasBoard = board && board.urlName;
    if (pathname.startsWith('/admin/feedback/') && hasBoard) {
      redirectURL =
        post && !post.error ? `/${board.urlName}/p/${post.urlName}` : `/${board.urlName}`;
    }

    return (
      <Link to={redirectURL} className="previewIcon">
        <Tooltip className="adminNavTooltipIcon" position="bottom" value="Public view">
          <div className="navIconContainer">
            <Eye color="white" strokeWidth="1.7" size="24" />
          </div>
        </Tooltip>
      </Link>
    );
  }

  renderMobileMenu() {
    const { mobileMenuOpen } = this.state;
    if (!mobileMenuOpen) {
      return null;
    }

    return (
      <div className="mobileMenu">
        <Link className="menuLink" to="/admin">
          Home
        </Link>
        <Link className="menuLink" to="/admin/feedback">
          Feedback
        </Link>
        <Link className="menuLink" to="/admin/roadmap">
          Roadmap
        </Link>
        <Link className="menuLink" to="/admin/users">
          Users
        </Link>
        <Link className="menuLink" to="/admin/changelog">
          Changelog
        </Link>
        <Link className="menuLink" to="/admin/autopilot">
          Autopilot
        </Link>
      </div>
    );
  }

  toggleMobileMenuOpen = () => {
    this.setState((state) => ({ mobileMenuOpen: !state.mobileMenuOpen }));
  };

  render() {
    const {
      location: { pathname },
      company,
    } = this.props;
    const { count, countLoading } = this.state;
    const isFeedbackActive =
      pathname.startsWith('/admin/feedback') || pathname.startsWith('/admin/moderation');
    const feedbackLinkClassName = classnames('navLink', {
      active: isFeedbackActive,
    });

    const isUsersActive = pathname.startsWith('/admin/users');
    const usersLinkClassName = classnames('navLink', {
      active: isUsersActive,
    });

    const isRoadmapActive = pathname.startsWith('/admin/roadmap');
    const roadmapLinkClassName = classnames('navLink', {
      active: isRoadmapActive,
    });

    return (
      <div className="adminNav">
        <div className="mainNav">
          <div className="leftContainer">
            <Link className="logo" to="/admin">
              <LazyLoadedImage alt="Canny Home" src={WhiteCannyIcon} />
            </Link>
            <div className="desktopLinks">
              <Link
                className={feedbackLinkClassName}
                disabled={isFeedbackActive}
                to="/admin/feedback">
                Feedback
              </Link>
              <Link className={roadmapLinkClassName} to="/admin/roadmap">
                Roadmap
              </Link>
              <Link className={usersLinkClassName} to="/admin/users">
                Users
              </Link>
              {this.renderChangelogLink()}
              <Link
                className={classnames('navLink withBadge', {
                  active: pathname.startsWith('/admin/autopilot'),
                })}
                to="/admin/autopilot">
                Autopilot{' '}
                {company.queueAutomation?.enabled && (
                  <Pill pillStyle={{ color: Colors.white, background: Colors.indigo110 }}>New</Pill>
                )}
                {!company.queueAutomation?.enabled && count ? (
                  <CountBadge size="small" count={count} max={99} variant="alert" />
                ) : null}
                {!company.queueAutomation?.enabled && countLoading && <SpinnerV2 size="small" />}
              </Link>
            </div>
            <div className="mobileMenuContainer" ref={this.containerRef}>
              <Tappable onTap={this.toggleMobileMenuOpen}>
                <Menu color="white" size="32" />
              </Tappable>
              {this.renderMobileMenu()}
            </div>
          </div>
          <div className="rightContainer">
            {this.renderOnboarding()}
            {this.renderPreviewIcon()}
            {this.renderChangelogIcon()}
            <NotificationsMenu
              iconStyle="light"
              linkToAdmin={true}
              notifications={this.props.notifications}
            />
            <AdminNavAccountMenu menuPosition="bottom" />
          </div>
        </div>
      </div>
    );
  }
}

export default withContexts(
  {
    company: CompanyContext,
    location: LocationContext,
  },
  {
    forwardRef: true,
  }
)(AdminNav);
