import React, { useContext, useRef, useState } from 'react';

import { Filter, GalleryVerticalEnd, PlusIcon, SettingsIcon, ShieldAlert } from 'lucide-react';

import { reloadCompany } from 'common/actions/company';
import Portal from 'common/common/Portal';
import { SourceMap } from 'common/constants/autopilotSources';
import { RouterContext } from 'common/containers/RouterContainer';
import connect from 'common/core/connect';
import useBackgroundClick from 'common/hooks/useBackgroundClick';
import IconButtonV2 from 'common/ui/IconButtonV2';
import { H1, Span } from 'common/ui/Text';
import styles from 'css-module/components/subdomain/admin/AdminQueue/_AdminQueueSidebar.module.scss';

import AdminQueueFiltersModal from './AdminQueueFiltersModal';
import AdminQueueSourceFilterItem from './AdminQueueSourceFilterItem';
import AutopilotUsageCard from './AutopilotUsageCard';
import useFilterControls from '../useFilterControls';

import CannyLogo from 'img/canny-icon.svg';

import type { Company } from 'common/api/endpoints/companies';
import type { SyncIntegrationNames } from 'common/constants/autopilotIntegrations';
import type { Dispatch } from 'redux-connect';

type OwnProps = {
  company: Company;
  counts: {
    totalCount: number;
    cannyCount: number;
    totalSourceCount: number;
    perSourceCounts: Partial<Record<SyncIntegrationNames, number>>;
    spam: number;
  };
};

type ConnectProps = {
  reloadCompany: () => Promise<void>;
};

type Props = OwnProps & ConnectProps;

enum Modal {
  filters = 'filters',
  sources = 'sources',
}

const AdminQueueSidebar = ({ company, counts, reloadCompany }: Props) => {
  const router = useContext(RouterContext);

  const [modalState, setModalState] = useState<Modal | null>(null);

  const filtersButtonRef = useRef<HTMLButtonElement>(null);
  const sidebarPortalRef = useRef<Portal>(null);

  const { queryParams, updateFilters } = useFilterControls({
    reloadCompany,
  });

  useBackgroundClick(() => {
    if (modalState) {
      setModalState(null);
    }
  }, [filtersButtonRef, sidebarPortalRef]);

  const resetSourceFilters = () => {
    updateFilters({ source: undefined });
  };

  const setSourceFilter = (source: string) => {
    // navigation breaks useBackgroundClick hook, so we need to manually close the modal
    setModalState(null);

    // reset sort and sortDirection when switching sources:
    // - prevents compatibility issues between spam and AI sources
    // - prevents sort for source being applied when viewing just one source
    updateFilters({ source, sort: undefined, sortDirection: undefined });
  };

  const isSelected = (source: string) => queryParams.source === source;

  return (
    <aside className={styles.adminQueueSidebar}>
      <header className={styles.adminQueueSidebarHeader}>
        <H1 variant="headingSm">Sources</H1>
        <div className={styles.settingsButtonLayout}>
          <IconButtonV2
            aria-label="Source settings"
            onClick={() => router.push('/admin/settings/autopilot')}
            icon={SettingsIcon}
            size="medium"
            variant="outlined"
          />
          <IconButtonV2
            ref={filtersButtonRef}
            aria-label="Autopilot filters"
            icon={Filter}
            onClick={() => setModalState(modalState === Modal.filters ? null : Modal.filters)}
            size="medium"
          />
        </div>
      </header>
      <ul className={styles.adminQueueSidebarSourceList}>
        <AdminQueueSourceFilterItem
          label="All sources"
          icon={GalleryVerticalEnd}
          count={counts.totalCount}
          onClick={resetSourceFilters}
          selected={!queryParams.source}
        />
        <AdminQueueSourceFilterItem
          label="Canny"
          imgSrc={CannyLogo}
          count={counts.cannyCount}
          onClick={() => setSourceFilter('canny')}
          paused={!company.deduplication?.enabled}
          selected={isSelected('canny')}
        />
        {company.installedSyncIntegrations.map(({ integrationName, disabled }) => (
          <AdminQueueSourceFilterItem
            key={integrationName}
            {...SourceMap[integrationName]}
            count={counts.perSourceCounts[integrationName] ?? 0}
            onClick={() => setSourceFilter(integrationName)}
            paused={disabled}
            selected={isSelected(integrationName)}
          />
        ))}
        <AdminQueueSourceFilterItem
          label="Spam"
          icon={ShieldAlert}
          count={counts.spam}
          onClick={() => setSourceFilter('spam')}
          selected={isSelected('spam')}
        />
      </ul>
      <button
        className={styles.addSourceButton}
        onClick={() => router.push('/admin/settings/autopilot/feedback-discovery')}>
        <PlusIcon className={styles.sourceIcon} />
        <Span className={styles.label}>Add source</Span>
      </button>
      <div className={styles.autopilotUsageContainer}>
        <AutopilotUsageCard company={company} />
      </div>
      {modalState && (
        <Portal
          className="adminQueueSidebarModalPortal"
          align="start"
          position="bottom"
          ref={sidebarPortalRef}
          relativeToRef={filtersButtonRef}>
          {modalState === Modal.filters ? (
            <AdminQueueFiltersModal reloadCompany={reloadCompany} />
          ) : null}
        </Portal>
      )}
    </aside>
  );
};

export default connect(null, (dispatch: Dispatch) => ({
  reloadCompany: () => dispatch(reloadCompany()),
}))(AdminQueueSidebar) as unknown as React.FC<OwnProps>;
