import React from 'react';

import classnames from 'classnames';
import { Loader2 } from 'lucide-react';

import type Colors from 'common/colors/constants';
import type { ObjectValues } from 'common/types/util';

import 'css/components/_SpinnerV2.scss';

type Size = 'small' | 'medium' | 'large' | 'xlarge' | 'xxxxlarge';

type Props = {
  className?: string;
  color?: ObjectValues<typeof Colors>;
  size: Size;
};
const SpinnerV2 = ({ className, color, size = 'medium' }: Props) => {
  return (
    <Loader2
      className={classnames(className, 'spinnerV2', { [size]: true })}
      style={{ ...(color && { color }) }}
    />
  );
};

export default SpinnerV2;
