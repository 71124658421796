import React, { useContext, useState } from 'react';

import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import ModernModal from 'common/modals/ModernModal';
import IntercomTeamInboxSourceFilter from 'common/subdomain/admin/AdminQueue/SourceFilters/IntercomTeamInboxSourceFilter';
import ButtonV2 from 'common/ui/ButtonV2';
import { P } from 'common/ui/Text';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import styles from 'css-module/components/subdomain/admin/AdminQueue/GettingStarted/_AdminAutopilotSourceFiltersModal.module.scss';

import type { Integration } from 'common/subdomain/admin/AdminQueue/GettingStarted/constants';

export type Props = {
  onClose: () => void;
  integration: Integration;
  enableIntegrationSync: (integration: Integration) => void;
};

const AdminAutopilotSourceFiltersModal = ({
  onClose,
  integration,
  enableIntegrationSync,
}: Props) => {
  const company = useContext(CompanyContext);
  const { intercom } = company;
  const [intercomInboxIDs, setIntercomInboxIDs] = useState<string[] | null>(
    intercom?.autopilotTeamInboxIDs ?? null
  );
  const [saveError, setSaveError] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);

  // helpers
  const onEnableClick = async () => {
    setSaving(true);
    setSaveError(false);

    const response = await AJAX.post('/api/intercom/setTeamInboxes', {
      teamIDs: intercomInboxIDs,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (error) {
      setSaveError(true);
    }

    setSaving(false);
    enableIntegrationSync(integration);
    onClose();
  };

  // render
  const header = <div className={styles.modalHeaderText}>Enable {integration.label}</div>;

  const footer = (
    <div className={styles.footer}>
      <div className={styles.connected}>
        <div className={styles.connectedDot} />
        <P className={styles.connectedText} variant="bodySm">
          {integration.label} connected
        </P>
      </div>
      <div className={styles.footerButtons}>
        <ButtonV2 variant="outlined" size="medium" onClick={onClose}>
          Cancel
        </ButtonV2>
        <ButtonV2 variant="contained" loading={saving} size="medium" onClick={onEnableClick}>
          Submit
        </ButtonV2>
      </div>
    </div>
  );

  return (
    <ModernModal
      modalClassName={styles.modal}
      sectionsClassName={styles.modalSections}
      header={header}
      onClose={onClose}
      sections={[
        <>
          <P className={styles.filterDescription} variant="bodyMd">
            Autopilot will process conversations based on this&nbsp;filter.
          </P>
          <IntercomTeamInboxSourceFilter
            handleChange={setIntercomInboxIDs}
            selectedInboxIDs={intercomInboxIDs}
          />
          {saveError && (
            <P className={styles.saveError} variant="bodySm">
              Something went wrong, please contact support.
            </P>
          )}
        </>,
      ]}
      footer={footer}
    />
  );
};

export default AdminAutopilotSourceFiltersModal;
