import React, { Component } from 'react';

import { compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import { type Company, CompanyContext } from 'common/containers/CompanyContainer';
import ContentContainer from 'common/containers/ContentContainer';
import { ShowIntercomContext } from 'common/containers/IntercomContainer';
import connect from 'common/core/connect';
import Button from 'common/inputs/Button';
import Link from 'common/Link';
import UpsellModal from 'common/modals/UpsellModal';
import Tappable from 'common/Tappable';
import ButtonV2 from 'common/ui/ButtonV2';
import getOnboardingTasks from 'common/util/getOnboardingTasks';
import isGrowth from 'common/util/isGrowth';
import isStarter, { StarterPlanID } from 'common/util/isStarter';
import nbspLastSpace from 'common/util/nbspLastSpace';
import numberWithCommas from 'common/util/numberWithCommas';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import withContexts from 'common/util/withContexts';

import type { PlanTrial } from 'common/api/endpoints/companies';

import 'css/components/subdomain/admin/_AdminPlanExpired.scss';

const BusinessTrialID = 'business-trial-1';

type ExtendOnboardingError = {
  type:
    | {
        error: string;
        limitFields: string[];
      }
    | string;
  message: string;
};

type Props = {
  lockoutReason:
    | 'onboardingOverdue'
    | 'activePostLimitLockout'
    | 'trialExpired'
    | 'extraFeatures'
    | 'noPlan';
  company: Company;
  planTrial: PlanTrial | null;
  showIntercom: () => void;
  reloadCompany: () => void;
};

type State = {
  error: boolean;
  extending: boolean;
  extensionBlocked: boolean;
  showUpsell: 'onboardingExpired' | 'limits.activePosts' | 'extraFeatures' | null;
  extensionBlockedFields: string | null;
};

class AdminPlanExpired extends Component<Props, State> {
  state = {
    error: false,
    extending: false,
    extensionBlocked: false,
    showUpsell: null,
    extensionBlockedFields: null,
  };

  onContactUsTapped = () => {
    const { showIntercom } = this.props;
    showIntercom();
  };

  onExtendOnboarding = async () => {
    this.setState({ extending: true, extensionBlocked: false, extensionBlockedFields: null });
    const response = await AJAX.post('/api/billing/extendOnboarding');

    const { error: _error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });
    const error = _error as ExtendOnboardingError | null;

    if (error?.type === 'does not qualify') {
      this.setState({
        extending: false,
        extensionBlocked: true,
      });
      return;
    } else if (typeof error?.type !== 'string' && error?.type?.error === 'limit exceeds') {
      this.setState({
        extending: false,
        extensionBlocked: true,
        extensionBlockedFields: error.type?.limitFields.join(','),
      });
      return;
    } else if (error) {
      this.setState({
        extending: false,
        error: true,
      });
      return;
    }

    await this.props.reloadCompany();
    this.setState({ extending: false });
  };

  setShowUpsell = (showUpsell: State['showUpsell']) => {
    this.setState({ showUpsell });
  };

  renderEmoji() {
    if (
      this.props.lockoutReason === 'onboardingOverdue' ||
      this.props.lockoutReason === 'extraFeatures' ||
      this.props.lockoutReason === 'noPlan'
    ) {
      return <div className="emoji">🤔</div>;
    }

    return <div className="emoji">🙌</div>;
  }

  renderHeading() {
    const { planTrial } = this.props;

    if (isStarter(planTrial?.planID)) {
      return <div className="heading">Thanks for trying our Starter Plan!</div>;
    } else if (isGrowth(planTrial?.planID)) {
      return <div className="heading">Thanks for trying our Growth Plan!</div>;
    } else if (planTrial?.planID === BusinessTrialID) {
      return <div className="heading">Thanks for trying our Business Plan!</div>;
    }

    return <div className="heading">Thanks for trying Canny!</div>;
  }

  renderAffirmation() {
    const { company, planTrial } = this.props;
    const { contributorCount, postCount } = company;

    if (planTrial) {
      return;
    }

    if (contributorCount < 5) {
      return;
    }

    const affirmation =
      contributorCount >= 10
        ? 'Looks like your trial went really well. '
        : 'Looks like your trial went well. ';

    return (
      <div className="text">
        {affirmation}
        <div className="emphasize">
          {numberWithCommas(contributorCount)}
          {'\u00a0'}
          {contributorCount === 1 ? 'user' : 'users'}
        </div>
        {' gave feedback on '}
        <div className="emphasize">
          {numberWithCommas(postCount)}
          {'\u00a0'}
          {postCount === 1 ? 'idea' : 'ideas'}
        </div>
        {'.'}
      </div>
    );
  }

  renderNextAction() {
    const { planTrial } = this.props;

    if (planTrial?.planID === BusinessTrialID) {
      return (
        <div className="text">
          To continue using Canny, end your trial or upgrade to&nbsp;Business.
        </div>
      );
    } else if (planTrial) {
      return (
        <div className="text">
          To continue using Canny, end your trial or upgrade to&nbsp;Growth.
        </div>
      );
    }

    return (
      <div className="text">
        To continue using Canny, subscribe to the Growth plan or switch back to the Free&nbsp;plan.
      </div>
    );
  }

  renderButtons() {
    const { planTrial } = this.props;

    if (planTrial) {
      return (
        <Link className="billingButton" to="/admin/settings/billing">
          <Button value="Go to Billing" />
        </Link>
      );
    }

    return (
      <Link className="billingButton" to="/admin/settings/billing">
        <Button value="Choose a Plan" />
      </Link>
    );
  }

  renderNoPlanMessage() {
    return (
      <div className="message">
        <div className="heading">Still interested in Canny?</div>
        <div className="text">You need to choose a plan to continue using Canny.</div>

        <div className="buttons">
          <ButtonV2 asChild size="large">
            <Link to="/admin/settings/billing">Choose a plan</Link>
          </ButtonV2>
        </div>
      </div>
    );
  }

  renderOnboardingIncompleteMessage() {
    const { company, planTrial } = this.props;
    const { extending } = this.state;
    const {
      stats: { onboardedAt },
    } = company;
    const { onboardingExtensions } = company.billingData!;

    const canExtend = !onboardedAt && onboardingExtensions < 2;

    const heading = canExtend ? 'Need more time?' : 'Still interested in Canny?';

    const checklist = getOnboardingTasks(company);
    const completedItems = checklist.filter(({ completed }) => completed);

    const whatHappened = onboardedAt
      ? 'Looks like there was an issue with'
      : `Looks like your team only completed ${completedItems.length} of 5 steps towards`;

    let nextAction: React.ReactNode = 'Click below to get more time to complete the steps.';

    if (!canExtend) {
      if (onboardedAt) {
        nextAction = (
          <>
            Please try requesting additional free time on{' '}
            <Link to="/admin/settings/billing"> the billing page</Link>.
          </>
        );
      } else {
        nextAction = 'Contact our team to get more time to complete the steps.';
      }
    }

    const nextNextAction = planTrial
      ? 'Alternatively, upgrade to our Growth plan now.'
      : 'Alternatively, you can start a 14-day trial of the Growth plan."';

    const primaryButton = canExtend ? (
      <Button loading={extending} onTap={this.onExtendOnboarding} value="Get More Time" />
    ) : (
      <Button onTap={this.onContactUsTapped} value="Contact Us" />
    );

    const secondaryCTA = planTrial ? 'Upgrade to Growth' : 'Start a Growth Trial';

    return (
      <div className="message">
        <div className="heading">{nbspLastSpace(heading)}</div>
        <div className="text">
          {whatHappened}&nbsp;
          <a href="https://help.canny.io/en/articles/8325109-unlocking-our-free-plan">
            unlocking Canny Free
          </a>
          . {nbspLastSpace(nextAction)}
        </div>
        <div className="text">{nbspLastSpace(nextNextAction)}</div>
        <div className="buttons">
          {primaryButton}
          <Button
            buttonType="ghostButton"
            className="secondaryButton"
            onTap={() => this.setShowUpsell('onboardingExpired')}
            value={secondaryCTA}
          />
        </div>
      </div>
    );
  }

  renderActivePostLimitLockoutMessage() {
    const { company, planTrial } = this.props;
    const { postCount } = company;

    const primaryCTA = planTrial ? 'Upgrade to Starter' : 'Trial Starter';

    return (
      <div className="message">
        <div className="heading">Need more feedback?</div>
        <div className="text">
          Congrats! Your team has collected {postCount} pieces of feedback from your customers.
        </div>
        <div className="text">
          Our free plan is limited to 100 posts, but you can keep the feedback coming with our
          Starter Plan.
        </div>
        <div className="buttons">
          <Button onTap={() => this.setShowUpsell('limits.activePosts')} value={primaryCTA} />
          <Button
            buttonType="ghostButton"
            className="secondaryButton"
            onTap={this.onContactUsTapped}
            value="Contact Us"
          />
        </div>
      </div>
    );
  }

  renderExtraFeaturesMessage() {
    const { planTrial } = this.props;

    const howToFix = planTrial
      ? 'You can turn them off or upgrade to continue.'
      : 'You can turn them off or start a trial to continue.';

    return (
      <div className="message">
        <div className="heading">Still interested in Canny?</div>
        <div className="text">
          You are using features that aren't available in your plan. {howToFix}
        </div>

        <div className="buttons">
          <ButtonV2 size="large" onClick={() => this.setShowUpsell('extraFeatures')}>
            {planTrial ? 'Upgrade to Growth' : 'Start a Growth Trial'}
          </ButtonV2>
          <ButtonV2 asChild variant="outlined" className="secondaryButton" size="large">
            <Link to="/admin/settings/billing?action=qualify">Review Features</Link>
          </ButtonV2>
        </div>
      </div>
    );
  }

  renderMessage() {
    if (this.props.lockoutReason === 'noPlan') {
      return this.renderNoPlanMessage();
    }

    if (this.props.lockoutReason === 'onboardingOverdue') {
      return this.renderOnboardingIncompleteMessage();
    }

    if (this.props.lockoutReason === 'activePostLimitLockout') {
      return this.renderActivePostLimitLockoutMessage();
    }

    if (this.props.lockoutReason === 'extraFeatures') {
      return this.renderExtraFeaturesMessage();
    }

    return (
      <div className="message">
        {this.renderHeading()}
        {this.renderAffirmation()}
        {this.renderNextAction()}
        {this.renderButtons()}
      </div>
    );
  }

  renderError() {
    const { error } = this.state;
    if (!error) {
      return null;
    }

    return (
      <div className="error">
        Something went wrong, please try again or{' '}
        <Tappable onTap={this.onContactUsTapped}>
          <span className="link">message us for help</span>
        </Tappable>
        .
      </div>
    );
  }

  renderExtensionBlock() {
    const { extensionBlocked, extensionBlockedFields } = this.state;
    if (!extensionBlocked) {
      return null;
    }

    return (
      <div className="error">
        {extensionBlockedFields ? (
          <span>
            You are exceeding limits for the following fields:{' '}
            <span className="bold">{`${extensionBlockedFields}.\n`}</span> Visit{' '}
            <Link to="/admin/settings/billing">
              <span className="link">your billing page</span>
            </Link>{' '}
            to resolve these limits and try again.
          </span>
        ) : (
          <span>
            Your account has issues that are preventing your onboarding from being extended. Go to{' '}
            <Link to="/admin/settings/billing">
              <span className="link">your billing page</span>
            </Link>{' '}
            to resolve these issues and get more time.
          </span>
        )}
      </div>
    );
  }

  render() {
    const { showUpsell } = this.state;

    let cta;
    let planID;
    if (showUpsell === 'limits.activePosts') {
      cta = 'Unlimited feedback';
      planID = StarterPlanID;
    }

    return (
      <ContentContainer innerClassName="adminPlanExpired">
        <div className="messageContainer">
          {this.renderEmoji()}
          <div className="message">
            {this.renderMessage()}
            {this.renderError()}
            {this.renderExtensionBlock()}
          </div>
        </div>
        <UpsellModal
          cta={cta}
          feature={showUpsell}
          onClose={() => this.setShowUpsell(null)}
          onUpsell={() => this.setShowUpsell(null)}
          planID={planID}
          show={!!showUpsell}
        />
      </ContentContainer>
    );
  }
}

export default compose(
  connect(null, (dispatch: Dispatch) => ({
    reloadCompany: () => {
      return dispatch(reloadCompany());
    },
  })),
  withContexts(
    {
      company: CompanyContext,
      showIntercom: ShowIntercomContext,
    },
    { forwardRef: true }
  )
)(AdminPlanExpired);
