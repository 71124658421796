import React, { useContext } from 'react';

import { ViewerContext } from 'common/containers/ViewerContainer';
import getFirstName from 'common/util/getFirstName';

import AdminOnboardingHeader, { AdminOnboardingSubHeader } from './AdminOnboardingHeader';
import AdminOnboardingMultiSelect from './AdminOnboardingMultiSelect';
import AdminOnboardingOtherInput from './AdminOnboardingOtherInput';

import type { UseCase } from './Types';

import 'css/components/subdomain/admin/_AdminOnboarding.scss';

type Props = {
  onChange: (useCase: UseCase) => void;
  useCase: UseCase;
};

const AdminOnboardingWhat = ({ onChange, useCase }: Props) => {
  // context
  const viewer = useContext(ViewerContext);

  // helpers
  const onChangeOther = (other: string) => {
    onChange({
      ...useCase,
      otherMotivation: other,
    });
  };

  const onChangeSelect = (motivations: string[]) => {
    onChange({
      ...useCase,
      motivations,
    });
  };

  const { motivations, otherMotivation } = useCase;
  const selectedOther = motivations.includes('other');
  const name = getFirstName(viewer?.name) || 'there';
  return (
    <div className="onboardingWhat">
      <AdminOnboardingHeader>Hey {name}, what brings you to Canny?</AdminOnboardingHeader>
      <AdminOnboardingSubHeader>I would like to (select all that apply):</AdminOnboardingSubHeader>
      <AdminOnboardingMultiSelect
        onChange={onChangeSelect}
        options={[
          { name: 'feedback', label: 'Collect feedback' },
          { name: 'prioritizeRoadmap', label: 'Prioritize my roadmap' },
          { name: 'productAnnouncements', label: 'Create product announcements' },
          { name: 'other', label: 'Other' },
        ]}
        selected={motivations}
      />
      {selectedOther && (
        <AdminOnboardingOtherInput
          onChange={onChangeOther}
          placeholder="e.g. surveys, project management"
          value={otherMotivation}
        />
      )}
    </div>
  );
};

export default AdminOnboardingWhat;
