import queryActionFactory from 'common/actions/queryActionFactory';
import DataV2 from 'common/DataV2';

import type { Idea } from 'common/api/endpoints/idea';
import type { View } from 'common/api/endpoints/view';

export type QueryParams = {
  groupURLName?: string;
  sort?: View['sort'];
};

export const {
  // query helpers
  getQueryKey,

  // action creators
  invalidateQueries: invalidateIdeaQueries,
  loadQuery,
  loadMore,

  // action types
  RequestType,
  RequestMoreType,
  LoadedType,
  LoadedMoreType,
  ErrorType,
  InvalidateType,
} = queryActionFactory<Idea, QueryParams>('ideaQueries', DataV2.queries.ideas);
