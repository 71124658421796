import React, { useContext, useRef, useState } from 'react';

import {
  Bot,
  ChevronUpSquare,
  GanttChartSquare,
  Lightbulb,
  Menu,
  ScrollText,
  User,
} from 'lucide-react';

import Portal from 'common/common/Portal';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { LocationContext } from 'common/containers/RouterContainer';
import useBackgroundClick from 'common/hooks/useBackgroundClick';
import LazyLoadedImage from 'common/LazyLoadedImage';
import Link from 'common/Link';
import SelectOptions from 'common/ui/common/select/SelectOptions';
import styles from 'css-module/components/subdomain/admin/v2/_AdminV2Nav.module.scss';

import CannyIcon from 'img/canny-icon.svg';

const AdminV2NavMobileMenu = () => {
  const location = useContext(LocationContext);
  const company = useContext(CompanyContext);

  const buttonRef = useRef<HTMLButtonElement>(null);
  const dropdownRef = useRef<HTMLLIElement>(null);

  const [isOpen, setIsOpen] = useState(false);

  // close menu when clicking outside
  useBackgroundClick(() => {
    setIsOpen(false);
  }, [buttonRef, dropdownRef]);

  const isIDBEnabled = company?.featureAllowlist?.includes('idea-database');

  const Options = [
    {
      prefixIcon: Lightbulb,
      label: 'Ideas',
      value: '/admin/beta/ideas',
      hidden: !isIDBEnabled,
    },
    {
      prefixIcon: ChevronUpSquare,
      label: 'Feedback',
      value: '/admin/feedback',
    },
    {
      prefixIcon: GanttChartSquare,
      label: 'Roadmap',
      value: '/admin/roadmap',
    },
    {
      prefixIcon: User,
      label: 'Users',
      value: '/admin/users',
    },
    {
      prefixIcon: ScrollText,
      label: 'Changelog',
      value: '/admin/changelog',
      hidden: !company.showChangelog,
    },
    {
      prefixIcon: Bot,
      label: 'Autopilot',
      value: '/admin/autopilot',
    },
  ]
    .filter((option) => !option.hidden)
    .map((option) => ({
      ...option,
      // render link and handle click to close menu
      render: (
        <Link onTap={() => setIsOpen(false)} to={option.value}>
          {option.label}
        </Link>
      ),
    }));

  const selectedItem = Options.find((option) => location.pathname.startsWith(option.value));
  return (
    <ul className={styles.navSection} data-screen-size="mobile">
      <li>
        <Link className={styles.cannyLogoLink} to="/admin">
          <LazyLoadedImage className={styles.cannyLogoImage} alt="Canny Home" src={CannyIcon} />
        </Link>
      </li>
      <li>
        <button
          aria-label="Menu"
          className={styles.iconLink}
          onClick={() => setIsOpen(!isOpen)}
          ref={buttonRef}>
          <Menu className={styles.icon} />
          {isOpen && (
            <Portal
              align="start"
              className={styles.mobileMenuOptions}
              position="bottom"
              relativeToRef={buttonRef}>
              <SelectOptions
                options={Options}
                ref={dropdownRef}
                selectedItems={selectedItem ? [selectedItem] : []}
                getItemProps={() => {}}
                getMenuProps={() => {}}
                isOpen={true}
              />
            </Portal>
          )}
        </button>
      </li>
    </ul>
  );
};

export default AdminV2NavMobileMenu;
