import React, { useContext, useEffect, useState } from 'react';

import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import SpinnerV2 from 'common/SpinnerV2';
import MultipleSelect from 'common/ui/MultipleSelect';
import Text, { P } from 'common/ui/Text';
import parseAPIResponse from 'common/util/parseAPIResponse';
import styles from 'css-module/components/subdomain/admin/AdminQueue/SourceFilters/_IntercomTeamInboxSourceFilter.module.scss';

import type { IntercomTeam } from 'common/api/endpoints/intercom';
import type { Option } from 'common/ui/common/select/SelectCommon';

type GetTeamInboxesResponse = {
  teams: IntercomTeam[];
};

export default function IntercomTeamInboxSourceFilter({
  handleChange,
  selectedInboxIDs,
}: {
  handleChange: (inboxIDs: string[] | null) => void;
  selectedInboxIDs: string[] | null;
}) {
  const company = useContext(CompanyContext);
  const { intercom } = company;

  const [teamInboxes, setTeamInboxes] = useState<IntercomTeam[] | null>(null);
  const [teamInboxesError, setTeamInboxesError] = useState<boolean>(false);

  useEffect(() => {
    if (!intercom || intercom.lostAccess) {
      return;
    }

    const fetchTeamInboxes = async () => {
      const response = await AJAX.post('/api/intercom/getTeamInboxes', {});
      const { error, parsedResponse } = parseAPIResponse<GetTeamInboxesResponse>(response, {
        isSuccessful: (parsedResponse) => {
          return typeof parsedResponse.teams === 'object' && Array.isArray(parsedResponse.teams);
        },
      });
      if (error || !parsedResponse) {
        setTeamInboxesError(true);
      } else {
        const { teams } = parsedResponse;
        setTeamInboxes(teams);
      }
    };
    fetchTeamInboxes();
  }, [intercom, setTeamInboxes, setTeamInboxesError]);

  const loadingState = !teamInboxes && !teamInboxesError;
  if (loadingState) {
    return <SpinnerV2 size="large" />;
  }

  const errorState = teamInboxesError || !teamInboxes;
  if (errorState) {
    return (
      <P className={styles.error} variant="bodySm">
        Something went wrong fetching team inboxes, please contact support.
      </P>
    );
  }

  const value = teamInboxes
    .map((inbox) => {
      const isSelected = selectedInboxIDs?.includes(inbox.id);
      return isSelected ? { label: inbox.name, value: inbox.id } : null;
    })
    .filter(Boolean) as Option[];
  return (
    <form className={styles.form}>
      <Text className={styles.filterLabel} as="label" fontWeight="medium">
        Filter by team inbox
      </Text>
      <MultipleSelect
        allowClear={true}
        onChange={(options: Option[]) => {
          const selectedInboxIDs =
            options.length === 0 ? null : options.map((option) => option.value);
          handleChange(selectedInboxIDs);
        }}
        options={teamInboxes.map((inbox) => ({ label: inbox.name, value: inbox.id }))}
        placeholder="All team inboxes"
        value={value}
      />
    </form>
  );
}
