import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadBoard } from 'common/actions/boards';
import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import Form from 'common/Form';
import generateRandomID from 'common/generateRandomID';
import Helmet from 'common/helmets/Helmet';
import AutoResizeTextarea from 'common/inputs/AutoResizeTextarea';
import Button from 'common/inputs/Button';
import TextInput from 'common/inputs/TextInput';
import Link from 'common/Link';
import AccessModal from 'common/modals/AccessModal';
import CreateBoardFieldModal from 'common/modals/CreateBoardFieldModal';
import EditBoardFieldModal from 'common/modals/EditBoardFieldModal';
import UpsellModal from 'common/modals/UpsellModal';
import CreatePostFormV2 from 'common/post/CreatePostFormV2';
import withAccessControl from 'common/routing/withAccessControl';
import Strings from 'common/Strings';
import AdminBoardSettingsCreateFormFields from 'common/subdomain/admin/AdminBoardSettingsCreateFormFields';
import SwitchV2 from 'common/ui/SwitchV2';
import { H2, H4, P } from 'common/ui/Text';
import hasPermission from 'common/util/hasPermission';
import mapify from 'common/util/mapify';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import withContexts from 'common/util/withContexts';
import validateInput from 'common/validateInput';

import 'css/components/subdomain/admin/_AdminBoardSettingsCreateForm.scss';

const Modals = {
  createField: 'createField',
  editField: 'editField',
  upsellCustomFields: 'upsellCustomFields',
  upsellHideForm: 'upsellHideForm',
};

class AdminBoardSettingsCreateForm extends Component {
  static propTypes = {
    board: PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
      urlName: PropTypes.string,
      settings: PropTypes.shape({
        detailsRequired: PropTypes.bool,
      }),
    }),
    company: PropTypes.object,
    openModal: PropTypes.func,
    router: PropTypes.object,
    viewer: PropTypes.object,
  };

  state = {
    edited: false,
    editing: false,
    editingField: null,
    error: null,
    hasFieldsEdits: false,
    hasStringsEdits: false,
    fields: [],
    modal: null,
    detailsRequired: this.props.board.settings.detailsRequired,
    strings: {
      formCTA: this.props.board.strings.formCTA,
      createCTA: this.props.board.strings.createCTA,
      titlePlaceholder: this.props.board.strings.titlePlaceholder,
      detailsPlaceholder: this.props.board.strings.detailsPlaceholder,
    },
  };

  constructor(props, context) {
    super(props, context);

    this.createCTARef = React.createRef();
    this.formCTARef = React.createRef();
    this.titlePlaceholderRef = React.createRef();
    this.detailsPlaceholderRef = React.createRef();
  }

  componentDidMount() {
    const { route, router } = this.props;

    // don't show alert on exit when running SSR tests
    if (!__SSR_TEST_RUNNER__) {
      window.addEventListener('beforeunload', this.onBeforeUnload, false);
      router.setRouteLeaveHook(route, this.onRouteLeave);
    }

    const fields = this.getFields();
    this.setState({ fields });
  }

  componentDidUpdate(prevProps) {
    const { board, customPostFields } = this.props;
    if (
      board.boardFields !== prevProps.board.boardFields ||
      customPostFields !== prevProps.customPostFields
    ) {
      const fields = this.getFields();
      this.setState({ fields });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onBeforeUnload, false);
  }

  getFields = () => {
    const { board, customPostFields } = this.props;
    if (!Array.isArray(board.boardFields)) {
      return [];
    }

    const customPostFieldMap = mapify(customPostFields, '_id');
    return board.boardFields
      .filter((boardField) => !!customPostFieldMap[boardField.customPostFieldID])
      .map((boardField) => {
        const customPostField = customPostFieldMap[boardField.customPostFieldID];
        return {
          customPostFieldID: boardField.customPostFieldID,
          delete: false,
          label: boardField.label,
          name: customPostField.name,
          new: true,
          placeholder: boardField.placeholder ?? '',
          required: boardField.required,
          tempID: generateRandomID(),
          type: customPostField.type,
        };
      });
  };

  getFieldsPayload = () => {
    const { fields } = this.state;

    return fields
      .filter((field) => !field.default)
      .map((field) => ({
        customPostFieldID: field.customPostFieldID,
        delete: field.delete,
        label: field.label,
        placeholder: field.placeholder,
        required: field.required ?? false,
      }))
      .filter((field) => !(field.new && field.delete));
  };

  onBeforeUnload = (e) => {
    const prompt = this.onRouteLeave();
    if (!prompt) {
      return;
    }

    const event = e || window.event;
    event.returnValue = prompt;
    return prompt;
  };

  onCreateField = ({ customPostField, label, placeholder }) => {
    const { fields } = this.state;

    const existingField = fields.find((field) => field.customPostFieldID === customPostField._id);
    if (existingField) {
      this.updateField({
        ...existingField,
        delete: false,
        label,
        placeholder: placeholder ?? '',
      });
      this.setState({ modal: null });
      return;
    }

    this.setState((state) => ({
      edited: false,
      fields: state.fields.concat({
        customPostFieldID: customPostField._id,
        delete: false,
        label,
        name: customPostField.name,
        new: true,
        placeholder: placeholder ?? '',
        tempID: existingField?.tempID ?? generateRandomID(),
        type: customPostField.type,
      }),
      hasFieldsEdits: true,
      modal: null,
    }));
  };

  onFormChange = () => {
    const formCTA = this.formCTARef.current.getValue();
    const createCTA = this.createCTARef.current.getValue();
    const titlePlaceholder = this.titlePlaceholderRef.current.getValue();
    const detailsPlaceholder = this.detailsPlaceholderRef.current.getValue();

    this.setState((state) => ({
      edited: false,
      hasStringsEdits: true,
      strings: {
        ...state.strings,
        createCTA,
        formCTA,
        titlePlaceholder,
        detailsPlaceholder,
      },
    }));
  };

  onRouteLeave = () => {
    const { hasFieldsEdits } = this.state;

    if (hasFieldsEdits) {
      return 'Your board has unsaved changes. Are you sure you want to leave?';
    }
  };

  onSubmit = async () => {
    const error = this.validateInput();

    if (error) {
      this.setState({ error });
      return;
    }

    this.setState({
      editing: true,
      error: null,
    });

    const { board } = this.props;
    const { hasFieldsEdits, hasStringsEdits, strings } = this.state;

    const requestPromises = [];

    if (hasFieldsEdits) {
      requestPromises.push(
        AJAX.post('/api/boards/editCreatePostForm', {
          boardID: board._id,
          ...(hasFieldsEdits && {
            customPostFields: this.getFieldsPayload(),
            detailsRequired: this.state.detailsRequired,
          }),
        })
      );
    }

    if (hasStringsEdits) {
      requestPromises.push(
        AJAX.post('/api/boards/editStrings', {
          boardID: board._id,
          ...board.strings,
          ...strings,
        })
      );
    }

    const responses = await Promise.all(requestPromises);
    const didRequestsSucceed = responses.every((response) => response === 'success');

    if (!didRequestsSucceed) {
      this.setState({
        editing: false,
        error: Strings.miscError,
      });
      return;
    }

    this.setState({
      edited: true,
      editing: false,
      error: null,
      hasFieldsEdits: false,
      hasStringsEdits: false,
    });

    this.props.reloadBoard(board.urlName);
  };

  onUpsell = () => {
    this.setState({ modal: null });
    this.onToggleShowCreateForm();
  };

  onUpsellDismiss = () => {
    this.setState({ modal: null });
  };

  onToggleDetailsRequired = (detailsRequired) => {
    this.setState({ detailsRequired, hasFieldsEdits: true });
  };

  validateInput = () => {
    const formCTA = this.formCTARef.current.getValue();
    const createCTA = this.createCTARef.current.getValue();
    const titlePlaceholder = this.titlePlaceholderRef.current.getValue();
    const detailsPlaceholder = this.detailsPlaceholderRef.current.getValue();

    if (!validateInput.board.strings.titlePlaceholder(titlePlaceholder)) {
      return 'Please enter a valid title placeholder (0-60 characters)';
    }

    if (!validateInput.board.strings.formCTA(formCTA)) {
      return 'Please enter a valid form call to action (0-60 characters)';
    }

    if (!validateInput.board.strings.createCTA(createCTA)) {
      return 'Please enter valid button text (1-15 characters)';
    }

    if (!validateInput.board.strings.detailsPlaceholder(detailsPlaceholder)) {
      return 'Please enter a valid details placeholder (0-200 characters)';
    }

    return null;
  };

  renderFormInput() {
    const { customPostFields } = this.props;
    const { fields, strings } = this.state;

    return (
      <section className="formInputs">
        <H4 variant="headingSm">About</H4>
        <TextInput
          className="fixedInput"
          defaultValue={strings.formCTA}
          inset="Call to action"
          onChange={this.onFormChange}
          maxLength={60}
          ref={this.formCTARef}
        />
        <TextInput
          className="fixedInput"
          defaultValue={strings.titlePlaceholder}
          inset="Title"
          onChange={this.onFormChange}
          maxLength={60}
          ref={this.titlePlaceholderRef}
        />
        <div>
          <AutoResizeTextarea
            defaultValue={strings.detailsPlaceholder}
            inset="Details"
            onChange={this.onFormChange}
            maxLength={200}
            ref={this.detailsPlaceholderRef}
          />
          <div className="detailsRequiredRow">
            <p>Make details field required</p>
            <SwitchV2
              onChange={this.onToggleDetailsRequired}
              checked={this.state.detailsRequired}
            />
          </div>
        </div>
        <TextInput
          className="fixedInput"
          defaultValue={strings.createCTA}
          inset="Button"
          onChange={this.onFormChange}
          maxLength={15}
          ref={this.createCTARef}
        />
        {customPostFields?.length > 0 ? (
          <>
            <H4 variant="headingSm">Custom fields</H4>
            <AdminBoardSettingsCreateFormFields
              customPostFields={customPostFields}
              fields={fields}
              onCreateField={this.onAddField}
              onDeleteField={this.onDeleteField}
              onEditField={this.onEditField}
              onReorderField={this.onReorderField}
              onUpdateField={this.updateField}
            />
          </>
        ) : (
          <>
            <H4 variant="headingSm">Custom fields</H4>
            <P className="subtitle">
              To add custom fields to your post form, define new fields in your&nbsp;
              <Link className="link" to="/admin/settings/fields/post-fields">
                post field settings.
              </Link>
            </P>
          </>
        )}
      </section>
    );
  }

  renderError() {
    if (!this.state.error) {
      return;
    }

    return <div className="error">{this.state.error}</div>;
  }

  renderPreview() {
    const { fields, settings, strings } = this.state;
    const { board, company, customPostFields } = this.props;

    const customPostFieldMap = mapify(customPostFields, '_id');
    const previewBoard = {
      ...board,
      boardFields: fields
        .filter((field) => !field.default && !field.delete)
        .map((boardField) => {
          const customPostField = customPostFieldMap[boardField.customPostFieldID];
          return {
            ...customPostField,
            ...boardField,
          };
        }),
      strings: strings,
      settings: { ...board.settings, ...settings },
    };

    return (
      <section className="preview">
        <H4 variant="headingSm">Preview</H4>
        <CreatePostFormV2
          className="createPostFormV2"
          tint={company.tintColor}
          board={previewBoard}
          loading={false}
          onCreate={() => null}
        />
      </section>
    );
  }

  onAddField = () => {
    const { company, openModal, viewer } = this.props;
    const viewerHasPermission = hasPermission('manageCustomPostFields', company, viewer);

    if (!company.features?.customPostFields) {
      this.setState({ modal: Modals.upsellCustomFields });
      return;
    } else if (!viewerHasPermission) {
      this.setState({ modal: null }, () => {
        openModal(AccessModal, {
          requiredPermissions: ['manageCustomPostFields'],
        });
      });
      return;
    }

    this.setState({
      modal: Modals.createField,
    });
  };

  onDeleteField = (field) => {
    const { company, openModal, viewer } = this.props;

    const viewerHasPermission = hasPermission('manageCustomPostFields', company, viewer);
    if (!viewerHasPermission) {
      openModal(AccessModal, {
        requiredPermissions: ['manageCustomPostFields'],
      });
      return;
    }

    this.updateField({ ...field, delete: true });
  };

  onEditField = (field) => {
    const { company, openModal, viewer } = this.props;
    const viewerHasPermission = hasPermission('manageCustomPostFields', company, viewer);

    if (!field.default && !company.features?.customPostFields) {
      this.setState({ modal: Modals.upsellCustomFields });
      return;
    } else if (!viewerHasPermission) {
      this.setState({ modal: null }, () => {
        openModal(AccessModal, {
          requiredPermissions: ['manageCustomPostFields'],
        });
      });
      return;
    }

    this.setState({
      editingField: field,
      modal: Modals.editField,
    });
  };

  onReorderField = (fromFieldTempID, intoFieldTempID, { order }) => {
    const { fields } = this.state;
    const fromField = fields.find((field) => field.tempID === fromFieldTempID);
    const intoField = fields.find((field) => field.tempID === intoFieldTempID);

    // if trying to move a field to the same position, do nothing
    if (fromFieldTempID === intoFieldTempID) {
      return null;
    }

    const updatedFields = fields.reduce((updatedFields, field) => {
      if (field.tempID === intoFieldTempID) {
        const appendableFields = {
          after: [intoField, fromField],
          before: [fromField, intoField],
        }[order];

        return updatedFields.concat(appendableFields);
      }

      return field.tempID === fromFieldTempID ? updatedFields : updatedFields.concat(field);
    }, []);

    this.setState({
      edited: false,
      hasFieldsEdits: true,
      fields: updatedFields,
    });
  };

  updateField = (updatedField) => {
    const { fields } = this.state;

    const updatedFields = fields.reduce((updatedFields, field) => {
      const isUpdated = field.tempID === updatedField.tempID;
      return updatedFields.concat(isUpdated ? updatedField : field);
    }, []);

    this.setState({
      edited: false,
      fields: updatedFields,
      hasFieldsEdits: true,
    });
  };

  renderCreateBoardFieldModal = () => {
    const { company, customPostFields } = this.props;
    const { fields, modal } = this.state;

    if (modal !== Modals.createField || !customPostFields?.length) {
      return null;
    }

    const fieldMap = mapify(fields, 'customPostFieldID');
    const nonSelectedFields = customPostFields.filter((customPostField) => {
      const field = fieldMap[customPostField._id];
      return !field || field.delete;
    });

    return (
      <CreateBoardFieldModal
        company={company}
        customPostFields={nonSelectedFields}
        onClose={() => this.setState({ modal: null })}
        onCreate={this.onCreateField}
      />
    );
  };

  renderEditBoardFieldModal = () => {
    const { company } = this.props;
    const { editingField, modal } = this.state;
    if (modal !== Modals.editField) {
      return null;
    }

    return (
      <EditBoardFieldModal
        company={company}
        field={editingField}
        onClose={() => this.setState({ modal: null })}
        onEdit={({ field, label, placeholder }) => {
          this.updateField({ ...field, label, placeholder });
          this.setState({ modal: null });
        }}
      />
    );
  };

  render() {
    const { board } = this.props;
    const hasEdits = this.state.hasStringsEdits || this.state.hasFieldsEdits;
    return (
      <div className="adminBoardSettingsCreateForm">
        <Helmet title={'Create Form Settings | ' + board.name + ' | Canny'} />
        <div className="innerContainer">
          <Form
            className="form"
            addEventsToDocument={false}
            disableSubmit={!hasEdits || this.state.editing}
            onSubmit={this.onSubmit}>
            <header className="header">
              <H2 variant="headingMd">Create Post Form</H2>
              <P className="subtitle">
                Personalize your post creation form with additional details and&nbsp;instructions.
              </P>
            </header>
            <div className="divider" />
            <div className="body">
              <div className="left">
                {this.renderFormInput()}
                {this.renderPreview()}
                {this.renderError()}
                <Button
                  className="saveButton"
                  buttonType="cannyButton"
                  disabled={!hasEdits}
                  formButton={true}
                  loading={this.state.editing}
                  value={this.state.edited ? 'Saved' : 'Save'}
                />
              </div>
            </div>
          </Form>
        </div>
        {this.renderCreateBoardFieldModal()}
        {this.renderEditBoardFieldModal()}
        <UpsellModal
          cta="Disable new posts from end&nbsp;users"
          feature="disableUserSubmissions"
          onClose={this.onUpsellDismiss}
          onUpsell={this.onUpsell}
          show={this.state.modal === Modals.upsellHideForm}
        />
        <UpsellModal
          cta="Add custom fields to your posts"
          feature="customPostfields"
          onClose={this.onUpsellDismiss}
          onUpsell={() => this.setState({ modal: null })}
          show={this.state.modal === Modals.upsellCustomFields}
        />
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadBoard: (boardURLName) => {
      return Promise.all([dispatch(reloadBoard(boardURLName))]);
    },
  })),
  withAccessControl(
    testEveryPermission(RoutePermissions.adminSettings.board['create-form']),
    '/admin/settings',
    { forwardRef: true }
  ),
  withContexts(
    {
      company: CompanyContext,
      openModal: OpenModalContext,
      viewer: ViewerContext,
    },
    { forwardRef: true }
  )
)(AdminBoardSettingsCreateForm);
