import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import classnames from 'classnames';
import { MoreHorizontal } from 'lucide-react';

import { invalidatePostQueries } from 'common/actions/postQueries';
import { invalidateUserPosts } from 'common/actions/userPosts';
import { invalidateUserQueries } from 'common/actions/userQueries';
import { invalidateUsers, reloadUser } from 'common/actions/users';
import AJAX from 'common/AJAX';
import Tooltip from 'common/common/Tooltip';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { TrackEventContext } from 'common/containers/EventContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { RouterContext } from 'common/containers/RouterContainer';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import useBackgroundClick from 'common/hooks/useBackgroundClick';
import Button from 'common/inputs/Button';
import TextInput from 'common/inputs/TextInput';
import AccessModal from 'common/modals/AccessModal';
import MergeUsersModal, { type Overwrites } from 'common/modals/MergeUsersModal';
import ModernConfirmModal from 'common/modals/ModernConfirmModal';
import ActionMenu from 'common/subdomain/admin/ActionMenu';
import { P } from 'common/ui/Text';
import UserAvatar from 'common/user/UserAvatar';
import UserDropdown from 'common/user/UserDropdown';
import hasPermission from 'common/util/hasPermission';
import mapify from 'common/util/mapify';
import numberWithCommas from 'common/util/numberWithCommas';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';

import type { Company, RolePermissionName } from 'common/api/endpoints/companies';
import type { CompanyUser } from 'common/api/endpoints/users';
import type { Dispatch } from 'redux';

import 'css/components/subdomain/admin/_AdminUsersDetailsSidebar.scss';

type UserNameProps = {
  isEditable: boolean;
  name: string;
  onNameChange: (name: string) => void;
};

const UserName = ({ isEditable, name, onNameChange }: UserNameProps) => {
  const [editedName, setEditedName] = useState(name);
  const [isEditing, setIsEditing] = useState(false);

  const closeInput = useCallback(() => {
    setIsEditing(false);
    setEditedName(name);
  }, [name]);

  const onSubmit = useCallback(() => {
    closeInput();
    if (editedName !== name) {
      onNameChange(editedName);
    }
  }, [closeInput, editedName, name, onNameChange]);

  // Key event listener
  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (!isEditing) {
        return;
      }

      if (e.key === 'Escape') {
        closeInput();
      }

      if (e.key === 'Enter') {
        onSubmit();
      }
    };

    document.addEventListener('keydown', onKeyDown);
    return () => document.removeEventListener('keydown', onKeyDown);
  }, [closeInput, isEditing, onSubmit]);

  // Keep the state in-sync with the name prop
  useEffect(() => {
    setEditedName(name);
  }, [name]);

  if (isEditing) {
    return (
      <TextInput
        autoFocus
        className={classnames('userNameInput')}
        defaultValue={name}
        key={name}
        onBlur={onSubmit}
        onChange={(e) => setEditedName(e.currentTarget.value)}
      />
    );
  }

  if (isEditable) {
    return (
      <button className="userName editable" onClick={() => setIsEditing(true)}>
        {name}
      </button>
    );
  }

  return (
    <Tooltip
      className="userNameTooltip"
      position="bottom"
      value="Users powered by integrations cannot be edited within&nbsp;Canny">
      <h2 className="userName">{name}</h2>
    </Tooltip>
  );
};

const Modals = {
  delete: 'delete',
  deleteAndBan: 'deleteAndBan',
  merge: 'merge',
};

type OwnProps = {
  user: CompanyUser;
};

type ConnectProps = {
  invalidateUsers: () => void;
  invalidatePosts: (queryParams: Record<string, unknown>) => void;
  reloadUser: (urlName: string) => void;
};

type Props = OwnProps & ConnectProps;

const AdminUsersDetailsSidebarUserHeader = ({
  user,
  invalidateUsers,
  invalidatePosts,
  reloadUser,
}: Props) => {
  const company = useContext<Company>(CompanyContext);
  const openModal = useContext(OpenModalContext);
  const router = useContext(RouterContext);
  const trackEvent = useContext(TrackEventContext);
  const showToast = useContext(ShowToastContext);
  const viewer = useContext(ViewerContext);

  const mergeRef = useRef(null);
  const menuRef = useRef(null);
  const menuPortalRef = useRef(null);

  const [userToMerge, setUserToMerge] = useState<CompanyUser | null>(null);
  const [isMerging, setIsMerging] = useState(false);
  const [actionsMenuOpen, setActionsMenuOpen] = useState(false);
  const [userDropdownOpen, setUserDropdownOpen] = useState(false);
  const [modal, setModal] = useState<(typeof Modals)[keyof typeof Modals] | null>(null);

  const editPermission: RolePermissionName = 'deleteUsers';
  const viewerHasDeleteAndBanPermission = hasPermission(editPermission, company, viewer);

  useBackgroundClick(() => {
    if (actionsMenuOpen) {
      setActionsMenuOpen(false);
    }
  }, [menuRef, menuPortalRef]);

  const memberMap = mapify(company.members, '_id');
  const userIsMember = memberMap[user._id];

  const merge = async (fromUser: CompanyUser, toUser: CompanyUser, overwrites: Overwrites) => {
    const flip = (overwriteType: 'origin' | 'target') =>
      overwriteType === 'origin' ? 'target' : 'origin';

    const params =
      overwrites.primary === 'origin'
        ? {
            fromUserID: toUser._id,
            intoUserID: fromUser._id,
            overwrites: {
              name: flip(overwrites.name),
              email: flip(overwrites.email),
              urlName: flip(overwrites.name),
            },
          }
        : {
            fromUserID: fromUser._id,
            intoUserID: toUser._id,
            overwrites: {
              name: overwrites.name,
              email: overwrites.email,
              urlName: overwrites.name,
            },
          };

    setIsMerging(true);
    setModal(null);
    trackEvent('Merge company users');

    const response = await AJAX.post('/api/users/merge', params);

    setIsMerging(false);

    const { error, parsedResponse } = parseAPIResponse<{ user: CompanyUser }>(response, {
      isSuccessful: (parsedResponse) => !!parsedResponse.user,
      errors: {
        'user does not exist': 'The user you are trying to merge does not exist',
        'user is admin': "Admin users can't be merged",
        'user does not belong to company': 'The user does not belong to your company',
        'not authorized': "Your user doesn't have permission to merge users",
      },
    });

    if (error) {
      showToast(error.message, ToastTypes.error);
      return;
    } else if (!parsedResponse) {
      return;
    }

    // redirect to new user
    const { user } = parsedResponse;
    await invalidateUsers();
    await invalidatePosts({
      filter: 'posts-votes-comments',
      user,
    });
    router.push(`/admin/users/${user.urlName}`);
  };

  const onNameChange = async (name: string) => {
    const response = await AJAX.post('/api/users/edit', { name, userID: user._id });
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: {
        'user does not exist': 'The user you are trying to edit does not exist',
        'user is synced': 'Cannot edit users that are synced with another service',
        'not authorized': 'You do not have permission to edit users',
      },
    });
    if (error) {
      showToast(error.message, ToastTypes.error);
      return;
    }
    reloadUser(user.urlName);
    showToast('Name successfully changed', ToastTypes.success);
  };

  const renderMergeModal = () => {
    if (!userToMerge) {
      return null;
    }

    return (
      <MergeUsersModal
        fromUser={user}
        toUser={userToMerge}
        onClose={() => setModal(null)}
        onMerge={merge}
      />
    );
  };

  const renderDeleteModal = () => {
    if (!viewerHasDeleteAndBanPermission) {
      setModal(null);
      openModal(AccessModal, {
        requiredPermissions: [editPermission],
      });
      return;
    }

    const onDelete = async () => {
      const response = await AJAX.post('/api/users/delete', {
        shouldBan: false,
        userIDs: [user._id],
      });

      const { error } = parseAPIResponse(response, {
        isSuccessful: isDefaultSuccessResponse,
      });

      if (error) {
        showToast(error.message, ToastTypes.error);
        return;
      }

      await invalidateUsers();
      router.push('/admin/users');
      showToast('User deleted', ToastTypes.success);
    };

    return (
      <ModernConfirmModal
        onClose={() => setModal(null)}
        header="Are you sure you want to delete this user?"
        onConfirm={onDelete}
        type="destructive"
        confirmText="Delete user">
        <>
          <P>Deleting a user will permanently remove:</P>
          <ul className="part list">
            <li>All of their votes and comments </li>
            <li>All of their posts without votes or comments</li>
          </ul>
          <P>Posts with votes or comments will remain, but the user will no longer be credited.</P>
          <P>There might be a delay before the data is removed. This action cannot be undone.</P>
        </>
      </ModernConfirmModal>
    );
  };

  const renderDeleteAndBanModal = () => {
    if (!viewerHasDeleteAndBanPermission) {
      setModal(null);
      openModal(AccessModal, {
        requiredPermissions: [editPermission],
      });
      return;
    }

    const onDeleteAndBan = async () => {
      const response = await AJAX.post('/api/users/delete', {
        shouldBan: true,
        userIDs: [user._id],
      });

      const { error } = parseAPIResponse(response, {
        isSuccessful: isDefaultSuccessResponse,
      });
      if (error) {
        showToast(error.message, ToastTypes.error);
        return;
      }

      await invalidateUsers();
      router.push('/admin/users');
      showToast('User banned', ToastTypes.success);
    };

    return (
      <ModernConfirmModal
        onClose={() => setModal(null)}
        header="Are you sure you want to ban this user?"
        onConfirm={onDeleteAndBan}
        type="destructive"
        confirmText="Ban user">
        <>
          <P>Banning a user will permanently remove:</P>
          <ul>
            <li>All of their votes and comments </li>
            <li>All of their posts without votes or comments</li>
            <li>Their ability to post, vote, and comment</li>
          </ul>
          <P>Posts with votes or comments will remain, but the user will no longer be credited.</P>
          <P>There might be a delay before the data is removed. This action cannot be undone.</P>
        </>
      </ModernConfirmModal>
    );
  };

  return (
    <div className="userHeader">
      <div className="userDetails">
        <UserAvatar user={user} showBadge={true} size="large" />
        <div className="userData">
          <UserName
            isEditable={!user.sources?.length}
            name={user.name}
            onNameChange={onNameChange}
          />
          <div className="userMetadata">
            <div className="userMetadataElement">
              <div className="value">{numberWithCommas(user.voteCount)}</div>
              <div className="label">{user.voteCount === 1 ? 'vote' : 'votes'}</div>
            </div>
            <div className="middot">&middot;</div>
            <div className="userMetadataElement">
              <div className="value">{numberWithCommas(user.postCount)}</div>
              <div className="label">{user.postCount === 1 ? 'post' : 'posts'}</div>
            </div>
            <div className="middot">&middot;</div>
            <div className="userMetadataElement">
              <div className="value">{numberWithCommas(user.commentCount)}</div>
              <div className="label">{user.commentCount === 1 ? 'comment' : 'comments'}</div>
            </div>
          </div>
        </div>
      </div>
      {!userIsMember && (
        <div className="actions">
          <Tooltip
            position="bottom"
            value={
              user.sources?.length
                ? "Users synced from integrations can't be merged"
                : 'Merge this user with another user'
            }>
            <div ref={mergeRef} className="actionContainer">
              <Button
                buttonType="ghostButton"
                className="actionButton"
                loading={isMerging}
                disabled={!!user.sources?.length}
                value="Merge users"
                onClick={() => setUserDropdownOpen(true)}
              />
            </div>
          </Tooltip>
          <div ref={menuRef} className="actionContainer">
            <Button
              buttonType="ghostButton"
              className="actionButton"
              value={<MoreHorizontal size={16} />}
              onClick={() => setActionsMenuOpen(!actionsMenuOpen)}
            />
          </div>
          {userDropdownOpen && (
            <UserDropdown
              userFilter={(userOption) => {
                const userIsSynced = userOption.sources?.length;
                const userIsMember = memberMap[userOption._id];
                const isSameUser = userOption._id === user._id;

                if (userIsSynced || userIsMember || isSameUser) {
                  return false;
                }

                return true;
              }}
              relativeElementRef={mergeRef}
              onUserSelected={(user) => {
                setUserToMerge(user);
                setModal(Modals.merge);
              }}
              buttonRef={mergeRef}
              onClickOutside={() => setUserDropdownOpen(false)}
            />
          )}
          {actionsMenuOpen && (
            <ActionMenu
              ref={menuPortalRef}
              relativeToRef={menuRef}
              options={[
                {
                  destructive: true,
                  label: 'Delete User',
                  action: () => {
                    setActionsMenuOpen(false);
                    setModal(Modals.delete);
                  },
                },
                {
                  destructive: true,
                  label: 'Ban User',
                  action: () => {
                    setActionsMenuOpen(false);
                    setModal(Modals.deleteAndBan);
                  },
                },
              ]}
            />
          )}
          {modal === Modals.deleteAndBan && renderDeleteAndBanModal()}
          {modal === Modals.delete && renderDeleteModal()}
          {modal === Modals.merge && renderMergeModal()}
        </div>
      )}
    </div>
  );
};

// TODO: remove cast once `connect` is typed
export default connect(null, (dispatch: Dispatch<any>) => ({
  invalidateUsers: () => {
    dispatch(invalidateUsers());
    dispatch(invalidateUserQueries());
  },
  invalidatePosts: (queryParams: Record<string, unknown>) => {
    dispatch(invalidateUserPosts(queryParams));
    dispatch(invalidatePostQueries());
  },
  reloadUser: (urlName: string) => {
    dispatch(reloadUser(urlName));
  },
}))(AdminUsersDetailsSidebarUserHeader) as unknown as React.FC<OwnProps>;
