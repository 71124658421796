import React from 'react';

import { loadAutopilotCounts } from 'common/actions/autopilotCounts';
import asyncConnect from 'common/core/asyncConnect';
import cloneElementWithProps from 'common/core/cloneElementWithProps';
import { type AutopilotCountsState, defaultState } from 'common/reducers/autopilotCounts';

import type { State } from 'redux-connect';

type Props = {
  autopilotCounts: AutopilotCountsState;
  children: React.ReactNode;
};

export const AutopilotCountsContext = React.createContext<AutopilotCountsState>(defaultState);

const AutopilotCountsContainer = (props: Props) => {
  const clonedElement = cloneElementWithProps(props.children, {
    ...props,
    autopilotCounts: props.autopilotCounts,
  });
  return (
    <AutopilotCountsContext.Provider value={props.autopilotCounts}>
      {clonedElement}
    </AutopilotCountsContext.Provider>
  );
};

export default asyncConnect(
  [
    {
      promise: ({ store: { dispatch } }) => dispatch(loadAutopilotCounts()),
    },
  ],
  (state: State) => ({ autopilotCounts: state.autopilotCounts })
)(AutopilotCountsContainer);
