interface Error {
  type: string;
  message: string;
}

type Response<T> =
  | {
      parsedResponse: T;
      error: null;
    }
  | {
      parsedResponse: null;
      error: Error | null;
    };

const standardErrors: Record<string, string> = {
  default: 'Something went wrong, please try again later.',
  'slow down': 'Too many requests, please try again later.',
};

type Options<T> = {
  errors?: Record<string, string>;
  isSuccessful: (response: T) => boolean;
};
const parseResponse = (str: string) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return str;
  }
};
export const isDefaultSuccessResponse = (response: unknown) => response === 'success';
// parses response and maps errors to the correct error message.
// optional param isSuccessful is necessary, only if request success depends on the parsed response.
export default function parseAPIResponse<T>(
  response: string,
  { errors = {}, isSuccessful }: Options<T>
): Response<T> {
  const mergedErrors = { ...standardErrors, ...errors };
  const errorDefault = mergedErrors.default || 'Something went wrong, please try again later.';
  try {
    const parsedResponse = parseResponse(response);

    if (isSuccessful(parsedResponse)) {
      return {
        parsedResponse: parsedResponse,
        error: null,
      };
    }

    const { error } = parsedResponse;

    if (!error) {
      throw new Error(`Response parsing error. Response: "${response}"`);
    }

    return {
      parsedResponse: null,
      error: {
        type: error,
        message: mergedErrors[error] ?? errorDefault,
      },
    };
  } catch (e) {
    return {
      parsedResponse: null,
      error: {
        type: 'default',
        message: errorDefault,
      },
    };
  }
}
