import isFree from 'common/util/isFree';
import isGrowth from 'common/util/isGrowth';
import isStarter from 'common/util/isStarter';

import type { Company } from 'common/containers/CompanyContainer';

const PlanNames = {
  business: 'Business',
  growth: 'Growth',
  starter: 'Starter',
  free: 'Free',
} as const;

const getPlanName = (planID: string | null) => {
  if (!planID) {
    return null;
  }

  if (isFree(planID)) {
    return PlanNames.free;
  }

  if (isStarter(planID)) {
    return PlanNames.starter;
  }

  if (isGrowth(planID)) {
    return PlanNames.growth;
  }

  if (planID === 'business-trial-1' || /f\d\d/.test(planID)) {
    return PlanNames.business;
  }

  return null;
};

const ReferralDiscounts = {
  monthly: {
    allowedPlanIDs: null,
    amountOff: null,
    couponID: 'monthly-referral-coupon',
    duration: 'repeating',
    durationInMonths: 3,
    percentOff: 20,
  },
  yearly: {
    allowedPlanIDs: null,
    amountOff: null,
    couponID: 'annual-referral-coupon',
    duration: 'once',
    durationInMonths: 12,
    percentOff: 5,
  },
} as const;

// Note: Referral program gives 20% off for 3 months (or %5 for annual plans) however discount is created after user buys a plan
// So we need to manually mock the discount Discount methods, if user has a referral code and did not buy before
const getCompanyDiscount = ({ discount, referee }: Company, billingCycle: 'monthly' | 'yearly') => {
  if (!discount && (!referee || referee.couponUsed)) {
    return null;
  }

  return discount ?? ReferralDiscounts[billingCycle];
};

export { getPlanName, PlanNames, getCompanyDiscount };
