import createCustomError from 'common/util/createCustomError';
import validateInput from 'common/validateInput';

import {
  isActionAddPostCategory,
  isActionAddPostTags,
  isActionAddToRoadmap,
  isActionApplyPostStatus,
} from '../actionUtils';

import type { Action } from '../constants';

export const ValidateAutomationActionError = createCustomError('validateAutomation');

const validateAction = (action: Action) => {
  if (isActionAddPostCategory(action)) {
    return validateInput.id(action.properties.categoryID);
  } else if (isActionAddPostTags(action)) {
    return (
      action.properties.tagIDs.length > 0 &&
      action.properties.tagIDs.every((tagID) => validateInput.id(tagID))
    );
  } else if (isActionApplyPostStatus(action)) {
    return validateInput.companyPostStatus.name(action.properties.statusID);
  } else if (isActionAddToRoadmap(action)) {
    return validateInput.id(action.properties.roadmapID);
  } else {
    throw new ValidateAutomationActionError({
      message: `Invalid action: ${(action as Action).type}`,
      action,
    });
  }
};

export default validateAction;
