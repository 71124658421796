import React from 'react';

import classnames from 'classnames';

import { type TranslationObject, hasTranslation } from 'common/i18n/translateString';
import nbspLastSpace from 'common/util/nbspLastSpace';
import styles from 'css-module/components/i18n/_TranslationToggle.module.scss';

import type { StringFieldKeys } from 'common/types/util';

type Props<P extends object> = {
  className?: string;
  hideTranslation: boolean;
  object: TranslationObject<P>;
  onToggle: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  stringKeys: StringFieldKeys<P>[];
};

const TranslationToggle = <P extends object>({
  className,
  hideTranslation,
  object,
  onToggle,
  stringKeys,
}: Props<P>) => {
  if (!hasTranslation(object, stringKeys)) {
    return null;
  }

  const label = hideTranslation ? 'Show Translation' : 'Show Original';
  return (
    <button className={classnames(styles.translationToggle, className)} onClick={onToggle}>
      {nbspLastSpace(label)}
    </button>
  );
};

export default TranslationToggle;
