import {
  type FailedFile,
  LoadStatus,
  type MimeTypeValues,
  type UploadedFile,
  type UploadingFile,
} from 'common/constants/files';
import generateFileID from 'common/file/utils/generateFileID';
import getExtensionFromContentType from 'common/file/utils/getExtensionFromContentType';
import getFileNameWithoutExtension from 'common/file/utils/getFileNameWithoutExtension';
import * as FileUploader from 'common/FileUploader';

import type { Viewer } from 'common/containers/ViewerContainer';

type OnFileErrorFunc = (file: FailedFile, error: string) => void;
type OnFileUploadingFunc = (file: UploadingFile) => void;
type OnFileUploadedFunc = (file: UploadedFile) => void;

export const numberOfFilesLimit = 10;

const validateFile = (file: File, viewer: Viewer) => {
  if (!file) {
    return 'Please select a file.';
  }

  if (!viewer || viewer.loggedOut) {
    return 'Please log in to upload images.';
  }

  return null;
};

const uploadFile = async ({
  file,
  viewer,
  onFileError,
  onFileUploading,
  onFileUploaded,
}: {
  file: File;
  viewer: Viewer;
  onFileError: OnFileErrorFunc;
  onFileUploading?: OnFileUploadingFunc;
  onFileUploaded: OnFileUploadedFunc;
}) => {
  const baseProps = {
    extension: getExtensionFromContentType(file.type),
    name: getFileNameWithoutExtension(file.name),
    uniqueID: generateFileID(),
    contentType: file.type as MimeTypeValues,
  };

  const error = validateFile(file, viewer);

  if (error) {
    onFileError({ ...baseProps, uploadStatus: LoadStatus.failed, url: null }, error);
    return;
  }

  onFileUploading?.({ ...baseProps, uploadStatus: LoadStatus.loading, url: null });

  try {
    const fileURL = await FileUploader.upload(file, viewer);
    onFileUploaded({ ...baseProps, uploadStatus: LoadStatus.loaded, url: fileURL });
  } catch (error: any) {
    const errorMessage = error?.data?.message || 'Server error';
    onFileError({ ...baseProps, uploadStatus: LoadStatus.failed, url: null }, errorMessage);
  }
};

export default uploadFile;
