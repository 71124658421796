import { MimeTypes } from 'common/constants/files';

export const SupportedMimeTypeList = [
  MimeTypes.pdf,
  MimeTypes.doc,
  MimeTypes.docx,
  MimeTypes.ppt,
  MimeTypes.pptx,
  MimeTypes.txt,
  MimeTypes.md,
  MimeTypes.html,
].flat();

export type SupportedMimeTypes = (typeof SupportedMimeTypeList)[number];

export const isSupportedMimeType = (fileType: string): fileType is SupportedMimeTypes => {
  return SupportedMimeTypeList.includes(fileType as SupportedMimeTypes);
};
