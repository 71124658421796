import loadScript from 'common/util/loadScript';

function shouldUseSentry() {
  if (__DEV__) {
    return false;
  } else if (__BROWSER_EXTENSION__) {
    return false;
  } else if (typeof window === 'undefined') {
    return false;
  } else {
    return true;
  }
}

const IgnoreErrors = [
  '$ is not defined',
  'androidInterface is not defined',
  'Blocked a frame with origin',
  'Blocked script execution in',
  "Can't find variable: pktAnnotationHighlighter",
  'Cannot redefine property: BetterJsPop',
  'Cannot redefine property: blockAdBlock',
  'Cannot redefine property: BlockAdBlock',
  'Cannot redefine property: googletag',
  'CoinCube is not defined',
  'Event `ErrorEvent` captured as exception with message `Script error.`',
  "Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
  'GetScreenshotBoundingBox is not defined',
  'NS_ERROR_FAILURE',
  'Non-Error promise rejection captured with value: Object Not Found Matching Id:',
  "ReferenceError: Can't find variable: cordova",
  'ReferenceError: grecaptcha is not defined',
  'Request failed with status code 400',
  'Request failed with status code 401',
  'Request failed with status code 403',
  'Request failed with status code 404',
  'Request failed with status code 429',
  'ResizeObserver loop limit exceeded',
  'ResizeObserver loop completed with undelivered notifications',
  'SecurityError: Blocked a frame with origin',
  'vid_mate_check is not defined',
];

const IgnoreURLs = [
  /extensions\//i, // chrome extensions
  /^chrome:\/\//i,
  /^moz-extension:\/\//i,
  /^safari-extension:\/\//i,
];

const errorQueue = [];

let pendingUser = null;

const SentrySDK = {
  captureException: function (error) {
    if (!shouldUseSentry()) {
      console.error(error);
      return;
    }

    if (!window.Sentry) {
      if (errorQueue.length < 100) {
        errorQueue.push(error);
      }
      return;
    }

    window.Sentry.captureException(error);
  },

  init: function (dsn) {
    if (!dsn) {
      console.error('[Sentry]: Missing DSN for init');
      return;
    }

    if (!shouldUseSentry()) {
      return;
    }

    loadScript(`https://js.sentry-cdn.com/${dsn}.min.js`, 'sentry-script', {
      attributes: {
        crossorigin: 'anonymous',
      },
      callback: () => {
        // sentry script is blocked
        if (!window.Sentry) {
          return;
        }

        window.Sentry.init({
          ignoreErrors: IgnoreErrors,
          release: __VERSION__,
          environment: __DEV_ENV__ ? 'dev' : __STAGING__ ? 'staging' : 'production',
          ...(!__BROWSER_EXTENSION__ && {
            allowUrls: [/canny\.io/i],
          }),
          beforeSend: (event) => {
            // ignore errors with specific messages
            if (event.message && IgnoreErrors.some((error) => event.message.includes(error))) {
              return null;
            }

            // ignore errors from blocklisted URLs
            if (
              event.exception?.values?.some((exception) => {
                return exception.stacktrace?.frames?.some((frame) => {
                  return frame.filename && IgnoreURLs.some((url) => frame.filename.match(url));
                });
              })
            ) {
              return null;
            }

            // Ignore errors with entirely `<anonymous>` stack traces
            if (
              event.exception?.values?.some((ex) =>
                ex.stacktrace?.frames?.every((frame) => frame.filename === '<anonymous>')
              )
            ) {
              return null;
            }

            return event;
          },
        });
        errorQueue.forEach((error) => {
          window.Sentry.captureException(error);
        });
        errorQueue.length = 0;

        if (pendingUser) {
          SentrySDK.setUser(pendingUser);
          pendingUser = null;
        }
      },
    });
  },

  setUser: function (user) {
    if (!shouldUseSentry()) {
      return;
    }

    if (!window.Sentry) {
      pendingUser = user;
      return;
    }

    // Docs for v7 API: https://github.com/getsentry/sentry-javascript/tree/7.120.0/packages/browser
    window.Sentry.configureScope((scope) => {
      scope.setUser(user);
    });
  },
};

export default SentrySDK;
