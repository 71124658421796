// NOTE: Importing images outside of a tsx file throws a build error.
import { type LucideIcon, TerminalSquare } from 'lucide-react';

import { IntegrationItemNames, SyncIntegrationNames } from 'common/constants/autopilotIntegrations';

import AppStoreLogo from 'img/app-store-logo-small.svg';
import CapterraLogo from 'img/capterra-logo-small.svg';
import FreshdeskLogo from 'img/freshdesk-logo-small.svg';
import G2Logo from 'img/g2-logo-small.svg';
import GongLogo from 'img/gong-logo.png';
import GooglePlayLogo from 'img/google-play-logo-small.svg';
import HelpscoutLogo from 'img/helpscout-logo-small.png';
import IntercomLogo from 'img/intercom-logo-small.png';
import ProductHuntLogo from 'img/product-hunt-logo-small.svg';
import SalesforceLogo from 'img/salesforce-text-logo-small.svg';
import ShopifyLogo from 'img/shopify-logo-small.svg';
import TLDVLogo from 'img/tldv-logo-small.svg';
import TrustpilotLogo from 'img/trustpilot-logo-small.svg';
import TrustRadiusLogo from 'img/trustradius-logo-small.svg';
import WordpressLogo from 'img/wordpress-logo-small.svg';
import ZapierLogo from 'img/zapier-logo-square-medium.png';
import ZendeskLogo from 'img/zendesk-logo-small.png';
import ZoomLogo from 'img/zoom-logo-small.png';

type SourceItemValue = {
  label: string;
  itemName: IntegrationItemNames;
} & ({ icon: LucideIcon } | { imgSrc: string });

const SourceMap: Record<SyncIntegrationNames, SourceItemValue> = {
  [SyncIntegrationNames.api]: {
    label: 'API',
    icon: TerminalSquare,
    itemName: IntegrationItemNames.call, // TODO: API item names can be either call or conversation
  },
  [SyncIntegrationNames.appStoreReviews]: {
    label: 'App Store',
    imgSrc: AppStoreLogo,
    itemName: IntegrationItemNames.review,
  },
  [SyncIntegrationNames.capterraReviews]: {
    label: 'Capterra',
    imgSrc: CapterraLogo,
    itemName: IntegrationItemNames.review,
  },
  [SyncIntegrationNames.freshdesk]: {
    label: 'Freshdesk',
    imgSrc: FreshdeskLogo,
    itemName: IntegrationItemNames.ticket,
  },
  [SyncIntegrationNames.gong]: {
    label: 'Gong',
    imgSrc: GongLogo,
    itemName: IntegrationItemNames.call,
  },
  [SyncIntegrationNames.g2Reviews]: {
    label: 'G2',
    imgSrc: G2Logo,
    itemName: IntegrationItemNames.review,
  },
  [SyncIntegrationNames.helpscout]: {
    label: 'Help Scout',
    imgSrc: HelpscoutLogo,
    itemName: IntegrationItemNames.conversation,
  },
  [SyncIntegrationNames.intercom]: {
    label: 'Intercom',
    imgSrc: IntercomLogo,
    itemName: IntegrationItemNames.conversation,
  },
  [SyncIntegrationNames.playStoreReviews]: {
    label: 'Google Play',
    imgSrc: GooglePlayLogo,
    itemName: IntegrationItemNames.review,
  },
  [SyncIntegrationNames.productHuntReviews]: {
    label: 'Product Hunt',
    imgSrc: ProductHuntLogo,
    itemName: IntegrationItemNames.review,
  },
  [SyncIntegrationNames.salesforceReviews]: {
    label: 'Salesforce',
    imgSrc: SalesforceLogo,
    itemName: IntegrationItemNames.review,
  },
  [SyncIntegrationNames.shopifyReviews]: {
    label: 'Shopify',
    imgSrc: ShopifyLogo,
    itemName: IntegrationItemNames.review,
  },
  [SyncIntegrationNames.tldv]: {
    label: 'tl;dv',
    imgSrc: TLDVLogo,
    itemName: IntegrationItemNames.call,
  },
  [SyncIntegrationNames.trustpilotReviews]: {
    label: 'Trustpilot',
    imgSrc: TrustpilotLogo,
    itemName: IntegrationItemNames.review,
  },
  [SyncIntegrationNames.trustradiusReviews]: {
    label: 'TrustRadius',
    imgSrc: TrustRadiusLogo,
    itemName: IntegrationItemNames.review,
  },
  [SyncIntegrationNames.wordpressReviews]: {
    label: 'WordPress',
    imgSrc: WordpressLogo,
    itemName: IntegrationItemNames.review,
  },
  [SyncIntegrationNames.zendesk]: {
    label: 'Zendesk',
    imgSrc: ZendeskLogo,
    itemName: IntegrationItemNames.ticket,
  },
  [SyncIntegrationNames.zapier]: {
    label: 'Zapier',
    imgSrc: ZapierLogo,
    itemName: IntegrationItemNames.call,
  },
  [SyncIntegrationNames.zoom]: {
    label: 'Zoom',
    imgSrc: ZoomLogo,
    itemName: IntegrationItemNames.call,
  },
};

export type { SourceItemValue };
export { SourceMap };
