import isGrowth from 'common/util/isGrowth';
import isStarter from 'common/util/isStarter';

import type { PlanTrial } from 'common/api/endpoints/companies';

const getPlanNameForTrial = (trialingPlan: Pick<PlanTrial, 'planID'>) => {
  const { planID } = trialingPlan;

  if (isStarter(planID)) {
    return 'Starter';
  }

  if (isGrowth(planID)) {
    return 'Growth';
  }

  if (planID === 'business-trial-1') {
    return 'Business';
  }

  return 'Legacy';
};

export default getPlanNameForTrial;
