import AJAX from './AJAX';
import DataQueries from './DataQueries';

const Data = {
  fetch(queries, cookies, callback) {
    if (!callback && typeof cookies === 'function') {
      callback = cookies;
      cookies = {};
    }

    const results = {};
    const numQueries = Object.keys(queries).length;
    let responses = 0;
    let errored = false;

    const promises = [];

    const handleResponse = (qk, qo, responseDataJSON) => {
      if (errored) {
        return;
      }

      let responseData;
      try {
        responseData = JSON.parse(responseDataJSON);
      } catch (e) {
        responseData = { error: 'server error' };
      }

      if (responseData.error) {
        callback(responseData.error, null);
        errored = true;
        return;
      }

      results[qk] = responseData[qo];

      if (++responses === numQueries) {
        callback(null, results);
      }
    };

    // for each query...
    for (const queryKey in queries) {
      const query = queries[queryKey].query;
      const input = queries[queryKey].input || {};

      if (!query) {
        throw new Error('Invalid Query: ' + queryKey);
      }

      // make sure all inputs are specified
      const expectedInput = query.input;
      for (const inputKey in expectedInput) {
        if (expectedInput[inputKey] === 'optional') {
          continue;
        }
        if (input[inputKey] === undefined || input[inputKey] === null) {
          console.log(`Missing input: ${inputKey}, query: ${query.name}`);
          throw new Error('Missing input: ' + inputKey + ', query: ' + query.name);
        }
      }

      // if so, hit the API endpoint
      const data = {};
      for (const cookieName in cookies) {
        data[cookieName] = cookies[cookieName];
      }
      for (const inputKey in expectedInput) {
        if (input[inputKey] === undefined || input[inputKey] === null) {
          continue;
        }
        data[inputKey] = input[inputKey];
      }

      promises.push(
        AJAX.post(query.endpoint, data, handleResponse.bind(null, queryKey, query.output))
      );
    }

    return Promise.all(promises);
  },

  queries: DataQueries,
};

export default Data;
