import { dayjs } from 'common/util/dayjsUtils';
import promisify from 'common/util/promisify';

import { loadCompany } from './company';
import Data from '../Data';

import type { QueueItem } from 'common/api/endpoints/queue';
import type { SyncIntegrationNames } from 'common/constants/autopilotIntegrations';
import type { State } from 'common/reducers/queueItemQueries';
import type { Dispatch, GetState } from 'redux-connect';

// Action Types
export const REMOVE_ITEM = 'canny/queue_item_queries/remove_item';
export const REQUEST_QUERY = 'canny/queue_item_queries/request_query';
export const QUERY_LOADED = 'canny/queue_item_queries/query_loaded';
export const QUERY_ERROR = 'canny/queue_item_queries/query_error';
export const INVALIDATE_AND_RELOAD = 'canny/queue_item_queries/invalidate_and_reload';
export const LOADING_MORE = 'canny/queue_item_queries/loading_more';
export const UPDATE_COUNT = 'canny/queue_item_queries/update_count';

export type PostType = 'duplicate' | 'newPost';

type FullReduxState = {
  queueItemQueries: State;
};

export type QueryParams = {
  boardURLNames?: string[];
  dateRange?: [string, string];
  sort?: 'created' | 'source';
  sortDirection?: 'asc' | 'desc';
  source?: string;
  postTypes?: PostType[];
};

type Result = {
  items: Record<string, QueueItem>;
  count: number;
  postsCount: number;
  draftsCount: number;
  draftsCountPerSource: Partial<Record<SyncIntegrationNames, number>>;
  hasMore: boolean;
};

export type QueueAction = Action & {
  queryParams: QueryParams;
  result: Result;
  item?: QueueItem;
};

// Actions

function requestQuery(queryParams: QueryParams) {
  return {
    queryParams,
    timestamp: Date.now(),
    type: REQUEST_QUERY,
  };
}

function removeItemFromState(item: Pick<QueueItem, '_id'>) {
  return {
    item,
    type: REMOVE_ITEM,
  };
}

function updateCount(result: Result) {
  return {
    result,
    timestamp: Date.now(),
    type: UPDATE_COUNT,
  };
}

function queryLoaded(queryParams: QueryParams, result: Result, reload = false) {
  return {
    queryParams,
    reload,
    result,
    timestamp: Date.now(),
    type: QUERY_LOADED,
  };
}

function queryError(queryParams: QueryParams, error: string) {
  return {
    error,
    queryParams,
    timestamp: Date.now(),
    type: QUERY_ERROR,
  };
}

function invalidatingAndReloading(queryParams: QueryParams) {
  return {
    timestamp: Date.now(),
    queryParams,
    type: INVALIDATE_AND_RELOAD,
  };
}

function loadingMore(queryParams: QueryParams, pages: number) {
  return {
    pages,
    queryParams,
    timestamp: Date.now(),
    type: LOADING_MORE,
  };
}

// Action Creators
function fetchQuery(queryParams: QueryParams, reload = false, additionalInput = {}) {
  return async (dispatch: Dispatch, getState: GetState) => {
    const cookies = getState().cookies;
    try {
      const data = await promisify(
        Data.fetch,
        {
          result: {
            input: { ...queryParams, ...additionalInput },
            query: Data.queries.queueItems,
          },
        },
        cookies
      );
      return await gotResult(dispatch, queryParams, '', data.result, reload);
    } catch (e) {
      const error = typeof e === 'string' ? e : 'server error';
      return gotResult(dispatch, queryParams, error);
    }
  };
}

export function loadCount() {
  return async (dispatch: Dispatch, getState: GetState) => {
    await dispatch(loadCompany());

    const { queueItemQueries, company } = getState();

    if (company.error || company.notFound) {
      return;
    }

    const isOutdated =
      !queueItemQueries.updatedAt ||
      dayjs(queueItemQueries.updatedAt).add(15, 'minute').isBefore(new Date());
    if (!isOutdated) {
      return;
    }

    const cookies = getState().cookies;
    try {
      const data = await promisify(
        Data.fetch,
        {
          result: {
            input: {},
            query: Data.queries.queueItemsCount,
          },
        },
        cookies
      );
      dispatch(updateCount(data.result));
    } catch (e) {
      // do not update count if request fails
    }
  };
}

export function loadQueueItems(queryParams: QueryParams) {
  return (dispatch: Dispatch, getState: GetState) => {
    if (shouldFetchQuery(getState(), queryParams)) {
      dispatch(requestQuery(queryParams));
      return dispatch(fetchQuery(queryParams, false));
    } else if (isFetchingQuery(getState(), queryParams)) {
      return waitForResult(queryParams);
    }
  };
}

export function loadMoreQueueItems(queryParams: QueryParams, pages: number) {
  return (dispatch: Dispatch, getState: GetState) => {
    dispatch(loadingMore(queryParams, pages));
    if (!isFetchingQuery(getState(), queryParams)) {
      return dispatch(fetchQuery(queryParams, false, { pages }));
    }
  };
}

export function invalidateQueriesAndReload(queryParams: QueryParams) {
  return (dispatch: Dispatch) => {
    dispatch(invalidatingAndReloading(queryParams));
    return dispatch(fetchQuery(queryParams, true));
  };
}

export function removeItem(item: Pick<QueueItem, '_id'>) {
  return (dispatch: Dispatch) => {
    return dispatch(removeItemFromState(item));
  };
}

// Helper Functions
export function getQueryKey(queryParams: QueryParams) {
  return JSON.stringify(queryParams);
}

function shouldFetchQuery(state: FullReduxState, queryParams: QueryParams) {
  const { queueItemQueries } = state;
  const queryKey = getQueryKey(queryParams);
  return !queueItemQueries.queries[queryKey];
}

function isFetchingQuery(state: FullReduxState, queryParams: QueryParams) {
  const { queueItemQueries } = state;
  const queryKey = getQueryKey(queryParams);
  return !!queueItemQueries.queries[queryKey].loading;
}

// Callback Queue
const resultCallbacks: Record<string, (() => void)[]> = {};

async function waitForResult(queryParams: QueryParams) {
  const queryKey = getQueryKey(queryParams);
  return new Promise((resolve) => {
    resultCallbacks[queryKey] = resultCallbacks[queryKey] || [];
    resultCallbacks[queryKey].push(() => resolve(true));
  });
}

async function gotResult(
  dispatch: Dispatch,
  queryParams: QueryParams,
  error: string,
  result?: Result,
  reload = false
) {
  const copiedParams = { ...queryParams };
  const resultAction = result
    ? queryLoaded(copiedParams, result, reload)
    : queryError(copiedParams, error);

  await dispatch(resultAction);

  const queryKey = getQueryKey(copiedParams);
  const callbacks = resultCallbacks[queryKey];
  if (!callbacks) {
    return;
  }

  callbacks.forEach((cb) => cb());
  resultCallbacks[queryKey].length = 0;
}
