import React, { useCallback, useContext, useEffect, useState } from 'react';

import classnames from 'classnames';

import { reloadCompany } from 'common/actions/company';
import { reloadPost } from 'common/actions/posts';
import { reloadRoadmapPosts } from 'common/actions/roadmapPosts';
import { reloadRoadmaps, updateVisibilitySettings } from 'common/actions/roadmaps';
import AJAX from 'common/AJAX';
import Error from 'common/common/Error';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import RoadmapPostContainer from 'common/containers/RoadmapPostContainer';
import { LocationContext, ParamsContext, RouterContext } from 'common/containers/RouterContainer';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import Helmet from 'common/helmets/Helmet';
import AccessModal from 'common/modals/AccessModal';
import ConfirmModal from 'common/modals/ConfirmModal';
import ModalPortal from 'common/modals/ModalPortal';
import AdminCreatePostModal from 'common/subdomain/admin/AdminCreatePostModal';
import AdminFeatureUpsell from 'common/subdomain/admin/AdminFeatureUpsell';
import AdminFeedbackPost from 'common/subdomain/admin/AdminFeedbackPost';
import { getQueryFilterParams } from 'common/util/filterPosts';
import findStringMatches from 'common/util/findStringMatches';
import hasPermission from 'common/util/hasPermission';
import { StarterPlanID } from 'common/util/isStarter';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';

import { RoadmapFilterContext } from './AdminRoadmapContext';
import AdminRoadmapHeader from './AdminRoadmapHeader';
import { getGroupByOptions } from './AdminRoadmapHeader/AdminRoadmapGroupBy';
import AdminRoadmapInviteModal from './AdminRoadmapHeader/AdminRoadmapInviteModal';
import AdminRoadmapNUX from './AdminRoadmapNUX';
import AdminRoadmapTable, { Errors } from './AdminRoadmapTable';
import AdminRoadmapViewSettingsModal from './AdminRoadmapViewSettingsModal';
import {
  type ViewDraft,
  findLastActiveFilter,
  getCurrentSavedView,
} from './AdminRoadmapViewSettingsModal/SavedViewUtils';

import type { Board } from 'common/api/endpoints/boards';
import type { Company, RolePermissionName } from 'common/api/endpoints/companies';
import type { RoadmapPost } from 'common/api/endpoints/roadmapPosts';
import type { Roadmap } from 'common/api/endpoints/roadmaps';
import type { CustomPostField } from 'common/api/resources/postFields';
import type { Post } from 'common/api/resources/posts';
import type { RoadmapPostState } from 'common/reducers/roadmapPosts';
import type { Option } from 'common/ui/common/select/SelectCommon';
import type { Dispatch } from 'redux-connect';

import 'css/components/subdomain/admin/AdminRoadmap/_AdminRoadmap.scss';

type Props = {
  boards: Board[];
  customPostFields: CustomPostField[];
  reloadCompany: () => void;
  reloadRoadmaps: () => void;
  // TODO: update when getQueryFilterParams uses TS
  reloadRoadmapPosts: (queryParams: { [key: string]: unknown }) => void;
  roadmap: Roadmap | undefined;
  roadmaps: Roadmap[];
  roadmapPosts: RoadmapPostState;
  roadmapPostsLoading: boolean;
  roadmapsError?: boolean;
  updateVisibilitySettings: (roadmap: Roadmap, hiddenColumnIDs: string[]) => void;
};

const AdminRoadmap = ({
  boards,
  customPostFields,
  reloadCompany,
  reloadRoadmaps,
  reloadRoadmapPosts,
  roadmap,
  roadmaps,
  roadmapPosts,
  roadmapPostsLoading,
  roadmapsError,
  updateVisibilitySettings,
}: Props) => {
  const company = useContext<Company>(CompanyContext);
  const openModal = useContext(OpenModalContext);
  const router = useContext(RouterContext);
  const viewer = useContext(ViewerContext);
  const params = useContext(ParamsContext);
  const location = useContext(LocationContext);
  const showToast = useContext(ShowToastContext);

  // main roadmap state
  const [error, setError] = useState(false);
  const [selectedRoadmap, setSelectedRoadmap] = useState<Roadmap | null>(null);
  const [viewableRoadmapPostsState, setViewableRoadmapPostsState] = useState<
    Omit<RoadmapPostState, 'loading' | 'error' | 'lastUpdated' | 'queryParams'>
  >({
    distinctBoards: [],
    distinctCategories: [],
    distinctOwners: [],
    posts: [],
    statuses: [],
  });

  // settings state
  // header state
  const [groupBy, setGroupBy] = useState<Option | undefined>(
    getGroupByOptions(customPostFields).find(
      (option) => option.value === roadmap?.settings?.groupBy
    ) || undefined
  );
  const [searchValue, setSearchValue] = useState('');
  // modal state
  const lastActiveViewID = roadmap?.settings?.lastActiveFilters?.viewID || undefined;
  const initialView = roadmap?.savedViews.find((savedView) => lastActiveViewID === savedView._id);

  const [showViewSettingsModal, setShowViewSettingsModal] = useState(false);
  const [showRoadmapInviteModal, setShowRoadmapInviteModal] = useState(false);
  const [creatingView, setCreatingView] = useState(false);
  const [viewName, setViewName] = useState('');
  const [viewDraft, setViewDraft] = useState<null | ViewDraft>(
    initialView
      ? {
          filterQuery: initialView.filterQuery,
          hiddenColumnIDs: initialView.hiddenColumnIDs,
        }
      : null
  );
  const [selectedView, setSelectedView] = useState<Option | undefined>(
    getCurrentSavedView(initialView) || undefined
  );
  const [updatingView, setUpdatingView] = useState(false);

  const selectDefaultRoadmap = useCallback(() => {
    const lastRoadmapID = company.viewerPreferences?.lastViewedRoadmap;
    const visibleRoadmaps = roadmaps.filter((roadmap) => !roadmap.archived);

    if (lastRoadmapID) {
      const roadmap = visibleRoadmaps.find((roadmap) => {
        return roadmap._id === lastRoadmapID;
      });

      if (roadmap) {
        const lastViewedFilter = findLastActiveFilter(roadmap.settings, roadmap.savedViews);

        router.replace(`/admin/roadmap/${roadmap.urlName}${lastViewedFilter ?? ''}`);
        return;
      }
    }

    const [{ urlName }] = visibleRoadmaps;
    router.replace(`/admin/roadmap/${urlName}`);
  }, [company.viewerPreferences?.lastViewedRoadmap, roadmaps, router]);

  const updateLastViewedRoadmap = useCallback(
    async (roadmap: Roadmap) => {
      const lastRoadmapID = company.viewerPreferences?.lastViewedRoadmap;
      if (lastRoadmapID && lastRoadmapID === roadmap._id) {
        return;
      }

      await AJAX.post('/api/viewer/updatePreferences', {
        preferences: {
          lastViewedRoadmap: roadmap._id,
        },
      });

      reloadCompany();
    },
    [company.viewerPreferences?.lastViewedRoadmap, reloadCompany]
  );

  const getSelectedPost = useCallback(() => {
    if (params.postURLName) {
      const board = params.roadmapPostBoardURLName
        ? boards.find((board) => board.urlName === params.roadmapPostBoardURLName)
        : null;

      return roadmapPosts?.posts?.find(
        (roadmapPost: RoadmapPost) =>
          roadmapPost.post.urlName === params.postURLName &&
          (!board || roadmapPost.post.board.urlName === board?.urlName)
      )?.post;
    }

    return;
  }, [params, roadmapPosts?.posts, boards]);

  const removeInvalidPostUrl = useCallback(() => {
    // If the post exists in the URL, but not in the roadmap list, remove it from the URL
    if (params.postURLName && !getSelectedPost() && roadmap) {
      router.push({
        pathname: `/admin/roadmap/${roadmap.urlName}`,
        query: location.query,
      });
    }
  }, [params, roadmap, getSelectedPost, router, location.query]);

  // If the list of roadmap posts changes, show everything
  useEffect(() => {
    setViewableRoadmapPostsState(roadmapPosts);
  }, [roadmapPosts]);

  // Keep the URL in sync with the roadmap
  useEffect(() => {
    if (!roadmap && roadmaps.length) {
      selectDefaultRoadmap();
      return;
    } else if (roadmap) {
      updateLastViewedRoadmap(roadmap);
    }
    removeInvalidPostUrl();

    roadmap && setSelectedRoadmap(roadmap);
    setGroupBy(
      getGroupByOptions(customPostFields).find(
        (option) => option.value === roadmap?.settings?.groupBy
      ) || undefined
    );

    const lastActiveViewID = roadmap?.settings?.lastActiveFilters?.viewID || undefined;
    const view = roadmap?.savedViews.find((savedView) => lastActiveViewID === savedView._id);

    setSelectedView(getCurrentSavedView(view) || undefined);
  }, [
    roadmap,
    roadmaps.length,
    selectDefaultRoadmap,
    updateLastViewedRoadmap,
    removeInvalidPostUrl,
    customPostFields,
  ]);

  const visibleRoadmaps = roadmaps.filter((roadmap) => !roadmap.archived);

  const createRoadmapPost = async (post: RoadmapPost) => {
    const permission: RolePermissionName = 'manageRoadmap';
    if (!roadmap) {
      return;
    }
    if (!hasPermission(permission, company, viewer)) {
      openModal(AccessModal, {
        requiredPermissions: [permission],
      });
      return;
    }

    setError(false);

    const response = await AJAX.post('/api/roadmaps/posts/add', {
      postID: post._id,
      roadmapID: roadmap?._id,
    });
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (error) {
      setError(true);
      return;
    }

    const board = boards.find((board) => board.urlName === params.boardURLName);
    const queryParams = getQueryFilterParams(board, company, location, {}, roadmap);

    await Promise.all([reloadPost(post), reloadRoadmapPosts(queryParams)]);

    if (searchValue) {
      // Clear the active search
      setViewableRoadmapPostsState(roadmapPosts);
      setSearchValue('');
    }
  };

  const createPost = () => {
    openModal(AdminCreatePostModal, {
      boards,
      onPostCreated: createRoadmapPost,
      allowCreateAnother: true,
    });
  };

  const deleteRoadmapPost = (roadmapPost: RoadmapPost) => {
    const { post } = roadmapPost;

    if (!roadmap) {
      return;
    }

    const onConfirm = async () => {
      await AJAX.post('/api/roadmaps/posts/remove', {
        roadmapPostID: roadmapPost._id,
      });

      const board = boards.find((board) => board.urlName === params.boardURLName);
      const queryParams = getQueryFilterParams(board, company, location, {}, roadmap);

      await Promise.all([
        reloadPost({
          ...post,
          board: boards.find((board) => board._id === post.boardID),
        }),
        reloadRoadmapPosts(queryParams),
      ]);

      if (roadmapPost.post.urlName === params.postURLName) {
        onClosePost();
      }
    };

    openModal(ConfirmModal, {
      message: `Are you sure you'd like to remove this post from your roadmap?`,
      onConfirm,
    });
  };

  const filterRoadmapPosts = (searchValue: string, roadmapPosts: RoadmapPost[]) => {
    if (!searchValue) {
      return roadmapPosts;
    }

    const getTitle = (roadmapPost: RoadmapPost) => roadmapPost.post.title;
    return findStringMatches(roadmapPosts, getTitle, searchValue);
  };

  const onClosePost = () => {
    if (!roadmap) {
      return;
    }

    router.push({
      pathname: `/admin/roadmap/${roadmap.urlName}`,
      query: location.query,
    });
  };

  const onEditedPost = () => {
    const board = boards.find((board) => board.urlName === params.boardURLName);
    const queryParams = getQueryFilterParams(board, company, location, {}, roadmap);

    roadmap && reloadRoadmapPosts(queryParams);
  };

  const onOpenPost = useCallback(
    (selectedPost: Post) => {
      const currentPost = getSelectedPost();

      if (currentPost?._id === selectedPost._id || !roadmap) {
        return;
      }

      const board = boards.find((board) => board._id === selectedPost.boardID);
      if (!board) {
        return;
      }

      router.push({
        pathname: `/admin/roadmap/${roadmap.urlName}/${board.urlName}/p/${selectedPost.urlName}`,
        query: location.query,
      });
    },
    [location.query, getSelectedPost, roadmap, router, boards]
  );

  const searchRoadmapPosts = (searchValue: string) => {
    const filteredPosts = filterRoadmapPosts(searchValue, roadmapPosts.posts);
    setViewableRoadmapPostsState({ ...viewableRoadmapPostsState, posts: filteredPosts });
    setSearchValue(searchValue);
  };

  const getError = () => {
    if (error) {
      return Errors.Creating;
    }

    return;
  };

  const updateServerSettings = useCallback(
    async (
      hiddenColumnIDs: string[],
      updatedGroupBy: string | undefined,
      selectedView: string | null,
      columnWidths: Record<string, number>
    ) => {
      if (!roadmap) {
        return;
      }

      // Update local settings first
      const roadmapWithUpdatedSettings = {
        ...roadmap,
        settings: {
          ...roadmap.settings,
          groupBy: updatedGroupBy || 'None',
          lastActiveFilters: {
            ...roadmap?.settings?.lastActiveFilters,
            filterQuery: location.search,
            viewID: selectedView,
          },
        },
      };

      await updateVisibilitySettings(roadmapWithUpdatedSettings, hiddenColumnIDs);

      // Update server settings
      const response = await AJAX.post('/api/roadmaps/users/updateSettings', {
        hiddenColumnIDs,
        columnWidths,
        groupBy: updatedGroupBy,
        roadmapID: roadmap._id,
        filterQuery: location.search,
        viewID: selectedView,
      });

      const { error } = parseAPIResponse(response, {
        errors: {
          default: 'View preferences could not be saved. Please refresh and try again.',
        },
        isSuccessful: isDefaultSuccessResponse,
      });

      if (error) {
        showToast(error.message, ToastTypes.error);
        return;
      }

      await reloadRoadmaps();
    },
    [location.search, roadmap, showToast, updateVisibilitySettings, reloadRoadmaps]
  );

  const createNewView = async (viewName: string) => {
    if (selectedRoadmap && viewDraft) {
      setUpdatingView(true);
      const response = await AJAX.post(
        '/api/roadmaps/users/createRoadmapSavedSettingsViewEndpoint',
        {
          filterQuery: viewDraft.filterQuery,
          hiddenColumnIDs: viewDraft.hiddenColumnIDs,
          name: viewName,
          roadmapID: selectedRoadmap._id,
        }
      );

      const { error, parsedResponse } = parseAPIResponse(response, {
        errors: {
          default: 'Your view could not be saved. Please refresh and try again.',
        },
        isSuccessful: (response: { viewID: string }) => !!response?.viewID,
      });

      if (error) {
        showToast(error.message, ToastTypes.error);
        setUpdatingView(false);
        return;
      }

      await updateServerSettings(
        roadmap?.settings?.hiddenColumnIDs || [],
        roadmap?.settings.groupBy,
        parsedResponse?.viewID || null,
        roadmap?.settings?.columnWidths || {}
      );

      // Reset so the value is not populated next time user creates a view
      setViewName('');
      setCreatingView(false);
      setUpdatingView(false);
    }
  };

  const updateViewDetails = async () => {
    if (selectedRoadmap && viewDraft && selectedView) {
      setUpdatingView(true);
      const response = await AJAX.post('/api/roadmaps/users/updateRoadmapSavedSettingsView', {
        filterID: selectedView.value,
        filterQuery: viewDraft.filterQuery,
        hiddenColumnIDs: viewDraft.hiddenColumnIDs,
        name: selectedView.label,
      });

      const { error } = parseAPIResponse(response, {
        errors: {
          default: 'Your view could not be updated. Please refresh and try again.',
        },
        isSuccessful: isDefaultSuccessResponse,
      });

      if (error) {
        showToast(error.message, ToastTypes.error);
        return;
      }

      await reloadRoadmaps();
    }
  };

  useEffect(() => {
    // Update UI if the filters have changed while creating or editing a view
    if (viewDraft && location.search !== viewDraft.filterQuery) {
      setViewDraft({
        ...viewDraft,
        filterQuery: location.search,
      });
      // Update server settings if any filters have been adjusted in main settings
    } else if (
      roadmap &&
      !selectedView &&
      location.search !== roadmap?.settings?.lastActiveFilters?.filterQuery
    ) {
      updateServerSettings(
        roadmap?.settings?.hiddenColumnIDs,
        roadmap?.settings?.groupBy,
        null,
        roadmap?.settings?.columnWidths || {}
      );
    }
  }, [location.search, updateServerSettings, roadmap, creatingView, viewDraft, selectedView]);

  const getHiddenColumnIDs = () => {
    const viewDraftHiddenColumns = viewDraft?.hiddenColumnIDs ?? [];
    const defaultHiddenColumnIDs = roadmap?.settings?.hiddenColumnIDs ?? [];

    const currentSelectedView = roadmap?.savedViews.find(
      (savedView) => savedView._id === selectedView?.value
    );

    if (!currentSelectedView && !creatingView) {
      return defaultHiddenColumnIDs;
    }

    return viewDraftHiddenColumns;
  };

  if (!company.features.prioritizationRoadmap) {
    return (
      <div className="adminRoadmap adminRoadmapUpsell">
        <AdminFeatureUpsell
          cta="Score and rank features to understand priority"
          feature="prioritizationRoadmap"
          planID={StarterPlanID}
        />
      </div>
    );
  }

  return (
    <div className="adminRoadmap">
      <Helmet title={`${selectedRoadmap ? `${selectedRoadmap.name} | ` : ''}Roadmap | Canny`} />
      {!roadmapsError ? (
        <RoadmapFilterContext.Provider value={{ setViewDraft }}>
          <AdminRoadmapNUX />
          <AdminRoadmapHeader
            boards={boards}
            customPostFields={customPostFields}
            groupBy={groupBy}
            loading={roadmapPostsLoading}
            modalOpen={showViewSettingsModal ? 'view' : null}
            onCreatePost={createPost}
            onToggleViewSettings={() => {
              if (!showViewSettingsModal && getSelectedPost()) {
                onClosePost();
              }
              setShowViewSettingsModal(!showViewSettingsModal);
            }}
            onGroupBy={setGroupBy}
            onSearch={searchRoadmapPosts}
            roadmap={selectedRoadmap}
            roadmaps={visibleRoadmaps}
            searchValue={searchValue}
            updateServerSettings={updateServerSettings}
          />
          <div className="tableContainer">
            <AdminRoadmapTable
              boards={boards}
              customPostFields={customPostFields}
              distinctBoards={viewableRoadmapPostsState?.distinctBoards}
              distinctCategories={viewableRoadmapPostsState?.distinctCategories}
              distinctOwners={viewableRoadmapPostsState?.distinctOwners}
              hiddenColumnIDs={getHiddenColumnIDs()}
              statuses={viewableRoadmapPostsState?.statuses}
              createRoadmapPost={createRoadmapPost}
              deleteRoadmapPost={deleteRoadmapPost}
              error={getError()}
              groupBy={groupBy?.value}
              loading={false}
              onOpenPost={onOpenPost}
              roadmap={selectedRoadmap}
              roadmapPosts={viewableRoadmapPostsState?.posts ?? []}
              selectedPost={getSelectedPost()}
              onClosePost={onClosePost}
              updateServerSettings={updateServerSettings}
            />
          </div>
          {selectedRoadmap ? (
            <AdminRoadmapViewSettingsModal
              boards={boards}
              creatingView={creatingView}
              createNewView={createNewView}
              distinctBoards={viewableRoadmapPostsState?.distinctBoards}
              groupBy={groupBy?.value}
              hiddenColumnIDs={getHiddenColumnIDs()}
              isVisible={showViewSettingsModal}
              roadmap={selectedRoadmap}
              setCreatingView={setCreatingView}
              setViewDraft={setViewDraft}
              setViewName={setViewName}
              onClose={() => setShowViewSettingsModal(false)}
              updateServerSettings={updateServerSettings}
              updateViewDetails={updateViewDetails}
              updatingView={updatingView}
              viewDraft={viewDraft}
              viewName={viewName}
              selectedView={selectedView}
              setSelectedView={setSelectedView}
              setUpdatingView={setUpdatingView}
              showShareModal={() => setShowRoadmapInviteModal(true)}
            />
          ) : null}
          {showRoadmapInviteModal ? (
            <AdminRoadmapInviteModal
              roadmap={selectedRoadmap}
              viewID={selectedRoadmap?.settings?.lastActiveFilters?.viewID}
              selectedView={selectedView}
              reloadRoadmaps={reloadRoadmaps}
              onClose={() => setShowRoadmapInviteModal(false)}
            />
          ) : null}
          <ModalPortal
            allowBodyScroll={true}
            className={classnames('adminRoadmapPortal', 'postModal', {
              v2: company?.featureAllowlist?.includes('idea-database'),
              modalVisible: !!getSelectedPost(),
              nuxVisible: !company.nux?.prioritizationOverview,
            })}
            closeOnClickAway={false}
            onClose={onClosePost}>
            <div className="modalContents">
              <RoadmapPostContainer boards={boards} post={getSelectedPost()}>
                <AdminFeedbackPost
                  key={getSelectedPost()?._id}
                  customPostFields={customPostFields}
                  onClose={onClosePost}
                  onDelete={onClosePost}
                  onEdited={onEditedPost}
                  roadmaps={roadmaps}
                  skipFixURL={true}
                />
              </RoadmapPostContainer>
            </div>
          </ModalPortal>
        </RoadmapFilterContext.Provider>
      ) : (
        <div className="center">
          <Error />
        </div>
      )}
    </div>
  );
};

export default connect(null, (dispatch: Dispatch) => ({
  reloadCompany: () => dispatch(reloadCompany()),
  reloadPost: (post: RoadmapPost) => {
    return dispatch(reloadPost(post));
  },
  reloadRoadmapPosts: (queryParams: { [key: string]: unknown }) => {
    return dispatch(reloadRoadmapPosts(queryParams));
  },
  reloadRoadmaps: () => {
    return dispatch(reloadRoadmaps());
  },
  updateVisibilitySettings: (roadmap: Roadmap, hiddenColumnIDs: string[]) => {
    return dispatch(updateVisibilitySettings(roadmap, hiddenColumnIDs));
  },
}))(AdminRoadmap);
