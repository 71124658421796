import {
  ActionType,
  type AllActions,
  type AllFilters,
  Resource,
} from 'common/automations/constants';
import { AlphanumericConditions } from 'common/automations/postFieldFilterUtils';
import createCustomError from 'common/util/createCustomError';
import stringSort from 'common/util/stringSort';

import type { Option } from 'common/ui/common/select/SelectCommon';

const CreateActionError = createCustomError('CreateActionError');

// When a new action is created, one is selected by default. For all the inputs, we should populate
// the values
export const createDefaultAction = (actionToCreate: ActionType): AllActions => {
  switch (actionToCreate) {
    case ActionType.addPostCategory:
      return {
        type: ActionType.addPostCategory,
        properties: {
          categoryID: '',
        },
      };
    case ActionType.addPostTags:
      return {
        type: ActionType.addPostTags,
        properties: {
          tagIDs: [],
        },
      };
    case ActionType.applyPostStatus:
      return {
        type: ActionType.applyPostStatus,
        properties: {
          statusID: '',
        },
      };
    case ActionType.addToRoadmap:
      return {
        type: ActionType.addToRoadmap,
        properties: {
          roadmapID: '',
        },
      };
    default:
      throw new CreateActionError({
        message: 'Cannot create action, unsupported resource type',
        action: actionToCreate,
      });
  }
};

// When a new filter is created, one is selected by default. For all the inputs, we should populate
// the values
export const createDefaultFilter = (resourceToCreate: Resource): AllFilters => {
  switch (resourceToCreate) {
    case Resource.postBoardURLName:
      return {
        resource: Resource.postBoardURLName,
        condition: 'equals',
        value: '',
      };
    case Resource.postCategory:
      return {
        resource: Resource.postCategory,
        condition: 'equals',
        value: '',
      };
    case Resource.postContent:
      return {
        resource: Resource.postContent,
        condition: 'containsAll',
        value: [],
      };
    case Resource.postCustomField:
      return {
        resource: Resource.postCustomField,
        condition: AlphanumericConditions.contains,
        value: {
          fieldID: '',
          value: '',
        },
      };
    default:
      throw new CreateActionError({
        message: 'Cannot create filter, unsupported resource type',
        filter: resourceToCreate,
      });
  }
};

export const findActiveOption = (options: Option[], value: string) => {
  return options.find((option) => option.value === value);
};

export const actionLabels = {
  [ActionType.addPostCategory]: 'Apply category',
  [ActionType.addPostTags]: 'Apply tag(s)',
  [ActionType.applyPostStatus]: 'Apply status',
  [ActionType.addToRoadmap]: 'Add to roadmap',
};

export const filterLabels = {
  [Resource.postBoardURLName]: 'Board',
  [Resource.postCategory]: 'Category',
  [Resource.postContent]: 'Post content',
  [Resource.postCustomField]: 'Custom field',
};

export const createOptions = <K extends ActionType | Resource>(
  keys: K[],
  map: Record<K, string>
) => {
  return keys.map((key) => ({ label: map[key], value: key }));
};

export const getFirstAction = (allowedActions: ActionType[]) => {
  if (allowedActions.length === 0) {
    throw new Error('Cannot create action, no choices exist');
  }
  return createOptions(allowedActions, actionLabels).sort(stringSort('label', 'asc', true))[0]
    .value;
};

export const getFirstFilter = (allowedFilters: Resource[]) => {
  if (allowedFilters.length === 0) {
    throw new Error('Cannot create action, no choices exist');
  }
  return createOptions(allowedFilters, filterLabels).sort(stringSort('label', 'asc', true))[0]
    .value;
};
