import {
  ErrorType,
  InvalidateType,
  LoadedMoreType,
  LoadedType,
  type QueryParams,
  RequestMoreType,
  RequestType,
} from 'common/actions/ideaImpact';

import queryReducerFactory, { type QueriesState, type QueryState } from './queryReducerFactory';
export { defaultState } from './queryReducerFactory';

import type { IdeaImpact } from 'common/api/endpoints/idea';

export type IdeaImpactQueriesState = QueriesState<IdeaImpact, QueryParams>;
export type IdeaImpactQueryState = QueryState<IdeaImpact, QueryParams>;

export default queryReducerFactory<IdeaImpact, QueryParams>({
  errorType: ErrorType,
  invalidateType: InvalidateType,
  loadedMoreType: LoadedMoreType,
  loadedType: LoadedType,
  requestMoreType: RequestMoreType,
  requestType: RequestType,
  uniqueKey: 'name',
});
