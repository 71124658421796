import React from 'react';

import classnames from 'classnames';

import TextInput from 'common/inputs/TextInput';
import KeyCodes from 'common/KeyCodes';
import Text from 'common/ui/Text';

import 'css/components/subdomain/public/PublicNav/_GlobalSearch.scss';

const GlobalSearchId = 'GlobalSearchId';
const GlobalSearch = ({ className, focused, onBlur, onChange, onFocus, value }) => {
  const clearSearch = () => {
    onChange('');
  };

  const onKeyDown = (e) => {
    if (e.which === KeyCodes.Escape) {
      e.currentTarget.blur();
      clearSearch();
    }
  };

  const isPopulated = value !== '';

  return (
    <div
      className={classnames('globalSearch', className, {
        isFocused: focused,
        populated: isPopulated,
      })}>
      <TextInput
        id={GlobalSearchId}
        className={classnames('globalSearchInput', { populated: isPopulated })}
        inset={
          <>
            <div className="icon icon-search" />
            <Text as="label" fontWeight="medium" variant="bodyMd" htmlFor={GlobalSearchId}>
              Search
            </Text>
          </>
        }
        placeholder=""
        onBlur={onBlur}
        onChange={(e) => onChange(e.currentTarget.value)}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        value={value}
      />
      {focused || isPopulated ? (
        <button className="x-button" onClick={clearSearch}>
          <div className={classnames('icon', 'icon-x')} />
        </button>
      ) : null}
    </div>
  );
};

export default GlobalSearch;
