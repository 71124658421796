import React from 'react';

import classNames from 'classnames';
import { useMultipleSelection, useSelect } from 'downshift';

import Label from 'common/ui/common/Label';
import LabelWrapper from 'common/ui/common/select/LabelWrapper';
import RightIcons from 'common/ui/common/select/RightIcons';
import SelectedItems from 'common/ui/common/select/SelectedItems';
import SelectOptions from 'common/ui/common/select/SelectOptions';
import SupportingTexts from 'common/ui/common/select/SupportingTexts';
import { getSelectedOptionsOnChange, sortByGroupBy } from 'common/ui/common/select/util';

import type { CommonSelectProps, Option } from 'common/ui/common/select/SelectCommon';

import 'css/components/_SelectCommon.scss';

export interface Props extends CommonSelectProps {
  onChange: (option: Option[]) => void;
  value: Option[];
  hideSelection?: boolean;
}

const MultipleSelect = ({
  allowClear,
  className,
  cta,
  label,
  loading,
  disabled,
  error,
  onChange,
  options,
  optionsPlacement = 'bottom',
  placeholder,
  prefixIcon: PrefixIcon,
  labelPlacement = 'top',
  required = false,
  supportingText,
  value = [],
  hideSelection = false,
  withBorder = true,
  optionsMaxWidth,
}: Props) => {
  const { getSelectedItemProps, getDropdownProps } = useMultipleSelection<Option>({
    selectedItems: value || [],
  });

  const sortedOptions = sortByGroupBy(options);

  const {
    closeMenu,
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect<Option>({
    items: cta ? [...sortedOptions, cta.option] : sortedOptions,
    itemToString: (item) => (item ? item.label : ''),
    stateReducer(state, actionAndChanges) {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useSelect.stateChangeTypes.MenuMouseLeave:
          return { ...changes, selectedItem: null };
        case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
        case useSelect.stateChangeTypes.ItemClick:
          if (changes.selectedItem) {
            if (changes.selectedItem.value === cta?.option.value) {
              cta.onAction();
              return changes;
            }
            onChange(getSelectedOptionsOnChange({ selectedOption: changes.selectedItem, value }));
            return {
              ...changes,
              isOpen: true,
              highlightedIndex: state.highlightedIndex,
            };
          }
          return changes;
        default:
          return changes;
      }
    },
  });

  const renderSelectedItems = () => {
    // if no items are selected, render placeholder
    if (!value?.length) {
      return <span className="dropdownPlaceholder">{placeholder}</span>;
    }

    // when selection is hidden, just render the number of items selected
    if (hideSelection) {
      return (
        <span className="dropdownPlaceholder">
          {value.length} option{value.length > 1 && 's'} selected
        </span>
      );
    }

    // otherwise, render the list of selected items.
    return (
      <SelectedItems
        getSelectedItemProps={getSelectedItemProps}
        disabled={disabled}
        value={value}
        onRemove={(removedOption) =>
          onChange(value.filter((option) => option.value !== removedOption.value))
        }
      />
    );
  };

  return (
    <div className="dropdownV2Wrapper">
      <LabelWrapper labelPlacement={labelPlacement}>
        {label && <Label {...getLabelProps()} label={label} />}
        <div
          className={classNames('dropdownV2 multipleSelect', className, {
            disabledV2: disabled,
            errorV2: error,
            withBorder,
          })}>
          <div className="dropdownWrapperV2">
            <div
              className="dropdownHeadWrapper"
              aria-required={required}
              aria-label="toggle menu"
              {...getToggleButtonProps(getDropdownProps({ preventKeyAction: isOpen, disabled }))}>
              <div className="leftWrapper">
                {PrefixIcon && <PrefixIcon className="prefixIcon" size={14} />}
                {renderSelectedItems()}
              </div>
              <RightIcons
                aria-label="remove all options"
                disabled={disabled}
                loading={loading}
                onClear={() => {
                  onChange([]);
                  closeMenu();
                }}
                showClear={allowClear && !!value?.length}
                error={error}
              />
            </div>
            <SelectOptions
              highlightedIndex={highlightedIndex}
              getItemProps={getItemProps}
              options={sortedOptions}
              ctaOption={cta?.option}
              optionsMaxWidth={optionsMaxWidth}
              selectedItems={value}
              isOpen={isOpen}
              disabled={disabled}
              optionsPlacement={optionsPlacement}
              getMenuProps={getMenuProps}
            />
          </div>
          <SupportingTexts supportingText={supportingText} error={!disabled ? error : undefined} />
        </div>
      </LabelWrapper>
    </div>
  );
};

export default MultipleSelect;
