import AJAX from './AJAX';
import DataQueries, { type DataQuery } from './DataQueries';
export type { DataQuery } from './DataQueries';

const DataV2 = {
  async fetch(query: DataQuery, data: Record<string, unknown>, cookies: Record<string, unknown>) {
    // calculate request data
    const requestData = {
      ...data,
      ...cookies,
    };

    // validate request data against expected input
    const expectedInput = query.input;
    for (const inputKey in expectedInput) {
      if (expectedInput[inputKey] === 'optional') {
        continue;
      }

      if (requestData[inputKey] === undefined || requestData[inputKey] === null) {
        throw new Error(`Missing input: ${inputKey}, query: ${query.name}`);
      }
    }

    // send request
    const responseJSON = await AJAX.post(query.endpoint, requestData);
    const responseData = JSON.parse(responseJSON);

    // handle errors
    if (responseData.error) {
      throw new Error(responseData.error);
    }

    // return response data
    return responseData;
  },

  queries: DataQueries,
};

export default DataV2;
