import React from 'react';

import Link from 'common/Link';
import styles from 'css-module/components/subdomain/admin/v2/_AdminV2Nav.module.scss';

import AdminV2NavTooltip from './AdminV2NavTooltip';

import type { LucideIcon } from 'lucide-react';

type Props = {
  Icon: LucideIcon;
  label: string;
  to: string;
  active?: boolean;
};

const AdminV2NavIconLink = ({ Icon, label, to, active = false }: Props) => {
  return (
    <Link
      aria-label={label}
      data-active={active ? '' : undefined}
      className={styles.iconLink}
      to={to}>
      <Icon className={styles.icon} strokeWidth={1.5} />
      <AdminV2NavTooltip label={label} className={styles.tooltip} />
    </Link>
  );
};

export default AdminV2NavIconLink;
