import React, { useContext, useState } from 'react';

import { reloadBoard } from 'common/actions/boards';
import { invalidateDashboardActivity } from 'common/actions/dashboardActivity';
import { invalidatePostQueries } from 'common/actions/postQueries';
import { invalidatePosts } from 'common/actions/posts';
import { invalidateSuggestions } from 'common/actions/postSuggestions';
import { invalidateRoadmap } from 'common/actions/roadmap';
import { removePostFromAllRoadmaps } from 'common/actions/roadmapPosts';
import AJAX from 'common/AJAX';
import { BoardsContext } from 'common/containers/BoardsContainer';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import connect from 'common/core/connect';
import ModernConfirmModal from 'common/modals/ModernConfirmModal';
import { P } from 'common/ui/Text';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';

import { ModalToastYOffset } from './constants';

import type { CommonModalProps } from './index';
import type { Board } from 'common/api/resources/board';
import type { Post } from 'common/api/resources/posts';
import type { Dispatch } from 'redux-connect';

type OwnProps = CommonModalProps;

type ConnectProps = {
  deletePostData: (posts: Post[], boards: Board[]) => Promise<void>;
};

type Props = OwnProps & ConnectProps;

const DeletePostsConfirmationModal = ({ deletePostData, onClose, selectedPosts }: Props) => {
  const boards = useContext(BoardsContext);
  const showToast = useContext(ShowToastContext);
  const [loading, setLoading] = useState(false);

  // asserts boards are loaded
  if ('loading' in boards) {
    return null;
  }

  const performDelete = async () => {
    setLoading(true);
    const responseJSON = await AJAX.post('/api/posts/bulkEdit', {
      operationType: 'delete',
      postIDs: selectedPosts.map((post) => post._id),
    });
    const { error } = parseAPIResponse(responseJSON, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (error) {
      setLoading(false);
      console.error(error);
      showToast('Something went wrong, please try again.', ToastTypes.error, {
        yOffset: ModalToastYOffset,
      });
      return;
    } else {
      await purgeDeletedPostsFromCache();
      setLoading(false);
      showToast(
        `Successfully deleted ${selectedPosts.length} ${
          selectedPosts.length > 1 ? 'posts' : 'post'
        }`,
        ToastTypes.success
      );
      onClose(true);
    }
  };

  const purgeDeletedPostsFromCache = async () => {
    const selectedBoardIDs = selectedPosts.map((post) => post.boardID);
    const selectedBoards = boards.filter((board) => selectedBoardIDs.includes(board._id));
    await deletePostData(selectedPosts, selectedBoards);
  };

  const postPlural = selectedPosts.length > 1 ? 'posts' : 'post';

  return (
    <ModernConfirmModal
      onClose={onClose}
      onConfirm={performDelete}
      confirmText={`Delete ${postPlural}`}
      type="destructive"
      header={`Are you sure you want to delete ${
        selectedPosts.length > 1 ? 'these' : 'this'
      } ${postPlural}?`}
      loading={loading}>
      <P>
        {selectedPosts.length > 1 ? 'These' : 'This'} {postPlural} will no longer be visible to you
        or other users in your workspace once deleted. This action cannot be&nbsp;reversed.
      </P>
    </ModernConfirmModal>
  );
};

// TODO: remove cast once `connect` is typed
export default connect(null, (dispatch: Dispatch) => ({
  deletePostData: (posts: Post[], boards: Board[]) => {
    return Promise.all([
      dispatch(invalidateDashboardActivity()),
      dispatch(invalidatePostQueries()),
      dispatch(invalidatePosts(posts)),
      dispatch(invalidateRoadmap()),
      dispatch(invalidateSuggestions()),
      ...boards.map((board) => dispatch(reloadBoard(board.urlName))),
      ...posts.map((post) => dispatch(removePostFromAllRoadmaps(post))),
    ]);
  },
}))(DeletePostsConfirmationModal) as unknown as React.FC<OwnProps>;
