export interface Features {
  adminRoles: boolean;
  advancedAnalytics: boolean;
  autoResponse: boolean;
  categories: boolean;
  changelogEmailSubscriptions: boolean;
  commentSearch: boolean;
  customAccess: boolean;
  customDomain: boolean;
  customEmailDomain: boolean;
  customPostFields: boolean;
  customRoles: boolean;
  customStatuses: boolean;
  deduplication: boolean;
  disableUserSubmissions: boolean;
  exportComments: boolean;
  fileAttachments: boolean;
  internalComments: boolean;
  knowledgeHub: boolean;
  liveSupport: boolean;
  moderationTools: boolean; // All plans have moderation tools
  postAutomation: boolean;
  postETAs: boolean;
  postOwners: boolean;
  prioritizationRoadmap: boolean;
  privateAuthors: boolean;
  privateBoards: boolean;
  privateChangelog: boolean;
  privateComments: boolean;
  privateVotes: boolean;
  removeBranding: boolean;
  reviewSources: boolean;
  summarizeComments: boolean;
  supportExtraction: boolean;
  shareRoadmaps: boolean;
  tags: boolean;
  translateContent: boolean;
  unlistedBoards: boolean;
  userProfiles: boolean;
  userSegmentation: boolean;
  voteWeights: boolean;
}

export type Feature = keyof Features;

export interface Integrations {
  api: boolean;
  asana: boolean;
  azureAD: boolean;
  azureDevops: boolean;
  browserExtension: boolean;
  clickup: boolean;
  discord: boolean;
  freshdesk: boolean;
  gong: boolean;
  gsuite: boolean;
  helpscout: boolean;
  hubspot: boolean;
  intercom: boolean;
  jira: boolean;
  linear: boolean;
  microsoftTeams: boolean;
  oidc: boolean;
  okta: boolean;
  oneLogin: boolean;
  salesforce: boolean;
  segment: boolean;
  slack: boolean;
  tldv: boolean;
  webhooks: boolean;
  zapier: boolean;
  zendesk: boolean;
  zoom: boolean;
}

export type Integration = keyof Integrations;

export type BasicLimits = {
  activePosts: number | null;
  admins: number | null;
  analyticsHistory: number | null;
  boardFields: number | null;
  boards: number | null;
  integrations: number | null;
  prioritizationRoadmaps: number | null;
  users: number | null;
};

export interface Limits extends BasicLimits {
  aiCredits: number | null;
}

export interface Sliding {
  increment: number;
  planID: string;
  price: number;
}

export interface Slidings {
  admins?: Sliding;
  users?: Sliding;
}

export const MaxAdditionalCreditLimit = 25000;
export const MaxCreditsPerPurchase = 5000;
