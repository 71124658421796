import React, { type ChangeEvent, useContext } from 'react';

import { Sparkles } from 'lucide-react';

import TextToggle from 'common/common/TextToggle';
import { BoardAccess } from 'common/constants/boards';
import { BoardsContext } from 'common/containers/BoardsContainer';
import { CompanyContext } from 'common/containers/CompanyContainer';
import TextInput from 'common/inputs/TextInput';
import URLNameInput from 'common/inputs/URLNameInput';
import SwitchV2 from 'common/ui/SwitchV2';
import { H5, P, Span } from 'common/ui/Text';
import getPlanIDFromUseCase from 'common/util/getPlanIDFromUseCase';
import getPlanNameForTrial from 'common/util/getPlanNameForTrial';
import urlNamify from 'common/util/urlNamify';

import AdminOnboardingHeader, {
  AdminOnboardingHeaderTwo,
  AdminOnboardingSubHeader,
} from './AdminOnboardingHeader';

import type { UseCase } from './Types';
import type { Company } from 'common/api/endpoints/companies';

import 'css/components/subdomain/admin/_AdminOnboarding.scss';

export type BoardInput = {
  name: string;
  url: string;
};

const UpsellBadge = ({ planName }: { planName: string }) => {
  return (
    <div className="upsellBadge">
      <Sparkles size={16} />
      <Span>{planName} Plan</Span>
    </div>
  );
};

const UpsellAlert = ({ useCase }: { useCase: UseCase }) => {
  const requiredPlanID = getPlanIDFromUseCase(useCase);
  if (!requiredPlanID) {
    return null;
  }

  const planName = getPlanNameForTrial({ planID: requiredPlanID });
  return (
    <div className="upsellAlert">
      <div>
        <Sparkles className="alertSparkles" size={18} />
      </div>
      <div className="words">
        <H5>Canny {planName} Plan</H5>
        <P variant="bodyLg">
          Your team will be put on a 14-day trial so you can try {planName} features. No credit card
          required.
        </P>
      </div>
    </div>
  );
};

type Props = {
  board: BoardInput;
  onChange: (useCase: UseCase) => void;
  onChangeBoard: (board: BoardInput) => void;
  useCase: UseCase;
};

const AdminOnboardingCreateBoard = ({ board, onChange, onChangeBoard, useCase }: Props) => {
  // context
  let boards = useContext(BoardsContext);
  const company = useContext<Company>(CompanyContext);

  // Boards could potentially be loading. If so, just chill out for a bit
  if (!Array.isArray(boards)) {
    boards = [];
  }

  // helpers
  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    const url = urlNamify(name);
    onChangeBoard({
      name,
      url,
    });
  };

  const firstBoard = boards[0];
  const { customAccess, private: isPrivate, indexed } = useCase;
  return (
    <div className="onboardingCreateBoard">
      <AdminOnboardingHeader>Let’s set up your first board!</AdminOnboardingHeader>
      <AdminOnboardingSubHeader>
        Boards represent a specific project/topic where people can post, comment, and vote on ideas.
      </AdminOnboardingSubHeader>
      <div className="inputs">
        <TextInput
          defaultValue={board.name}
          disabled={!!firstBoard}
          inset="name"
          onChange={onChangeName}
          placeholder={firstBoard ? firstBoard.name : 'e.g. Feature Requests'}
        />
        <URLNameInput
          company={company}
          defaultValue={board.url}
          disabled={!!firstBoard}
          input={board.name}
          inset="url"
          onChange={(url: string) => {
            onChangeBoard({
              ...board,
              url,
            });
          }}
          placeholder={firstBoard ? firstBoard.urlName : null}
        />
      </div>
      <div className="line" />
      <AdminOnboardingHeaderTwo>Privacy Settings</AdminOnboardingHeaderTwo>
      <AdminOnboardingSubHeader>
        Private boards have restricted access, while public boards are visible to anyone with the
        link.
      </AdminOnboardingSubHeader>
      <div className="privateToggle">
        <P variant="bodyLg">I want this board to be</P>
        <TextToggle
          onChange={(value: string) =>
            onChange({
              ...useCase,
              customAccess: false,
              indexed: false,
              private: value === BoardAccess.private,
            })
          }
          options={[
            { value: BoardAccess.private, label: 'Private' },
            { value: BoardAccess.public, label: 'Public' },
          ]}
          selected={isPrivate ? BoardAccess.private : BoardAccess.public}
        />
      </div>
      {isPrivate ? (
        <div className="indexedToggle">
          <P variant="bodyLg">I want to grant access to specific users</P>
          <SwitchV2
            checked={customAccess}
            onChange={(customAccess: boolean) => onChange({ ...useCase, customAccess })}
            size="medium"
          />
          <UpsellBadge planName="Growth" />
        </div>
      ) : (
        <div className="indexedToggle">
          <P variant="bodyLg">Allow search engines to index this board</P>
          <SwitchV2
            checked={indexed}
            onChange={(indexed: boolean) => onChange({ ...useCase, indexed })}
            size="medium"
          />
          <UpsellBadge planName="Starter" />
        </div>
      )}
      <UpsellAlert useCase={useCase} />
    </div>
  );
};

export default AdminOnboardingCreateBoard;
