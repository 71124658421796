import React, { cloneElement, forwardRef, isValidElement } from 'react';

import { Slot, Slottable } from '@radix-ui/react-slot';
import classnames from 'classnames';

import SpinnerV2 from 'common/SpinnerV2';

import type { LucideProps } from 'lucide-react';
import 'css/components/_ButtonV2.scss';

// Design System Button Styles https://www.figma.com/file/MKc5gEytDNyueOf4TXtxZs/Canny-UI-Kit?type=design&node-id=1200-2094&t=UtWrfBBDu2oV9LlV-0
// 1. primary contained
// 2. error contained
// 3. primary outlined
// 4. error outlined
// 5. primary ghost
// 6. error ghost
// 7. primary plain
// 8. error plain

type Size = 'small' | 'medium' | 'large';

const IconSizes = {
  small: 14,
  medium: 16,
  large: 18,
} as const;

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  asChild?: boolean;
  color?: 'primary' | 'error' | 'success';
  endIcon?: React.ComponentType<LucideProps>;
  iconSize?: number;
  iconStrokeWidth?: number;
  loading?: boolean;
  size: Size;
  startIcon?: React.ComponentType<LucideProps>;
  variant?: 'contained' | 'outlined' | 'plain' | 'ghost' | 'toggleOff' | 'toggleOn';
}

const ButtonV2 = forwardRef<HTMLButtonElement, Props>(
  (
    {
      asChild,
      children,
      className,
      color = 'primary',
      disabled,
      endIcon: EndIcon,
      iconSize,
      iconStrokeWidth,
      loading,
      onClick,
      size = 'medium',
      startIcon: StartIcon,
      type = 'button',
      variant = 'contained',
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button';
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      if (disabled) {
        return;
      }

      onClick?.(e);
    };

    let child = <span className="buttonLabel">{children}</span>;
    if (asChild && isValidElement(children)) {
      child = cloneElement(
        children,
        children.props,
        <span className="buttonLabel">{children.props.children}</span>
      );
    }

    return (
      <Comp
        ref={ref}
        aria-disabled={disabled}
        className={classnames(className, 'buttonV2', {
          [color]: true,
          [size]: true,
          [variant]: true,
          disabled,
          loading,
        })}
        onClick={handleClick}
        type={type}
        {...props}>
        {loading && <SpinnerV2 size={size} />}
        {!loading && StartIcon && (
          <StartIcon
            strokeWidth={iconStrokeWidth ?? 1}
            className="buttonIcon"
            size={iconSize ?? IconSizes[size]}
          />
        )}
        <Slottable>{child}</Slottable>
        {!loading && EndIcon && (
          <EndIcon
            strokeWidth={iconStrokeWidth ?? 1}
            className="buttonIcon"
            size={iconSize ?? IconSizes[size]}
          />
        )}
      </Comp>
    );
  }
);

export default ButtonV2;
