import { useInView } from 'react-intersection-observer';

import AJAX from 'common/AJAX';

import type { Company } from 'common/api/endpoints/companies';
import type { Viewer } from 'common/api/endpoints/viewer';

const useViewTracking = (
  viewer: Viewer,
  objectType: string,
  objectID: string,
  company: Company,
  logView: boolean
) => {
  const { ref } = useInView({
    triggerOnce: true,
    onChange: async (inView) => {
      if (inView && logView) {
        const url = '/api/viewAnalytics/create';

        await AJAX.post(url, {
          company,
          objectID,
          objectType,
          viewer,
        });
      }
    },
  });

  return ref;
};

export default useViewTracking;
