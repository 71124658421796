import isServer from 'common/util/isServer';

import { Error, NotFound, type ServerResponseAction } from '../actions/serverResponse';

type ServerResponseState = {
  status?: 404 | 500;
};

// this reducer is used on the server to tell it which http response code to respond with
// if nothing is set, it will default to 200
export default function serverResponse(
  state: ServerResponseState = {},
  action: ServerResponseAction
) {
  // only used on server for http response code, skip on client
  if (!isServer()) {
    return state;
  }

  switch (action.type) {
    case Error: {
      return Object.assign({}, state, {
        status: 500,
      });
    }

    case NotFound: {
      return Object.assign({}, state, {
        status: 404,
      });
    }

    default:
      return state;
  }
}
