import React from 'react';

import isGrowth from 'common/util/isGrowth';
import isStarter from 'common/util/isStarter';
import styles from 'css-module/components/subdomain/admin/v2/AdminV2Notices/_AdminV2TrialNotice.module.scss';

import type { Company } from 'common/api/endpoints/companies';

const AdminV2TrialNotice = ({ plan }: { plan: NonNullable<Company['trialingPlan']> }) => {
  const trialEndMilliseconds = new Date(plan.end).getTime();
  const nowMilliseconds = new Date().getTime();

  const dayMilliseconds = 1000 * 60 * 60 * 24;
  const millisecondsTilTrialEnds = trialEndMilliseconds - nowMilliseconds;
  const daysTilTrialEnds = Math.round(millisecondsTilTrialEnds / dayMilliseconds);
  const dayOrDays = daysTilTrialEnds === 1 ? 'day' : 'days';

  let planName = 'plan';
  if (plan.planID === 'business-trial-1') {
    planName = 'business';
  } else if (isGrowth(plan.planID)) {
    planName = 'growth';
  } else if (isStarter(plan.planID)) {
    planName = 'starter';
  }

  return (
    <div className={styles.trialCountdown}>
      <span className={styles.callTo}>
        {daysTilTrialEnds} {dayOrDays} left on your {planName} trial
      </span>
    </div>
  );
};

export default AdminV2TrialNotice;
