import React from 'react';

import type { ViewDraft } from './AdminRoadmapViewSettingsModal/SavedViewUtils';

type RoadmapFilterContextType = {
  setViewDraft: (viewDraft: ViewDraft) => void;
};

export const RoadmapFilterContext = React.createContext<RoadmapFilterContextType>({
  setViewDraft: () => {},
});

export default RoadmapFilterContext;
