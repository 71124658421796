import type { Dispatch } from 'redux';

// Action Types

export const Error = 'canny/server_response/error';
export const NotFound = 'canny/server_response/not_found';

// Actions

export type ServerResponseAction = {
  timestamp: number;
  type: typeof Error | typeof NotFound;
};

function error() {
  return {
    timestamp: Date.now(),
    type: Error,
  };
}

function notFound() {
  return {
    timestamp: Date.now(),
    type: NotFound,
  };
}

// Action Creators

export function respondError() {
  return (dispatch: Dispatch) => {
    return dispatch(error());
  };
}

export function respondNotFound() {
  return (dispatch: Dispatch) => {
    return dispatch(notFound());
  };
}
