import {
  ErrorType,
  InvalidateType,
  LoadedMoreType,
  LoadedType,
  type QueryParams,
  RequestMoreType,
  RequestType,
} from 'common/actions/viewQueries';

import queryReducerFactory, { type QueriesState, type QueryState } from './queryReducerFactory';
export { defaultState } from './queryReducerFactory';

import type { View } from 'common/api/endpoints/view';

export type ViewQueriesState = QueriesState<View, QueryParams>;
export type ViewQueryState = QueryState<View, QueryParams>;

export default queryReducerFactory<View, QueryParams>({
  errorType: ErrorType,
  invalidateType: InvalidateType,
  loadedMoreType: LoadedMoreType,
  loadedType: LoadedType,
  requestMoreType: RequestMoreType,
  requestType: RequestType,
});
