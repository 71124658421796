import React, { useState } from 'react';

import { PartyPopper, StickyNote } from 'lucide-react';

import UpsellModal from 'common/modals/UpsellModal';
import { dayjs } from 'common/util/dayjsUtils';
import { isNotNil } from 'common/util/isNil';
import { StarterPlanID } from 'common/util/isStarter';
import styles from 'css-module/components/subdomain/admin/v2/AdminV2Notices/_AdminV2PostLimitNotice.module.scss';

import type { Company } from 'common/api/endpoints/companies';

enum Modal {
  Upsell = 'upsell',
}

const AdminV2PostLimitNotice = ({
  limits,
  stats,
}: {
  limits: Company['limits'];
  stats: Company['stats'];
}) => {
  const { activePostCount, activePostLimitLockoutAt } = stats;
  const [modal, setModal] = useState<Modal | null>(null);

  const Upsell =
    modal === Modal.Upsell ? (
      <UpsellModal
        cta="Unlimited active posts"
        feature="limits.activePosts"
        onClose={() => setModal(null)}
        onUpsell={() => setModal(null)}
        show={modal === Modal.Upsell}
        planID={StarterPlanID}
      />
    ) : null;

  if (isNotNil(limits.activePosts) && activePostCount > limits.activePosts) {
    let countdown = 'within a few days';
    if (activePostLimitLockoutAt) {
      const lockoutAt = dayjs(activePostLimitLockoutAt);
      const now = dayjs();
      const daysTil = lockoutAt.diff(now, 'days');
      countdown = daysTil > 1 ? `within ${daysTil} days` : 'today';
    }

    return (
      <div className={styles.postLimit}>
        <PartyPopper className={styles.icon} size={12} />
        <span className={styles.message}>
          You've reached {activePostCount}/100 active posts! Mark posts as complete {countdown} or
          upgrade for limitless posts!
        </span>
        <div className={styles.middot}>&middot;</div>
        <button className={styles.button} onClick={() => setModal(Modal.Upsell)}>
          <span className={styles.action}>Get More Time</span>
        </button>
        {Upsell}
      </div>
    );
  } else if (isNotNil(limits.activePosts) && activePostCount > limits.activePosts * 0.75) {
    const postsLeft = limits.activePosts - activePostCount;
    return (
      <div className={styles.postLimit}>
        <StickyNote className={styles.icon} size={12} />
        <span className={styles.message}>
          You're growing! You can track {postsLeft} more posts for free
        </span>
        <div className={styles.middot}>&middot;</div>
        <button className={styles.button} onClick={() => setModal(Modal.Upsell)}>
          <span className={styles.action}>Get More</span>
        </button>
        {Upsell}
      </div>
    );
  }

  return Upsell;
};

export default AdminV2PostLimitNotice;
