import promisify from 'common/util/promisify';

import { loadCompany } from './company';
import requestActionFactory from './requestActionFactory';
import Data from '../Data';

import type { AutopilotCounts } from 'common/api/endpoints/autopilot';
import type { Dispatch, GetState, State } from 'redux-connect';

const {
  requestAction: autopilotCountsRequested,
  loadedAction: autopilotCountsLoaded,
  errorAction: autopilotCountsError,

  RequestType,
  LoadedType,
  ErrorType,
} = requestActionFactory<AutopilotCounts>('autopilotCounts');

export { RequestType, LoadedType, ErrorType };

// Action Creators
function fetchAutopilotCounts() {
  return async (dispatch: Dispatch, getState: GetState) => {
    const { cookies } = getState();
    try {
      const response = await promisify(
        Data.fetch,
        {
          queueItemsCount: {
            query: Data.queries.queueItemsCount,
          },
        },
        cookies
      );

      return dispatch(autopilotCountsLoaded(response.queueItemsCount));
    } catch (e) {
      const message = typeof e === 'string' ? e : 'server error';
      return dispatch(autopilotCountsError(message));
    }
  };
}

export function loadAutopilotCounts() {
  return async (dispatch: Dispatch, getState: GetState) => {
    await dispatch(loadCompany());

    if (!shouldFetch(getState())) {
      return;
    }

    dispatch(autopilotCountsRequested());
    return dispatch(fetchAutopilotCounts());
  };
}

function shouldFetch(state: State) {
  return !state.autopilotCounts.data;
}
