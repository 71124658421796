import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import AJAX from 'common/AJAX';
import * as AuthRequests from 'common/auth/AuthRequests';
import AuthButton from 'common/AuthButton';
import CannyAttribution from 'common/CannyAttribution';
import BoardGrid from 'common/common/BoardGrid';
import { DisconnectAzureContext, DisconnectGoogleContext } from 'common/containers/AuthContainer';
import { CompanyContext } from 'common/containers/CompanyContainer';
import ContentContainer from 'common/containers/ContentContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import SubdomainHomeHelmet from 'common/helmets/SubdomainHomeHelmet';
import withContexts from 'common/util/withContexts';

import EmailVerificationForm from './EmailVerificationForm';
import VerifyEmail from './VerifyEmail';
import RoadmapView from '../RoadmapView';

import 'css/components/subdomain/public/_SubdomainHome.scss';

class SubdomainHome extends Component {
  static propTypes = {
    boards: PropTypes.array,
    company: PropTypes.object,
    disconnectAzure: PropTypes.func,
    disconnectGoogle: PropTypes.func,
    location: PropTypes.object,
    router: PropTypes.object,
    viewer: PropTypes.object,
  };

  componentWillMount() {
    const {
      boardCount,
      changelog: { hasPublishedEntries },
    } = this.props.company;
    if (boardCount > 0 || !hasPublishedEntries) {
      return;
    }

    const { router } = this.props;
    router.replace('/changelog');
  }

  onOAuthFailure = (error) => {
    this.setState({
      error,
    });
  };

  onOAuthSuccess = (oauthData) => {
    const { viewer } = this.props;
    if (viewer.loggedOut) {
      this.login(oauthData);
    } else if (oauthData.azureUserID) {
      this.addAzureDomain(oauthData);
    } else if (oauthData.googleUserID) {
      this.addGoogleDomain(oauthData);
    }
  };

  addAzureDomain = (oauthData) => {
    AJAX.post(
      '/api/viewer/addAzureDomain',
      {
        ...oauthData,
      },
      (response) => {
        if (response === 'success') {
          window.location.reload();
          return;
        }

        var responseObject;
        try {
          responseObject = JSON.parse(response);
        } catch (e) {
          responseObject = { error: 'server error' };
        }

        if (responseObject.error === 'invalid email') {
          const { disconnectAzure } = this.props;
          disconnectAzure();
          this.onOAuthFailure(
            'Invalid email. Please try again and make sure to sign in with your company email account.'
          );
          return;
        }

        this.onOAuthFailure('Something went wrong, please try again later.');
      }
    );
  };

  addGoogleDomain = (oauthData) => {
    AJAX.post(
      '/api/viewer/addGSuiteDomain',
      {
        ...oauthData, // googleUserID, googleIDToken
      },
      (response) => {
        if (response === 'success') {
          window.location.reload();
          return;
        }

        var responseObject;
        try {
          responseObject = JSON.parse(response);
        } catch (e) {
          responseObject = { error: 'server error' };
        }

        if (responseObject.error === 'invalid email') {
          const { disconnectGoogle } = this.props;
          disconnectGoogle();
          this.onOAuthFailure(
            'Invalid email. Please try again and make sure to sign in with your company email account.'
          );
          return;
        }

        this.onOAuthFailure('Something went wrong, please try again later.');
      }
    );
  };

  hasEmailVerification = () => {
    const {
      location: { query },
    } = this.props;

    return !!query.verificationID;
  };

  login = async (oauthData) => {
    const { error, redirecting } = await AuthRequests.login(oauthData);

    if (redirecting) {
      return;
    }

    if (error) {
      if (error.type === 'slow down') {
        this.setState({ error: error.message });
        return;
      }

      this.signup(oauthData);
      return;
    }

    if (oauthData.azureUserID) {
      this.addAzureDomain(oauthData);
    } else if (oauthData.googleUserID) {
      this.addGoogleDomain(oauthData);
    } else {
      this.signup(oauthData);
    }
  };

  signup = async (oauthData) => {
    const { error, redirecting } = await AuthRequests.signup(oauthData);

    if (redirecting) {
      return;
    }

    if (!error) {
      if (oauthData.azureUserID) {
        this.addAzureDomain(oauthData);
      } else {
        this.addGoogleDomain(oauthData);
      }
      return;
    }

    this.onOAuthFailure(error.message);
  };

  renderRoadmapView() {
    if (!this.props.company.showRoadmap) {
      return null;
    }

    const { company, roadmap } = this.props;
    if (!roadmap?.posts) {
      return null;
    } else if (!roadmap.filters && roadmap.posts.length === 0 && !company.viewerIsMember) {
      return null;
    }

    return (
      <div className="roadmapSpacer">
        <RoadmapView roadmap={this.props.roadmap} />
      </div>
    );
  }

  renderVerification() {
    if (this.hasEmailVerification()) {
      return (
        <div className="verification">
          <VerifyEmail />
        </div>
      );
    }

    const { company, viewer } = this.props;
    const verifiedBySet = company.privateDomains.reduce((verifiedBySet, domainEntry) => {
      Object.keys(domainEntry.verifiedBy).forEach((verifiedBy) => {
        verifiedBySet[verifiedBy] = true;
      });
      return verifiedBySet;
    }, {});

    if (verifiedBySet.email) {
      if (viewer.loggedOut) {
        return `${company.name}'s feedback is private. Log in to see if you have\u00a0access.`;
      }
      const allowListedDomain = company.privateDomains
        .map((domainEntry) => {
          return domainEntry.domain;
        })
        .join(', ');
      return (
        <div className="verification">
          <EmailVerificationForm
            shouldHideAllowlistedDomain={company.featureAllowlist.includes(
              'hide_whitelisted_domains'
            )}
            allowListedDomain={allowListedDomain}
          />
        </div>
      );
    } else if (verifiedBySet.azure) {
      return (
        <div className="verification">
          <div className="prompt">
            Log in with your Microsoft Entra ID account to access&nbsp;this&nbsp;page.
          </div>
          <AuthButton
            authType="azure"
            onFailure={this.onOAuthFailure}
            onSuccess={this.onOAuthSuccess}
            value="Log in with Microsoft Entra ID"
          />
        </div>
      );
    } else if (verifiedBySet.gsuite) {
      const domains = company.privateDomains
        .map((domainEntry) => {
          return '@' + domainEntry.domain;
        })
        .join(', ');
      return (
        <div className="verification">
          <div className="prompt">
            Log in with your Google Workspace account ({domains}) to access&nbsp;this&nbsp;page.
          </div>
          <AuthButton
            authType="google"
            onFailure={this.onOAuthFailure}
            onSuccess={this.onOAuthSuccess}
            value="Log in with Google"
          />
        </div>
      );
    } else if (verifiedBySet.okta) {
      return (
        <div className="verification">
          <div className="prompt">Log in with your Okta account to access&nbsp;this&nbsp;page.</div>
          <AuthButton
            additionalData={{
              okta: company.okta,
            }}
            authType="okta"
            onFailure={this.onOAuthFailure}
            onSuccess={this.onOAuthSuccess}
            value="Log in with Okta"
          />
        </div>
      );
    } else if (verifiedBySet.oidc) {
      return (
        <div className="verification">
          <div className="prompt">
            Log in with your OpenID Connect provider to access&nbsp;this&nbsp;page.
          </div>
          <AuthButton
            additionalData={{
              companyID: company._id,
            }}
            authType="oidc"
            onFailure={this.onOAuthFailure}
            onSuccess={this.onOAuthSuccess}
            value="Log in with OpenID Connect"
          />
        </div>
      );
    } else {
      throw new Error('Domains not verified by email or Google');
    }
  }

  render() {
    const { company, viewer } = this.props;
    const havePrivateBoards = company.privateDomains.length > 0 && this.props.boards.length === 0;

    if (company.boardCount === 0) {
      // Company has no boards
      return (
        <ContentContainer outerClassName="subdomainHome">
          <SubdomainHomeHelmet />
          <div className="noBoards">{company.name} isn't collecting feedback&nbsp;yet.</div>
        </ContentContainer>
      );
    } else if (havePrivateBoards || this.hasEmailVerification()) {
      return (
        <ContentContainer outerClassName="subdomainHome">
          <SubdomainHomeHelmet />
          <div className="noBoards">{this.renderVerification()}</div>
          <div className="bottomContainer">
            <CannyAttribution source="roadmap_subdomain" />
          </div>
        </ContentContainer>
      );
    } else if (viewer.loggedOut && this.props.boards.length === 0) {
      // User is logged out and there are no public boards
      return (
        <ContentContainer outerClassName="subdomainHome">
          <SubdomainHomeHelmet />
          <div className="noBoards">
            {company.name}'s feedback is private. Log in to see if you have&nbsp;access.
          </div>
        </ContentContainer>
      );
    } else if (this.props.boards.length === 0) {
      // User doesn't have access to any boards
      return (
        <ContentContainer outerClassName="subdomainHome">
          <SubdomainHomeHelmet />
          <div className="noBoards">You do not have access to {company.name}'s&nbsp;feedback.</div>
        </ContentContainer>
      );
    }

    return (
      <ContentContainer outerClassName="subdomainHome" innerClassName="subdomainHomeContents">
        <SubdomainHomeHelmet />
        <div className="topContainer">
          <BoardGrid boards={this.props.boards} />
          {this.renderRoadmapView()}
        </div>
        <div className="bottomContainer">
          <CannyAttribution source="roadmap_subdomain" />
        </div>
      </ContentContainer>
    );
  }
}

export default compose(
  withContexts({
    company: CompanyContext,
    disconnectAzure: DisconnectAzureContext,
    disconnectGoogle: DisconnectGoogleContext,
    viewer: ViewerContext,
  })
)(SubdomainHome);
