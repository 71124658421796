import React from 'react';

import SpinnerV2 from 'common/SpinnerV2';
import { P } from 'common/ui/Text';
import styles from 'css-module/components/post/_Summary.module.scss';

interface MenuButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  value: string;
  loading?: boolean;
}

const MenuButton = ({ value, loading = false, ...props }: MenuButtonProps) => {
  return (
    <button className={styles.menuButton} {...props}>
      {loading && <SpinnerV2 size="small" />}
      <P variant="bodySm" fontWeight="regular">
        {value}
      </P>
    </button>
  );
};

export default MenuButton;
