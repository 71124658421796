import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import Toggle from 'common/common/Toggle';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import connect from 'common/core/connect';
import publicConfig from 'common/core/publicConfig';
import Helmet from 'common/helmets/Helmet';
import Button from 'common/inputs/Button';
import LazyLoadedImage from 'common/LazyLoadedImage';
import ConfirmModal from 'common/modals/ConfirmModal';
import withAccessControl from 'common/routing/withAccessControl';
import Spinner from 'common/Spinner';
import AdminFeatureBlock from 'common/subdomain/admin/AdminFeatureBlock';
import Alert from 'common/ui/Alert';
import ButtonV2 from 'common/ui/ButtonV2';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import queryString from 'common/util/queryString';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/_AdminAzureSettings.scss';

import AzureLogoIcon from 'img/azure-logo-icon.png';

const AzureClientID = publicConfig('azureClientID');
const RedirectURI = 'https://canny.io/azure-redirect';

class AdminAzureSettings extends Component {
  static propTypes = {
    company: PropTypes.shape({
      _id: PropTypes.string,
      subdomain: PropTypes.string,
    }),
    location: PropTypes.shape({
      query: PropTypes.shape({
        code: PropTypes.string,
      }),
    }),
    openModal: PropTypes.func,
    router: PropTypes.object,
    viewer: PropTypes.object,
  };

  state = {
    code: this.props.location.query.code,
    error: null,
    registering: false,
    savingOffboard: false,
  };

  constructor(props, context) {
    super(props, context);

    this.revokeAdminRef = React.createRef();
    this.revokeBoardRef = React.createRef();
  }

  componentDidMount() {
    const { location, router } = this.props;

    const { code } = this.state;
    if (!code) {
      return;
    }

    router.replace({
      pathname: location.pathname,
    });

    this.setState({
      registering: true,
    });

    AJAX.post(
      '/api/azure/register',
      {
        code,
      },
      (response) => {
        if (response !== 'success') {
          this.setState({
            error: 'Something went wrong, please try again later',
            registering: false,
          });
          return;
        }

        const { reloadCompany } = this.props;
        reloadCompany().then(() => {
          this.setState({
            registering: false,
          });
        });
      }
    );
  }

  getAuthURL = () => {
    const {
      company: { subdomain },
    } = this.props;

    const authURL =
      'https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize' +
      queryString.stringify({
        client_id: AzureClientID,
        domain_hint: 'organizations',
        redirect_uri: RedirectURI,
        response_mode: 'query',
        response_type: 'code',
        scope:
          'offline_access user.read.all group.read.all organization.read.all directory.read.all openid email profile',
        state: JSON.stringify({
          subdomain,
        }),
      });

    return authURL;
  };

  onDisconnect = () => {
    const { openModal } = this.props;
    openModal(ConfirmModal, {
      message: `Are you sure you'd like to disconnect your Microsoft Entra ID account?`,
      onConfirm: () => {
        AJAX.post('/api/azure/disconnect', {}, (response) => {
          if (response !== 'success') {
            this.setState({
              error: 'Something went wrong, please contact our team for support.',
            });
          } else {
            const { reloadCompany } = this.props;
            reloadCompany();
          }
        });
      },
    });
  };

  onSaveOffboard = () => {
    const { savingOffboard } = this.state;
    if (savingOffboard) {
      return;
    }

    const revokeAdminAccess = this.revokeAdminRef.current.getValue();
    const revokeBoardAccess = this.revokeBoardRef.current.getValue();

    this.setState({
      error: null,
      savingOffboard: true,
    });

    AJAX.post(
      '/api/azure/updateOffboardSettings',
      {
        revokeAdminAccess,
        revokeBoardAccess,
      },
      (response) => {
        if (response !== 'success') {
          this.setState({
            error: 'Something went wrong, please try again later',
            savingOffboard: false,
          });
          return;
        }

        const { reloadCompany } = this.props;
        reloadCompany().then(() => {
          this.setState({
            error: null,
            offboardChanged: false,
            savingOffboard: false,
          });
        });
      }
    );
  };

  renderAuthSection() {
    return (
      <div className="authSection">
        <div className="subheading">Authenticate your teammates into private boards</div>
        <div className="text">
          Grant your teammates access to Canny by signing in with their company Microsoft Entra ID
          account.
        </div>
      </div>
    );
  }

  renderConnected() {
    const { registering } = this.state;
    const { azure } = this.props.company;
    if (!azure) {
      return null;
    }

    const authURL = this.getAuthURL();
    return (
      <div className="connectedContainer">
        {azure.lostAccess && (
          <Alert type={Alert.AlertTypes.Danger}>
            <Alert.Heading>Lost access</Alert.Heading>
            <Alert.Content>
              Your Microsoft Entra ID integration has lost access to Canny. Please reinstall
              the&nbsp;integration.
            </Alert.Content>
            <Alert.CTA>
              <ButtonV2 asChild size="medium" loading={registering}>
                <a
                  href={authURL}
                  className="buttonContainer"
                  target="_blank"
                  rel="noreferrer noopener nofollow">
                  Reinstall Microsoft Entra ID
                </a>
              </ButtonV2>
            </Alert.CTA>
          </Alert>
        )}
        <div className="connected">
          <div className="text">
            Your Microsoft Entra ID account ({azure.organizationName}) is connected.
          </div>
          <ButtonV2 variant="outlined" color="primary" size="medium" onClick={this.onDisconnect}>
            Uninstall Microsoft Entra ID
          </ButtonV2>
        </div>
      </div>
    );
  }

  renderOffboardSection() {
    return (
      <div className="offboard">
        <div className="subheading">Revoke access from offboarded&nbsp;employees</div>
        <div className="text">
          This feature lets you revoke access from people once they leave your company. The way it
          works is Microsoft Entra ID notifies us when an Microsoft Entra ID user account is
          deleted, and then we revoke permissions accordingly.
        </div>
        {this.renderOffboardSettings()}
      </div>
    );
  }

  renderOffboardSettings() {
    const {
      company: { azure },
    } = this.props;
    if (!azure) {
      return null;
    }

    const { offboardChanged, savingOffboard } = this.state;
    return (
      <div className="offboardSettings">
        <div className="toggleContainer">
          <Toggle
            defaultValue={azure.revokeAdminAccess}
            onToggle={() => {
              this.setState({ offboardChanged: true });
            }}
            ref={this.revokeAdminRef}
          />
          <div className="label">Revoke admin account</div>
        </div>
        <div className="toggleContainer">
          <Toggle
            defaultValue={azure.revokeBoardAccess}
            onToggle={() => {
              this.setState({ offboardChanged: true });
            }}
            ref={this.revokeBoardRef}
          />
          <div className="label">Revoke private board access</div>
        </div>
        <Button
          disabled={!offboardChanged || savingOffboard}
          loading={savingOffboard}
          onTap={this.onSaveOffboard}
          value={'Save Offboard Settings'}
        />
      </div>
    );
  }

  renderUpsell() {
    const { company } = this.props;
    if (company.azure) {
      return null;
    }

    if (company?.integrations?.azureAD) {
      return null;
    }

    return (
      <AdminFeatureBlock
        feature="Microsoft Entra ID Integration"
        benefit="Authenticate your teammates into Canny."
        showBillingLink={false}
      />
    );
  }

  renderIntegrateButton() {
    const {
      company: { integrations },
    } = this.props;
    if (!integrations?.azureAD) {
      return null;
    }

    const authURL = this.getAuthURL();
    const { registering } = this.state;
    return (
      <div className="disconnected">
        <a
          className="connectButton"
          href={authURL}
          rel="noreferrer noopener nofollow"
          target="_blank">
          <LazyLoadedImage className="logo" src={AzureLogoIcon} alt="Microsoft Logo" />
          {registering ? <Spinner /> : <div className="label">Connect with Microsoft Entra ID</div>}
        </a>
      </div>
    );
  }

  renderError() {
    const { error } = this.state;
    if (!error) {
      return null;
    }

    return <div className="error">{this.state.error}</div>;
  }

  render() {
    const { azure } = this.props.company;
    return (
      <div className="adminAzureSettings">
        <Helmet title="Microsoft Entra ID Integration | Canny" />
        <div className="settingsHeading">Microsoft Entra ID Integration</div>
        <div className="content">
          {this.renderConnected()}
          {this.renderAuthSection()}
          {this.renderOffboardSection()}
          {!azure && this.renderIntegrateButton()}
          {this.renderUpsell()}
          {this.renderError()}
        </div>
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadCompany: (post) => {
      return Promise.all([dispatch(reloadCompany())]);
    },
  })),
  withAccessControl(testEveryPermission(RoutePermissions.integrations.azure), '/admin/settings', {
    forwardRef: true,
  }),
  withContexts(
    {
      company: CompanyContext,
      openModal: OpenModalContext,
    },
    {
      forwardRef: true,
    }
  )
)(AdminAzureSettings);
