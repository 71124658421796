import React, { Component } from 'react';

import { compose } from 'redux';

import { respondNotFound } from 'common/actions/serverResponse';
import BoardsContainer from 'common/containers/BoardsContainer';
import asyncConnect from 'common/core/asyncConnect';
import FourOhFour from 'common/routeComponents/general/FourOhFour';

import ContentContainer from './containers/ContentContainer';
import FourOhFourHelmet from './helmets/FourOhFourHelmet';
import Link from './Link';

import 'css/components/_SubdomainFourOhFour.scss';

class SubdomainFourOhFour extends Component {
  getBoardUrl() {
    const { boards } = this.props;
    const boardsArray = boards?.items ? Object.values(boards.items) : [];
    if (boardsArray.length === 1) {
      return boardsArray[0]?.urlName;
    }
    return null;
  }

  renderContent() {
    const { company } = this.props;
    const isCompanyFound = company && !company.notFound;

    const boardUrl = this.getBoardUrl();

    const companyBoardLink = isCompanyFound && boardUrl ? `/${boardUrl}` : null;

    if (!isCompanyFound) {
      return (
        <ContentContainer innerClassName="subdomainFourOhFour">
          <FourOhFourHelmet />
          <div className="heading">Company Not Found</div>
          <div className="message">There is no such company. Did you enter the right URL?</div>
        </ContentContainer>
      );
    }
    if (!boardUrl) {
      return (
        <ContentContainer innerClassName="subdomainFourOhFour">
          <FourOhFourHelmet />
          <span>Oops! We couldn't find the page you were looking&nbsp;for.</span>
          <span>
            <span>Go back to the </span>
            <Link className="underline" to="/">
              home&nbsp;page
            </Link>
            <span>.</span>
          </span>
        </ContentContainer>
      );
    }
    return <FourOhFour boardUrl={companyBoardLink} />;
  }

  render() {
    return this.renderContent();
  }
}

export default compose(
  asyncConnect(
    [
      BoardsContainer.asyncConnect,
      {
        promise: ({ store: { dispatch } }) => {
          return dispatch(respondNotFound());
        },
      },
    ],
    (state) => ({ boards: state.boards })
  )
)(SubdomainFourOhFour);
