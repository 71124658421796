import React, { type JSX, useState } from 'react';

import classnames from 'classnames';
import { CheckCircle, ClipboardList, DollarSign, MessageSquare, Triangle } from 'lucide-react';

import Pill, { DefaultPillStyles } from 'common/common/Pill';
import Tooltip from 'common/common/Tooltip';
import Truncate from 'common/common/Truncate';
import translateString, {
  type TranslationObject,
  hasTranslation,
} from 'common/i18n/translateString';
import TranslationToggle from 'common/i18n/TranslationToggle';
import plaintext from 'common/markdown/plaintext';
import Timestamp from 'common/Timestamp';
import truncateString from 'common/truncateString';
import Text, { H3, P } from 'common/ui/Text';
import UserLockup from 'common/user/UserLockup';
import abbreviateNumber from 'common/util/abbreviateNumber';

import type { Board } from 'common/api/endpoints/boards';
import type { StrippedUser } from 'common/api/endpoints/users';
import type { Translation } from 'common/api/resources/i18n';
import type { StringFieldKeys } from 'common/types/util';

import 'css/components/subdomain/admin/_AdminQueuePost.scss';

type PostObject = {
  title: string;
  details: string;
};

type Props = {
  className?: string;
  title: string;
  translation?: Translation<PostObject>;
  details: string | JSX.Element | null;
  author?: StrippedUser;
  created?: string;
  board?: Board;
  manualTruncate?: boolean;
  meta?: {
    score: number;
    commentCount: number;
    monthlySpend?: number;
  };
  workaround?: string | null;
  truncate?: boolean;
};

const AdminQueuePost = ({
  title,
  translation,
  className,
  details,
  author,
  created,
  board,
  manualTruncate = false,
  meta,
  workaround,
  truncate = true,
}: Props) => {
  const [hideTranslation, setHideTranslation] = useState<boolean>(false);

  const isDetailsText = typeof details === 'string';
  const detailsText = isDetailsText ? plaintext(details) : details;
  const translationObject: TranslationObject<PostObject> = {
    title,
    details: detailsText,
    translation,
  };
  const translationStringKeys: StringFieldKeys<PostObject>[] = ['title', 'details'];

  const canTranslate = hasTranslation(translationObject, translationStringKeys);
  const translatedTitle = translateString(translationObject, 'title', hideTranslation);
  const translatedDetails = isDetailsText
    ? translateString(translationObject, 'details', hideTranslation)
    : detailsText;
  const formattedDetails =
    isDetailsText && manualTruncate
      ? truncateString(plaintext(translatedDetails), details.length)
      : translatedDetails;

  const toggleTranslation = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setHideTranslation((value) => !value);
  };

  return (
    <section className={classnames('adminQueuePost', className)}>
      <header className="postHeader">
        <H3 variant="bodyMd" fontWeight="medium" className="postTitle">
          {translatedTitle}
        </H3>
        {workaround ? (
          <Tooltip value="Autopilot detected this feature already&nbsp;exists" position="top">
            <Pill pillStyle={DefaultPillStyles.success}>
              <div className="workaroundPill">
                <CheckCircle size={14} strokeWidth={2} />
                <P>Existing feature</P>
              </div>
            </Pill>
          </Tooltip>
        ) : null}
      </header>
      <Text as="div" variant="bodyMd" className="postDetails">
        {truncate ? (
          <Truncate numberOfLines={2}>{formattedDetails}</Truncate>
        ) : (
          <>{formattedDetails}</>
        )}
      </Text>
      <footer className="postFooter">
        <div className="left">
          {author && (
            <>
              <UserLockup containerClassName="user" truncate={true} user={author} />
              <div className="divider" />
            </>
          )}
          <Timestamp format="MMM D, YYYY" className="timestamp" timestamp={created} />
          {board && (
            <>
              <div className="divider" />
              <div className="postBoard">
                <ClipboardList className="icon" />
                <P variant="bodySm" className="boardName">
                  {truncateString(board.name, 25)}
                </P>
              </div>
            </>
          )}
          {canTranslate && (
            <>
              <div className="divider" />
              <TranslationToggle
                className="translationToggle"
                hideTranslation={hideTranslation}
                object={translationObject}
                onToggle={toggleTranslation}
                stringKeys={translationStringKeys}
              />
            </>
          )}
        </div>
        {meta && (
          <div className="right">
            <Text as="div" variant="bodySm" className="meta">
              <Triangle className="icon" />
              {meta.score}
              <div className="divider" />
              <MessageSquare className="icon" />
              {meta.commentCount}
              {meta.monthlySpend ? (
                <>
                  <div className="divider" />
                  <DollarSign className="icon" />
                  {abbreviateNumber(meta.monthlySpend)}
                </>
              ) : null}
            </Text>
          </div>
        )}
      </footer>
    </section>
  );
};

export default AdminQueuePost;
