import React, { Component } from 'react';

import { hot } from 'react-hot-loader/root';

import CannyRouter from 'common/core/CannyRouter';

import SubdomainRoutes from './SubdomainRoutes';

import 'css/subdomain.scss';

class SubdomainApp extends Component {
  render() {
    const { history } = this.props;
    return <CannyRouter history={history} routes={SubdomainRoutes()} />;
  }
}

export default hot(SubdomainApp);
